import React, { Component } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../helpers/PrivateRoute";
import AdministrationLayout from "../../layout/AdminstrationLayout/AdministrationLayout";
import Transactions from "../Transactions/Transactions";
import ActivateCouponDetails from "./ActivateCoupon/ActivateCouponDetails";
import ActivateCouponSuccess from "./ActivateCoupon/ActivateCouponSuccess";
import ActivateForm from "./ActivateCoupon/ActivateForm";
import ChangePassword from "./ChangePassword";
import ChangePasswordSuccess from "./ChangePasswordSuccess";
import Cards from "./CouponEmployees/Cards/Cards";
import Jobs from "./CouponEmployees/Jobs/Jobs";
import OrderCoupons from "./CouponEmployees/orderCoupons/OrderCoupons";
import Dashboard from "./Dashboard/Dashboard";
import RedeemCoupon from "./RedeemCoupon/RedeemCoupon";

export default class Administration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headerTitle: "",
        };

        this.setHeaderTitle = this.setHeaderTitle.bind(this);
    }

    setHeaderTitle(title) {
        this.setState({
            headerTitle: title,
        });
    }

    render() {
        return (
            <AdministrationLayout headerTitle={this.state.headerTitle}>
                <Switch>
                    <PrivateRoute
                        right={5}
                        path={"/administration/redeem-coupon/:barcode?"}
                        type={"redeem"}
                        render={() => <RedeemCoupon setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        path={"/administration/transactions"}
                        type={"transactions"}
                        render={() => <Transactions setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        path={"/administration/change-password"}
                        render={() => <ChangePassword setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        path={"/administration/change-password-success"}
                        render={() => <ChangePasswordSuccess setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={6}
                        path={"/administration/activate-coupon/:barcode?"}
                        type={"activate"}
                        render={() => <ActivateForm setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={6}
                        path={"/administration/activate-coupon-details"}
                        render={() => <ActivateCouponDetails setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={6}
                        path={"/administration/activate-coupon-success"}
                        render={() => <ActivateCouponSuccess setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={2}
                        path={"/administration/employee-coupons/employees"}
                        render={() => <Cards setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={2}
                        path={"/administration/employee-coupons/jobs"}
                        render={() => <Jobs setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={2}
                        path={"/administration/employee-coupons/OrderCoupons"}
                        render={() => <OrderCoupons setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        path={"/administration/"}
                        render={() => <Dashboard setHeaderTitle={this.setHeaderTitle} />}
                    />
                </Switch>
            </AdministrationLayout>
        );
    }
}
