import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import { Typography, Grid, TextField, Button, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";

class ReportMailAddressForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            accountsDepartmentMail: this.props.retailer.informations
                ? this.props.retailer.informations.reportMailingList.length !== 0
                    ? this.props.retailer.informations.reportMailingList[0]
                    : ""
                : "",
            error: false,
            errorText: "",
        };

        this.updateReportMailAddress = this.updateReportMailAddress.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
    }

    updateReportMailAddress(values) {
        let reportList = [values.accountsDepartmentMail];
        let newLocalData = {
            ...this.props.localData,
            reportMailingList: reportList,
        };

        this.props.successCallback(newLocalData, this.props.townData, this.props.stateData);
    }

    handleSkip() {
        let reportList = this.props.localData.reportMailingList;
        reportList.push(this.state.accountsDepartmentMail);
        let newLocalData = {
            ...this.props.localData,
            reportMailingList: reportList,
        };

        this.props.successCallback(newLocalData, this.props.townData, this.props.stateData);
    }

    render() {
        return (
            <div className="NewStoreReportMailAddress">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <Typography variant="h6">Buchhaltungsadresse</Typography>
                <Formik
                    initialValues={{
                        accountsDepartmentMail: this.state.accountsDepartmentMail,
                    }}
                    validationSchema={Yup.object().shape({
                        accountsDepartmentMail: Yup.string()
                            .email("Keine korrekte E-Mail-Adresse")
                            .required("Dieses Feld wird benötigt"),
                    })}
                    onSubmit={(values) => {
                        this.setState({
                            showSpinner: true,
                            error: false,
                            errorText: "",
                        });
                        this.updateReportMailAddress(values);
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="accountsDepartmentMail"
                                        name="accountsDepartmentMail"
                                        label="E-Mail-Adresse"
                                        type="email"
                                        onChange={props.handleChange}
                                        value={props.values.accountsDepartmentMail}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid className="NewStoreStepperButtons" container>
                                    <Grid item xs={6} className="PrevButtons">
                                        <Button
                                            type="button"
                                            onClick={this.props.handlePrevStep}
                                            variant="text"
                                            color="secondary"
                                        >
                                            Zurück
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={this.props.handleCancel}
                                            variant="text"
                                            color="secondary"
                                        >
                                            Abbrechen
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} className="NextButtons">
                                        <Button
                                            disabled={this.state.showSpinner}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Speichern
                                        </Button>
                                        <Button
                                            onClick={this.handleSkip}
                                            disabled={this.state.showSpinner}
                                            type="button"
                                            variant="contained"
                                            color="default"
                                        >
                                            Überspringen
                                        </Button>
                                    </Grid>
                                    {this.state.showSpinner && <CircularProgress size={24} className="ButtonSpinner" />}
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(ReportMailAddressForm);
