import { API, Auth } from "aws-amplify";

export const LOGIN_USER = "LOGIN_USER";

export function loginUser(json) {
    // json.attributes.sub = '259ac87b-2a65-4e7f-8640-c840e58c27f4'
    return {
        type: LOGIN_USER,
        cognitoUser: json,
    };
}

export function userLoginWithEmailAndPassword(email, password) {
    return function (dispatch) {
        return Auth.signIn(email, password).then((user) => dispatch(loginUser(user)));
    };
}

export const TOGGLE_QUICK_REDEEM_MODE = "TOGGLE_QUICK_REDEEM_MODE";
export function toggleQuickRedeemMode(quickRedeemModeEnabled) {
    return {
        type: TOGGLE_QUICK_REDEEM_MODE,
        quickRedeemModeEnabled: quickRedeemModeEnabled,
    };
}

export const TOGGLE_USER_SCAN_TUTORIAL = "TOGGLE_USER_SCAN_TUTORIAL";
export function toggleUserTutorial(scanTutorialEnabled) {
    return {
        type: TOGGLE_USER_SCAN_TUTORIAL,
        scanTutorialEnabled: scanTutorialEnabled,
    };
}

export function updateEmployeeSettings(cognitoId, scanTutorialEnabled, quickRedeemModeEnabled) {
    const myInit = {
        body: {
            cognitoId: cognitoId,
            scanTutorialEnabled: scanTutorialEnabled,
            quickRedeemModeEnabled: quickRedeemModeEnabled,
        },
    };
    return function (dispatch) {
        return API.post("employee", "/updateEmployeeSettings", myInit)
            .then((response) => {
                dispatch(toggleUserTutorial(scanTutorialEnabled));
                dispatch(toggleQuickRedeemMode(quickRedeemModeEnabled));
            })
            .catch((err) => console.log(err));
    };
}

export function getCurrentAuthenticatedUser() {
    return function (dispatch) {
        return Auth.currentAuthenticatedUser().then((user) => dispatch(loginUser(user)));
    };
}

export function updateEmployeeRights(cognitoId, retailersId, Rights) {
    const myInit = {
        body: { cognitoId: cognitoId, retailersId: retailersId, Rights: Rights },
    };
    return function (dispatch) {
        return API.post("employee", "/retailer/updateEmployee", myInit).catch((err) => console.log(err));
    };
}

export function userRegisterWithEmailAndPassword(username, password, attributes) {
    return function (dispatch) {
        return Auth.signUp({ username, password, attributes });
    };
}

export function userConfirmRegistrationWithCode(username, code) {
    return function (dispatch) {
        return Auth.confirmSignUp(username, code);
    };
}

export function userResendConfirmationCode(username) {
    return function (dispatch) {
        return Auth.resendSignUp(username);
    };
}

export const RECEIVE_USERCOORDINATES = "RECEIVE_USERCOORDINATES";
export function receiveUserCoordinates(json) {
    return {
        type: RECEIVE_USERCOORDINATES,
        coordinates: json,
    };
}

export function setUserCoordinates(coordinates) {
    return function (dispatch) {
        return dispatch(receiveUserCoordinates(coordinates));
    };
}

export function getRetailerInformationFromDbWithCognitoId(cognitoId = "") {
    const myInit = {
        body: { cognitoId: cognitoId },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getRetailerInformations", myInit).then((json) => {
            dispatch(receiveRetailerFromDB(json));
        });
    };
}
export const RECEIVE_RETAILERINFORMATIONFROMDB = "RECEIVE_RETAILERINFORMATIONFROMDB";
export function receiveRetailerFromDB(json) {
    return {
        type: RECEIVE_RETAILERINFORMATIONFROMDB,
        retailer: json.body,
    };
}

export function getEmployeeWithCognitoId(cognitoId) {
    const myInit = {
        body: { cognitoId: cognitoId },
    };
    return function (dispatch) {
        return API.post("employee", "/retailer/getEmployee", myInit)
            .then((json) => dispatch(receiveEmployee(json)))
            .catch((err) => console.log(err));
    };
}
export const RECEIVE_EMPLOYEE = "RECEIVE_EMPLOYEE";
export function receiveEmployee(json) {
    return {
        type: RECEIVE_EMPLOYEE,
        employee: json,
    };
}

export function createEmployeeWithCognitoId(cognitoId, Retailers, surname, forename) {
    const myInit = {
        body: { cognitoId: cognitoId, Retailers: Retailers, surname: surname, forename: forename },
    };
    return function (dispatch) {
        return API.post("employee", "/retailer/createEmployeeWithCognitoId", myInit);
    };
}

export function addEmployeeToRetailer(cognitoId, QRCode) {
    const myInit = {
        body: { cognitoId: cognitoId, QRCode: QRCode },
    };
    return function (dispatch) {
        return API.post("employee", "/retailer/addEmployeeToRetailer", myInit).then((response) =>
            dispatch(receiveEmployee(response))
        );
    };
}

export function acceptAGB(retailersId) {
    const myInit = {
        body: { retailersId: retailersId },
    };
    return function (dispatch) {
        return API.post("employee", "/retailer/acceptAGB", myInit);
    };
}

export const REMOVE_USER = "REMOVE_USER";
export function removeUser() {
    return {
        type: REMOVE_USER,
    };
}
