import React, { Component } from 'react'
import ChangePasswordForm from '../../helpers/ChangePasswordForm'
import { Box, Grid, Paper } from '@material-ui/core'

export default class ChangePassword extends Component {
    componentDidMount() {
        //this.props.setHeaderTitle('Passwort ändern')
    }

    render() {
        return (
            <Box className="RedeemCoupon">
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={12} sm={12} md={7} lg={5} xl={3}>
                        <Box m={2}>
                            <Paper>
                                <Box p={2}>
                                    <Grid container spacing={2} alignItems="center" justify='space-between'>
                                        <Grid item style={{ flexGrow: 1 }}>
                                            <ChangePasswordForm />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}
