import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import "./SkipStepWarning.scss";

export default function SkipStepWarning({ visible, onHiding, onSkip, children, title = "Überspringen" }) {
    return (
        <Dialog className="skipStepWarning" open={visible} onBackdropClick={onHiding} onClose={onHiding}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button onClick={onHiding} color="default">
                    Abbrechen
                </Button>
                <Button type="submit" color="secondary" onClick={onSkip}>
                    Überspringen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
