import { Box, Grid, IconButton, List, ListItem, ListItemText, Paper, TextField } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

export default function day(props) {
    if (props.opening.length === 0)
        return (
            <React.Fragment>
                <Box m={1} />
                <Paper elevation={1} style={{ width: "300px", minHeight: "230px", display: "block" }}>
                    {" "}
                    {props.day}
                    <List>
                        <ListItemText primary="Geschlossen" />
                    </List>
                    <Box m={14} />
                    <IconButton
                        variant="contained"
                        color="secondary"
                        style={{ align: "bottom" }}
                        onClick={(day) => props.handleAdd(props.day)}
                    >
                        <AddCircleIcon />
                    </IconButton>
                </Paper>
            </React.Fragment>
        );
    else
        return (
            <div>
                <Box m={1} />
                <Paper
                    elevation={1}
                    style={{ width: "300px", alignContent: "center", minHeight: "230px", display: "block" }}
                >
                    {" "}
                    {props.day}
                    {props.opening.map((time, index) => {
                        return (
                            <div key={index}>
                                <List>
                                    <ListItem>
                                        <ListItemText />
                                        <IconButton
                                            color="secondary"
                                            onClick={(day, index) =>
                                                props.handleDelete(props.day, props.opening.indexOf(time))
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                        <Box m={1} />
                                        <TextField
                                            style={{ width: "90px" }}
                                            id={props.changeID + "Open"}
                                            type="time"
                                            label="Öffnung"
                                            value={props.opening[props.opening.indexOf(time)]}
                                            onChange={(event) => props.handleChange(event, props.opening.indexOf(time))}
                                        />
                                        <Box m={1} />
                                        <TextField
                                            style={{ width: "90px" }}
                                            id={props.changeID + "Close"}
                                            type="time"
                                            label="Schließung"
                                            value={props.closing[props.opening.indexOf(time)]}
                                            onChange={(event) => {
                                                props.handleChange(event, props.opening.indexOf(time));
                                            }}
                                        />
                                    </ListItem>
                                </List>
                                <Box m={props.opening.length >= 2 ? 0 : 10} />
                            </div>
                        );
                    })}
                    <Grid container justify="center" alignItems="center" alignContent="flex-end">
                        <IconButton
                            variant="contained"
                            color="secondary"
                            style={{ align: "bottom" }}
                            onClick={(day) => props.handleAdd(props.day)}
                        >
                            <AddCircleIcon />
                        </IconButton>
                    </Grid>
                </Paper>
            </div>
        );
}
