import { TextField, IconButton, Typography, Modal } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import ReceiptIcon from '@material-ui/icons/Receipt';
import EditIcon from '@material-ui/icons/Edit';
import numeral from "numeral";
import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import { useSelector } from "react-redux";
import { useFilters, useTable } from "react-table";
import { globalConfig } from "../../../../helpers/globalConfig";

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
        <TextField
            variant="outlined"
            size="small"
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Durchsuche ${count} Einträge...`}
        />
    );
}

// function IntervalFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
//     // Calculate the options for filtering
//     // using the preFilteredRows
//     const options = React.useMemo(() => {
//         const options = new Set();
//         preFilteredRows.forEach((row) => {
//             options.add(row.values[id]);
//         });
//         return [...options.values()];
//     }, [id, preFilteredRows]);

//     let value = filterValue === undefined ? "" : filterValue;
//     // Render a multi-select box
//     return (
//         <FormControl variant="outlined" size="small">
//             <Select
//                 displayEmpty
//                 value={value}
//                 onChange={(e) => {
//                     setFilter(e.target.value || undefined);
//                 }}
//             >
//                 <MenuItem key="0" value="">
//                     Alle
//                 </MenuItem>
//                 {options.map((option, i) => (
//                     <MenuItem key={i} value={option}>
//                         {getIntervalLabel(option)}
//                     </MenuItem>
//                 ))}
//             </Select>
//         </FormControl>
//     );
// }

// function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
//     // Calculate the options for filtering
//     // using the preFilteredRows
//     const options = React.useMemo(() => {
//         const options = new Set();
//         preFilteredRows.forEach((row) => {
//             options.add(row.values[id]);
//         });
//         return [...options.values()];
//     }, [id, preFilteredRows]);

//     // Render a multi-select box
//     return (
//         <FormControl variant="outlined" size="small">
//             <Select
//                 displayEmpty
//                 value={filterValue}
//                 onChange={(e) => {
//                     setFilter(e.target.value || undefined);
//                 }}
//                 defaultValue=""
//             >
//                 <MenuItem value="">Alle</MenuItem>
//                 {options.map((option, i) => (
//                     <MenuItem key={option} value={option}>
//                         {option}
//                     </MenuItem>
//                 ))}
//             </Select>
//         </FormControl>
//     );
// }

// function getIntervalLabel(key) {
//     var intervalList = [
//         { label: "jährlich", key: 1 },
//         { label: "vierteljährlich", key: 4 },
//         { label: "halbjährlich", key: 2 },
//         { label: "monatlich", key: 12 },
//     ];
//     var label = intervalList.map((interval) => {
//         if (interval.key === key) {
//             return interval.label;
//         } else {
//             return "";
//         }
//     });

//     return label;
// }

function totalChargingValue(cardNumbers) {
    let result = 0;
    let i = 0;

    while (cardNumbers[i]) {
        result += cardNumbers[i].value;
        i++;
    }

    let resultFormated = numeral(result).format(globalConfig.currencyFormat);

    return resultFormated;
}

function Table({ columns, data, toggleHelperText, showHelperText, showChargings, openEditCharging }) {
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters
    );

    // Render the UI for your table
    return (
        <React.Fragment>
            <MaUTable {...getTableProps()} size="small">
                <TableHead style={{ backgroundColor: grey["200"] }}>
                    {headerGroups.map((headerGroup, i) => (
                        <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => 
                                {if (column.Header === "maximaler Gesamtbetrag") {
                                    return (<TableCell {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                        <div className={column.canFilter ? "" : "noFilter"}>
                                            {column.canFilter ? column.render("Filter") : null}
                                        </div>
                                        <InfoIcon
                                            style={{ fontSize: 18 }}
                                            onClick={() => toggleHelperText()}
                                        />
                                    </TableCell>)
                                }
                                else {
                                    return (<TableCell {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                        <div className={column.canFilter ? "" : "noFilter"}>
                                            {column.canFilter ? column.render("Filter") : null}
                                        </div>
                                    </TableCell>)
                                }
                            })}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        if (row.original.startDate) {
                            prepareRow(row);
                            return (
                                <TableRow key={i} {...row.getRowProps()} style={{backgroundColor: moment.unix(row.original.nextExecutionDate) > moment() ? "" : "#688470"}}>
                                    {row.cells.map((cell) => {
                                        if (cell.column.id === "ordersId") {
                                            return (
                                                <TableCell {...cell.getCellProps()}>
                                                    <IconButton
                                                        title={"Abrechnungen"}
                                                        color="primary"
                                                        component="span"
                                                        onClick={() => showChargings(cell.row.original.chargingsId, cell.row.original.chargingNumber)}
                                                    >
                                                        <ReceiptIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        title={"Bearbeiten"}
                                                        color="primary"
                                                        component="span"
                                                        onClick={() => openEditCharging(cell.row.original)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            );
                                        }
                                        if (cell.column.Header === "Anzahl Gutscheine")
                                            return (
                                                <TableCell {...cell.getCellProps()}>
                                                    {cell.row.original.cardNumbers.length}
                                                </TableCell>
                                            );
                                        if (cell.column.Header === "maximaler Gesamtbetrag")
                                            return (
                                                <TableCell {...cell.getCellProps()}>
                                                    {totalChargingValue(cell.row.original.cardNumbers)}
                                                </TableCell>
                                            );
                                        if (cell.column.id === "startDate")
                                            return (
                                                <TableCell {...cell.getCellProps()}>
                                                    {moment.unix(cell.row.values.startDate).format("DD.MM.YYYY")}
                                                </TableCell>
                                            );
                                        if (cell.column.id === "lastDate")
                                            return (
                                                <TableCell {...cell.getCellProps()}>
                                                    {cell.row.values.lastDate
                                                        ? moment.unix(cell.row.values.lastDate).format("DD.MM.YYYY")
                                                        : ""}
                                                </TableCell>
                                            );
                                        return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>;
                                    })}
                                </TableRow>
                            );
                        } else {
                            return null;
                        }
                    })}
                </TableBody>
            </MaUTable>
            <Modal
                    open={showHelperText}
                    onBackdropClick={toggleHelperText}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            width: 500,
                            padding: "20px",
                            borderRadius: "13px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <InfoIcon style={{ marginRight: 20 }} />
                        <Typography>Bei jeder Ausführung werden nur aktive Mitarbeiter berücksichtigt. Daher kann der Abrechnungsbetrag geringer ausfallen als hier angezeigt.</Typography>
                    </div>
                </Modal>
        </React.Fragment>
    );
}

function OrdersTable(props) {
    const columns = React.useMemo(
        () => [
            {
                Header: "Auftragsnummer",
                disableFilters: true,
                accessor: "chargingNumber"
            },
            {
                Header: "Anzahl Gutscheine",
                disableFilters: true,
            },
            {
                Header: "maximaler Gesamtbetrag",
                disableFilters: true,
            },
            {
                Header: "Kommentar",
                accessor: "comment",
                disableFilters: true,
            },
            {
                Header: "Startdatum",
                accessor: "startDate",
                disableFilters: true,
            },
            {
                Header: "letzte Ausführung",
                accessor: "lastDate",
                disableFilters: true,
            },
            {
                accessor: "ordersId",
                disableFilters: true,
            },
        ],
        []
    );

    const data = useSelector((state) => props.data);

    return <Table columns={columns} data={data} toggleHelperText={props.toggleHelperText} showHelperText={props.showHelperText} showChargings={props.showChargings} openEditCharging={props.openEditCharging}/>;
}

export default OrdersTable;
