import { Paper, Typography } from "@material-ui/core";
import { CardMembership } from "@material-ui/icons";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import TransformIcon from "@material-ui/icons/Transform";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { userHasRight } from "../../../helpers/UserRights";
import CheckCouponDialog from "../CheckCoupon/CheckCouponDialog";
import "./Dashboard.scss";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            animationClass: "",
            openCheckCoupon: false,
        };

        this.handleOpenCheckCoupon = this.handleOpenCheckCoupon.bind(this);
        this.handleCloseCheckCoupon = this.handleCloseCheckCoupon.bind(this);
    }

    componentDidMount() {
        this.props.setHeaderTitle("Dashboard");
        var self = this;
        this.animationTimeout = setTimeout(() => {
            self.setState({
                animationClass: "revealed",
            });
        }, 300);
    }

    componentWillUnmount() {
        if (this.animationTimeout) {
            clearTimeout(this.animationTimeout);
        }
    }

    handleOpenCheckCoupon() {
        this.setState({
            openCheckCoupon: true,
        });
    }

    handleCloseCheckCoupon() {
        this.setState({
            openCheckCoupon: false,
        });
    }

    render() {
        return (
            <div className="tilesContainerWrapper">
                <div id="tilesContainer">
                    <div className="tiles">
                        <div className={this.state.animationClass}>
                            {this.props.user && this.props.retailer && this.props.retailer.informations && (
                                <Link to="/administration/transactions">
                                    <Paper>
                                        <Typography variant="body1">
                                            <TransformIcon /> <span>Trans&shy;ak&shy;tio&shy;nen</span>
                                        </Typography>
                                    </Paper>
                                </Link>
                            )}
                            {this.props.user &&
                                userHasRight(6) &&
                                this.props.retailer &&
                                this.props.retailer.informations && (
                                    <Link to="/administration/activate-coupon">
                                        <Paper>
                                            <Typography variant="body1">
                                                <PlaylistAddIcon />{" "}
                                                <span>Gut&shy;schei&shy;ne &shy;ak&shy;ti&shy;vie&shy;ren</span>
                                            </Typography>
                                        </Paper>
                                    </Link>
                                )}
                            {this.props.user &&
                                userHasRight(5) &&
                                this.props.retailer &&
                                this.props.retailer.informations && (
                                    <Link to="/administration/redeem-coupon">
                                        <Paper>
                                            <Typography variant="body1">
                                                <PlaylistAddCheckIcon />{" "}
                                                <span>Gut&shy;schei&shy;ne &shy;ein&shy;lö&shy;sen</span>
                                            </Typography>
                                        </Paper>
                                    </Link>
                                )}
                            {this.props.user && userHasRight(5) && (
                                <Link to="#" onClick={this.handleOpenCheckCoupon}>
                                    <Paper>
                                        <Typography variant="body1">
                                            <CardMembership /> <span>Gut&shy;schein&shy;wert</span>
                                        </Typography>
                                    </Paper>
                                </Link>
                            )}
                            {/* {this.props.user && (
                                <Link to="/administration/employee-coupons/employees">
                                    <Paper>
                                        <Typography variant="body1">
                                            <PaymentIcon /> <span>Gut&shy;ha&shy;ben&shy;kar&shy;ten</span>
                                        </Typography>
                                    </Paper>
                                </Link>
                            )} */}
                        </div>
                    </div>
                </div>
                <CheckCouponDialog
                    openCheckCoupon={this.state.openCheckCoupon}
                    handleCloseCheckCoupon={this.handleCloseCheckCoupon}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { retailer, user } = state;
    return {
        retailer,
        user,
    };
}

export default connect(mapStateToProps)(withRouter(Dashboard));
