import { Box, Card, CardContent, Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import logo from "../../img/logo.png";
import "./Account.scss";
import AddToRetailer from "./AddToRetailer";
import ChangePassword from "./ChangePassword";
import ChooseRetailer from "./ChooseRetailer";
import Confirm from "./Confirm";
import Login from "./Login";
import NewEmployee from "./NewEmployee";
import NewStore from "./NewStore";
import NewStoreBillingInformation from "./NewStoreBillingInformation";
import NewStoreManagingDirector from "./NewStoreManagingDirector";
import NewStoreReportMailAddress from "./NewStoreReportMailAddress";
import NewStoreUploadTradingLicence from "./NewStoreUploadTradingLicence";
import Registration from "./Registration";
import RequestChangePassword from "./RequestChangePassword";
import ChangePasswordSuccess from "./ChangePasswordSuccess";
import ForceNewPassword from "./ForceNewPassword";
import { Auth } from "aws-amplify";
import { store } from "../../store/store";
import { removeUser } from "../../store/actions/userAction";
import NewStoreContactPerson from "./NewStoreContactPerson";

export default class Account extends Component {
    componentDidMount() {
        Auth.currentAuthenticatedUser().catch(() => store.dispatch(removeUser()));
    }

    render() {
        return (
            <Grid container className="Account" alignItems="center" justify="center">
                <Grid item xs={12} sm={4} md={4} lg={3}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box mb={4}>
                                <img className="Logo" src={logo} alt="KeepLocal: Von hier. Für Dich." />
                            </Box>
                            <Card>
                                <CardContent>
                                    <Switch>
                                        <Route exact path={`${this.props.match.path}/`} component={Login} />
                                        <Route
                                            path={`${this.props.match.path}/registration`}
                                            component={Registration}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/request-password-change`}
                                            component={RequestChangePassword}
                                        />
                                        <Route path={`${this.props.match.path}/confirm`} component={Confirm} />
                                        <Route
                                            path={`${this.props.match.path}/change-password`}
                                            component={ChangePassword}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/force-new-password`}
                                            component={ForceNewPassword}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/change-password-success`}
                                            component={ChangePasswordSuccess}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/choose-retailer`}
                                            component={ChooseRetailer}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/add-to-retailer`}
                                            component={AddToRetailer}
                                        />
                                        <Route path={`${this.props.match.path}/new-employee`} component={NewEmployee} />
                                        <Route path={`${this.props.match.path}/new-store`} component={NewStore} />
                                        <Route
                                            path={`${this.props.match.path}/new-store-managing-director`}
                                            component={NewStoreManagingDirector}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/new-store-contact-person`}
                                            component={NewStoreContactPerson}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/new-store-report-mail-address`}
                                            component={NewStoreReportMailAddress}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/new-store-billing-information`}
                                            component={NewStoreBillingInformation}
                                        />
                                        <Route
                                            path={`${this.props.match.path}/new-store-upload-trading-licence`}
                                            component={NewStoreUploadTradingLicence}
                                        />
                                    </Switch>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
