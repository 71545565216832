import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React, { Component } from "react";

export default class ConfirmOrderDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            errorText: "",
            showSpinner: false,
        };

        this.handleReset = this.handleReset.bind(this);
    }

    handleReset() {
        this.setState(
            {
                error: false,
                errorText: "",
                showSpinner: false,
            },
            this.props.onClose
        );
    }

    render() {
        return (
            <Dialog
                maxWidth="xs"
                open={this.props.open}
                onClose={this.onClose}
                aria-labelledby="form-dialog-title"
                className="CheckCoupon"
            >
                <DialogTitle id="form-dialog-title">Möchten sie die Bestellung in Auftrag geben?</DialogTitle>
                <DialogContent>
                    <Typography>{`Gesamtbetrag: ${this.props.sum}€`}</Typography>
                    <DialogActions
                        style={{
                            position: "relative",
                        }}
                    >
                        <Button onClick={() => this.props.onClose()}>Abbrechen</Button>
                        <Button
                            onClick={() => {
                                this.props.order();
                                this.props.onClose();
                            }}
                            color="secondary"
                        >
                            Bestätigen
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    }
}
