import { Grid, Step, StepLabel, Stepper } from "@material-ui/core";
import React, { Component } from "react";
import ManagingDirectorForm from "../../../components/newStore/ManagingDirectorForm";
import NewStoreForm from "../../../components/newStore/NewStoreForm";
import ReportMailAddressForm from "../../../components/newStore/ReportMailAddressForm";
import TradingLicenceForm from "../../../components/newStore/TradingLicenceForm";
import ContactPersonForm from "../../../components/newStore/ContactPersonForm.jsx";
import BillingAddressForm from "../../../components/newStore/BillingAddressForm";

export default class NewStore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeStep: 0,
            localData: undefined,
            townData: undefined,
            stateData: undefined,
        };

        this.handleNextStep = this.handleNextStep.bind(this);
        this.handlePrevStep = this.handlePrevStep.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleNextStep(localData, townData, StateData) {
        this.setState(
            {
                localData: localData,
                townData: townData,
                stateData: StateData,
            },
            () => {
                this.setState({
                    activeStep: this.state.activeStep + 1,
                });
            }
        );
    }

    handlePrevStep() {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
    }

    handleCancel() {
        this.setState({
            activeStep: 0,
            localData: undefined,
            townData: undefined,
            stateData: undefined,
        });
    }

    render() {
        return (
            <Grid
                container
                spacing={2}
                alignItems="center"
                justify="center"
                style={{ textAlign: "left" }}
                className="NewStore"
            >
                <Grid item xs={12}>
                    <Stepper activeStep={this.state.activeStep}>
                        <Step key={0}>
                            <StepLabel>Unternehmen</StepLabel>
                        </Step>
                        <Step key={1}>
                            <StepLabel>Geschäftsführer</StepLabel>
                        </Step>
                        <Step key={2}>
                            <StepLabel>Ansprechpartner</StepLabel>
                        </Step>
                        <Step key={3}>
                            <StepLabel>Buchhaltung</StepLabel>
                        </Step>
                        <Step key={4}>
                            <StepLabel>Zahlungsinformationen</StepLabel>
                        </Step>
                        <Step key={5}>
                            <StepLabel>Gewerbeschein</StepLabel>
                        </Step>
                    </Stepper>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
                    {this.state.activeStep === 0 && <NewStoreForm successCallback={this.handleNextStep} />}
                    {this.state.activeStep === 1 && (
                        <ManagingDirectorForm
                            localData={this.state.localData}
                            townData={this.state.townData}
                            stateData={this.state.stateData}
                            successCallback={this.handleNextStep}
                            handlePrevStep={this.handlePrevStep}
                            handleCancel={this.handleCancel}
                        />
                    )}
                    {this.state.activeStep === 2 && (
                        <ContactPersonForm
                            localData={this.state.localData}
                            townData={this.state.townData}
                            stateData={this.state.stateData}
                            successCallback={this.handleNextStep}
                            handlePrevStep={this.handlePrevStep}
                            handleCancel={this.handleCancel}
                        />
                    )}
                    {this.state.activeStep === 3 && (
                        <ReportMailAddressForm
                            localData={this.state.localData}
                            townData={this.state.townData}
                            stateData={this.state.stateData}
                            successCallback={this.handleNextStep}
                            handlePrevStep={this.handlePrevStep}
                            handleCancel={this.handleCancel}
                        />
                    )}
                    {this.state.activeStep === 4 && (
                        <BillingAddressForm
                            localData={this.state.localData}
                            townData={this.state.townData}
                            stateData={this.state.stateData}
                            successCallback={this.handleNextStep}
                            handlePrevStep={this.handlePrevStep}
                            handleCancel={this.handleCancel}
                        />
                    )}

                    {this.state.activeStep === 5 && (
                        <TradingLicenceForm
                            localData={this.state.localData}
                            townData={this.state.townData}
                            stateData={this.state.stateData}
                            successCallback={this.handleNextStep}
                            handlePrevStep={this.handlePrevStep}
                            handleCancel={this.handleCancel}
                        />
                    )}
                </Grid>
            </Grid>
        );
    }
}
