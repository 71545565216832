import { Box, CircularProgress } from "@material-ui/core";
import React, { Component } from "react";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

export default class Licence extends Component {
    constructor() {
        super();

        this.state = {
            showSpinner: true,
            licenceFileType: undefined,
            numPages: null,
        };

        this.setLicenceFileType = this.setLicenceFileType.bind(this);
    }

    componentDidMount() {
        this.setLicenceFileType();
    }

    componentDidUpdate(prevProps) {
        if (this.props.preview) {
            if (this.props.licence.path !== prevProps.licence.path) {
                this.setLicenceFileType();
            }
        } else {
            if (prevProps.licence !== this.props.licence) {
                this.setLicenceFileType();
            }
        }
    }

    fetchNumPages(licence) {
        pdfjs
            .getDocument(licence)
            .promise.then((pdf) => {
                const numPages = pdf.numPages;
                this.setState({ numPages });
            })
            .catch((error) => {
                console.log("Error occurred while fetching the number of pages:", error);
            });
    }

    setLicenceFileType() {
        if (this.props.preview) {
            if (this.props.licence) {
                console.log(this.props.licence);
                this.setState({
                    showSpinner: false,
                    licenceFileType: this.props.licence.type,
                });
                if (this.props.licence.type === "application/pdf")
                    this.fetchNumPages(
                        this.props.preview ? URL.createObjectURL(this.props.licence) : this.props.licence
                    );
            }
        } else {
            if (this.props.licence) {
                fetch(this.props.licence)
                    .then((response) => response.blob())
                    .then((data) => {
                        if (data.type === "application/pdf")
                            this.fetchNumPages(this.props.preview ? URL.createObjectURL(data) : data);
                        this.setState({ showSpinner: false, licenceFileType: data.type });
                    });
            }
        }
    }

    render() {
        console.log(this.props.licence);
        console.log(this.props.licenceFileType);
        return (
            <div>
                {this.props.licence && (
                    <React.Fragment>
                        {this.state.showSpinner && <CircularProgress />}
                        {!this.state.showSpinner && (
                            <Box>
                                {(this.state.licenceFileType === "image/png" ||
                                    this.state.licenceFileType === "image/jpg") && (
                                    <img
                                        style={{ width: "100%", height: "auto" }}
                                        src={
                                            this.props.preview
                                                ? URL.createObjectURL(this.props.licence)
                                                : this.props.licence
                                        }
                                        alt="Gewerbeschein"
                                    />
                                )}
                                {this.state.licenceFileType === "application/pdf" && (
                                    <div>
                                        <iframe
                                            src={
                                                this.props.preview
                                                    ? URL.createObjectURL(this.props.licence)
                                                    : this.props.licence
                                            }
                                            style={{ width: "100%", height: "auto" }}
                                        />
                                    </div>
                                )}
                                {this.state.licenceFileType === undefined && (
                                    <div>
                                        <iframe
                                            src={this.props.preview ? this.props.licence : ""}
                                            style={{ width: "100%", height: "auto" }}
                                        />
                                    </div>
                                )}
                            </Box>
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}
