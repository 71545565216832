import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../../helpers/PrivateRoute";
import AdministrationLayout from "../../layout/AdminstrationLayout/AdministrationLayout";
import AddToRetailer from "./AddToRetailer/AddToRetailer";
import EmployeeDetails from "./Employees/EmployeeDetails";
import Employees from "./Employees/Employees";
import NewEmployeeQRCode from "./Employees/NewEmployeeQRCode";
import NewStore from "./NewStore/NewStore";

export default class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headerTitle: "",
        };

        this.setHeaderTitle = this.setHeaderTitle.bind(this);
    }

    setHeaderTitle(title) {
        this.setState({
            headerTitle: title,
        });
    }

    render() {
        return (
            <AdministrationLayout headerTitle={this.state.headerTitle}>
                <Switch>
                    <PrivateRoute
                        right={3}
                        path={"/settings/employees"}
                        render={() => <Employees setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={3}
                        path={"/settings/employee-details"}
                        render={() => <EmployeeDetails setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={3}
                        path={"/settings/new-employee-qrcode"}
                        render={() => <NewEmployeeQRCode setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <Route
                        path={"/settings/add-to-retailer"}
                        render={() => <AddToRetailer setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={1}
                        path={"/settings/new-store"}
                        render={() => <NewStore setHeaderTitle={this.setHeaderTitle} />}
                    />
                </Switch>
            </AdministrationLayout>
        );
    }
}
