import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Switch,
    TextField,
} from "@material-ui/core";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import Help from "@material-ui/icons/Help";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { ADD_COUPON_TO_LIST, getCouponWithActivationcode } from "../../../store/actions/couponActions";
import { store } from "../../../store/store";
import "./ActivateCoupon.scss";
import CouponList from "./CouponList";
import EuroIcon from "@material-ui/icons/Euro";
import NumberFormat from "react-number-format";
import { withSnackbar } from "notistack";

class ActivateForm extends Component {
    constructor() {
        super();

        this.state = {
            showSpinner: false,
            error: false,
            errorText: "",
            openDialog: false,
            multipleCouponsMode: false,
            currentStartValue: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
        this.switchMode = this.switchMode.bind(this);
        this.addCouponToQuery = this.addCouponToQuery.bind(this);
        this.numberFormatCustom = this.numberFormatCustom.bind(this);

        this.formRef = React.createRef();
        this.fieldRef = React.createRef();
    }

    componentDidMount() {
        this.props.setHeaderTitle("Gutschein aktivieren");
    }

    addCouponToQuery(values) {
        let duplicateActivationcode = this.props.coupon.couponList.find(
            (x) => x.activationcode === values.activationcode
        );

        if (duplicateActivationcode !== undefined) {
            this.props.enqueueSnackbar("Gutschein wurde bereits eingescannt", {
                variant: "warning",
            });
        }

        store.dispatch({
            type: ADD_COUPON_TO_LIST,
            coupon: { activationcode: values.activationcode },
        });

        // this.formRef.current.resetForm();
        this.formRef.current.setFieldValue("activationcode", "");
        this.formRef.current.setFieldTouched("activationcode", false);
        this.fieldRef.current.focus();
    }

    handleSubmit(values) {
        console.log(values);
        this.setState({
            error: false,
            errorText: "",
            currentStartValue: values.startValue,
        });

        values.activationcode = values.activationcode.replace(/\D/g, "");
        if (values.activationcode.length === 20) {
            if (this.state.multipleCouponsMode) {
                this.addCouponToQuery(values);
                return;
            }
            this.setState({
                showSpinner: true,
            });
            store
                .dispatch(getCouponWithActivationcode(values.activationcode))
                .then((response) => {
                    if (response !== null) {
                        this.setState({ showSpinner: false }, () => {
                            this.props.history.push("/administration/activate-coupon-details", {
                                couponDetails: response,
                            });
                        });
                    } else {
                        this.setState({
                            error: true,
                            errorText: "Der verwendete Code ist kein gültiger Aktivierungscode.",
                            showSpinner: false,
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        error: true,
                        errorText: "Der verwendete Code ist kein gültiger Aktivierungscode.",
                        showSpinner: false,
                    });
                });
        } else {
            this.setState({
                error: true,
                errorText: "Der verwendete Code ist kein gültiger Aktivierungscode.",
            });
        }
    }

    toggleDialog() {
        this.setState({
            openDialog: !this.state.openDialog,
        });
    }

    switchMode() {
        this.setState({
            multipleCouponsMode: !this.state.multipleCouponsMode,
        });
    }

    numberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator="."
                decimalSeparator=","
                isNumericString
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
            />
        );
    }

    render() {
        return (
            <Box className={this.state.multipleCouponsMode ? "ActivateCoupon multiple" : "ActivateCoupon"}>
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={12} sm={12} md={7} lg={6} xl={6}>
                        <Box m={2}>
                            <Paper>
                                <Box p={2}>
                                    <Grid container spacing={2} alignItems="center" justify="space-between">
                                        <Grid item style={{ flexGrow: 1 }}>
                                            <div>
                                                <Formik
                                                    innerRef={this.formRef}
                                                    initialValues={{
                                                        activationcode: "",
                                                        startValue: undefined,
                                                    }}
                                                    validationSchema={Yup.object().shape({
                                                        activationcode: Yup.string()
                                                            .min(20, "kein korrekter Gutscheincode")
                                                            .required("Dieses Feld wird benötigt"),
                                                        startValue: Yup.number()
                                                            .min(
                                                                0.02,
                                                                "Bitte geben Sie einen Wert der größer 0,00 € ist ein"
                                                            )
                                                            .max(
                                                                500,
                                                                "Bitte geben Sie einen Wert der unter 500 € ist ein"
                                                            ),
                                                    })}
                                                    onSubmit={(values) => {
                                                        this.handleSubmit(values);
                                                    }}
                                                >
                                                    {(props) => (
                                                        <Form onSubmit={props.handleSubmit}>
                                                            {this.state.error && (
                                                                <Box mb={2}>
                                                                    <Alert severity="error">
                                                                        {this.state.errorText}
                                                                    </Alert>
                                                                </Box>
                                                            )}
                                                            <Box mb={2} display="flex" alignItems="center">
                                                                <TextField
                                                                    disabled={this.state.showSpinner}
                                                                    autoFocus={true}
                                                                    autoComplete="off"
                                                                    name="activationcode"
                                                                    label="Aktivierungscode scannen oder manuell eingeben"
                                                                    size="small"
                                                                    variant="outlined"
                                                                    className="activationcode"
                                                                    onChange={props.handleChange}
                                                                    onBlur={(e) => {
                                                                        props.setFieldValue(
                                                                            "activationcode",
                                                                            e.target.value.replace(/\D/g, "")
                                                                        );
                                                                    }}
                                                                    value={props.values.activationcode}
                                                                    fullWidth
                                                                    error={
                                                                        props.errors.activationcode &&
                                                                        props.touched.activationcode
                                                                    }
                                                                    helperText={
                                                                        props.errors.activationcode &&
                                                                        props.touched.activationcode
                                                                            ? props.errors.activationcode
                                                                            : ""
                                                                    }
                                                                    InputProps={{
                                                                        inputRef: this.fieldRef,
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <CardGiftcardIcon fontSize="small" />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                                <label htmlFor="icon-button-file">
                                                                    <IconButton
                                                                        size="small"
                                                                        color="primary"
                                                                        aria-label="upload picture"
                                                                        component="span"
                                                                        onClick={this.toggleDialog}
                                                                    >
                                                                        <Help />
                                                                    </IconButton>
                                                                </label>
                                                            </Box>
                                                            <Box mb={2} display="flex" alignItems="center">
                                                                <TextField
                                                                    disabled={this.state.showSpinner}
                                                                    name="startValue"
                                                                    label="Gutscheinwert (bei Flexi-Gutscheinen)"
                                                                    size="small"
                                                                    variant="outlined"
                                                                    className="startValue"
                                                                    onChange={props.handleChange}
                                                                    value={props.values.startValue}
                                                                    fullWidth
                                                                    error={
                                                                        props.errors.startValue &&
                                                                        props.touched.startValue
                                                                    }
                                                                    helperText={
                                                                        props.errors.startValue &&
                                                                        props.touched.startValue
                                                                            ? props.errors.startValue
                                                                            : ""
                                                                    }
                                                                    InputProps={{
                                                                        inputComponent: this.numberFormatCustom,
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <EuroIcon fontSize="small" />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="space-between"
                                                            >
                                                                <Box>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={this.state.multipleCouponsMode}
                                                                                onChange={this.switchMode}
                                                                                name="multipleCoupons"
                                                                            />
                                                                        }
                                                                        label="mehrere Gutscheine aktivieren"
                                                                    />
                                                                </Box>
                                                                <Box
                                                                    className="submitSingle"
                                                                    textAlign="right"
                                                                    position="relative"
                                                                >
                                                                    {this.state.showSpinner && (
                                                                        <CircularProgress
                                                                            size={24}
                                                                            className="ButtonSpinner"
                                                                        />
                                                                    )}
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        type="submit"
                                                                        disabled={
                                                                            (!props.isValid && !props.dirty) ||
                                                                            this.state.showSpinner
                                                                        }
                                                                    >
                                                                        {this.state.multipleCouponsMode
                                                                            ? "Aktivieren"
                                                                            : "Weiter"}
                                                                    </Button>
                                                                    {this.state.showSpinner && (
                                                                        <CircularProgress
                                                                            size={24}
                                                                            className="ButtonSpinner"
                                                                        />
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Form>
                                                    )}
                                                </Formik>
                                                <Dialog
                                                    onClose={this.toggleDialog}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={this.state.openDialog}
                                                >
                                                    <DialogTitle
                                                        id="customized-dialog-title"
                                                        onClose={this.toggleDialog}
                                                    >
                                                        Beispielgutschein
                                                    </DialogTitle>
                                                    <DialogContent dividers>
                                                        <img
                                                            className="dummyImage"
                                                            alt="dummy"
                                                            src={require("../../../img/KL-Gutschein-Rueckseite-Partner.png")}
                                                        />
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus onClick={this.toggleDialog} color="primary">
                                                            Verstanden
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                        <Box m={2} className="couponList">
                            <CouponList currentStartValue={this.state.currentStartValue} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer, coupon } = state;
    return {
        user,
        retailer,
        coupon,
    };
}

export default connect(mapStateToProps)(withSnackbar(withRouter(ActivateForm)));
