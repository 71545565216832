import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import numeral from "numeral";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class CouponValue extends Component {
    constructor() {
        super();

        this.canUseCoupon = this.canUseCoupon.bind(this);
        this.goToRedeemCoupon = this.goToRedeemCoupon.bind(this);
    }

    canUseCoupon() {
        let result = false;
        const { coupon } = this.props;
        if (coupon.isValid && coupon.isActive && coupon.currentValue > 0) {
            result = true;
        }
        return result;
    }

    goToRedeemCoupon() {
        const { coupon, handleCloseCheckCoupon } = this.props;
        if (coupon) {
            this.props.history.push("/administration/redeem-coupon/" + coupon.barcode);
        }
        handleCloseCheckCoupon();
    }

    render() {
        const { coupon, handleCloseCheckCoupon } = this.props;

        return (
            <React.Fragment>
                <DialogTitle id="form-dialog-title">Gutscheinwert</DialogTitle>
                {!coupon.isActive && (
                    <Box>
                        <Alert severity="error">Gutschein wurde noch nicht aktiviert!</Alert>
                    </Box>
                )}
                {!coupon.isValid && (
                    <Box>
                        <Alert severity="error">Gutschein ist ungültig!</Alert>
                    </Box>
                )}
                <DialogContent>
                    <Box textAlign="center">
                        <Typography variant="subtitle1">{coupon.Retailer.name}</Typography>
                    </Box>
                    <Box textAlign="center">
                        <Typography variant="h2" color={coupon.currentValue > 0 ? "secondary" : "default"}>
                            {numeral(coupon.currentValue).format("0[.]00 $")}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions
                    style={{
                        position: "relative",
                    }}
                >
                    <Button onClick={handleCloseCheckCoupon} color="default">
                        Abbrechen
                    </Button>
                    {this.canUseCoupon() && (
                        <Button type="submit" color="secondary" onClick={this.goToRedeemCoupon}>
                            Benutzen
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        );
    }
}

export default withRouter(CouponValue);
