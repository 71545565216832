import { Dialog, DialogTitle } from "@material-ui/core";
import React, { Component } from "react";
import CreateOrderForm from "./CreateOrderForm";

export default class CreateOrderPopup extends Component {
    constructor() {
        super();

        this.state = {
            error: false,
            errorText: "",
            showSpinner: false,
        };

        this.handleReset = this.handleReset.bind(this);
    }

    static defaultProps = {
        openCreateOrderPopup: false,
        handleCloseCreateOrderPopup: () => console.log("no handleCloseImportOrderPopup"),
        handleSubmit: () => console.log("no handleSubmit"),
    };

    handleReset() {
        this.setState(
            {
                error: false,
                errorText: "",
                showSpinner: false,
            },
            this.props.handleCloseCreateOrderPopup
        );
    }

    render() {
        const { openCreateOrderPopup, handleSubmit } = this.props;
        const { error, errorText, showSpinner } = this.state;

        return (
            <Dialog maxWidth="xs" open={openCreateOrderPopup}>
                <DialogTitle>Auftrag erstellen</DialogTitle>
                <CreateOrderForm
                        handleSubmit={handleSubmit}
                        error={error}
                        errorText={errorText}
                        showSpinner={showSpinner}
                        handleCloseCreateOrderPopup={this.handleReset}
                        charging={this.props.charging}
                    />
            </Dialog>
        );
    }
}
