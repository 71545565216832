import { Grid } from '@material-ui/core'
import React, { Component } from 'react'
import Header from '../Header/Header'
import Navigation from '../Navigation/Navigation'
import './AdministrationLayout.scss'

export default class AdministrationLayout extends Component {
    render() {
        return (
            <Grid item xs={12} className="AdministrationLayout">
                <Navigation />
                <div>
                    <Header title={this.props.headerTitle} />
                    <div className="Administration">
                        {this.props.children}
                    </div>
                </div>
            </Grid>
        )
    }
}
