import { Dialog } from "@material-ui/core";
import React, { Component } from "react";
import AddCouponEmployeeForm from "./AddCouponEmployeeForm";

export default class AddCouponEmployeeDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            errorText: "",
            showSpinner: false,
        };

        this.handleReset = this.handleReset.bind(this);
    }

    handleReset() {
        this.setState(
            {
                error: false,
                errorText: "",
                showSpinner: false,
            },
            this.props.handleCloseAddCouponEmployeeDialog
        );
    }

    render() {
        return (
            <Dialog
                maxWidth="xs"
                open={this.props.openAddCouponEmployeeDialog}
                onClose={this.handleReset}
                aria-labelledby="form-dialog-title"
                className="CheckCoupon"
            >
                <AddCouponEmployeeForm
                    handleSubmitNewCouponEmployeeEmail={this.props.handleSubmitNewCouponEmployeeEmail}
                    handleSubmitNewCouponEmployeeCard={this.props.handleSubmitNewCouponEmployeeCard}
                    error={this.state.error}
                    errorText={this.state.errorText}
                    showSpinner={this.state.showSpinner}
                    handleCloseAddCouponEmployeeDialog={this.handleReset}
                    selectedEmployeeCard={this.props.selectedEmployeeCard}
                />
            </Dialog>
        );
    }
}
