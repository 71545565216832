import { RECEIVE_ORDERS, REQUEST_ORDERS } from "../actions/chargingActions";

const initialState = {
    chargings: [],
    orders: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_ORDERS:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case RECEIVE_ORDERS:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
                orders: action.orders,
            });
        default:
            return state;
    }
};
