import React, { Component } from 'react'
import './Employees.scss'
import { store } from '../../../store/store'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton, ListItemSecondaryAction, CircularProgress } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import AddCircleOutlined from '@material-ui/icons/AddCircleOutlined';
import { getAllEmployees } from '../../../store/actions/retailerActions'

class Employees extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showSpinner: false
        }
    }

    componentDidMount() {
        if (!this.props.retailer || !this.props.retailer.informations) {
            this.props.history.push('/account/choose-retailer')
        }
        else {
            //this.props.setHeaderTitle('Benutzer')
            if (!this.state.showSpinner) {
                this.setState({ showSpinner: true })
                store.dispatch(getAllEmployees(this.props.retailer.informations.parentRetailersId !== ' ' ? this.props.retailer.informations.parentRetailersId : this.props.retailer.informations.retailersId))
                    .then(response => {
                        this.setState({ showSpinner: false })
                    })
                    .catch(err => {
                        console.log(err.message)
                    })
            }
        }
    }

    render() {
        return (
            <div>
                <div style={{ display: 'flex', marginLeft: 20 }}>
                    <p>Neuen Benutzer hinzufügen</p>
                    <IconButton size='medium' edge="end" aria-label="Details" onClick={() => this.props.history.push('/settings/employee-details')}>
                        <AddCircleOutlined />
                    </IconButton>
                </div>
                <List>
                    {!this.state.showSpinner && this.props.retailer.employees && this.props.retailer.employees.map((employee, index) => {
                        return (
                            <ListItem style={index % 2 ? { background: "#F5F5F5" } : { background: "white" }} key={employee.cognitoId}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AccountCircle />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={employee.forename + ' ' + employee.surname}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="Details" onClick={() => this.props.history.push('/settings/employee-details', { employee: employee })}>
                                        <ArrowForwardIos />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    })}
                    {this.state.showSpinner && <CircularProgress size={24} className='ButtonSpinner' />}
                </List>
            </div >
        )
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state
    return {
        user, retailer
    }
}

export default connect(mapStateToProps)(withRouter(Employees))
