import { Dialog } from "@material-ui/core";
import React, { Component } from "react";
import UpdateCardForm from "./UpdateCardForm";

export default class UpdateCardDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            errorText: "",
            showSpinner: false,
        };

        this.handleReset = this.handleReset.bind(this);
    }

    handleReset() {
        this.setState(
            {
                error: false,
                errorText: "",
                showSpinner: false,
            },
            this.props.handleCloseUpdateCardDialog
        );
    }

    render() {
        return (
            <Dialog
                maxWidth="xs"
                open={this.props.openUpdateEmployeeCardsDialog}
                onClose={this.handleReset}
                aria-labelledby="form-dialog-title"
                className="CheckCoupon"
            >
                <UpdateCardForm
                    handleSubmitUpdateCard={this.props.handleSubmitUpdateCard}
                    error={this.state.error}
                    errorText={this.state.errorText}
                    showSpinner={this.state.showSpinner}
                    handleCloseUpdateCardDialog={this.handleReset}
                    couponEmployee={this.props.couponEmployee}
                />
            </Dialog>
        );
    }
}
