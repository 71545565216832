import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";

import React, { Component } from "react";
import OrderOverview from "./OrderOverview";
import OrderView from "./OrderView";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={"div"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
export default class orderCoupons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: "one",
        };
        this.handleChangeTab = this.handleChangeTab.bind(this);
    }
    handleChangeTab(event, newValue) {
        this.setState({
            tab: newValue,
        });
    }

    componentDidMount() {
        this.props.setHeaderTitle("Mitarbeiterkarten bestellen");
    }
    render() {
        return (
            <div>
                <AppBar position="static" color="secondary">
                    <Tabs
                        value={this.state.tab}
                        onChange={this.handleChangeTab}
                        aria-label="wrapped label tabs example"
                        variant="fullWidth"
                    >
                        <Tab value="one" label={"Bestellen"} />
                        <Tab value="two" label={"Bestellübersicht"} />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.tab} index="one">
                    <OrderView />
                </TabPanel>
                <TabPanel value={this.state.tab} index="two">
                    <OrderOverview />
                </TabPanel>
            </div>
        );
    }
}
