import { Button, Card, CardContent, CardHeader, List, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { CLEAR_COUPON_LIST } from "../../../store/actions/couponActions";
import { store } from "../../../store/store";
import CouponListItem from "./CouponListItem";

class CouponList extends Component {
    constructor() {
        super();

        this.state = {
            couponsActivated: [],
            couponsWithError: [],
        };

        this.addActivatedCouponToSet = this.addActivatedCouponToSet.bind(this);
        this.addCouponWithErrorToSet = this.addCouponWithErrorToSet.bind(this);
        this.deleteCouponWithErrorFromSet = this.deleteCouponWithErrorFromSet.bind(this);
        this.clearList = this.clearList.bind(this);
        this.couponActivationSummary = this.couponActivationSummary.bind(this);
    }

    addActivatedCouponToSet(activationcode) {
        let couponSet = new Set([...this.state.couponsActivated]);
        couponSet.add(activationcode);

        this.setState({
            couponsActivated: Array.from(couponSet),
        });
    }

    addCouponWithErrorToSet(activationcode) {
        let couponSet = new Set([...this.state.couponsWithError]);
        couponSet.add(activationcode);

        this.setState({
            couponsWithError: Array.from(couponSet),
        });
    }

    deleteCouponWithErrorFromSet(activationcode) {
        let couponSet = new Set([...this.state.couponsWithError]);
        couponSet.delete(activationcode);

        this.setState({
            couponsWithError: Array.from(couponSet),
        });
    }

    clearList() {
        store.dispatch({ type: CLEAR_COUPON_LIST });

        this.setState({
            couponsActivated: [],
            couponsWithError: [],
        });
    }

    couponActivationSummary() {
        const { couponsActivated, couponsWithError } = this.state;
        const { coupon } = this.props;

        let summary = null;
        let total = coupon.couponList.length;
        let activated = couponsActivated.length;
        let withError = couponsWithError.length;

        if (total > 0) {
            summary = (
                <React.Fragment>
                    <Typography component="span">{activated + "/" + total + " aktiviert"}</Typography>
                    {withError > 0 && (
                        <Typography component="span" color="error">
                            {" (" + withError + " Fehler)"}
                        </Typography>
                    )}
                </React.Fragment>
            );
        }

        return summary;
    }

    render() {
        return (
            <Card>
                <CardHeader
                    title="Gutscheine"
                    style={{ textAlign: "left" }}
                    action={
                        <React.Fragment>
                            {this.props.coupon.couponList.length > 0 && (
                                <Button variant="outlined" color="secondary" type="submit" onClick={this.clearList}>
                                    Auftrag abschließen
                                </Button>
                            )}
                        </React.Fragment>
                    }
                    subheader={this.couponActivationSummary()}
                />
                <CardContent>
                    {this.props.coupon.couponList.length > 0 && (
                        <List>
                            {this.props.coupon.couponList.map((coupon, index) => {
                                return (
                                    <CouponListItem
                                        key={coupon.activationcode}
                                        coupon={coupon}
                                        showDivider={this.props.coupon.couponList.length > index + 1}
                                        addActivatedCouponToSet={this.addActivatedCouponToSet}
                                        addCouponWithErrorToSet={this.addCouponWithErrorToSet}
                                        deleteCouponWithErrorFromSet={this.deleteCouponWithErrorFromSet}
                                        currentStartValue={this.props.currentStartValue}
                                    />
                                );
                            })}
                        </List>
                    )}
                </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
    const { coupon } = state;
    return { coupon };
}

export default connect(mapStateToProps)(CouponList);
