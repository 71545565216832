import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    TextField,
} from "@material-ui/core";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import PersonIcon from "@material-ui/icons/Person";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import { DatePicker } from "@material-ui/pickers";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as Yup from "yup";
import { getAllRetailers } from "../../../../store/actions/retailerActions";
import { store } from "../../../../store/store";

class AddCouponEmployeeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            showSpinner: false,
            activationType: "employee",
            retailers: [],
            cardNumbers: [],
            selectedRetailer: undefined,
            selectedEmployeeCard: this.props.selectedEmployeeCard,
        };

        this.switchType = this.switchType.bind(this);
        this.handleSubmitNewCouponEmployeeEmail = this.handleSubmitNewCouponEmployeeEmail.bind(this);
        this.handleSubmitNewCouponEmployeeCard = this.handleSubmitNewCouponEmployeeCard.bind(this);
    }

    componentDidMount() {
        store.dispatch(getAllRetailers()).then((response) => {
            if (response) {
                var sortedList = response.sort(function (a, b) {
                    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                });
                this.setState({ retailers: sortedList });
            }
        });
    }

    switchType(event, newType) {
        this.setState({
            activationType: newType,
        });
    }

    handleSubmitNewCouponEmployeeEmail(values) {
        this.props.handleSubmitNewCouponEmployeeEmail(values, this.state.selectedRetailer);
    }

    handleSubmitNewCouponEmployeeCard(values) {
        this.props.handleSubmitNewCouponEmployeeCard(values, this.state.selectedEmployeeCard);
    }

    render() {
        const { showSpinner } = this.state;
        return (
            <React.Fragment>
                {!this.props.selectedEmployeeCard && (
                    <ToggleButtonGroup
                        value={this.state.activationType}
                        onChange={this.switchType}
                        exclusive
                        style={{ margin: "10px", alignSelf: "center" }}
                    >
                        <ToggleButton value="employee" aria-label="bold">
                            <PersonIcon />
                            Mitarbeiter
                        </ToggleButton>
                        <ToggleButton value="card" aria-label="italic">
                            <CardGiftcardIcon />
                            Mitarbeiterkarte
                        </ToggleButton>
                    </ToggleButtonGroup>
                )}
                {(this.state.activationType === "card" || this.props.selectedEmployeeCard) && (
                    <Formik
                        initialValues={{
                            comment: "",
                            name: "",
                            firstTransaction: null,
                            lastTransaction: null,
                        }}
                        onSubmit={(values) => {
                            this.handleSubmitNewCouponEmployeeCard(values);
                        }}
                    >
                        {(props) => {
                            return (
                                <Form onSubmit={props.handleSubmit}>
                                    <DialogTitle id="form-dialog-title">Mitarbeiterkarte hinzufügen</DialogTitle>
                                    {this.props.error && (
                                        <Box mb={2}>
                                            <Alert severity="error">{this.props.errorText}</Alert>
                                        </Box>
                                    )}
                                    <DialogContent>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.retailer.employeeCardsNotActive}
                                                    getOptionLabel={(card) => card.id.toString()}
                                                    getOptionSelected={(option, value) => value.id === option.id}
                                                    value={this.state.selectedEmployeeCard}
                                                    style={{ width: "100%" }}
                                                    onChange={(event, newValue) => {
                                                        this.setState({
                                                            selectedEmployeeCard: newValue,
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="wähle eine Karte aus"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Name (optional)"
                                                    name="name"
                                                    fullWidth
                                                    size="small"
                                                    onChange={props.handleChange}
                                                    value={props.values.name}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Kommentar (optional)"
                                                    name="comment"
                                                    fullWidth
                                                    size="small"
                                                    onChange={props.handleChange}
                                                    value={props.values.comment}
                                                />
                                            </Grid>
                                            {!this.state.multiActivation && (
                                                <Grid item xs={12}>
                                                    <InputLabel id="demo-mutiple-checkbox-label">
                                                        Eintrittsdatum (optional)
                                                    </InputLabel>
                                                    <DatePicker
                                                        variant="inline"
                                                        openTo="month"
                                                        label=""
                                                        fullWidth
                                                        format="DD/MM/yyyy"
                                                        helperText=""
                                                        value={props.values.firstTransaction}
                                                        emptyLabel="wähle ein Eintrittsdatum aus"
                                                        onChange={(value) =>
                                                            props.setFieldValue("firstTransaction", value)
                                                        }
                                                    />
                                                </Grid>
                                            )}
                                            {!this.state.multiActivation && (
                                                <Grid item xs={12}>
                                                    <InputLabel id="demo-mutiple-checkbox-label">
                                                        Austrittsdatum (optional)
                                                    </InputLabel>
                                                    <DatePicker
                                                        variant="inline"
                                                        openTo="month"
                                                        label=""
                                                        fullWidth
                                                        format="DD/MM/yyyy"
                                                        helperText=""
                                                        value={props.values.lastTransaction}
                                                        emptyLabel="wähle ein Austrittsdatum aus"
                                                        onChange={(value) =>
                                                            props.setFieldValue("lastTransaction", value)
                                                        }
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions
                                        style={{
                                            position: "relative",
                                        }}
                                    >
                                        <Button
                                            onClick={this.props.handleCloseAddCouponEmployeeDialog}
                                            color="default"
                                            disabled={showSpinner}
                                        >
                                            Abbrechen
                                        </Button>
                                        <Box display="inline" position="relative">
                                            <Button type="submit" color="secondary" disabled={showSpinner}>
                                                Anlegen
                                            </Button>
                                            {showSpinner && (
                                                <CircularProgress
                                                    size=""
                                                    style={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        marginTop: -12,
                                                        marginLeft: -12,
                                                        width: "24px",
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
                {this.state.activationType === "employee" && !this.props.selectedEmployeeCard && (
                    <Formik
                        initialValues={{
                            firstTransaction: null,
                            lastTransaction: null,
                            email: "",
                            comment: "",
                            name: "",
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email("Keine korrekte E-Mail-Adresse")
                                .required("Dieses Feld wird benötigt"),
                        })}
                        onSubmit={(values) => {
                            this.handleSubmitNewCouponEmployeeEmail(values);
                        }}
                    >
                        {(props) => {
                            return (
                                <Form onSubmit={props.handleSubmit}>
                                    <DialogTitle id="form-dialog-title">Mitarbeiter hinzufügen</DialogTitle>
                                    {this.props.error && (
                                        <Box mb={2}>
                                            <Alert severity="error">{this.props.errorText}</Alert>
                                        </Box>
                                    )}
                                    <DialogContent>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.state.retailers}
                                                    getOptionLabel={(retailer) => retailer.name}
                                                    style={{ width: "100%" }}
                                                    onChange={(event, newValue) => {
                                                        this.setState({
                                                            selectedRetailer: newValue,
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="wähle einen Händler aus"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="email"
                                                    name="email"
                                                    label="E-Mail-Adresse"
                                                    type="email"
                                                    onChange={props.handleChange}
                                                    value={props.values.email}
                                                    error={props.errors.email}
                                                    helperText={props.errors.email ? props.errors.email : ""}
                                                    fullWidth
                                                    disabled={this.state.showSpinner}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Name (optional)"
                                                    name="name"
                                                    fullWidth
                                                    size="small"
                                                    onChange={props.handleChange}
                                                    value={props.values.name}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Kommentar (optional)"
                                                    name="comment"
                                                    fullWidth
                                                    size="small"
                                                    onChange={props.handleChange}
                                                    value={props.values.comment}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InputLabel id="demo-mutiple-checkbox-label">
                                                    Eintrittsdatum (optional)
                                                </InputLabel>
                                                <DatePicker
                                                    variant="inline"
                                                    openTo="month"
                                                    label=""
                                                    fullWidth
                                                    format="DD/MM/yyyy"
                                                    helperText=""
                                                    value={props.values.firstTransaction}
                                                    emptyLabel="wähle ein Eintrittsdatum aus"
                                                    onChange={(value) => props.setFieldValue("firstTransaction", value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InputLabel id="demo-mutiple-checkbox-label">
                                                    Austrittsdatum (optional)
                                                </InputLabel>
                                                <DatePicker
                                                    variant="inline"
                                                    openTo="month"
                                                    label=""
                                                    fullWidth
                                                    format="DD/MM/yyyy"
                                                    helperText=""
                                                    value={props.values.lastTransaction}
                                                    emptyLabel="wähle ein Austrittsdatum aus"
                                                    onChange={(value) => props.setFieldValue("lastTransaction", value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions
                                        style={{
                                            position: "relative",
                                        }}
                                    >
                                        <Button
                                            onClick={this.props.handleCloseAddCouponEmployeeDialog}
                                            color="default"
                                            disabled={showSpinner}
                                        >
                                            Abbrechen
                                        </Button>
                                        <Box display="inline" position="relative">
                                            <Button type="submit" color="secondary" disabled={showSpinner}>
                                                Anlegen
                                            </Button>
                                            {showSpinner && (
                                                <CircularProgress
                                                    size=""
                                                    style={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        marginTop: -12,
                                                        marginLeft: -12,
                                                        width: "24px",
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(withRouter(AddCouponEmployeeForm));
