import React, { Component } from 'react'
import ChangePasswordForm from '../../helpers/ChangePasswordForm'

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: this.props.location.state.username,
        }
    }

    render() {
        return (
            <ChangePasswordForm username={this.state.username} />
        )
    }
}
