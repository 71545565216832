import { Box, Card, CardContent, CardHeader, Grid, Modal, TextField, Typography } from "@material-ui/core";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import { Form, Formik } from "formik";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import InfoIcon from "@material-ui/icons/Info";

class OrderCouponContactPerson extends Component {
    constructor(props) {
        super(props);
        let cn = props.retailer.informations.contactPerson ? props.retailer.informations.contactPerson.contactName : "";
        let cp = props.retailer.informations.contactPerson
            ? props.retailer.informations.contactPerson.contactPhone
            : "";
        let cm = props.retailer.informations.contactPerson ? props.retailer.informations.contactPerson.contactMail : "";
        this.state = {
            showSpinner: true,
            setBillingAddress: false,
            contactName: cn,
            contactPhone: cp,
            contactMail: cm,
            helperText: "",
            showHelperText: false,
        };

        this.update = this.update.bind(this);
        this.formRef = React.createRef();
        this.toggleHelperText = this.toggleHelperText.bind(this);
    }

    componentDidMount() {
        if (this.props.retailer.informations.contactPerson) {
            let newValues = {
                contactName: this.props.retailer.informations.contactPerson.contactName,
                contactPhone: this.props.retailer.informations.contactPerson.contactPhone,
                contactMail: this.props.retailer.informations.contactPerson.contactMail,
            };
            this.formRef.current.resetForm({ values: newValues });
        }
    }

    update(newValues) {
        let contactPerson = {
            contactName: newValues.contactName,
            contactPhone: newValues.contactPhone,
            contactMail: newValues.contactMail,
        };
        this.props.setContactPerson(contactPerson);
    }

    toggleHelperText() {
        this.setState({ showHelperText: !this.state.showHelperText });
    }
    render() {
        return (
            <React.Fragment>
                <Card style={{ height: "325px", width: "350px" }}>
                    <CardHeader
                        title={
                            <Box display="flex" alignItems="center">
                                <PermContactCalendarIcon style={{ marginRight: 5 }} /> Ansprechpartner
                                <InfoIcon
                                    style={{ position: "relative", left: 90, fontSize: 18 }}
                                    onClick={() =>
                                        this.setState(
                                            {
                                                helperText:
                                                    "Geben Sie hier einen Ansprechpartner für diese Bestellung an, der bei Rückfragen zum Design oder zur Bestellung kontaktiert werden kann",
                                            },
                                            () => this.toggleHelperText()
                                        )
                                    }
                                />
                            </Box>
                        }
                    />
                    <CardContent>
                        <Formik
                            innerRef={this.formRef}
                            initialValues={{
                                contactName: "",
                                contactPhone: "",
                                contactMail: "",
                            }}
                            onSubmit={(values) => {
                                this.update(values);
                            }}
                            validateOnChange={true}
                        >
                            {(props) => (
                                <Form onBlur={props.handleSubmit}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="contactName"
                                            name="contactName"
                                            label="Name"
                                            value={props.values.contactName}
                                            onChange={props.handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="contactPhone"
                                            name="contactPhone"
                                            label="Telefonnummer"
                                            value={props.values.contactPhone}
                                            onChange={props.handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="contactMail"
                                            name="contactMail"
                                            value={props.values.contactMail}
                                            onChange={props.handleChange}
                                            label="E-Mail"
                                            fullWidth
                                        />
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
                <Modal
                    open={this.state.showHelperText}
                    onBackdropClick={this.toggleHelperText}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "13px",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            width: 500,
                            padding: "20px",
                            borderRadius: "13px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {" "}
                        <InfoIcon style={{ marginRight: 20 }} />
                        <Typography>{this.state.helperText}</Typography>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withSnackbar(OrderCouponContactPerson));
