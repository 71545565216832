import { Dialog, DialogTitle } from "@material-ui/core";
import React, { Component } from "react";
import ImportOrderForm from "./ImportOrderForm";

export default class ImportOrderPopup extends Component {
    constructor() {
        super();

        this.state = {
            error: false,
            errorText: "",
            showSpinner: false,
        };

        this.handleReset = this.handleReset.bind(this);
    }

    static defaultProps = {
        openImportOrderPopup: false,
        handleCloseImportOrderPopup: () => console.log("no handleCloseImportOrderPopup"),
        handleSubmit: () => console.log("no handleSubmit"),
    };

    handleReset() {
        this.setState(
            {
                error: false,
                errorText: "",
                showSpinner: false,
            },
            this.props.handleCloseImportOrderPopup
        );
    }

    render() {
        const { openImportOrderPopup, handleSubmit } = this.props;
        const { error, errorText, showSpinner } = this.state;

        return (
            <Dialog className="importOrderPopup" maxWidth="xs" open={openImportOrderPopup}>
                <DialogTitle>Auftrag importieren</DialogTitle>
                <ImportOrderForm
                    handleSubmit={handleSubmit}
                    error={error}
                    errorText={errorText}
                    showSpinner={showSpinner}
                    handleCloseImportOrderPopup={this.handleReset}
                />
            </Dialog>
        );
    }
}
