import React from "react";
import { connect } from "react-redux";
import { Component } from "react";
import { store } from "../../store/store";
import {
    getRetailerInformationsWithRetailersId,
    getRetailerTransactions,
    getActualCouponAccountValue,
} from "../../store/actions/retailerActions";
import { Dialog, ListItem, ListItemText, Typography, Modal } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router";
import AcceptAGBs from "./AcceptAGBs/AcceptAGBs";

class ChooseRetailer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            showDialog: false,
            dialogText: "",
            openAgbPopup: false,
        };

        this.handleClose = this.handleClose.bind(this);
        this.toggleAgbPopup = this.toggleAgbPopup.bind(this);
        this.acceptAGBLater = this.acceptAGBLater.bind(this);
    }

    handleClose() {
        this.setState({
            showDialog: false,
        });
    }

    toggleAgbPopup(retailersId) {
        if (retailersId) {
            console.log(retailersId);
        }
        this.setState({
            openAgbPopup: !this.state.openAgbPopup,
        });
    }

    acceptAGBLater() {
        this.setState({
            openAgbPopup: false
        }, () => {
            window.location = "/administration";
        });
    }


    render() {
        console.log(this.props.retailer.informations);
        const { openAgbPopup } = this.state;
        return (
            <div>
                {this.props.retailer.informations && (
                    <Modal open={openAgbPopup} onClose={() => this.setState({ openAgbPopup: false })}>
                        <AcceptAGBs
                            retailersId={this.props.retailer.informations.retailersId}
                            open={openAgbPopup}
                            toggle={this.toggleAgbPopup}
                            acceptAGBLater={this.acceptAGBLater}
                        />
                    </Modal>
                )}
                <Dialog
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Fehlende Daten"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{this.state.dialogText}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() =>
                                this.setState({ showSpinner: false }, this.props.history.push("/administration/"))
                            }
                            color="primary"
                            autoFocus
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Typography style={{ marginBottom: 10 }} variant="h6">
                    Filiale auswählen
                </Typography>

                {this.props.user.employee.Retailers.map((retailer) => {
                    return (
                        <ListItem
                            button
                            key={retailer.retailersId}
                            onClick={() => {
                                this.setState(() => ({ showSpinner: true }));
                                store.dispatch(getRetailerTransactions(retailer.retailersId));
                                store.dispatch(getActualCouponAccountValue(retailer.retailersId));
                                store
                                    .dispatch(getRetailerInformationsWithRetailersId(retailer.retailersId))
                                    .then((response) => {
                                        console.log(response);
                                        try {
                                            if (this.props.retailer.informations.acceptNewAgbs !== true) {
                                                this.toggleAgbPopup();
                                            } else {
                                                if (this.props.user.employee.Rights.find((element) => element === 1)) {
                                                    if (
                                                        this.props.retailer.informations.imageUrl ===
                                                            "https://local-heroes-retailer-images.s3.eu-central-1.amazonaws.com/keeplocallogofarbig.png" ||
                                                        this.props.retailer.informations.licence === " " ||
                                                        !this.props.retailer.informations.ManagingDirector
                                                    ) {
                                                        const logo =
                                                            this.props.retailer.informations.imageUrl ===
                                                            "https://local-heroes-retailer-images.s3.eu-central-1.amazonaws.com/keeplocallogofarbig.png"
                                                                ? " \n - Händlerlogo"
                                                                : "";
                                                        const licence =
                                                            this.props.retailer.informations.licence === " "
                                                                ? " \n - Gewerbeschein"
                                                                : "";
                                                        const managingDirector = !this.props.retailer.informations
                                                            .ManagingDirector
                                                            ? " \n - Geschäftsführer"
                                                            : "";
                                                        this.setState({
                                                            showDialog: true,
                                                            dialogText:
                                                                "Bitte vervollständige deine Daten in der App. Folgende Informationen fehlen: \n " +
                                                                logo +
                                                                licence +
                                                                managingDirector,
                                                        });
                                                    } else {
                                                        this.setState({ showSpinner: false }, () =>
                                                            this.props.history.push("/administration/")
                                                        );
                                                    }
                                                } else {
                                                    this.setState({ showSpinner: false }, () =>
                                                        this.props.history.push("/administration/")
                                                    );
                                                }
                                            }
                                        } catch (e) {
                                            console.log(e);
                                            this.setState({ showSpinner: false }, () =>
                                                this.props.history.push("/administration/")
                                            );
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err.message);
                                        this.setState({ showSpinner: false });
                                    });
                            }}
                        >
                            <ListItemText primary={retailer.name} />
                        </ListItem>
                    );
                })}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(withRouter(ChooseRetailer));
