import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Grid,
    TextField,
    Button,
    CircularProgress,
    Typography,
    Link,
    FormControlLabel,
    Checkbox,
    FormHelperText,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import "./Registration.scss";
import Alert from "@material-ui/lab/Alert";
import { store } from "../../store/store";
import { userRegisterWithEmailAndPassword } from "../../store/actions/userAction";

export default class Registration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            error: false,
            errorText: "",
        };
    }

    render() {
        return (
            <div className="Registration">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <Formik
                    initialValues={{
                        firstname: "",
                        lastname: "",
                        email: "",
                        password: "",
                        passwordConfirm: "",
                        legal: false,
                    }}
                    validationSchema={Yup.object().shape({
                        firstname: Yup.string().required("Dieses Feld wird benötigt"),
                        lastname: Yup.string().required("Dieses Feld wird benötigt"),
                        email: Yup.string()
                            .email("Keine korrekte E-Mail-Adresse")
                            .required("Dieses Feld wird benötigt"),
                        password: Yup.string().required("Dieses Feld wird benötigt"),
                        passwordConfirm: Yup.string()
                            .oneOf([Yup.ref("password"), null], "Passwörter stimmen nicht überein")
                            .required("Dieses Feld wird benötigt"),
                        legal: Yup.boolean().oneOf(
                            [true],
                            "Bitte akzeptiere unsere AGB's und die Datenschutzerklärung"
                        ),
                    })}
                    onSubmit={(values) => {
                        this.setState({
                            showSpinner: true,
                            error: false,
                            errorText: "",
                        });
                        var userAttributes = {};
                        userAttributes.name = values.firstname;
                        userAttributes.family_name = values.lastname;
                        store
                            .dispatch(
                                userRegisterWithEmailAndPassword(
                                    values.email.trim().toLowerCase(),
                                    values.password,
                                    userAttributes
                                )
                            )
                            .then((response) => {
                                this.setState({
                                    showSpinner: false,
                                });
                                this.props.history.push("confirm", { username: values.email.trim() });
                            })
                            .catch((err) => {
                                switch (err.code) {
                                    case "UsernameExistsException":
                                        this.setState({
                                            error: true,
                                            errorText: "Diese E-Mail Addresse wird bereits verwendet!",
                                        });
                                        break;
                                    case "TooManyRequestsException":
                                        this.setState({
                                            error: true,
                                            errorText:
                                                "Du hast zu oft versucht dich zu registrieren. Bitte versuche es später erneut.",
                                        });
                                        break;
                                    case "InvalidPasswordException":
                                        this.setState({
                                            error: true,
                                            errorText: "Das Passwort muss mindestens 8 Zeichen lang sein.",
                                        });
                                        break;
                                    case "InvalidParameterException":
                                        this.setState({
                                            error: true,
                                            errorText:
                                                "Fehler bei den angegebenen Daten. Bitte prüfe deine Eingaben und versuche es erneut.",
                                        });
                                        break;
                                    default:
                                        this.setState({ error: true, errorText: err.message });
                                }
                                this.setState({ showSpinner: false });
                            });
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="firstname"
                                        name="firstname"
                                        label="Vorname"
                                        defaultValue={props.values.firstname}
                                        onChange={props.handleChange}
                                        error={props.errors.firstname ? true : false}
                                        helperText={props.errors.firstname}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="lastname"
                                        name="lastname"
                                        label="Nachname"
                                        defaultValue={props.values.lastname}
                                        onChange={props.handleChange}
                                        error={props.errors.lastname ? true : false}
                                        helperText={props.errors.lastname}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="E-Mail-Adresse"
                                        type="email"
                                        defaultValue={props.values.email}
                                        onChange={props.handleChange}
                                        error={props.errors.email ? true : false}
                                        helperText={props.errors.email}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="password"
                                        name="password"
                                        label="Passwort"
                                        type="password"
                                        defaultValue={props.values.password}
                                        onChange={props.handleChange}
                                        error={props.errors.password ? true : false}
                                        helperText={props.errors.password}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="passwordConfirm"
                                        name="passwordConfirm"
                                        label="Passwort wiederholen"
                                        type="password"
                                        defaultValue={props.values.passwordConfirm}
                                        onChange={props.handleChange}
                                        error={props.errors.passwordConfirm ? true : false}
                                        helperText={props.errors.passwordConfirm}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="legal"
                                                name="legal"
                                                color="primary"
                                                checked={props.values.legal}
                                                onChange={props.handleChange}
                                                disabled={this.state.showSpinner}
                                            />
                                        }
                                        label={
                                            <>
                                                Ich habe die{" "}
                                                <Link
                                                    href="https://keeplocal.de/agb-partner/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    AGB
                                                </Link>{" "}
                                                und die{" "}
                                                <Link
                                                    href="https://keeplocal.de/datenschutzerklaerung/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Datenschutzerklärung
                                                </Link>{" "}
                                                gelesen und akzeptiere diese.
                                            </>
                                        }
                                    />
                                    <FormHelperText error={props.errors.legal}>{props.errors.legal}</FormHelperText>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", position: "relative" }}
                                >
                                    <Button
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Registrieren
                                    </Button>
                                    {this.state.showSpinner && <CircularProgress size={24} className="loginSpinner" />}
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <Typography variant="body2">
                    Du hast schon einen Account?{" "}
                    <Link component={RouterLink} to="/account">
                        Login
                    </Link>
                </Typography>
                <Typography variant="body2">
                    <Link component={RouterLink} to="/account/request-password-change">
                        Passwort vergessen?
                    </Link>
                </Typography>
            </div>
        );
    }
}
