import {
    Backdrop,
    Box,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import PaymentIcon from "@material-ui/icons/Payment";
import { Auth } from "aws-amplify";
import { Form, Formik } from "formik";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getBillingInformations, updateBillingInformation } from "../../../../store/actions/retailerActions";
import { store } from "../../../../store/store";

class BillingInformation extends Component {
    constructor() {
        super();

        this.state = {
            showSpinner: true,
            setbillingAddress: false,
            billingInformation: {},
            billingName: "",
            billingStreet: "",
            billingTown: "",
            iban: "",
            bic: "",
            accountOwner: "",
        };

        this.update = this.update.bind(this);
        this.setAdress = this.setAdress.bind(this);
        this.formRef = React.createRef();
    }

    componentDidMount() {
        store.dispatch(getBillingInformations(this.props.retailer.informations.retailersId)).then((response) => {
            this.setState({
                showSpinner: false,
                billingInformation: response.billingInformation,
                billingName: response.billingInformation.billingAddress
                    ? response.billingInformation.billingAddress.billingName
                    : "",
                billingTown: response.billingInformation.billingAddress
                    ? response.billingInformation.billingAddress.billingTown
                    : "",
                billingStreet: response.billingInformation.billingAddress
                    ? response.billingInformation.billingAddress.billingStreet
                    : "",
                bic: response.billingInformation.bic,
                iban: response.billingInformation.iban,
                accountOwner: response.billingInformation.accountOwner,
            });

            if (response.billingInformation.billingAddress) this.setState({ setbillingAddress: true });

            let newValues = {
                billingInformation: response.billingInformation,
                billingName: response.billingInformation.billingAddress
                    ? response.billingInformation.billingAddress.billingName
                    : "",
                billingTown: response.billingInformation.billingAddress
                    ? response.billingInformation.billingAddress.billingTown
                    : "",
                billingStreet: response.billingInformation.billingAddress
                    ? response.billingInformation.billingAddress.billingStreet
                    : "",
                bic: response.billingInformation.bic,
                iban: response.billingInformation.iban,
                accountOwner: response.billingInformation.accountOwner,
            };
            this.formRef.current.resetForm({ values: newValues });

            // this.formRef.current.setFieldValue("bic", response.billingInformation.bic.trim());
            // this.formRef.current.setFieldValue("iban", response.billingInformation.iban.trim());
            // this.formRef.current.setFieldValue("accountOwner", response.billingInformation.accountOwner.trim());
        });
    }

    update(newValues) {
        let ba = {
            billingName: newValues.billingName,
            billingTown: newValues.billingTown,
            billingStreet: newValues.billingStreet,
        };
        Auth.currentAuthenticatedUser()
            .then((user) => {
                store
                    .dispatch(
                        updateBillingInformation(
                            newValues.iban,
                            newValues.bic,
                            newValues.accountOwner,
                            user.attributes.sub,
                            ba,
                            this.props.retailer.informations.retailersId
                        )
                    )
                    .then(() => {
                        this.props.enqueueSnackbar("Zahlungsinformationen erfolgreich aktualisiert", {
                            variant: "success",
                        });
                    })
                    .catch(() => {
                        this.props.enqueueSnackbar("Fehler beim aktualsieren der Zahlungsinformationen", {
                            variant: "error",
                        });
                    });
            })
            .catch(() => {
                this.props.enqueueSnackbar("Fehler beim aktualsieren der Zahlungsinformationen", {
                    variant: "error",
                });
            });
    }

    setAdress(name, town, street) {
        this.setState({ billingName: name, billingTown: town, billingStreet: street });
    }

    render() {
        return (
            <Card>
                <CardHeader
                    title={
                        <Box display="flex" alignItems="center">
                            <PaymentIcon style={{ marginRight: 5 }} /> Zahlungsinformationen
                        </Box>
                    }
                />
                <CardContent>
                    <Formik
                        innerRef={this.formRef}
                        initialValues={{
                            iban: "",
                            bic: "",
                            accountOwner: "",
                            billingName: "",
                            billingTown: "",
                            billingStreet: "",
                        }}
                        onSubmit={(values) => {
                            this.update(values);
                        }}
                        validateOnChange={true}
                    >
                        {(props) => {
                            return (
                                <Form onBlur={props.handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="iban"
                                                name="iban"
                                                label="IBAN"
                                                value={props.values.iban}
                                                onChange={props.handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="bic"
                                                name="bic"
                                                label="BIC"
                                                value={props.values.bic}
                                                onChange={props.handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="accountOwner"
                                                name="accountOwner"
                                                label="Kontoinhaber"
                                                value={props.values.accountOwner}
                                                onChange={props.handleChange}
                                                fullWidth
                                            />
                                            <Box m={1} />
                                        </Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id="billingAddress"
                                                    checked={this.state.setbillingAddress}
                                                    onChange={() =>
                                                        this.setState({
                                                            setbillingAddress: !this.state.setbillingAddress,
                                                        })
                                                    }
                                                    fullWidth
                                                />
                                            }
                                            label="Rechnungsadresse weicht von Geschäftsadresse ab"
                                        />
                                    </Grid>
                                    {this.state.setbillingAddress && (
                                        <>
                                            <Typography>Rechnungsadresse:</Typography>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="billingName"
                                                    name="billingName"
                                                    label="Name"
                                                    value={props.values.billingName}
                                                    onChange={props.handleChange}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="billingTown"
                                                    name="billingTown"
                                                    label="Stadt"
                                                    value={props.values.billingTown}
                                                    onChange={props.handleChange}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="billingStreet"
                                                    name="billingStreet"
                                                    value={props.values.billingStreet}
                                                    onChange={props.handleChange}
                                                    label="Straße und Hausnummer"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Form>
                            );
                        }}
                    </Formik>

                    <Backdrop open={this.state.showSpinner} style={{ zIndex: 99999999 }}>
                        <CircularProgress />
                    </Backdrop>
                </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withSnackbar(BillingInformation));
