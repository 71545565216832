import React, { Component } from 'react'
import { Box, Icon, Typography, Button } from '@material-ui/core'
import CheckCircle from '@material-ui/icons/CheckCircle';
import { withRouter } from 'react-router-dom';

class ChangePasswordSuccess extends Component {

    render() {
        return (
            <div style={{ alignItems: 'center' }}>
                <Typography gutterBottom variant="h5" component="h3">
                    Passwort erfolgreich geändert
            </Typography>
                <Icon fontSize='large'>
                    <CheckCircle fontSize='large' style={{ fill: "green" }} />
                </Icon>
                <Box textAlign="right" position="relative">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.props.history.push('/account')}
                        style={{ marginTop: 10 }}
                    >
                        Zur Anmeldung
                                </Button>
                </Box>
            </div>
        )
    }
}

export default withRouter(ChangePasswordSuccess)
