import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import RedeemForm from "./RedeemForm";
import RedeemInsertCoupon from "./RedeemInsertCoupon";
import RedeemConfirm from "./RedeemConfirm";
import RedeemSuccess from "./RedeemSuccess";
import { connect } from "react-redux";
import { redeemCoupon } from "../../../store/actions/retailerActions";
import { store } from "../../../store/store";

class RedeemCoupon extends Component {
    constructor() {
        super();

        this.state = {
            openSuccess: false,
            showSpinner: false,
            openConfirm: false,
            invoiceValue: 0,
            barcode: "",
            remainingValue: "0",
            transactions: [],
            wasEnough: false,
            resetForm: false,
            status: 1,
            couponsValue: 0,
            couponList: [],
            scannedCodeCount: 0,
            invoiceIsPaid: false,
            orderIndex: 0,
            prevStatus: 2
        };

        this.handleOpenSuccess = this.handleOpenSuccess.bind(this);
        this.handleCloseSuccess = this.handleCloseSuccess.bind(this);
        this.handleRedeemFormResponse = this.handleRedeemFormResponse.bind(this);
        this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
        this.handleGoToTransactions = this.handleGoToTransactions.bind(this);
        this.setInvoiceValue = this.setInvoiceValue.bind(this);
        this.onChangeInvoiceValueClick = this.onChangeInvoiceValueClick.bind(this);
        this.handleShowConfirm = this.handleShowConfirm.bind(this);
        this.onAddCouponClick = this.onAddCouponClick.bind(this);
        this.showRedeemSuccessScreen = this.showRedeemSuccessScreen.bind(this);
        this.redeemCouponClick = this.redeemCouponClick.bind(this);
    }

    componentDidMount() {
        this.props.setHeaderTitle("Gutschein einlösen");
        this.setState({
            barcode: this.props.match.params.barcode ? this.props.match.params.barcode : "",
        });
    }

    onChangeInvoiceValueClick(invoiceValue, couponList, couponsValue, remainingValue, scannedCodeCount, wasEnough, transactions, invoiceIsPaid, orderIndex, prevStatus) {
        this.setState(
            {
                invoiceValue: invoiceValue,
                couponList: couponList,
                couponsValue: couponsValue,
                remainingValue: remainingValue,
                scannedCodeCount: scannedCodeCount,
                wasEnough: wasEnough,
                transactions: transactions,
                invoiceIsPaid: invoiceIsPaid,
                orderIndex: orderIndex,
                status: 1, 
                prevStatus: prevStatus
            }
        );
    }

    onAddCouponClick(couponList, couponsValue, remainingValue, scannedCodeCount, wasEnough, transactions, invoiceIsPaid, orderIndex) {
        this.setState(
            {
                couponList: couponList,
                couponsValue: couponsValue,
                remainingValue: remainingValue,
                scannedCodeCount: scannedCodeCount,
                wasEnough: wasEnough,
                transactions: transactions,
                invoiceIsPaid: invoiceIsPaid,
                orderIndex: orderIndex,
                status: 2, 
            }
        );
    }

    componentDidUpdate() {
        let newBarcode = this.props.match.params.barcode;

        if (newBarcode === undefined) {
            newBarcode = "";
        }

        if (this.state.barcode !== "") {
            newBarcode = this.state.barcode;
        }

        if (this.state.barcode !== newBarcode) {
            this.setState({
                barcode: newBarcode,
            });
        }
    }

    handleOpenSuccess(redeemData) {
        this.setState(
            {
                openConfirm: false,
                transactions: redeemData.transactions,
            },
            () =>
                this.setState({
                    openSuccess: true,
                })
        );
    }

    handleCloseSuccess() {
        this.setState({
            openSuccess: false,
            invoiceValue: 0,
            barcode: "",
            couponsValue: 0,
            remainingValue: "0",
            transactions: [],
            couponList: [],
            wasEnough: false,
            resetForm: true,
            status: 1,
            scannedCodeCount: 0,
            prevStatus: 2, 
            showSpinner: false,
        });
    }

    handleGoToTransactions() {
        this.props.history.push("/administration/transactions");
    }

    handleCloseConfirm() {
        this.setState({
            openConfirm: false,
        });
    }

    setInvoiceValue(newInvoiceValue, couponsValue, wasEnough, couponList, transactions, scannedCodeCount, invoiceIsPaid, orderIndex) {
        var newRemainingValue = parseFloat(newInvoiceValue) - couponsValue;
        this.setState({
            invoiceValue: newInvoiceValue,
            status: this.state.prevStatus,
            wasEnough: newRemainingValue <= 0,
            remainingValue: newRemainingValue.toFixed(2),
            couponList: couponList,
            transactions: transactions,
            scannedCodeCount: scannedCodeCount,
            invoiceIsPaid: invoiceIsPaid,
            orderIndex: orderIndex,
            couponsValue: couponsValue,
            wasEnough: wasEnough,

        });
    }

    handleRedeemFormResponse(invoiceValue, couponsValue, remainingValue, barcode, wasEnough, transactions) {
        this.setState(
            {
                invoiceValue: invoiceValue,
                couponsValue: couponsValue,
                remainingValue: remainingValue,
                barcode: barcode,
                wasEnough: wasEnough,
                transactions: transactions,
                resetForm: false,
            }
        );
    }

    showRedeemSuccessScreen(invoiceValue, couponsValue, remainingValue, wasEnough, transactions, scannedCodeCount) {
        this.setState(
            {
                invoiceValue: invoiceValue,
                couponsValue: couponsValue,
                remainingValue: remainingValue,
                wasEnough: wasEnough,
                transactions: transactions,
                scannedCodeCount: scannedCodeCount,
                status: 4,
            }
        );
    }

    redeemCouponClick(couponList, invoiceValue) {
        this.setState({ showSpinner: true }, () =>
        store
            .dispatch(
                redeemCoupon(
                    couponList,
                    invoiceValue,
                    this.props.retailer.informations.retailersId
                )
            )
            .then((response) => {
                this.setState({ showSpinner: false });
                if (response.success) {
                    this.showRedeemSuccessScreen(
                        invoiceValue,
                        response.couponValue,
                        response.remainingValue,
                        response.wasEnough,
                        response.transactions,
                        couponList.length,
                    );
                } else {
                    this.setState({
                        showSpinner: false,
                    });
                    this.setState({ errorText: "Fehler: " + response.message})
                }
            })
            .catch((err) => {
                this.setState({
                    showSpinner: false,
                });
                console.log(err);
                if (err.message === "Network Error") {
                    this.setState({ errorText: "Fehler: Bitte überprüfe Deine Internetverbindung und versuche es erneut!"})
                } else this.setState({ errorText: "Fehler beim Einlösen des Gutscheins. Bitte versuch es erneut."})
            })
        );
    }

    handleShowConfirm(invoiceValue, couponsValue, remainingValue, wasEnough, couponList, transactions, scannedCodeCount) {
        this.setState(
            {
                invoiceValue: invoiceValue,
                couponsValue: couponsValue,
                remainingValue: remainingValue,
                wasEnough: wasEnough,
                couponList: couponList,
                transactions: transactions,
                scannedCodeCount: scannedCodeCount,
                status: 3
            }
        );
    }

    render() {
        var statusText = "";
        switch (this.state.status) {
            case 1:
                statusText = "Zu zahlenden Betrag eingeben";
                break;
            case 2:
                statusText = "Gutscheincode eingeben";
                break;
            case 3:
                statusText = "Zwischenübersicht";
                break;
            case 4:
                statusText = "Übersicht";
                break;
            default:
                statusText = "";
                break;
        }
        return (
            <Box className="RedeemCoupon">
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={12} sm={12} md={7} lg={5} xl={3}>
                        <Box m={2}>
                            <Paper>
                                <div style={{backgroundColor:"lightgray", display: "flex", justifyContent: "space-between", borderTopLeftRadius: 5, borderTopRightRadius: 5, paddingLeft: 10}}>
                                    <Typography variant="h6">{statusText}</Typography>
                                    <Button
                                        onClick={this.handleCloseSuccess}
                                        color="secondary"
                                        type="submit"
                                        autoFocus
                                        disabled={this.state.status === 1 || this.state.status === 4 || this.state.showSpinner}
                                    >
                                        Abbrechen
                                    </Button>
                                </div>
                                <Box p={2}>
                                    <Grid container spacing={2} alignItems="center" justify="space-between">
                                        <Grid item style={{ flexGrow: 1 }}>
                                            {this.state.status === 1 &&
                                                <RedeemForm
                                                    resetForm={this.state.resetForm}
                                                    barcode={this.state.barcode}
                                                    invoiceValue={this.state.invoiceValue}
                                                    handleRedeemFormResponse={this.handleRedeemFormResponse}
                                                    showRedeemSuccessScreen={this.showRedeemSuccessScreen}
                                                    setInvoiceValue={this.setInvoiceValue}
                                                    couponList={this.state.couponList}
                                                    couponsValue={this.state.couponsValue}
                                                    transactions={this.state.transactions}
                                                    handleShowConfirm={this.handleShowConfirm}
                                                    scannedCodeCount={this.state.scannedCodeCount}
                                                    invoiceIsPaid={this.state.invoiceIsPaid}
                                                    showSpinner={this.state.showSpinner}
                                                    redeemCouponClick={this.redeemCouponClick}
                                            />}
                                            {this.state.status === 2 &&
                                                <RedeemInsertCoupon
                                                    resetForm={this.state.resetForm}
                                                    barcode={this.state.barcode}
                                                    invoiceValue={this.state.invoiceValue}
                                                    onChangeInvoiceValueClick={this.onChangeInvoiceValueClick}
                                                    couponList={this.state.couponList}
                                                    couponsValue={this.state.couponsValue}
                                                    remainingValue={this.state.remainingValue}
                                                    scannedCodeCount={this.state.scannedCodeCount}
                                                    wasEnough={this.state.wasEnough}
                                                    transactions={this.state.transactions}
                                                    invoiceIsPaid={this.state.invoiceIsPaid}
                                                    orderIndex={this.state.orderIndex}
                                                    handleShowConfirm={this.handleShowConfirm}
                                                    showRedeemSuccessScreen={this.showRedeemSuccessScreen}
                                                    redeemCouponClick={this.redeemCouponClick}
                                                    showSpinner={this.state.showSpinner}
                                            />}
                                            {this.state.status === 3 &&
                                                <RedeemConfirm
                                                    invoiceValue={this.state.invoiceValue}
                                                    couponList={this.state.couponList}
                                                    couponsValue={this.state.couponsValue}
                                                    remainingValue={this.state.remainingValue}
                                                    transactions={this.state.transactions}
                                                    scannedCodeCount={this.state.scannedCodeCount}
                                                    onChangeInvoiceValueClick={this.onChangeInvoiceValueClick}
                                                    onAddCouponClick={this.onAddCouponClick}
                                                    wasEnough={this.state.wasEnough}
                                                    invoiceIsPaid={this.state.invoiceIsPaid}
                                                    orderIndex={this.state.orderIndex}
                                                    showRedeemSuccessScreen={this.showRedeemSuccessScreen}
                                                    showSpinner={this.state.showSpinner}
                                                    redeemCouponClick={this.redeemCouponClick}
                                            />}
                                            {this.state.status === 4 &&
                                                <RedeemSuccess
                                                    invoiceValue={this.state.invoiceValue}
                                                    couponList={this.state.couponList}
                                                    couponsValue={this.state.couponsValue}
                                                    remainingValue={this.state.remainingValue}
                                                    transactions={this.state.transactions}
                                                    scannedCodeCount={this.state.scannedCodeCount}
                                                    handleCloseSuccess={this.handleCloseSuccess}
                                                    handleGoToTransactions={this.handleGoToTransactions}
                                                    wasEnough={this.state.wasEnough}
                                                    invoiceIsPaid={this.state.invoiceIsPaid}
                                                    orderIndex={this.state.orderIndex}
                                            />}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(withRouter(RedeemCoupon));
