import { LOGIN_USER, RECEIVE_RETAILERINFORMATIONFROMDB, RECEIVE_EMPLOYEE, REMOVE_USER, TOGGLE_USER_SCAN_TUTORIAL,
    TOGGLE_QUICK_REDEEM_MODE } from "../actions/userAction";

const initialState = {
    cognitoUser: undefined,
    employee: undefined,
    retailer: undefined,
    isFetching: false,
    scanTutorialEnabled: true,
    quickRedeemModeEnabled: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return Object.assign({}, state, {
                cognitoUser: action.cognitoUser,
            });
        case RECEIVE_RETAILERINFORMATIONFROMDB:
            return Object.assign({}, state, {
                retailer: action.retailer,
            });
        case RECEIVE_EMPLOYEE:
            return Object.assign({}, state, {
                employee: action.employee,
                scanTutorialEnabled: action.employee.scanTutorialEnabled,
                quickRedeemModeEnabled: action.employee.quickRedeemModeEnabled,
            });
        case TOGGLE_USER_SCAN_TUTORIAL:      
            return Object.assign({}, state, {
                scanTutorialEnabled: action.scanTutorialEnabled
            })
        case TOGGLE_QUICK_REDEEM_MODE:      
            return Object.assign({}, state, {
                quickRedeemModeEnabled: action.quickRedeemModeEnabled
            })
        case REMOVE_USER:
            return Object.assign({}, state, {
                cognitoUser: undefined,
                employee: undefined,
                retailer: undefined,
                isFetching: false,
            });
        default:
            return state;
    }
};
