import { Button, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";

class ManagingDirectorForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            error: false,
            errorText: "",
            setContactPerson: false,
            contactName: "",
            contactPhone: "",
            contactMail: "",
        };

        this.updateManagingDirector = this.updateManagingDirector.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
    }

    updateManagingDirector(values) {
        let newLocalData = {
            ...this.props.localData,
            ManagingDirector: {
                name: values.managingDirectorName === "" ? " " : values.managingDirectorName,
                address: values.managingDirectorAddress === "" ? " " : values.managingDirectorAddress,
                phoneNumber: values.managingDirectorPhoneNumber === "" ? " " : values.managingDirectorPhoneNumber,
            },
        };

        this.props.successCallback(newLocalData, this.props.townData, this.props.stateData);
    }

    handleSkip() {
        this.props.successCallback(this.props.localData, this.props.townData, this.props.stateData);
    }

    render() {
        return (
            <div className="NewManagingDirector">
                {this.state.showSpinner && <CircularProgress size={24} className="ButtonSpinner" />}
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <Typography variant="h6">Geschäftsführer</Typography>
                <Formik
                    initialValues={{
                        managingDirectorName: this.props.localData.ManagingDirector
                            ? this.props.localData.ManagingDirector.managingDirectorName
                            : "",
                        managingDirectorAddress: this.props.localData.ManagingDirector
                            ? this.props.localData.ManagingDirector.managingDirectorAddress
                            : "",
                        managingDirectorPhoneNumber: this.props.localData.ManagingDirector
                            ? this.props.localData.ManagingDirector.managingDirectorPhoneNumber
                            : "",
                    }}
                    onSubmit={(values) => {
                        this.setState({
                            error: false,
                            errorText: "",
                        });
                        this.updateManagingDirector(values);
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="managingDirectorName"
                                        name="managingDirectorName"
                                        label="Name"
                                        onChange={props.handleChange}
                                        value={props.values.managingDirectorName}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="managingDirectorAddress"
                                        name="managingDirectorAddress"
                                        label="Adresse"
                                        onChange={props.handleChange}
                                        value={props.values.managingDirectorAddress}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="managingDirectorPhoneNumber"
                                        name="managingDirectorPhoneNumber"
                                        label="Private Telefonnummer"
                                        onChange={props.handleChange}
                                        value={props.values.managingDirectorPhoneNumber}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid className="NewStoreStepperButtons" container>
                                    <Grid item xs={6} className="PrevButtons">
                                        <Button
                                            type="button"
                                            onClick={this.props.handlePrevStep}
                                            variant="text"
                                            color="secondary"
                                        >
                                            Zurück
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={this.props.handleCancel}
                                            variant="text"
                                            color="secondary"
                                        >
                                            Abbrechen
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} className="NextButtons">
                                        <Button
                                            disabled={this.state.showSpinner}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Speichern
                                        </Button>
                                        <Button
                                            onClick={this.handleSkip}
                                            disabled={this.state.showSpinner}
                                            type="button"
                                            variant="contained"
                                            color="default"
                                        >
                                            Überspringen
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(ManagingDirectorForm);
