import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Auth, Storage } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createRetailer } from "../../store/actions/retailerActions";
import { getEmployeeWithCognitoId } from "../../store/actions/userAction";
import { store } from "../../store/store";
import Licence from "../StoreSettings/TradingLicence/Licence";
import SkipStepWarning from "./SkipStepWarning";
import UploadTradingLicence from "../../components/newStore/UploadTradingLicense";

class NewStoreUploadTradingLicence extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errorText: "",
            success: false,
            successText: "",
            image: null,
            showSpinner: false,
            overlayIsVisible: false,
            localData: this.props.location.state.localData ? this.props.location.state.localData : undefined,
            townData: this.props.location.state.townData ? this.props.location.state.townData : undefined,
            stateData: this.props.location.state.stateData ? this.props.location.state.stateData : undefined,
            skipVisible: false,
        };

        Storage.configure({
            Storage: {
                AWSS3: {
                    bucket: "licence90108-develop",
                },
            },
        });

        this.handleUploadTradingLicence = this.handleUploadTradingLicence.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
        this.handleToggleSkip = this.handleToggleSkip.bind(this);
        this.setTradingLicence = this.setTradingLicence.bind(this);
    }

    handleToggleSkip() {
        this.setState({
            skipVisible: !this.state.skipVisible,
        });
    }

    setTradingLicence(licence) {
        this.setState({
            licence: licence
        });
    }

    handleUploadTradingLicence = async () => {
        this.setState({ showSpinner: true });
        if (this.state.image !== null) {
            try {
                let fileType = this.state.licence.type.split("/");
                let fileName = this.state.localData.name + "." + fileType[1];

                Storage.put(fileName, this.state.licence, {
                    contentType: this.state.licence.type,
                })
                    .then((result) => {
                        this.setState(
                            {
                                localData: {
                                    ...this.state.localData,
                                    licence: result.key,
                                },
                            },
                            () => {
                                Auth.currentAuthenticatedUser().then((user) => {
                                    store
                                        .dispatch(
                                            createRetailer(
                                                this.state.localData,
                                                this.state.stateData,
                                                this.state.townData,
                                                user.attributes.sub
                                            )
                                        )
                                        .then((response) => {
                                            store
                                                .dispatch(getEmployeeWithCognitoId(user.attributes.sub))
                                                .then((res) => {
                                                    if (
                                                        this.state.localData.image === " " ||
                                                        this.state.localData.ManagingDirector.name === " " ||
                                                        this.state.localData.billingInformation.iban === " "
                                                    ) {
                                                        this.setState({
                                                            showSpinner: false,
                                                            success: true,
                                                            successText:
                                                                "Zur Überprüfung und zur Freischaltung des Händlers werden weitere Informationen benötigt. Bitte vervollständigen Sie ihre Daten.",
                                                        });
                                                    } else {
                                                        this.setState({
                                                            showSpinner: false,
                                                            success: true,
                                                            successText:
                                                                "Händler wurde erfolgreich angelegt und wird nun geprüft.",
                                                        });
                                                    }
                                                })
                                                .catch((err) => {
                                                    if (
                                                        this.state.localData.image === " " ||
                                                        this.state.localData.ManagingDirector.name === " " ||
                                                        this.state.localData.billingInformation.iban === " "
                                                    ) {
                                                        this.setState({
                                                            showSpinner: false,
                                                            success: true,
                                                            successText:
                                                                "Zur Überprüfung und zur Freischaltung des Händlers werden weitere Informationen benötigt. Bitte vervollständigen Sie ihre Daten.",
                                                        });
                                                    } else {
                                                        this.setState({
                                                            showSpinner: false,
                                                            success: true,
                                                            successText:
                                                                "Händler wurde erfolgreich angelegt und wird nun geprüft.",
                                                        });
                                                    }
                                                });
                                        })
                                        .catch((err) => {
                                            this.setState({
                                                showSpinner: false,
                                                error: true,
                                                errorText:
                                                    "Leider ist ein Fehler beim Anlegen passiert. Bitte wende dich an unseren Support unter support@keeplocal.de",
                                            });
                                        });
                                });
                            }
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({
                            showSpinner: false,
                            error: true,
                            errorText:
                                "Leider ist ein Fehler beim Hochladen des Gewerbescheins aufgtreten. Bitte schicken sie uns den Gewerbeschein per Mail an support@keeplocal.de",
                        });
                    });
            } catch (err) {
                console.log(err);
                this.setState({
                    showSpinner: false,
                    error: true,
                    errorText:
                        "Leider ist ein Fehler beim Hochladen des Gewerbescheins aufgtreten. Bitte schicken sie uns den Gewerbeschein per Mail an support@keeplocal.de",
                });
            }
        } else {
            this.setState({
                showSpinner: false,
                error: true,
                errorText: "Es wurde kein Bild ausgewählt",
            });
        }
    };

    handleSkip() {
        this.setState({ showSpinner: true, skipVisible: false });
        Auth.currentAuthenticatedUser().then((user) => {
            store
                .dispatch(
                    createRetailer(this.state.localData, this.state.stateData, this.state.townData, user.attributes.sub)
                )
                .then((response) => {
                    store
                        .dispatch(getEmployeeWithCognitoId(user.attributes.sub))
                        .then((res) => {
                            if (
                                this.state.localData.image === " " ||
                                this.state.localData.ManagingDirector.name === " " ||
                                this.state.localData.billingInformation.iban === " "
                            ) {
                                this.setState({
                                    showSpinner: false,
                                    success: true,
                                    successText:
                                        "Zur Überprüfung und zur Freischaltung des Händlers werden weitere Informationen benötigt. Bitte vervollständigen Sie ihre Daten.",
                                });
                            } else {
                                this.setState({
                                    showSpinner: false,
                                    success: true,
                                    successText: "Händler wurde erfolgreich angelegt und wird nun geprüft.",
                                });
                            }
                        })
                        .catch((err) => {
                            if (
                                this.state.localData.image === " " ||
                                this.state.localData.ManagingDirector.name === " " ||
                                this.state.localData.billingInformation.iban === " "
                            ) {
                                this.setState({
                                    showSpinner: false,
                                    success: true,
                                    successText:
                                        "Zur Überprüfung und zur Freischaltung des Händlers werden weitere Informationen benötigt. Bitte vervollständigen Sie ihre Daten.",
                                });
                            } else {
                                this.setState({
                                    showSpinner: false,
                                    success: true,
                                    successText: "Händler wurde erfolgreich angelegt und wird nun geprüft.",
                                });
                            }
                        });
                })
                .catch((err) => {
                    this.setState({
                        showSpinner: false,
                        error: true,
                        errorText:
                            "Leider ist ein Fehler beim Anlegen passiert. Bitte wende dich an unseren Support unter support@keeplocal.de",
                    });
                });
        });
    }

    render() {
        return (
            <div className="NewStoreUploadTradingLicence">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <Typography variant="h6" style={{ marginBottom: "16px" }}>
                    Händlerlizenze
                </Typography>
                <UploadTradingLicence setTradingLicence={this.setTradingLicence}/>
                {this.state.image && (
                    <React.Fragment>
                        <Licence licence={this.state.licence} preview={true} />
                    </React.Fragment>
                )}
                <Box mt={2} position="relative">
                    <Button
                        fullWidth
                        disabled={this.state.showSpinner}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={this.handleUploadTradingLicence}
                    >
                        Speichern
                    </Button>
                    {this.state.showSpinner && <CircularProgress size={24} className="Spinner" />}
                </Box>
                <Box mt={2}>
                    <Button
                        onClick={this.handleToggleSkip}
                        fullWidth
                        disabled={this.state.showSpinner}
                        type="button"
                        variant="contained"
                        color="default"
                    >
                        Überspringen
                    </Button>
                </Box>
                <Dialog
                    open={this.state.success}
                    onBackdropClick={() => this.props.history.push("/administration/")}
                    onClose={() => this.props.history.push("/administration/")}
                    aria-labelledby="success-dialog-title"
                >
                    <DialogTitle id="success-dialog-title">Händler erfolgreich angelegt</DialogTitle>
                    <DialogContent>{this.state.successText}</DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.history.push("/administration/")}>Weiter</Button>
                    </DialogActions>
                </Dialog>
                <SkipStepWarning
                    visible={this.state.skipVisible}
                    onHiding={this.handleToggleSkip}
                    onSkip={this.handleSkip}
                    title="Achtung"
                >
                    <p>
                        <b>Ohne Gewerbeschein / Handelsregisterauszug können wir Dich nicht freischalten.</b>
                    </p>
                    <p>
                        Bitte hab Verständnis, dass wir Dein Unternehmen erst freischalten können, wenn wir den Nachweis
                        haben, dass Du hier auch Dein eigenes Unternehmen registrierst. Wenn Du ihn gerade nicht parat
                        hast, schick ihn uns gerne per E-Mail an{" "}
                        <a href="mailto:support@keeplocal.de">support@keeplocal.de</a>.
                    </p>
                </SkipStepWarning>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(withRouter(NewStoreUploadTradingLicence));
