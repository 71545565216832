import { API } from "aws-amplify";
import { store } from "../store";

export function createCharging(values) {
    let message = {
        body: values,
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/createCharging", message);
    };
}

export function updateCharging(values) {
    let message = {
        body: values,
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/updateCharging", message);
    };
}

export function getChargings(retailersId) {
    store.dispatch({ type: REQUEST_CHARGINGS, isLoading: true });
    let message = {
        body: { retailersId: retailersId },
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/getChargings", message).then((res) => {
            dispatch({ type: RECEIVE_CHARGINGS, isLoading: false, chargings: res });
        });
    };
}

export const REQUEST_CHARGINGS = "REQUEST_CHARGINGS";
export function requestChargings(isLoading) {
    return {
        type: REQUEST_CHARGINGS,
        isLoading: isLoading,
    };
}

export const RECEIVE_CHARGINGS = "RECEIVE_CHARGINGS";
export function receiveChargings(isLoading, chargings) {
    return {
        type: RECEIVE_CHARGINGS,
        isLoading: false,
        chargings: chargings,
    };
}

export function getOrders(retailersId) {
    store.dispatch({ type: REQUEST_ORDERS, isLoading: true });
    let message = {
        body: { retailersId: retailersId },
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/getOrders", message).then((res) => {
            dispatch({ type: RECEIVE_ORDERS, isLoading: false, orders: res });
        });
    };
}

export const REQUEST_ORDERS = "REQUEST_ORDERS";
export function requestOrders(isLoading) {
    return {
        type: REQUEST_ORDERS,
        isLoading: isLoading,
    };
}

export const RECEIVE_ORDERS = "RECEIVE_ORDERS";
export function receiveOrders(isLoading, orders) {
    return {
        type: RECEIVE_ORDERS,
        isLoading: false,
        orders: orders,
    };
}
