import { Box, Card, CardContent, CardHeader, Grid, Modal, Typography } from "@material-ui/core";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import { Storage } from "aws-amplify";
import { Form, Formik } from "formik";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import InfoIcon from "@material-ui/icons/Info";

class SettingsCoupon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showFontColorPicker: false,
            showBackgroundColorPicker: false,
            logo: this.props.retailer.informations.imageUrl,
            helperText: "",
            showHelperText: false,
        };

        Storage.configure({
            Storage: {
                AWSS3: {
                    bucket: "localheroesretailer-retailer-logos-master",
                },
            },
        });

        this.formRef = React.createRef();
        this.setDefaultColors = true;

        this.handleChangeFontColor = this.handleChangeFontColor.bind(this);
        this.handleChangeBackgroundColor = this.handleChangeBackgroundColor.bind(this);

        this.uploadImage = this.uploadImage.bind(this);
        this.uploadImageRejected = this.uploadImageRejected.bind(this);
        this.toggleHelperText = this.toggleHelperText.bind(this);
        this.getAWSImageUrl = this.getAWSImageUrl.bind(this);
    }

    handleChangeFontColor(e) {
        this.props.handleSetFontColor(e);
    }

    handleChangeBackgroundColor(e) {
        this.props.handleSetBackgroundColor(e);
    }

    uploadImageRejected(e) {
        let errors = e[0].errors;
        var errorText = "Dateityp wurde nicht akzeptiert. Bitte nur *.pdf-Dateien, *.jpg- und *.png-Bilder verwenden";

        switch (errors[0].code) {
            case "file-too-large":
                errorText = "Datei ist zu groß!";
                break;

            default:
                break;
        }

        this.props.enqueueSnackbar(errorText, {
            variant: "error",
        });
    }

    uploadImage(e) {
        if (e[0] && this.props.retailer) {
            let timestamp = new Date().getTime();
            let fileType = e[0].type.split("/");
            let fileName = this.props.retailer.informations.name + "_logo_" + timestamp + "." + fileType[1];

            Storage.put(fileName, e[0], {
                contentType: e[0].type,
                level: "public",
            })
                .then((result) => {
                    var licenceKey = result.key;

                    this.props.handleSetImage(
                        "https://keeplocalretailerimages-develop.s3.eu-central-1.amazonaws.com/public/" + result.key
                    );
                    Storage.get(licenceKey)
                        .then((result) => {
                            this.setState({
                                logo: result,
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            this.props.enqueueSnackbar(
                                "Fehler beim Hochladen der Datei. Bitte wende dich an unseren Support.",
                                {
                                    variant: "error",
                                }
                            );
                        });
                })
                .catch((err) => {
                    console.log(err);
                    this.props.enqueueSnackbar(
                        "Fehler beim Hochladen der Datei. Bitte wende dich an unseren Support.",
                        {
                            variant: "error",
                        }
                    );
                });
        }
    }

    getAWSImageUrl() {
        if (!this.state.logo && this.props.image) {
            if (typeof this.props.image === "string") {
                var key = this.props.image.split("/");
                Storage.get(key[key.length - 1])
                    .then((result) => {
                        this.setState({ logo: result });
                        this.props.handleSetImage(result, false);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }

    toggleHelperText() {
        this.setState({ showHelperText: !this.state.showHelperText });
    }

    componentDidUpdate(prevProps) {
        if (this.setDefaultColors) {
            this.formRef.current.setFieldValue("fontColor", this.props.fontColor);
            this.formRef.current.setFieldValue("backgroundColor", this.props.backgroundColor);
            this.formRef.current.validateForm();
            this.setDefaultColors = false;
        }

        if (this.props.image !== prevProps.image) {
            this.getAWSImageUrl();
        }
    }

    render() {
        const FILE_SIZE = 100 * 1024;
        const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

        return (
            <React.Fragment>
                <Card>
                    <CardHeader
                        title={
                            <Box display="flex" alignItems="center">
                                <ColorLensIcon style={{ marginRight: 5 }} /> Unternehmenslogo{" "}
                                <InfoIcon
                                    style={{ position: "relative", left: 110, fontSize: 18 }}
                                    onClick={() =>
                                        this.setState(
                                            {
                                                helperText:
                                                    "Laden Sie hier das Logo hoch das später auf Ihren Mitarbeitergutscheinen erscheinen soll oder verwenden sie das bereits definierte Logo.",
                                            },
                                            () => this.toggleHelperText()
                                        )
                                    }
                                />
                            </Box>
                        }
                    />
                    <CardContent>
                        <Formik
                            innerRef={this.formRef}
                            initialValues={{
                                fontColor: this.props.fontColor,
                                backgroundColor: this.props.backgroundColor,
                            }}
                            validationSchema={Yup.object().shape({
                                fontColor: Yup.string()
                                    .required("Dieses Feld wird benötigt")
                                    .matches("^#(?:[0-9a-fA-F]{3}){1,2}$", "Kein gültiger Farbcode"),
                                backgroundColor: Yup.string()
                                    .required("Dieses Feld wird benötigt")
                                    .matches("^#(?:[0-9a-fA-F]{3}){1,2}$", "Kein gültiger Farbcode"),
                            })}
                            onSubmit={(values) => {
                                this.props.handleSetColorsAndUpdate(values);
                            }}
                            validateOnChange={true}
                        >
                            {(props) => (
                                <Form>
                                    <Grid container spacing={1}>
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item xs={12}>
                                                <Box mt={2}>
                                                    <Typography variant="subtitle1">Logo</Typography>
                                                    <Dropzone
                                                        accept={SUPPORTED_FORMATS}
                                                        onDropRejected={this.uploadImageRejected}
                                                        maxSize={FILE_SIZE}
                                                        multiple={false}
                                                        onDrop={this.uploadImage}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section
                                                                className={
                                                                    this.state.error
                                                                        ? "dropzoneContainer error"
                                                                        : "dropzoneContainer"
                                                                }
                                                            >
                                                                <div {...getRootProps({ className: "dropzone" })}>
                                                                    <input {...getInputProps()} />
                                                                    {this.props.image === null && (
                                                                        <React.Fragment>
                                                                            <Typography variant="body1">
                                                                                Ziehen Sie Dateien per Drag 'n' Drop
                                                                                hierher oder klicken Sie, um Dateien
                                                                                auszuwählen.
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                (Nur *.jpg- und *.png-Bilder werden
                                                                                akzeptiert)
                                                                            </Typography>
                                                                        </React.Fragment>
                                                                    )}
                                                                    {this.props.image && (
                                                                        <React.Fragment>
                                                                            <Box>
                                                                                {this.state.logo && (
                                                                                    <img
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            maxWidth: "100%",
                                                                                            maxHeight: "110px",
                                                                                        }}
                                                                                        alt="Logo"
                                                                                        src={this.state.logo}
                                                                                    />
                                                                                )}
                                                                            </Box>
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
                <Modal
                    open={this.state.showHelperText}
                    onBackdropClick={this.toggleHelperText}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            width: 500,
                            padding: "20px",
                            borderRadius: "13px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <InfoIcon style={{ marginRight: 20 }} />
                        <Typography>{this.state.helperText}</Typography>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withSnackbar(SettingsCoupon);
