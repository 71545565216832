import { Box, Button, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from "@material-ui/core";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import * as Yup from "yup";

class BarcodeForm extends Component {
    render() {
        return (
            <Formik
                initialValues={{
                    barcode: "",
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    barcode: Yup.string().min(20, "kein korrekter Gutscheincode").required("Dieses Feld wird benötigt"),
                })}
                onSubmit={(values) => {
                    this.props.handleSubmit(values);
                }}
            >
                {(props) => (
                    <Form onSubmit={props.handleSubmit}>
                        <DialogTitle id="form-dialog-title">Gutscheinwert abfragen</DialogTitle>
                        {this.props.error && (
                            <Box mb={2}>
                                <Alert severity="error">{this.props.errorText}</Alert>
                            </Box>
                        )}
                        <DialogContent style={{ overflow: "hidden" }}>
                            <TextField
                                disabled={this.props.showSpinner}
                                autoComplete="off"
                                name="barcode"
                                label="Barcode scannen oder manuell eingeben"
                                size="small"
                                variant="outlined"
                                className="barcode"
                                onChange={props.handleChange}
                                onBlur={(e) => {
                                    props.setFieldValue("barcode", e.target.value.replace(/\D/g, ""));
                                }}
                                value={props.values.barcode}
                                fullWidth
                                error={props.errors.barcode && props.touched.barcode}
                                helperText={props.errors.barcode && props.touched.barcode ? props.errors.barcode : ""}
                                autoFocus={true}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CardGiftcardIcon fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </DialogContent>
                        <DialogActions
                            style={{
                                position: "relative",
                            }}
                        >
                            <Button onClick={this.props.handleCloseCheckCoupon} color="default">
                                Abbrechen
                            </Button>
                            <Button type="submit" color="secondary">
                                weiter
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default BarcodeForm;
