import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { removeRetailer } from "../../store/actions/retailerActions";
import { store } from "../../store/store";
import "./Header.scss";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            open: false,
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleMenu = this.handleMenu.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }

    handleMenu = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            open: true,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            open: false,
        });
    };

    handleLogout() {
        Auth.signOut().then((response) => {
            store.dispatch(removeRetailer());
            this.props.history.push("/account");
        });
    }

    handleChangePassword() {
        this.props.history.push("/administration/change-password");
    }

    render() {
        return (
            <AppBar className="Header" position="sticky" color="secondary" variant="outlined">
                <Toolbar className="Toolbar">
                    <Typography variant="h6" noWrap>
                        {this.props.title}
                    </Typography>
                    {/* {this.props.user && (
                        <img onClick={() => this.props.history.push("/partner-recruiting/recruit-new-partner")} style={{height: 66, cursor: "pointer"}} src={require('../../img/Partner_werben_Partner_Banner.png')} alt="50€ Schein" className="banner-image" />
                    )} */}
                    {this.props.user && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography noWrap>
                                {this.props.user.employee.forename} {this.props.user.employee.surname}
                            </Typography>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={this.handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={this.state.anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={this.state.open}
                                onClose={this.handleClose}
                            >
                                <MenuItem onClick={this.handleChangePassword}>Passwort ändern</MenuItem>
                                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state;
    return {
        user,
    };
}

export default connect(mapStateToProps)(withRouter(Header));
