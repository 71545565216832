import { Box, Card, CardContent, CardHeader, Grid, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { updateRetailerSetReportMailAddress } from "../../../../store/actions/retailerActions";
import { store } from "../../../../store/store";
import MailIcon from "@material-ui/icons/Mail";

class ReportMailAddress extends Component {
    constructor() {
        super();

        this.formRef = React.createRef();
        this.update = this.update.bind(this);
    }

    update(newValues) {
        var reportList = [newValues.email];

        if (this.formRef.current.dirty) {
            store
                .dispatch(updateRetailerSetReportMailAddress(this.props.retailer.informations.retailersId, reportList))
                .then(() => {
                    this.props.enqueueSnackbar("Buchhaltungsadresse erfolgreich aktualisiert", {
                        variant: "success",
                    });
                    this.formRef.current.resetForm({ values: newValues });
                })
                .catch(() => {
                    this.props.enqueueSnackbar("Fehler beim aktualsieren der Buchhaltungsadresse", {
                        variant: "error",
                    });
                });
        }
    }

    render() {
        return (
            <Card>
                <CardHeader
                    title={
                        <Box display="flex" alignItems="center">
                            <MailIcon style={{ marginRight: 5 }} /> Buchhaltungsadresse
                        </Box>
                    }
                />
                <CardContent>
                    <Formik
                        innerRef={this.formRef}
                        initialValues={{
                            email: this.props.retailer.informations
                                ? this.props.retailer.informations.reportMailingList.length !== 0
                                    ? this.props.retailer.informations.reportMailingList[0]
                                    : ""
                                : "",
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email("Keine korrekte E-Mail-Adresse"),
                        })}
                        onSubmit={(values) => {
                            this.update(values);
                        }}
                        validateOnChange={true}
                    >
                        {(props) => (
                            <Form onSubmit={props.handleSubmit} onBlur={props.handleSubmit}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="email"
                                            name="email"
                                            label="E-Mail-Adresse"
                                            onChange={props.handleChange}
                                            value={props.values.email}
                                            fullWidth
                                            error={props.errors.email ? true : false}
                                            helperText={props.errors.email}
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withSnackbar(ReportMailAddress));
