import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { getRetailerInformationsWithRetailersId } from "../store/actions/retailerActions";
import { addEmployeeToRetailer } from "../store/actions/userAction";
import { store } from "../store/store";

class AddEmployeeToRetailer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showSpinner: false,
      error: false,
      errorText: "",
      showDialog: false,
      dialogTitle: "",
      dialogText: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleSubmit(values) {
    this.setState(
      {
        showSpinner: true,
      },
      () =>
        store
          .dispatch(
            addEmployeeToRetailer(
              this.props.user.employee.cognitoId,
              values.code
            )
          )
          .then((response) => {
            console.log(response);
            store
              .dispatch(
                getRetailerInformationsWithRetailersId(
                  response.employee.newRetailer
                )
              )
              .then((res) => {
                this.setState({
                  showSpinner: false,
                  showDialog: true,
                  dialogTitle: "Erfolgreich",
                  dialogText: "Du wurdest erfolgreich der Filiale hinzugefügt",
                });
              });
          })
          .catch((err) => {
            console.log(this);
            this.setState({
              showSpinner: false,
              showDialog: true,
              dialogTitle: "Fehler",
              dialogText: err.message,
            });
          })
    );
  }

  handleClose() {
    if (this.state.error) {
      this.setState({
        showDialog: false,
      });
    } else {
      this.props.history.push("/administration/transactions");
    }
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.showDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.dialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Formik
          initialValues={{
            code: "",
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string()
              .min(8, "kein korrekter Code")
              .max(8, "kein korrekter Code")
              .required("Dieses Feld wird benötigt"),
          })}
          onSubmit={(values) => {
            this.handleSubmit(values);
          }}
          innerRef={this.formRef}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              {this.state.error && (
                <Box mb={2}>
                  <Alert severity="error">{this.state.errorText}</Alert>
                </Box>
              )}
              <Box mb={2} display="flex" alignItems="center">
                <TextField
                  disabled={this.state.showSpinner}
                  name="code"
                  label="Code eingeben"
                  size="small"
                  variant="outlined"
                  className="code"
                  onChange={props.handleChange}
                  value={props.values.code}
                  fullWidth
                  error={props.errors.code && props.touched.code}
                  helperText={
                    props.errors.code && props.touched.code
                      ? props.errors.code
                      : ""
                  }
                />
              </Box>
              <Box textAlign="right" position="relative">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    (!props.isValid && !props.dirty) || this.state.showSpinner
                  }
                >
                  Weiter
                </Button>
                {this.state.showSpinner && (
                  <CircularProgress size={24} className="ButtonSpinner" />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withRouter(AddEmployeeToRetailer));
