import { Box, Card, CardContent, CardHeader } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import Preview from "../../../../Preview.png";
import SettingsCoupon from "./SettingsCoupon";

class BrandedCardDesigner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fontColor: { rgb: { r: 0, g: 0, b: 0 }, hex: "#000000" },
            backgroundColor: { rgb: { r: 255, g: 255, b: 255 }, hex: "#FFFFFF" },
            image: this.props.retailer.informations.imageUrl,
        };

        this.handleSetBackgroundColor = this.handleSetBackgroundColor.bind(this);
        this.handleSetImage = this.handleSetImage.bind(this);
        this.handleSetFontColor = this.handleSetFontColor.bind(this);
    }

    handleSetBackgroundColor(color) {
        this.setState({ backgroundColor: color });
    }

    handleSetFontColor(color) {
        this.setState({ fontColor: color });
    }

    handleSetImage(image) {
        this.setState({ image: image });
    }

    render() {
        return (
            <div
                style={{
                    width: "400px",
                    height: "600px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                    marginLeft: "50px",
                    marginBottom: "50px",
                }}
            >
                <div style={{ width: "400px" }}>
                    <SettingsCoupon
                        fontColor={this.state.fontColor.hex}
                        backgroundColor={this.state.backgroundColor.hex}
                        image={this.state.image}
                        handleSetFontColor={this.handleSetFontColor}
                        handleSetBackgroundColor={this.handleSetBackgroundColor}
                        handleSetColorsAndUpdate={this.handleSetColorsAndUpdate}
                        handleSetImage={this.props.handleSetImage}
                        retailer={this.props.retailer}
                        update={this.update}
                    />
                </div>
                <div>
                    <Card style={{ height: "325px", width: "400px", marginTop: "20px" }}>
                        <CardHeader
                            title={
                                <Box display="flex" alignItems="center">
                                    <VisibilityIcon style={{ marginRight: 5 }} /> Beispiel
                                </Box>
                            }
                        />
                        <CardContent>
                            <div
                                style={{
                                    width: "340px",
                                    height: "216px",
                                    borderColor: "black",
                                    borderStyle: "solid",
                                    marginTop: "20px",
                                    borderWidth: "2px",
                                    borderRadius: "13px",
                                    position: "relative",
                                    color: this.state.fontColor.hex,
                                    backgroundColor: this.state.backgroundColor.hex,
                                    display: "inline-block",
                                    overflow: "hidden",
                                }}
                            >
                                <img
                                    alt="Vorschau"
                                    src={Preview}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "13px",
                                    }}
                                ></img>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withSnackbar(BrandedCardDesigner));
