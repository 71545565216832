import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    TextField,
    Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CachedIcon from "@material-ui/icons/Cached";
import GetAppIcon from "@material-ui/icons/GetApp";
import InfoIcon from "@material-ui/icons/Info";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Form, Formik } from "formik";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import handleLocalGoogleData from "../../../../helpers/GoogleDataHelper";
import {
    getGoogleData,
    getGoogleDataWithPlacesId,
    updateGoogleData,
    updateRetailerData,
    getTags,
} from "../../../../store/actions/retailerActions";
import { store } from "../../../../store/store";

class StoreData extends Component {
    constructor() {
        super();

        this.state = {
            googleUpdateDialogOpen: false,
            googleImportDialogOpen: false,
            showSpinner: false,
            name: "",
            town: "",
            candidates: [],
            candidateID: 0,
            tags: [],
            tagError: false,
            tagErrorText: "",
        };

        this.formRef = React.createRef();

        this.toggleGoogleUpdateDialog = this.toggleGoogleUpdateDialog.bind(this);
        this.handleGoogleUpdate = this.handleGoogleUpdate.bind(this);
        this.toggleGoogleImportDialog = this.toggleGoogleImportDialog.bind(this);
        this.getData = this.getData.bind(this);
        this.update = this.update.bind(this);
        this.importData = this.importData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.tagList = [];
        this.updateTags = this.updateTags.bind(this);
        this.updateTagList = this.updateTagList.bind(this);
    }

    componentDidMount() {
        this.tagList = [];
        store.dispatch(getTags()).then((result) => {            
            this.tagList = result.filter((obj) => obj.parentTag !== undefined && obj.parentTag !== "Internes");
        });
    }
    getData(name, town) {
        store.dispatch(getGoogleData(name, town)).then((response) => {
            this.setState({
                candidates: response.candidates,
            });
        });
    }

    importData(placeID) {
        this.props.retailer.informations.place_id = placeID;
        this.handleGoogleUpdate();
    }

    toggleGoogleUpdateDialog() {
        this.setState({
            googleUpdateDialogOpen: !this.state.googleUpdateDialogOpen,
        });
    }

    toggleGoogleImportDialog() {
        this.setState({
            googleImportDialogOpen: !this.state.googleImportDialogOpen,
        });
    }

    handleGoogleUpdate() {
        this.setState({
            googleUpdateDialogOpen: false,
            showSpinner: true,
        });
        if (this.props.retailer.informations.place_id !== " ") {
            store
                .dispatch(getGoogleDataWithPlacesId(this.props.retailer.informations.place_id))
                .then((placesData) => {
                    var localData = handleLocalGoogleData(
                        placesData.result,
                        this.props.retailer.informations.name,
                        this.props.retailer.informations.sellsAndRedeemOwnCoupons,
                        this.props.retailer.informations.redeemCityCoupons,
                        this.props.retailer.informations.isRetailer,
                        this.props.retailer.informations.weekday_text,
                        this.props.retailer.informations.periods
                    );
                    this.formRef.current.setFieldValue("phoneNumber", localData.phoneNumber);
                    this.formRef.current.setFieldValue("website", localData.website);
                    this.formRef.current.setFieldValue("weekday_text", localData.weekday_text);
                    this.formRef.current.setFieldValue("userRatingTotal", localData.userRatingTotal);
                    this.formRef.current.setFieldValue("rating", localData.rating);
                    this.formRef.current.setFieldValue("periods", localData.periods);
                    this.formRef.current.submitForm();
                    this.setState({
                        showSpinner: false,
                    });
                })
                .catch(() => {
                    this.props.enqueueSnackbar("Fehler beim Abrufen der Daten von Google", {
                        variant: "error",
                    });
                    this.setState({
                        showSpinner: false,
                    });
                });
        } else {
            this.props.enqueueSnackbar(
                "Das aktualisieren des Standortes wird in der App aktuell nicht unterstützt. Für eine Änderung wende dich bitte an unseren Support",
                {
                    variant: "error",
                }
            );
            this.setState({
                showSpinner: false,
            });
        }
    }

    update(newValues) {
        console.log(newValues);
        let contactPerson = this.props.retailer.informations.contactPerson;
        if (this.formRef.current.dirty) {
            var localData = {
                name: newValues.name,
                legalName: newValues.legalName,
                street: this.props.retailer.informations.street,
                town: this.props.retailer.informations.town,
                houseNumber: this.props.retailer.informations.houseNumber,
                state: this.props.retailer.informations.state,
                sellsAndRedeemOwnCoupons: newValues.sellsAndRedeemOwnCoupons,
                redeemCityCoupons: newValues.redeemCityCoupons,
                weekday_text: newValues.weekday_text,
                periods: newValues.periods,
                contactName: contactPerson.contactName ? contactPerson.contactName : " ",
                contactPhone: contactPerson.contactName ? contactPerson.contactName : " ",
                contactMail: contactPerson.contactName ? contactPerson.contactName : " ",
            };

            store
                .dispatch(
                    updateRetailerData(this.props.retailer.informations.retailersId, "newName", localData, {}, {})
                )
                .then(
                    store
                        .dispatch(
                            updateGoogleData(
                                this.props.retailer.informations.retailersId,
                                newValues.phoneNumber,
                                this.props.retailer.informations.rating,
                                this.props.retailer.informations.userRatingTotal,
                                newValues.website,
                                newValues.weekday_text,
                                this.state.tags,
                                newValues.text,
                                this.props.retailer.informations.periods,
                                contactPerson
                            )
                        )

                        .then((response) => {
                            this.props.enqueueSnackbar("Allgemeine Informationen erfolgreich aktualisiert", {
                                variant: "success",
                            });

                            this.formRef.current.resetForm({ values: newValues });
                        })
                        .catch(() => {
                            this.props.enqueueSnackbar("Fehler beim aktualsieren der allgemeinen Informationen", {
                                variant: "error",
                            });
                        })
                );
        }
    }

    async updateTagList(values) {
        this.setState({
            showSpinner: true,
        })
        console.log(values);       
        if (values.length > 2) {
            this.setState({
                tagError: true,
                tagErrorText: "Es können maximal 2 Tags ausgewählt werden",
                showSpinner: false,
            });
        } else if (values.length === 0){
            this.setState({
                tagError: true,
                tagErrorText: "Es muss mindestens 1 Tag ausgewählt werden",
                showSpinner: false,
            })
        } else {
            this.setState({
                tagError: false,
                tagErrorText: "",                
            });
            await this.updateTags(values);
        }
    }

    render() {
        return (
            <div>
                <Card>
                    <CardHeader
                        title={
                            <Box display="flex" alignItems="center">
                                <InfoIcon style={{ marginRight: 5 }} /> Allgemeine Informationen
                            </Box>
                        }
                        action={
                            <>
                                <Button
                                    onClick={this.toggleGoogleUpdateDialog}
                                    style={{ float: "right" }}
                                    variant="text"
                                    color="secondary"
                                    endIcon={<CachedIcon />}
                                >
                                    Google Daten
                                </Button>
                                <Button
                                    onClick={this.toggleGoogleImportDialog}
                                    style={{ float: "right" }}
                                    variant="text"
                                    color="secondary"
                                    endIcon={<GetAppIcon />}
                                >
                                    IMPORTIEREN
                                </Button>
                            </>
                        }
                    />
                    <CardContent>
                        <Formik
                            innerRef={this.formRef}
                            initialValues={{
                                name: this.props.retailer.informations.name,
                                legalName: this.props.retailer.informations.legalName,
                                town: this.props.retailer.informations.town,
                                street: this.props.retailer.informations.street,
                                houseNumber: this.props.retailer.informations.houseNumber,
                                phoneNumber: this.props.retailer.informations.phoneNumber,
                                website: this.props.retailer.informations.website.trim(),
                                text: this.props.retailer.informations.text,
                                sellsAndRedeemOwnCoupons: this.props.retailer.informations.sellsAndRedeemOwnCoupons,
                                redeemCityCoupons: this.props.retailer.informations.redeemCityCoupons,
                                userRatingTotal: this.props.retailer.informations.userRatingTotal,
                                rating: this.props.retailer.informations.rating,
                                weekday_text: this.props.retailer.informations.weekday_text,
                                tags: this.props.retailer.informations.tags,
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required("Dieses Feld wird benötigt"),
                                town: Yup.string().required("Dieses Feld wird benötigt"),
                                street: Yup.string().required("Dieses Feld wird benötigt"),
                                houseNumber: Yup.string().required("Dieses Feld wird benötigt"),
                                website: Yup.string().url("Das ist keine korrekte URL"),
                            })}
                            onSubmit={(values) => {
                                this.update(values);
                            }}
                            validateOnChange={true}
                        >
                            {(props) => (
                                <Form onBlur={props.handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="name"
                                                name="name"
                                                label="Name des Unternehmens / der Filiale"
                                                onChange={props.handleChange}
                                                value={props.values.name}
                                                fullWidth
                                                error={props.errors.name ? true : false}
                                                helperText={props.errors.name}
                                                tabIndex="1"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="legalName"
                                                name="legalName"
                                                label="Rechtliche Name des Unternehmens / der Filiale"
                                                onChange={props.handleChange}
                                                value={props.values.legalName}
                                                fullWidth
                                                error={props.errors.legalName ? true : false}
                                                helperText={props.errors.legalName}
                                                tabIndex="1"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="town"
                                                name="town"
                                                label="Stadt"
                                                onChange={props.handleChange}
                                                value={props.values.town}
                                                fullWidth
                                                disabled={true}
                                                error={props.errors.town ? true : false}
                                                helperText={props.errors.town}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="street"
                                                name="street"
                                                label="Straße"
                                                onChange={props.handleChange}
                                                value={props.values.street}
                                                fullWidth
                                                disabled={true}
                                                error={props.errors.street ? true : false}
                                                helperText={props.errors.street}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="houseNumber"
                                                name="houseNumber"
                                                label="Hausnummer"
                                                onChange={props.handleChange}
                                                value={props.values.houseNumber}
                                                fullWidth
                                                disabled={true}
                                                error={props.errors.houseNumber ? true : false}
                                                helperText={props.errors.houseNumber}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                label="Telefonnummer"
                                                onChange={props.handleChange}
                                                value={props.values.phoneNumber}
                                                fullWidth
                                                tabIndex="2"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                Öffnungszeiten{" "}
                                            </Typography>
                                            <Card
                                                id="opening_hours"
                                                name="opening_hours"
                                                label="Öffnungszeiten"
                                                onChange={props.handleChange}
                                                style={{ border: "none", boxShadow: "none" }}
                                                value={props.values.weekday_text}
                                                fullWidth
                                                error={props.errors.weekday_text ? true : false}
                                                helperText={props.errors.weekday_text}
                                                tabIndex="3"
                                            >
                                                <CardContent>
                                                    <Typography>
                                                        {this.props.retailer.informations.weekday_text[0]}
                                                    </Typography>
                                                    <Typography>
                                                        {this.props.retailer.informations.weekday_text[1]}
                                                    </Typography>
                                                    <Typography>
                                                        {this.props.retailer.informations.weekday_text[2]}
                                                    </Typography>
                                                    <Typography>
                                                        {this.props.retailer.informations.weekday_text[3]}
                                                    </Typography>
                                                    <Typography>
                                                        {this.props.retailer.informations.weekday_text[4]}
                                                    </Typography>
                                                    <Typography>
                                                        {this.props.retailer.informations.weekday_text[5]}
                                                    </Typography>
                                                    <Typography>
                                                        {this.props.retailer.informations.weekday_text[6]}
                                                    </Typography>
                                                </CardContent>{" "}
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="text"
                                                name="text"
                                                label="Unternehmensbeschreibung"
                                                onChange={props.handleChange}
                                                value={props.values.text}
                                                fullWidth
                                                multiline
                                                rows={6}
                                                tabIndex="4"
                                            />
                                        </Grid>
                                        <Grid item xs={12} />

                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={props.values.sellsAndRedeemOwnCoupons}
                                                        onChange={props.handleChange}
                                                        id="sellsAndRedeemOwnCoupons"
                                                        name="sellsAndRedeemOwnCoupons"
                                                        tabIndex="5"
                                                    />
                                                }
                                                label="Eigene Gutscheine über die Keep Local Plattform verkaufen"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={props.values.redeemCityCoupons}
                                                        onChange={props.handleChange}
                                                        id="redeemCityCoupons"
                                                        name="redeemCityCoupons"
                                                        tabIndex="6"
                                                    />
                                                }
                                                label="Am Stadtgutscheinsystem teilnehmen"
                                            />
                                        </Grid>
                                        <Grid item xs={12} hidden>
                                            <TextField
                                                id="userRatingTotal"
                                                name="userRatingTotal"
                                                label="userRatingTotal"
                                                onChange={props.handleChange}
                                                value={props.values.userRatingTotal}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} hidden>
                                            <TextField
                                                id="rating"
                                                name="rating"
                                                label="rating"
                                                onChange={props.handleChange}
                                                value={props.values.rating}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>

                        <Dialog
                            open={this.state.googleUpdateDialogOpen}
                            onBackdropClick={this.toggleGoogleUpdateDialog}
                        >
                            <DialogTitle>Google Daten Update</DialogTitle>
                            <DialogContent>
                                <Typography variant="body1">
                                    Möchtest du wirklich deine Daten von Google aktualisieren?
                                    <br />
                                    Damit würdest du ggf. aktuelle Daten überschreiben!
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button color="default" onClick={this.toggleGoogleUpdateDialog}>
                                    Nein erstmal nicht
                                </Button>
                                <Button color="secondary" onClick={this.handleGoogleUpdate}>
                                    Ja aktualisieren
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.googleImportDialogOpen}
                            onBackdropClick={this.toggleGoogleImportDialog}
                            onEnter={() =>
                                this.getData(
                                    this.props.retailer.informations.name,
                                    this.props.retailer.informations.town
                                )
                            }
                        >
                            <DialogTitle>
                                <Typography variant="h4">Google Daten Import</Typography>
                                <Typography variant="h6">Geschäft auswählen</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="body1" primary="Eintrag auswählen" />

                                {this.state.candidates.length > 0 &&
                                    this.state.candidates.map((candidate) => {
                                        return (
                                            <List
                                                onClick={() => {
                                                    this.importData(
                                                        this.state.candidates[this.state.candidateID].place_id
                                                    );
                                                    this.toggleGoogleImportDialog();
                                                }}
                                            >
                                                <Box
                                                    key={candidate.place_id}
                                                    borderBottom={2}
                                                    style={{
                                                        borderColor: "green",
                                                    }}
                                                >
                                                    <ListItem>
                                                        <Typography variant="h5">{candidate.name}</Typography>{" "}
                                                    </ListItem>
                                                    <ListItem>
                                                        <Typography variant="caption">
                                                            {candidate.formatted_address}
                                                        </Typography>
                                                    </ListItem>
                                                </Box>
                                            </List>
                                        );
                                    })}
                            </DialogContent>
                            <DialogActions></DialogActions>
                        </Dialog>

                        <Backdrop open={this.state.showSpinner} style={{ zIndex: 99999999 }}>
                            <CircularProgress />
                        </Backdrop>
                    </CardContent>
                </Card>
                <Box m={4} />
                <Card>
                    <CardHeader
                        title={
                            <>
                                <Typography variant="h5">
                                    <LoyaltyIcon />
                                    {" Tags"}
                                </Typography>
                                <Typography variant="caption">
                                    {" "}
                                    Wähle Tags aus, die zum Unternehmen passen. Dies ermöglicht es Ihren Kunden Sie
                                    unter diesen Schlagwörtern zu finden
                                </Typography>
                            </>
                        }
                    ></CardHeader>
                    <CardContent>
                        <Grid container justify="center">
                        {this.state.tagError && (
                                <Box mb={2}>
                                    <Alert severity="error">{this.state.tagErrorText}</Alert>
                                </Box>
                            )}                           
                              <Autocomplete
                                  multiple
                                  id="Tag-autocomplete"
                                  options={this.tagList}
                                  getOptionLabel={(tag) => tag.tagName}
                                  getOptionSelected={(option, value) => option.tagName === value.tagName}
                                  getOptionDisabled={(option) => this.state.tags.includes(option)}
                                  style={{ minWidth: "500px" }}
                                  value={this.props.retailer.informations.tags}
                                  onChange={(event, value) => {
                                      this.updateTagList(value);
                                  }}
                                renderTags={(tagValue, getTagProps) =>                                    
                                    tagValue.map((option, index) => (
                                        <Chip
                                            variant="outlined"
                                            color="secondary"
                                            label={option.tagName}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => <TextField {...params} label="Tags" variant="outlined" />}
                            ></Autocomplete>
                        </Grid>{" "}
                        <Box m={1} />
                    </CardContent>
                </Card>
            </div>
        );
    }

    handleChange(event) {
        console.log(event.target);
    }

    async updateTags(values) {
        console.log(this.props.retailer.informations, this.state);
        const retailer = await store.dispatch(
            updateGoogleData(
                this.props.retailer.informations.retailersId,
                this.props.retailer.informations.phoneNumber,
                this.props.retailer.informations.rating,
                this.props.retailer.informations.userRatingTotal,
                this.props.retailer.informations.website,
                this.props.retailer.informations.weekday_text,
                values,
                this.props.retailer.informations.text,
                this.props.retailer.informations.periods ? this.props.retailer.informations.periods : [],
                this.props.retailer.informations.contactPerson
                    ? this.props.retailer.informations.contactPerson
                    : { contactName: " ", contactPhone: " ", contactMail: " " }
            )
        ). then((result) => {
            console.log(result);
        });
        
        this.setState({
            showSpinner: false,            
        })
    }

    handleDelete(tagToDelete) {
        let newTags = this.state.tags.filter((tag) => tag !== tagToDelete);
        this.setState({ tags: newTags }, () => this.updateTags());
    }
}
function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withSnackbar(StoreData));
