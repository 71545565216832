import {
    DialogActions,
    FormControl,
    FormControlLabel,
    DialogContent,
    Button,
    Box,
    Grid,
    InputAdornment,
    InputLabel,
    CircularProgress,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@material-ui/core";
import { CalendarToday, Euro } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import * as Yup from "yup";
import { weekOfTheMonth } from "../../../../helpers/weekOfTheMonth";
import { createCharging, getChargings, updateCharging } from "../../../../store/actions/chargingActions";
import { store } from "../../../../store/store";
import { getCouponEmployeesByRetailersId } from "../../../../store/actions/retailerActions";
import { Autocomplete } from "@material-ui/lab";

class CreateOrderForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            allCards: this.props.charging ? this.props.charging.allCards : false,
            selectedCouponEmployees: [],
            initWithCharging: this.props.charging !== undefined,
        };

        this.handleReset = this.handleReset.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSwitchAllCards = this.handleSwitchAllCards.bind(this);
        this.setCouponEmployees = this.setCouponEmployees.bind(this);
    }

    componentDidMount() {
        store
            .dispatch(getCouponEmployeesByRetailersId(this.props.retailer.informations.retailersId, true))
            .then((response) => {
                if (this.props.charging) {
                    var cards = [];
                    if (this.props.charging.allCards) {
                        cards = this.props.retailer.couponEmployeesActive;
                    } else {
                        this.props.retailer.couponEmployeesActive.map((couponEmployeeActive) => {
                            var cardNumber = this.props.charging.cardNumbers.find(
                                (cardNumber) => cardNumber.couponEmployeesId === couponEmployeeActive.couponEmployeesId
                            );
                            if (cardNumber) {
                                cards.push(couponEmployeeActive);
                            }
                            return;
                        });
                        this.props.retailer.couponEmployeesNotActive.map((couponEmployeeNotActive) => {
                            var cardNumber = this.props.charging.cardNumbers.find(
                                (cardNumber) =>
                                    cardNumber.couponEmployeesId === couponEmployeeNotActive.couponEmployeesId
                            );
                            if (cardNumber) {
                                cards.push(couponEmployeeNotActive);
                            }
                            return;
                        });
                    }
                    this.setState({
                        selectedCouponEmployees: cards,
                    });
                }
            })
            .then((response) => {
                this.setState({ refreshing: false });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ refreshing: false });
            });
    }

    handleReset() {
        if (!this.state.showSpinner) {
            this.setState(
                {
                    showSpinner: false,
                },
                this.props.handleCloseCreateOrderPopup
            );
        }
    }

    handleSubmit(values) {
        let data = {};
        this.setState({
            showSpinner: true,
        });
        data.allCards = this.state.allCards;
        data.cardNumbers = this.state.selectedCouponEmployees.map((value) => {
            return { couponEmployeesId: value.couponEmployeesId, value: Number(values.couponValue) };
        });
        data.retailersId = this.props.retailer.informations.retailersId;
        if (!this.props.charging || this.props.charging.couponValue) {
            data.couponValue = Number(values.couponValue);
        }
        data.startDate = values.startDate.unix();
        data.comment = values.comment;
        data.repetition = Number(values.repetition);
        data.lastDate = values.lastDate.unix();
        data.importedOrder = false;
        if (this.props.charging) {
            data.chargingsId = this.props.charging.chargingsId;
        }

        if (values.repetition !== "0") {
            if (values.endsType === "0") {
                data.lastDate = null;
            }
            if (values.endsType === "1") {
                data.lastDate = values.lastDate.unix();
            }
            if (values.endsType === "2") {
                if (values.repetition === "3") {
                    data.lastDate = values.startDate.clone().add(Number(values.endsInterval), "y").unix();
                } else {
                    data.lastDate = values.startDate.clone().add(Number(values.endsInterval), "M").unix();
                }
            }
        } else {
            data.lastDate = data.startDate;
        }

        if (this.props.charging) {
            store
                .dispatch(updateCharging(data))
                .then((res) => console.log(res))
                .catch((err) => {
                    console.log(err);
                })
                .then(() => {
                    store.dispatch(getChargings(this.props.retailer.informations.retailersId));

                    this.setState(
                        {
                            showSpinner: false,
                        },
                        () => {
                            this.handleReset();
                        }
                    );
                });
        } else {
            store
                .dispatch(createCharging(data))
                .then((res) => console.log(res))
                .catch((err) => {
                    console.log(err);
                })
                .then(() => {
                    store.dispatch(getChargings(this.props.retailer.informations.retailersId));

                    this.setState(
                        {
                            showSpinner: false,
                        },
                        () => {
                            this.handleReset();
                        }
                    );
                });
        }
    }

    buildData(data) {
        let i = 0;
        let result = [];

        if (data) {
            data.shift();
            while (data[i]) {
                let item = {};
                if (data[i][0] !== "0") {
                    if (data[i][0] !== "") {
                        item.id = parseInt(data[i][0], 10);
                        item.value = parseFloat(data[i][1]);
                        if (isNaN(item.id)) {
                            item.id = data[i][0];
                        }
                        result.push(item);
                    }
                }
                i++;
            }
        }

        return result;
    }

    numberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator="."
                decimalSeparator=","
                isNumericString
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
            />
        );
    }

    handleSwitchAllCards() {
        this.setState(
            {
                allCards: !this.state.allCards,
            },
            () => {
                this.setState({
                    selectedCouponEmployees: this.state.allCards ? this.props.retailer.couponEmployeesActive : [],
                });
            }
        );
    }

    setCouponEmployees(value) {
        this.setState({
            selectedCouponEmployees: value,
            allCards: false,
        });
    }

    render() {
        const { showSpinner } = this.state;
        return (
            <div>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        couponValue:
                            this.props.charging && this.props.charging.couponValue
                                ? this.props.charging.couponValue
                                : 44,
                        comment: this.props.charging ? this.props.charging.comment : "",
                        startDate: this.props.charging
                            ? moment.unix(this.props.charging.startDate)
                            : moment().add(15, "days"),
                        repetition: this.props.charging ? this.props.charging.repetition.toString() : "0",
                        endsType: this.props.charging ? (this.props.charging.lastDate === null ? "0" : "date") : "0",
                        lastDate:
                            this.props.charging && this.props.charging.lastDate
                                ? moment.unix(this.props.charging.lastDate)
                                : moment().add(15, "days").add(1, "M"),
                        endsInterval: 2,
                        selectedCouponEmployees: this.state.selectedCouponEmployees,
                    }}
                    validationSchema={Yup.object().shape({
                        allCards: Yup.bool(),
                        couponValue: Yup.number()
                            .min(1, "Minimal 1€")
                            .max(250, "Maximal 250 €")
                            .required("Dieses Feld wird benötigt"),
                        startDate: Yup.date().required("Dieses Feld wird benötigt"),
                        selectedCouponEmployees: Yup.array().min(1, "Mindestens einen Mitarbeiter auswählen"),
                    })}
                    onSubmit={(values) => {
                        this.handleSubmit(values);
                    }}
                >
                    {(props) => {
                        return (
                            <Form onSubmit={props.handleSubmit}>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={this.state.allCards}
                                                            onChange={this.handleSwitchAllCards}
                                                            name="allCards"
                                                            size="small"
                                                        />
                                                    }
                                                    label="Alle angelegten Mitarbeiter(-karten)"
                                                />
                                            </FormControl>
                                        </Grid>
                                        {this.props.retailer.couponEmployeesActive && (
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    multiple
                                                    options={this.props.retailer.couponEmployeesActive}
                                                    getOptionLabel={(couponEmployee) => couponEmployee.displayValue}
                                                    value={this.state.selectedCouponEmployees}
                                                    style={{ width: "100%" }}
                                                    onChange={(e, value) => this.setCouponEmployees(value)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Mitarbeiter(-karten)"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Gutscheinwert"
                                                name="couponValue"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    inputComponent: this.numberFormatCustom,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Euro />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={props.handleChange}
                                                value={props.values.couponValue}
                                                error={props.errors.couponValue}
                                                helperText={props.errors.couponValue ? props.errors.couponValue : ""}
                                            />
                                        </Grid>
                                    </Grid>
                                    {this.props.retailer.couponEmployeesActive && (
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                multiple
                                                options={this.props.retailer.couponEmployeesActive}
                                                getOptionLabel={(couponEmployee) => couponEmployee.displayValue}
                                                value={props.values.selectedCouponEmployees}
                                                style={{ width: "100%" }}
                                                onChange={(e, value) => this.setCouponEmployees(value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Mitarbeiter(-karten)"
                                                        variant="outlined"
                                                        error={props.errors.selectedCouponEmployees}
                                                        helperText={
                                                            props.errors.selectedCouponEmployees
                                                                ? props.errors.selectedCouponEmployees
                                                                : ""
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                    {!this.props.charging ||
                                        (this.props.charging.couponValue && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Gutscheinwert"
                                                    name="couponValue"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    InputProps={{
                                                        inputComponent: this.numberFormatCustom,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Euro />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={props.handleChange}
                                                    value={props.values.couponValue}
                                                    error={props.errors.couponValue}
                                                    helperText={
                                                        props.errors.couponValue ? props.errors.couponValue : ""
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Kommentar (optional)"
                                            name="comment"
                                            fullWidth
                                            size="small"
                                            onChange={props.handleChange}
                                            value={props.values.comment}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            label="Termin"
                                            name="startDate"
                                            fullWidth
                                            size="small"
                                            inputVariant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CalendarToday />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={(value) => {
                                                this.setState({
                                                    initWithCharging: false,
                                                });
                                                props.setFieldValue("startDate", value);
                                                props.setFieldValue("lastDate", value.clone().add(1, "month"));
                                            }}
                                            value={props.values.startDate}
                                            minDate={this.state.initWithCharging ? undefined : moment().add(15, "days")}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ display: "flex", alignItems: "flex-end" }}>
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Select
                                                name="repetition"
                                                fullWidth
                                                size="small"
                                                onChange={props.handleChange}
                                                value={props.values.comment}
                                            />
                                        </FormControl>
                                        <Grid item xs={6}>
                                            <DatePicker
                                                label="Termin"
                                                name="startDate"
                                                fullWidth
                                                size="small"
                                                inputVariant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CalendarToday />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(value) => {
                                                    props.setFieldValue("startDate", value);
                                                    props.setFieldValue("lastDate", value.clone().add(1, "month"));
                                                }}
                                                value={props.values.startDate}
                                                minDate={moment().add(5, "days")}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ display: "flex", alignItems: "flex-end" }}>
                                            <FormControl fullWidth variant="outlined" size="small">
                                                <Select
                                                    name="repetition"
                                                    fullWidth
                                                    onChange={props.handleChange}
                                                    value={props.values.repetition}
                                                >
                                                    <MenuItem value="0">einmalig</MenuItem>
                                                    <MenuItem value="121">
                                                        monatlich am {props.values.startDate.date()}.
                                                    </MenuItem>
                                                    <MenuItem value="122">
                                                        monatlich am {weekOfTheMonth(props.values.startDate)}{" "}
                                                        {props.values.startDate.format("dddd")}
                                                    </MenuItem>
                                                    <MenuItem value="1">jährlich</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {props.values.repetition !== "0" && (
                                            <React.Fragment>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth variant="outlined" size="small">
                                                        <InputLabel variant="outlined" shrink>
                                                            Endet
                                                        </InputLabel>
                                                        <Select
                                                            name="endsType"
                                                            fullWidth
                                                            label="Endet"
                                                            onChange={props.handleChange}
                                                            value={props.values.endsType}
                                                        >
                                                            <MenuItem value="0">nie</MenuItem>
                                                            <MenuItem value="date">am</MenuItem>
                                                            <MenuItem value="interval">nach</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: "flex", alignItems: "flex-end" }}>
                                                    {props.values.endsType === "date" && (
                                                        <DatePicker
                                                            fullWidth
                                                            size="small"
                                                            inputVariant="outlined"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <CalendarToday />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onChange={(value) => props.setFieldValue("lastDate", value)}
                                                            value={props.values.lastDate}
                                                            minDate={props.values.startDate.clone().add(1, "month")}
                                                        />
                                                    )}
                                                    {props.values.endsType === "interval" && (
                                                        <TextField
                                                            variant="outlined"
                                                            name="endsInterval"
                                                            type="number"
                                                            inputProps={{ min: 2 }}
                                                            size="small"
                                                            fullWidth
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        Terminen
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onChange={props.handleChange}
                                                            value={props.values.endsInterval}
                                                            error={
                                                                props.errors.endsInterval && props.touched.endsInterval
                                                            }
                                                            helperText={
                                                                props.errors.endsInterval && props.touched.endsInterval
                                                                    ? props.errors.endsInterval
                                                                    : ""
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleReset} color="default" disabled={showSpinner}>
                                        Abbrechen
                                    </Button>
                                    <Box display="inline" position="relative">
                                        <Button type="submit" color="secondary" disabled={showSpinner}>
                                            {this.props.charging ? "Änderungen speichern" : "Erstellen"}
                                        </Button>
                                        {showSpinner && (
                                            <CircularProgress
                                                size=""
                                                style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    marginTop: -12,
                                                    marginLeft: -12,
                                                    width: "24px",
                                                }}
                                            />
                                        )}
                                    </Box>
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(CreateOrderForm);
