import { Backdrop, CircularProgress, Dialog } from "@material-ui/core";
import React, { Component } from "react";
import { getCouponDetails } from "../../../store/actions/couponActions";
import { store } from "../../../store/store";
import BarcodeForm from "./BarcodeForm";
import CouponValue from "./CouponValue";

export default class CheckCouponDialog extends Component {
    constructor() {
        super();

        this.state = {
            error: false,
            errorText: "",
            showSpinner: false,
            coupon: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    handleReset() {
        this.setState(
            {
                error: false,
                errorText: "",
                showSpinner: false,
                coupon: null,
            },
            this.props.handleCloseCheckCoupon
        );
    }

    handleSubmit(values) {
        this.setState({
            showSpinner: true,
        });

        store
            .dispatch(getCouponDetails(values.barcode))
            .then((response) => {
                this.setState({
                    showSpinner: false,
                    coupon: response,
                });
            })
            .catch((e) => {
                this.setState({
                    showSpinner: false,
                    error: true,
                    errorText: "Gutscheinwert konnte nicht abgerufen werden.",
                });
            });
    }

    render() {
        return (
            <Dialog
                fullWidth
                maxWidth="xs"
                open={this.props.openCheckCoupon}
                onClose={this.handleReset}
                aria-labelledby="form-dialog-title"
                className="CheckCoupon"
            >
                {this.state.coupon === null && (
                    <BarcodeForm
                        handleSubmit={this.handleSubmit}
                        error={this.state.error}
                        errorText={this.state.errorText}
                        showSpinner={this.state.showSpinner}
                        handleCloseCheckCoupon={this.handleReset}
                    ></BarcodeForm>
                )}

                {this.state.coupon !== null && (
                    <CouponValue coupon={this.state.coupon} handleCloseCheckCoupon={this.handleReset} />
                )}
                {this.state.showSpinner && (
                    <Backdrop style={{ zIndex: 1 }} open={this.state.showSpinner}>
                        <CircularProgress size={24} className="ButtonSpinner" />
                    </Backdrop>
                )}
            </Dialog>
        );
    }
}
