import { Button, CircularProgress, Grid, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Auth } from "aws-amplify";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { userLoginWithEmailAndPassword } from "../store/actions/userAction";
import { store } from "../store/store";

class ForceNewPasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            error: false,
            errorText: "",
        };
    }

    render() {
        var shape = {
            password: Yup.string().required("Dieses Feld wird benötigt"),
            passwordConfirm: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwörter stimmen nicht überein")
                .required("Dieses Feld wird benötigt"),
        };
        return (
            <div className="ChangePassword">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <Formik
                    initialValues={{
                        password: "",
                        passwordConfirm: "",
                    }}
                    validationSchema={Yup.object().shape(shape)}
                    onSubmit={(values) => {
                        this.setState({
                            showSpinner: true,
                            error: false,
                            errorText: "",
                        });
                        if (this.props.username && this.props.password) {
                            store
                                .dispatch(userLoginWithEmailAndPassword(this.props.username, this.props.password))
                                .then((user) => {
                                    if (user.cognitoUser.challengeName) {
                                        if (user.cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                                            console.log(user);
                                            Auth.completeNewPassword(user.cognitoUser, values.password)
                                                .then((data) => {
                                                    this.setState({ showSpinner: false });
                                                    this.props.history.push("/account/change-password-success", {
                                                        changePasswordSuccessful: true,
                                                    });
                                                })
                                                .catch((err) => {
                                                    this.setState({ showSpinner: false });
                                                    switch (err.code) {
                                                        case "InvalidPasswordException":
                                                            this.setState({
                                                                error: true,
                                                                errorText:
                                                                    "Das Passwort muss mindestens 8 Zeichen lang sein",
                                                            });
                                                            break;
                                                        case "InvalidParameterException":
                                                            this.setState({
                                                                error: true,
                                                                errorText:
                                                                    "Das Passwort muss mindestens 8 Zeichen lang sein",
                                                            });
                                                            break;
                                                        default:
                                                            this.setState({ error: true, errorText: err.message });
                                                    }
                                                });
                                        }
                                    }
                                })
                                .catch((err) => {
                                    switch (err.code) {
                                        case "UserNotConfirmedException":
                                            this.setState({ showSpinner: false }, () => {
                                                this.props.history.push("/account/confirm", {
                                                    username: this.state.username,
                                                });
                                            });
                                            break;
                                        case "PasswordResetRequiredException":
                                            this.setState({ showSpinner: false }, () => {
                                                this.props.history.push("/account/change-password", {
                                                    username: this.state.username,
                                                });
                                            });
                                            break;
                                        case "NotAuthorizedException":
                                            this.setState({
                                                showSpinner: false,
                                                error: true,
                                                errorText: "Passwort falsch",
                                            });
                                            break;
                                        case "UserNotFoundException":
                                            this.setState({
                                                showSpinner: false,
                                                error: true,
                                                errorText: "Benutzer nicht gefunden",
                                            });
                                            break;
                                        default:
                                            this.setState({
                                                showSpinner: false,
                                                error: true,
                                                errorText: "Fehler",
                                            });
                                            break;
                                    }
                                });
                        }
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="password"
                                        name="password"
                                        label="Neues Passwort"
                                        type="password"
                                        defaultValue={props.values.password}
                                        onChange={props.handleChange}
                                        error={props.errors.password ? true : false}
                                        helperText={props.errors.password}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="passwordConfirm"
                                        name="passwordConfirm"
                                        label="Neues Passwort wiederholen"
                                        type="password"
                                        defaultValue={props.values.passwordConfirm}
                                        onChange={props.handleChange}
                                        error={props.errors.passwordConfirm ? true : false}
                                        helperText={props.errors.passwordConfirm}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", position: "relative" }}
                                >
                                    <Button
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        weiter
                                    </Button>
                                    {this.state.showSpinner && <CircularProgress size={24} className="Spinner" />}
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withRouter(ForceNewPasswordForm);
