export default function handleLocalGoogleData(response, name, isRetailer) {
    var locationData = {};
    var town = " ",
        state = " ",
        street = " ",
        country = " ",
        zipCode = " ",
        houseNumber = " ";

    response.address_components.forEach((value) => {
        switch (value.types[0]) {
            case "street_number":
                houseNumber = value.long_name;
                break;
            case "route":
                street = value.long_name;
                break;
            case "locality":
                town = value.long_name;
                break;
            case "administrative_area_level_1":
                state = value.long_name;
                break;
            case "country":
                country = value.long_name;
                break;
            case "postal_code":
                zipCode = value.long_name;
                break;
            default:
                break;
        }
    });
    locationData.additionalCoupons = [];
    locationData.backgroundColor = "#FFFFFF";
    locationData.ChildRetailers = [];
    locationData.country = country;
    locationData.CouponEmployees = [];
    locationData.fontColor = "#000000";
    locationData.houseNumber = houseNumber;
    locationData.imageUrl =
        "https://local-heroes-retailer-images.s3.eu-central-1.amazonaws.com/keeplocallogofarbig.png";
    locationData.isRetailer = isRetailer;
    locationData.isActive = false;
    locationData.isAllowedToAcceptTownCoupons = false;
    locationData.sellsAndRedeemOwnCoupons = false;
    locationData.redeemCityCoupons = false;
    locationData.licence = " ";
    locationData.ManagingDirector = {
        address: " ",
        name: " ",
        phoneNumber: " ",
    };
    locationData.name = name;
    locationData.parentRetailersId = " ";
    locationData.phoneNumber = response.formatted_phone_number ? response.formatted_phone_number : " ";
    locationData.place_id = response.place_id ? response.place_id : " ";
    locationData.rating = response.rating ? response.rating.toString() : "0";
    locationData.reportMailingList = [" "];
    locationData.state = state;
    locationData.street = street;
    locationData.tags = [];
    locationData.text = " ";
    locationData.town = town;
    locationData.userRatingTotal = response.user_ratings_total ? response.user_ratings_total.toString() : "0";
    locationData.weekday_text = response.weekday_text ? response.weekday_text : [];
    locationData.zipCode = zipCode;
    locationData.billingInformation = {
        iban: " ",
        bic: " ",
        accountOwner: " ",
    };
    locationData.paidToParent = false;
    locationData.website = response.website ? response.website : " ";
    locationData.latitude = response.geometry.location.lat;
    locationData.longitude = response.geometry.location.lng;
    locationData.isBlocked = false;
    return locationData;
}
