import { Button, Typography, Dialog } from "@material-ui/core";
import { connect } from "react-redux";
import React, { Component } from "react";
import "./RedeemForm.scss";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

class RedeemWarningDialog extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Dialog
                open={this.props.invoicePaidPopupVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={null} 
                BackdropClick={false}
            >
                <DialogTitle id="alert-dialog-title">{this.props.invoiceIsPaid ? "Bezahlbetrag erreicht" : "Mitarbeiterkarte"}</DialogTitle>
                <DialogContent>
                    <Typography>
                        {this.props.notUsedCoupons && this.props.notUsedCoupons.length > 0 && (
                            <Typography>
                                <span>
                                    Die folgenden Gutscheine werden wegen der Anpassung des Rechnungsbetrages nicht mehr verwendet:
                                </span>
                                <br />
                                {this.props.notUsedCoupons.map((coupon, index) => {
                                    return (
                                        <span key={index}>
                                            {coupon.barcode} <br />
                                        </span>
                                    );
                                })}
                                <br />
                                <span style={{ fontWeight: "bold" }}>Bitte gib sie an den Kunden zurück.</span>
                                <br /><br />
                            </Typography>
                        )}
                        {this.props.transactions && this.props.transactions.length > 0 && !this.props.transactions.slice(-1)[0]?.isEmployeeCard ? (
                            <Typography>
                                {this.props.couponValueLastCoupon ? (
                                    <Typography>
                                        Nach der Einlösung werden sich noch{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {this.props.couponValueLastCoupon.toFixed(2).replace(".", ",") + " €"}
                                        </span>{" "}
                                        auf dem zuletzt gescannten Gutschein mit dem Barcode {this.props.couponList && this.props.couponList.length > 0 ? this.props.couponList.slice(-1)[0].barcode : ""} befinden.
                                        <br />
                                        Bitte gib ihn <span style={{ fontWeight: "bold" }}>an den Kunden zurück</span>.
                                    </Typography>
                                ) : (
                                    <Typography>
                                        {this.props.scannedCodeCount === 1 ? (
                                            <span>
                                                Nach der Einlösung wird der Gutschein{" "}
                                                <span style={{ fontWeight: "bold" }}>komplett aufgebraucht</span> sein.
                                                <br />
                                            </span>
                                        ) : (
                                            <span>
                                                Nach der Einlösung sind die eingescannten Gutscheine{" "}
                                                <span style={{ fontWeight: "bold" }}>komplett aufgebraucht</span>.
                                                <br />
                                            </span>
                                        )}
                                        <br />
                                        <span style={{ fontWeight: "bold" }}>
                                            Bitte entsorge{" "}
                                            {this.props.scannedCodeCount > 1 ? "die Gutscheine" : "den Gutschein"} erst,
                                            wenn der Kaufprozess komplett abgeschlossen ist.
                                        </span>
                                    </Typography>
                                )}
                            </Typography>
                        ) : (
                            <Typography>
                                Der zuletzt gescannte Gutschein mit dem Barcode {this.props.couponList && this.props.couponList.length > 0 ? this.props.couponList.slice(-1)[0].barcode : ""} ist eine Mitarbeiterkarte.
                                <br />Bitte gib sie nach der Einlösung{" "}
                                <span style={{ fontWeight: "bold" }}>an den Kunden zurück</span>.<br /><br />
                                {this.props.scannedCodeCount > 1 ? (
                                    <span style={{ fontWeight: "bold" }}>
                                        {"\n"}
                                        {"\n"}Bitte entsorge{" "}
                                        {this.props.scannedCodeCount > 2
                                            ? "die restlichen Gutscheine"
                                            : "den anderen Gutschein"}{" "}
                                        erst, wenn der Kaufprozess komplett abgeschlossen ist.
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Typography>
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.props.closeRedeemWarningPopup}
                        variant="contained"
                        color="secondary"
                        type="submit"
                        autoFocus
                    >
                        {this.props.invoiceIsPaid && this.props.user.quickRedeemModeEnabled ? "EINLÖSEN" : "OK"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(RedeemWarningDialog);
