import React, { Component } from "react";
import ForceNewPasswordForm from "../../helpers/ForceNewPasswordForm";

export default class ForceNewPassword extends Component {
    constructor() {
        super();

        this.state = {
            username: null,
            password: null,
        };
    }

    componentDidMount() {
        if (this.props.location.state.username && this.props.location.state.password) {
            this.setState({
                username: this.props.location.state.username,
                password: this.props.location.state.password,
            });
        } else {
            this.props.history.push("/account");
        }
    }

    render() {
        return <ForceNewPasswordForm username={this.state.username} password={this.state.password} />;
    }
}
