import { Backdrop, Box, Card, CardContent, CardHeader, CircularProgress, Grid, Button } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import Alert from "@material-ui/lab/Alert";
import { Storage } from "aws-amplify";
import { withSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { updateTradingLicence } from "../../../store/actions/retailerActions";
import { store } from "../../../store/store";
import Licence from "./Licence";
import "./TradingLicence.scss";
import UploadTradingLicence from "../../../components/newStore/UploadTradingLicense";

class TradingLicence extends Component {
    static propTypes = {
        setHeaderTitle: PropTypes.func,
    };

    constructor() {
        super();

        this.state = {
            licence: null,
            licenceType: "image",
            showSpinner: false,
            error: false,
            errorText: "",
        };

        Storage.configure({
            Storage: {
                AWSS3: {
                    bucket: "licence90108-develop",
                },
            },
        });

        this.uploadTradingLicence = this.uploadTradingLicence.bind(this);
        this.setTradingLicence = this.setTradingLicence.bind(this);
    }

    componentDidMount() {
        this.props.setHeaderTitle("Gewerbeschein");

        if (this.props.retailer.informations.licence.trim()) {
            this.setState({
                showSpinner: true,
            });
            Storage.get(this.props.retailer.informations.licence)
                .then((result) => {
                    this.setState({
                        licence: result,
                        showSpinner: false,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        showSpinner: false,
                    });
                });
        }
    }

    setTradingLicence(licence) {
        console.log(licence);
        this.setState({
            licence: licence,
        });
    }

    uploadTradingLicence() {
        if (this.state.licence && this.props.retailer) {
            this.setState({
                showSpinner: true,
                error: false,
                errorText: "",
            });
            let fileType = this.state.licence.type.split("/");
            let fileName = this.props.retailer.informations.name + "." + fileType[1];

            Storage.put(fileName, this.state.licence, {
                contentType: this.state.licence.type,
            })
                .then((result) => {
                    var licenceKey = result.key;
                    store
                        .dispatch(updateTradingLicence(this.props.retailer.informations.retailersId, result.key))
                        .then(() => {
                            Storage.get(licenceKey)
                                .then((result) => {
                                    this.setState({
                                        licence: result,
                                        showSpinner: false,
                                    });
                                })
                                .catch((err) => {
                                    console.log(err);
                                    this.setState({
                                        showSpinner: false,
                                        error: true,
                                        errorText:
                                            "Fehler beim Hochladen der Datei. Bitte wende dich an unseren Support.",
                                    });
                                });
                        })
                        .catch((err) => {
                            console.log(err);
                            this.setState({
                                showSpinner: false,
                                error: true,
                                errorText: "Fehler beim Hochladen der Datei. Bitte wende dich an unseren Support.",
                            });
                        });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        showSpinner: false,
                        error: true,
                        errorText: "Fehler beim Hochladen der Datei. Bitte wende dich an unseren Support.",
                    });
                });
        }
    }

    render() {
        console.log(this.state.licence);
        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="center"
                    style={{ textAlign: "left" }}
                    className="TradingLicence"
                >
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <Box pb={4} pt={4}>
                            <Card>
                                <CardHeader
                                    title={
                                        <Box display="flex" alignItems="center">
                                            <DescriptionIcon style={{ marginRight: 5 }} /> Gewerbeschein
                                        </Box>
                                    }
                                />
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            {this.state.error && (
                                                <Alert style={{ marginBottom: "20px" }} severity="error">
                                                    {this.state.errorText}
                                                </Alert>
                                            )}
                                            <UploadTradingLicence setTradingLicence={this.setTradingLicence} />
                                            {this.state.licence !== null && (
                                                <React.Fragment>
                                                    <Licence licence={this.state.licence} preview={true} />
                                                </React.Fragment>
                                            )}
                                            <Box mt={2} position="relative">
                                                <Button
                                                    fullWidth
                                                    disabled={this.state.showSpinner}
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.uploadTradingLicence}
                                                >
                                                    Speichern
                                                </Button>
                                                {this.state.showSpinner && (
                                                    <CircularProgress size={24} className="Spinner" />
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
                <Backdrop open={this.state.showSpinner} style={{ zIndex: 99999999 }}>
                    <CircularProgress />
                </Backdrop>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return {
        retailer,
    };
}

export default connect(mapStateToProps)(withSnackbar(TradingLicence));
