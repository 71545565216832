import { Box, Card, CardContent, CardHeader, Grid, TextField } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { Form, Formik } from "formik";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import { updateManagingDirector } from "../../../../store/actions/retailerActions";
import { store } from "../../../../store/store";

class ManagingDirector extends Component {
    constructor() {
        super();

        this.formRef = React.createRef();

        this.update = this.update.bind(this);
        this.state = {
            setContactPerson: false,
            contactName: "",
            contactPhone: "",
            contactMail: "",
        };
    }

    update(newValues) {
        if (this.formRef.current.dirty) {
            store
                .dispatch(
                    updateManagingDirector(
                        newValues.name,
                        newValues.address,
                        newValues.phoneNumber,
                        this.props.retailer.informations.retailersId
                    )
                )
                .then(() => {
                    this.props.enqueueSnackbar("Geschäftsführer erfolgreich aktualisiert", {
                        variant: "success",
                    });

                    this.formRef.current.resetForm({ values: newValues });
                })
                .catch(() => {
                    this.props.enqueueSnackbar("Fehler beim aktualsieren des Geschäftsführers", {
                        variant: "error",
                    });
                });
        }
    }

    render() {
        return (
            <Card>
                <CardHeader
                    title={
                        <Box display="flex" alignItems="center">
                            <PersonIcon style={{ marginRight: 5 }} /> Geschäftsführer
                        </Box>
                    }
                />
                <CardContent p={2}>
                    <Formik
                        innerRef={this.formRef}
                        initialValues={{
                            name:
                                this.props.retailer.informations.ManagingDirector.name !== " "
                                    ? this.props.retailer.informations.ManagingDirector.name
                                    : "",
                            address:
                                this.props.retailer.informations.ManagingDirector.address !== " "
                                    ? this.props.retailer.informations.ManagingDirector.address
                                    : "",
                            phoneNumber:
                                this.props.retailer.informations.ManagingDirector.phoneNumber !== " "
                                    ? this.props.retailer.informations.ManagingDirector.phoneNumber
                                    : "",
                        }}
                        onSubmit={(values) => {
                            this.update(values);
                        }}
                        validateOnChange={true}
                    >
                        {(props) => (
                            <Form onSubmit={props.handleSubmit} onBlur={props.handleSubmit}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="Name"
                                            onChange={props.handleChange}
                                            value={props.values.name}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="address"
                                            name="address"
                                            label="Adresse"
                                            onChange={props.handleChange}
                                            value={props.values.address}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            label="Telefonnummer"
                                            onChange={props.handleChange}
                                            value={props.values.phoneNumber}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withSnackbar(ManagingDirector));
