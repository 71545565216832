import { RECEIVE_CHARGINGS, REQUEST_CHARGINGS } from "../actions/chargingActions";

const initialState = {
    isLoading: false,
    chargings: [],
    orders: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_CHARGINGS:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case RECEIVE_CHARGINGS:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
                chargings: action.chargings,
            });
        default:
            return state;
    }
};
