import { Button, Card, CardContent, MenuItem, Modal, Select, TextField, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import { orderEmployeeCards } from "../../../../store/actions/couponActions";
import { store } from "../../../../store/store";
import BrandedCardDesigner from "./BrandedCardDesigner";
import ConfirmOrderDialog from "./ConfirmOrderDialog";
import OrderCouponsContactPerson from "./OrderCouponsContactPerson";

class OrderView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blanko: false,
            amount: 1,
            contactPerson: this.props.retailer.informations.contactPerson,
            text: "",
            image: undefined,
            showDialog: false,
            helperText: "",
            showHelperText: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.order = this.order.bind(this);
        this.handleSetImage = this.handleSetImage.bind(this);
        this.setContactPerson = this.setContactPerson.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
        this.toggleHelperText = this.toggleHelperText.bind(this);
    }

    handleChange(blanko) {
        this.setState({ blanko: blanko.target.value });
    }
    toggleHelperText() {
        this.setState({ showHelperText: !this.state.showHelperText });
    }

    order() {
        store
            .dispatch(
                orderEmployeeCards(
                    this.props.retailer.informations.retailersId,
                    this.state.amount,
                    this.state.blanko,
                    this.state.contactPerson,
                    this.state.blanko ? null : this.state.text,
                    this.state.blanko ? null : this.state.image
                )
            )
            .then((response) => {
                if (response.success)
                    this.props.enqueueSnackbar("Bestellung erfolgreich abgegeben", { variant: "success" });
                else this.props.enqueueSnackbar("Bestellung konnte nicht erstellt werden", { variant: "error" });
            });
    }

    handleSetImage(image) {
        this.setState({ image: image });
    }

    setContactPerson(contactPerson) {
        this.setState({ contactPerson: contactPerson });
    }

    toggleDialog() {
        this.setState({ showDialog: !this.state.showDialog });
    }

    render() {
        return (
            <React.Fragment>
                <Card
                    style={{
                        width: 1000,

                        height: 800,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                    }}
                >
                    <CardContent>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: this.state.blanko ? "column" : "row",
                                    justifyContent: this.state.blanko ? "center" : "flex-start",
                                    alignItems: this.state.blanko ? "center" : "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                        width: "400px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginBottom: "50px",
                                        }}
                                    >
                                        <Typography style={{ marginRight: "20px" }} component={"div"}>
                                            Art der Karten
                                        </Typography>
                                        <Select
                                            value={this.state.blanko}
                                            onChange={this.handleChange}
                                            style={{ marginLeft: 25 }}
                                        >
                                            <MenuItem value={true}>Standard</MenuItem>
                                            <MenuItem value={false}>Personalisiert</MenuItem>
                                        </Select>
                                        <InfoIcon
                                            style={{ position: "relative", left: 20, fontSize: 18 }}
                                            onClick={() =>
                                                this.setState(
                                                    {
                                                        helperText:
                                                            "Standard Karten sind kostenlos (Sie bezahlen nur die Aufladung). Personalisierte Karten kosten einmalig 100€, sowie jeweils 1€ pro Karte",
                                                    },
                                                    () => this.toggleHelperText()
                                                )
                                            }
                                        />
                                    </div>

                                    <div
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "50px",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography style={{ marginRight: "20px" }} component={"div"}>
                                            Anzahl an Karten
                                        </Typography>

                                        <TextField
                                            label="Anzahl"
                                            value={this.state.amount}
                                            onChange={(e) => {
                                                this.setState({ amount: parseInt(e.target.value) });
                                            }}
                                        ></TextField>
                                    </div>
                                    {!this.state.blanko && (
                                        <div
                                            style={{
                                                marginBottom: "50px",
                                                marginTop: "30px",
                                                marginRight: "50px",
                                            }}
                                        >
                                            <TextField
                                                label="Anmerkungen zu Ihrem Design"
                                                style={{ width: "350px" }}
                                                variant="outlined"
                                                multiline
                                                value={this.state.text}
                                                onChange={(e) => {
                                                    this.setState({ text: e.target.value });
                                                }}
                                            ></TextField>
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            flexDirection: "row",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        <OrderCouponsContactPerson setContactPerson={this.setContactPerson} />{" "}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "350px",

                                        flexDirection: "column",
                                    }}
                                >
                                    {!this.state.blanko && (
                                        <BrandedCardDesigner
                                            setContactPerson={(contactPerson) =>
                                                this.setState({ contactPerson: contactPerson })
                                            }
                                            order={() => this.order()}
                                            handleSetImage={this.handleSetImage}
                                        />
                                    )}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    width: 350,
                                    marginRight: 50,
                                }}
                            >
                                <Typography style={{ marginLeft: this.state.blanko ? 0 : 50 }}>{`Gesamtbetrag: ${
                                    this.state.blanko ? "0" : this.state.amount + 100
                                }€`}</Typography>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => this.toggleDialog()}
                                    style={{ width: 350, marginLeft: this.state.blanko ? 0 : 50, marginTop: 20 }}
                                >
                                    Bestellen
                                </Button>{" "}
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <Modal
                    open={this.state.showHelperText}
                    onBackdropClick={this.toggleHelperText}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "13px",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            width: 600,
                            padding: "20px",
                            borderRadius: "13px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <InfoIcon style={{ marginRight: 20 }} />
                        <Typography>{this.state.helperText}</Typography>
                    </div>
                </Modal>

                <ConfirmOrderDialog
                    open={this.state.showDialog}
                    onClose={() => this.toggleDialog()}
                    order={() => this.order()}
                    sum={this.state.blanko ? 0 : this.state.amount + 100}
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withSnackbar(OrderView));
