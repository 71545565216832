import { Container } from "@material-ui/core";
import { Amplify } from "aws-amplify";
import numeral from "numeral";
import "numeral/locales/de";
import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.scss";
import amplifySettings from "./aws-exports";
import Footer from "./layout/Footer/Footer";
import Account from "./pages/Account/Account";
import Administration from "./pages/Administration/Administration";
import Home from "./pages/Home/Home";
import Settings from "./pages/Settings/Settings";
import PrivateRoute from "./helpers/PrivateRoute";
import moment from "moment";
import "moment/locale/de";
import StoreSettings from "./pages/StoreSettings/StoreSettings";
import "./fonts/keeplocal/style.css";
import Recruiting from "./pages/PartnerRecruiting/Recruiting";

Amplify.configure(amplifySettings);

moment.locale("de");

class App extends Component {
    componentDidMount() {
        numeral.locale("de");
    }

    render() {
        return (
            <div className="App">
                <Router>
                    <Container maxWidth={false} disableGutters>
                        <Route exact path="/" component={Home} />
                        <Route path="/account" component={Account} />
                        <PrivateRoute path="/administration" component={Administration} />
                        <PrivateRoute path="/settings" component={Settings} />
                        <PrivateRoute path="/partner-recruiting" component={Recruiting} />
                        <PrivateRoute path="/store-settings" component={StoreSettings} />
                        {/* <Route path="/Signup" component={SignupScreen} />
                        <Route path="/RequestChangePassword" component={RequestChangePassword} />
                        <Route path="/ChangePassword" component={ChangePasswordScreen} />
                        <Route path="/Confirm" component={userConfirmScreen} /> */}
                    </Container>
                    <Footer />
                </Router>
            </div>
        );
    }
}

export default App;
