import React, { Component } from "react";
import { Box, Grid, Typography, Card, CardHeader, Backdrop, CircularProgress } from "@material-ui/core";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { Storage } from "aws-amplify";
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";
import PhotoViewer from "./PhotoViewer";
import imageCompression from "browser-image-compression";
import { store } from "../../../../store/store";
import { getTags } from "../../../../store/actions/retailerActions";

class PhotoUpload extends Component {
    constructor() {
        super();
        this.state = {
            images: [],
            showSpinner: false,
        };

        this.downloadImages = this.downloadImages.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.uploadImageRejected = this.uploadImageRejected.bind(this);

        Storage.configure({
            Storage: {
                AWSS3: {
                    bucket: "keeplocalretailerimages-develop",
                },
            },
        });
    }

    componentDidMount() {
        this.downloadImages();
        this.props.setHeaderTitle("Fotos");
        store.dispatch(getTags()).then((result) => {
            this.setState({ tags: result });
        });
    }

    uploadImageRejected(e) {
        let errors = e[0].errors;
        var errorText = "Dateityp wurde nicht akzeptiert. Bitte nur *.jpg- und *.png-Bilder verwenden";

        const options = {
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };

        switch (errors[0].code) {
            case "file-too-large":
                try {
                    this.setState({ showSpinner: true });
                    this.props.enqueueSnackbar("Ihr Foto wird komprimiert!", { variant: "success" });
                    imageCompression(e[0].file, options).then((result) => {
                        this.uploadImage(result);
                        this.setState({ showSpinner: false });
                    });
                } catch (err) {
                    console.log(err);
                }
                break;

            default:
                this.props.enqueueSnackbar(errorText, {
                    variant: "error",
                });
                break;
        }
    }

    deleteImage(key) {
        Storage.remove(key)
            .then(() => {
                this.props.enqueueSnackbar(`Foto wurde gelöscht!`, { variant: "success" });
                this.downloadImages();
            })
            .catch((err) => console.log(err));
    }

    downloadImages() {
        Storage.list(`${this.props.retailer.informations.retailersId}/`, { level: "public" })
            .then((result) => {
                this.setState({ images: result });
            })
            .catch((err) => console.log(err));
    }

    uploadImage(e) {
        let MAX_IMAGE_NUMBER = 10; //if this is changed, you have to change it in render too
        if (this.state.images.length >= MAX_IMAGE_NUMBER) alert("Maximale Anzahl Bilder erreicht");
        else if (e[0] && this.props.retailer) {
            this.setState({ showSpinner: true });
            this.props.enqueueSnackbar("Starte Upload!", { variant: "success" });
            let fileName = this.props.retailer.informations.retailersId + "/" + e[0].name;

            Storage.put(fileName, e[0], {
                contentType: e[0].type,
                level: "public",
            })
                .then((result) => {
                    var licenceKey = result.key;
                    this.props.enqueueSnackbar("Upload abgeschlossen!", { variant: "success" });
                    Storage.get(licenceKey)
                        .then((result) => {
                            this.downloadImages();
                        })
                        .catch((err) => {
                            console.log(err);
                            this.props.enqueueSnackbar(
                                "Fehler beim Hochladen der Datei. Bitte wende dich an unseren Support.",
                                {
                                    variant: "error",
                                }
                            );
                        });
                })
                .catch((err) => {
                    console.log(err);
                    this.props.enqueueSnackbar(
                        "Fehler beim Hochladen der Datei. Bitte wende dich an unseren Support.",
                        {
                            variant: "error",
                        }
                    );
                });
            this.setState({ showSpinner: false });
        }
    }

    render() {
        const FILE_SIZE = 200 * 1024;
        const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
        const MAX_IMAGE_NUMBER = 10; //if this is changed, you have to change it in uploadImage too
        return (
            <React.Fragment>
                <Box pt={4} pb={4} style={{ margin: "auto", maxWidth: "300px" }}>
                    {this.state.images.length <= MAX_IMAGE_NUMBER && (
                        <Card style={{ width: "300px", height: "290px" }}>
                            <CardHeader
                                title={
                                    <Box display="flex" alignItems="center">
                                        <PhotoSizeSelectActualIcon style={{ marginRight: 5 }} /> Fotos hochladen
                                    </Box>
                                }
                            />
                            <Grid item xs={12}>
                                <Box mt={2} style={{ width: "290px", height: "290px", margin: "auto" }}>
                                    <Dropzone
                                        accept={SUPPORTED_FORMATS}
                                        onDropRejected={this.uploadImageRejected}
                                        maxSize={FILE_SIZE}
                                        multiple={false}
                                        onDrop={this.uploadImage}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section
                                                className={
                                                    this.state.error ? "dropzoneContainer error" : "dropzoneContainer"
                                                }
                                            >
                                                <div {...getRootProps({ className: "dropzone" })}>
                                                    <input {...getInputProps()} />
                                                    {
                                                        <React.Fragment>
                                                            <Typography variant="body1">
                                                                Ziehen Sie Dateien per Drag 'n' Drop hierher oder
                                                                klicken Sie, um Dateien auszuwählen.
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                (Nur *.jpg- und *.png-Bilder werden akzeptiert)
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Box>
                            </Grid>
                        </Card>
                    )}
                    {this.state.images.length > MAX_IMAGE_NUMBER && (
                        <Box>
                            <Typography style={{ color: "red" }}>
                                Maximale Anzahl Bilder erreicht, löschen Sie Bilder um neue hochladen zu können
                            </Typography>
                        </Box>
                    )}
                </Box>
                <PhotoViewer
                    images={this.state.images}
                    delete={(key) => this.deleteImage(key)}
                    retailersId={this.props.retailer.informations.retailersId}
                    favorite={this.props.retailer.informations.favoriteImage}
                />

                <Backdrop open={this.state.showSpinner} style={{ zIndex: 99999999 }}>
                    <CircularProgress />
                </Backdrop>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withSnackbar(PhotoUpload));
