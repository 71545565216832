import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";
import { CalendarToday } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { Form, Formik } from "formik";
import moment from "moment";
import Papa from "papaparse";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import * as Yup from "yup";
import { createCharging, getChargings } from "../../../../store/actions/chargingActions";
import { store } from "../../../../store/store";

const FILE_SIZE = 100 * 1024;
const SUPPORTED_FORMATS = ["text/csv", "text/plain", "text/x-csv", "application/vnd.ms-excel"];

class ImportOrderForm extends Component {
    constructor() {
        super();

        this.state = {
            showSpinner: false,
            fileName: "",
        };

        this.handleReset = this.handleReset.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleReset() {
        if (!this.state.showSpinner) {
            this.setState(
                {
                    showSpinner: false,
                },
                this.props.handleCloseImportOrderPopup
            );
        }
    }

    handleSubmit(values) {
        let data = {};
        this.setState({
            showSpinner: true,
        });
        data.allCards = values.allCards;
        data.cardNumbers = this.buildData(values.cardNumbers);
        data.retailersId = this.props.retailer.informations.retailersId;
        data.couponValue = Number(values.couponValue);
        data.startDate = values.startDate.unix();
        data.repetition = Number(values.repetition);
        data.comment = values.comment;
        data.lastDate = values.lastDate.unix();
        data.importedOrder = true;

        if (values.repetition !== "0") {
            if (values.endsType === "0") {
                data.lastDate = null;
            }
            if (values.endsType === "1") {
                data.lastDate = values.lastDate.unix();
            }
            if (values.endsType === "2") {
                if (values.repetition === "3") {
                    data.lastDate = values.startDate.clone().add(Number(values.endsInterval), "y").unix();
                } else {
                    data.lastDate = values.startDate.clone().add(Number(values.endsInterval), "M").unix();
                }
            }
        } else {
            data.lastDate = data.startDate;
        }

        store
            .dispatch(createCharging(data))
            .then((res) => console.log(res))
            .catch((err) => {
                console.log(err);
            })
            .then(() => {
                store.dispatch(getChargings(this.props.retailer.informations.retailersId));

                this.setState(
                    {
                        showSpinner: false,
                    },
                    () => {
                        this.handleReset();
                    }
                );
            });
    }

    buildData(data) {
        let i = 0;
        let result = [];

        if (data) {
            data.shift();
            while (data[i]) {
                let item = {};
                if (data[i][0] !== "0") {
                    if (data[i][0] !== "") {
                        item.id = parseInt(data[i][0], 10);
                        item.value = parseFloat(data[i][1]);
                        if (isNaN(item.id)) {
                            item.id = data[i][0];
                        }
                        result.push(item);
                    }
                }
                i++;
            }
        }

        return result;
    }

    render() {
        const { showSpinner, fileName } = this.state;

        return (
            <React.Fragment>
                <Formik
                    initialValues={{
                        allCards: false,
                        cardNumbers: null,
                        couponValue: 0,
                        startDate: moment().add(15, "days"),
                        repetition: "0",
                        endsType: "0",
                        lastDate: moment().add(15, "days").add(1, "M"),
                        endsInterval: 2,
                        comment: "",
                    }}
                    validationSchema={Yup.object().shape({
                        cardNumbers: Yup.array().required("Dieses Feld wird benötigt"),
                        startDate: Yup.date().required("Dieses Feld wird benötigt"),
                    })}
                    onSubmit={(values) => {
                        this.handleSubmit(values);
                    }}
                >
                    {(props) => {
                        return (
                            <Form onSubmit={props.handleSubmit}>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Dropzone
                                                disabled={showSpinner}
                                                onDropAccepted={(files) => {
                                                    console.log(files);
                                                    this.setState({
                                                        fileName: files[0].path,
                                                    });
                                                    Papa.parse(files[0], {
                                                        complete: function (results) {
                                                            props.setFieldValue("cardNumbers", results.data);
                                                        },
                                                    });
                                                }}
                                                onDropRejected={(err, event) => console.log(err)}
                                                accept={SUPPORTED_FORMATS}
                                                maxSize={FILE_SIZE}
                                                multiple={false}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section
                                                        className={
                                                            props.errors.cardNumbers
                                                                ? "dropzoneContainer error"
                                                                : "dropzoneContainer"
                                                        }
                                                        style={{
                                                            borderColor: props.errors.cardNumbers
                                                                ? red["300"]
                                                                : grey["300"],
                                                        }}
                                                    >
                                                        <div {...getRootProps({ className: "dropzone" })}>
                                                            <input {...getInputProps()} />
                                                            {props.values.cardNumbers === null && (
                                                                <React.Fragment>
                                                                    <Typography variant="body1">
                                                                        Ziehen Sie Dateien per Drag 'n' Drop hierher
                                                                        oder klicken Sie, um Dateien auszuwählen.
                                                                    </Typography>
                                                                    <Typography variant="body2">
                                                                        (Nur *.csv-Dateien werden akzeptiert)
                                                                    </Typography>
                                                                </React.Fragment>
                                                            )}
                                                            {props.values.cardNumbers && (
                                                                <React.Fragment>{fileName}</React.Fragment>
                                                            )}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Kommentar (optional)"
                                                name="comment"
                                                fullWidth
                                                size="small"
                                                onChange={props.handleChange}
                                                value={props.values.comment}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DatePicker
                                                label="Termin"
                                                name="startDate"
                                                fullWidth
                                                size="small"
                                                inputVariant="outlined"
                                                disabled={showSpinner}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CalendarToday />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(value) => {
                                                    props.setFieldValue("startDate", value);
                                                    props.setFieldValue(
                                                        "lastDate",
                                                        value
                                                            .clone()
                                                            .add(1, props.values.repetition === "3" ? "y" : "M")
                                                    );
                                                }}
                                                value={props.values.startDate}
                                                minDate={moment().add(15, "days")}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ display: "flex", alignItems: "flex-end" }}>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                disabled={showSpinner}
                                            >
                                                <Select
                                                    name="repetition"
                                                    fullWidth
                                                    onChange={(e) => {
                                                        console.log(e);
                                                        props.setFieldValue("repetition", e.target.value);
                                                        props.setFieldValue(
                                                            "lastDate",
                                                            props.values.startDate
                                                                .clone()
                                                                .add(1, e.target.value === "3" ? "y" : "M")
                                                        );
                                                    }}
                                                    value={props.values.repetition}
                                                    disabled={showSpinner}
                                                >
                                                    <MenuItem value="0">einmalig</MenuItem>
                                                    <MenuItem value="1">
                                                        monatlich am {props.values.startDate.date()}.
                                                    </MenuItem>
                                                    {/* <MenuItem value="2">
                                                        monatlich am {weekOfTheMonth(props.values.startDate)}{" "}
                                                        {props.values.startDate.format("dddd")}
                                                    </MenuItem> */}
                                                    <MenuItem value="3">
                                                        jährlich am {props.values.startDate.format("D. MMMM")}
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {props.values.repetition !== "0" && (
                                            <React.Fragment>
                                                <Grid item xs={6}>
                                                    <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        disabled={showSpinner}
                                                    >
                                                        <InputLabel variant="outlined" shrink>
                                                            Endet
                                                        </InputLabel>
                                                        <Select
                                                            name="endsType"
                                                            fullWidth
                                                            label="Endet"
                                                            onChange={props.handleChange}
                                                            value={props.values.endsType}
                                                            disabled={showSpinner}
                                                        >
                                                            <MenuItem value="0">nie</MenuItem>
                                                            <MenuItem value="1">am</MenuItem>
                                                            <MenuItem value="2">nach</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: "flex", alignItems: "flex-end" }}>
                                                    {props.values.endsType === "1" && (
                                                        <DatePicker
                                                            fullWidth
                                                            size="small"
                                                            inputVariant="outlined"
                                                            disabled={showSpinner}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <CalendarToday />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onChange={(value) => props.setFieldValue("lastDate", value)}
                                                            value={props.values.lastDate}
                                                            minDate={props.values.startDate
                                                                .clone()
                                                                .add(1, props.values.repetition === "3" ? "y" : "M")}
                                                        />
                                                    )}
                                                    {props.values.endsType === "2" && (
                                                        <TextField
                                                            variant="outlined"
                                                            name="endsInterval"
                                                            type="number"
                                                            inputProps={{ min: 2 }}
                                                            size="small"
                                                            disabled={showSpinner}
                                                            fullWidth
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        Terminen
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onChange={props.handleChange}
                                                            value={props.values.endsInterval}
                                                            error={
                                                                props.errors.endsInterval && props.touched.endsInterval
                                                            }
                                                            helperText={
                                                                props.errors.endsInterval && props.touched.endsInterval
                                                                    ? props.errors.endsInterval
                                                                    : ""
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                        <Grid item xs={12} className="uploadInformation">
                                            <Typography variant="subtitle2" component="p" color="textSecondary">
                                                Lade hier eine *.csv-Datei hoch, um Aufträge zu importieren. In der
                                                *.csv-Datei bitte alle Mitarbeiterkarten mit ihrer jeweiligen Kartennummer
                                                in der Spalte "Kartennummer" anlegen und eine zweite Spalte
                                                "Gutscheinwert" für die jeweiligen Gutscheinwerte.
                                            </Typography>
                                            <Typography variant="subtitle2" component="p" color="textSecondary">
                                                Eine Beispiel-Datei kann{" "}
                                                <Link href="/Auftrag_Import_Beispiel.csv">hier</Link> heruntergeladen
                                                werden.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleReset} color="default" disabled={showSpinner}>
                                        Abbrechen
                                    </Button>
                                    <Box display="inline" position="relative">
                                        <Button type="submit" color="secondary" disabled={showSpinner}>
                                            Erstellen
                                        </Button>
                                        {showSpinner && (
                                            <CircularProgress
                                                size=""
                                                style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    marginTop: -12,
                                                    marginLeft: -12,
                                                    width: "24px",
                                                }}
                                            />
                                        )}
                                    </Box>
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(ImportOrderForm);
