import React from "react";
import { connect } from "react-redux";
import "./Preview.scss";

function Preview({ fontColor, backrgoundColor, image, retailer }) {
    return (
        <div id="preview">
            <div className="outerBorder">
                <div className="innerBorder">
                    <div className="notch"></div>
                    <div className="previewCoupon" style={{ backgroundColor: backrgoundColor }}>
                        <div className="image">
                            <img src={image} alt="" />
                        </div>
                        <div className="info" style={{ color: fontColor }}>
                            <div className="value">100 €</div>
                            <div className="name">{retailer.informations.name}</div>
                            {retailer.informations.tags.length > 0 && <div className="tags"></div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(Preview);
