import "./RecruitNewPartner.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import RecruitingFaqsPopup from "../RecruitingFaqsPopup/RecruitingFaqsPopup";
import { Tooltip } from "@material-ui/core";

class RecruitNewPartner extends Component {
    constructor() {
        super();

        this.state = {
            popupOpen: false,
        };

        this.closePopup = this.closePopup.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.openEmailClient = this.openEmailClient.bind(this);
    }

    componentDidMount() {
        this.props.setHeaderTitle("Neuen Partner werben");
    }

    closePopup() {
        this.setState({ popupOpen: false });
    }

    openPopup() {
        this.setState({ popupOpen: true });
    }

    copyToClipboard() {
        const linkToCopy =
            "https://keeplocal.de/mitmachen/partner/partnerregistrierung-partner-werben-partner?retailersId=" +
            this.props.retailer.informations.retailersId;

        const textArea = document.createElement("textarea");
        textArea.value = linkToCopy;
        document.body.appendChild(textArea);

        textArea.select();
        document.execCommand("copy");

        document.body.removeChild(textArea);

        this.setState({
            showLinkcopiedAlert: true,
        });
    }

    openEmailClient() {
        var user =
            this.props.user.employee.forename || this.props.user.employee.surname
                ? (this.props.user.employee.forename ? this.props.user.employee.forename + " " : "") +
                  (this.props.user.employee.surname ? this.props.user.employee.surname : "")
                : this.props.retailer.informations.name;
        const emailText =
            "Hallo,\n\n" +
            user +
            " findet, dass Du super zu KeepLocal passen würdest! Deshalb flattert diese Empfehlung, bei uns kostenfrei Partner zu werden, in Dein Postfach.\n\n" +
            "Dein Link: https://keeplocal.de/mitmachen/partner/partnerregistrierung-partner-werben-partner?retailersId=" +
            this.props.retailer.informations.retailersId +
            "\n\n" +
            "Was die KeepLocal-Partnerschaft bedeutet\n\n" +
            "KeepLocal-Partner können in ihren Unternehmen kostenfrei Stadt- und Regionalgutscheine einlösen und verkaufen. Verkäufer erhalten sogar eine kleine Provision. Als Partner bezahlst du keine Grundgebühren und gehst keine kostenpflichtige Mitgliedschaft ein. Wir wollen nämlich, dass jedes Unternehmen, größen- und branchenunabhängig, ohne Hürden bei uns mitmachen kann. Je mehr Unternehmen mitmachen, desto vielfältiger und attraktiver wird das Angebot für unsere Kunden. Schließlich wollen wir genau diese bunte Vielfalt für künftige Generationen erhalten!\n" +
            "So läuft die Registrierung ab\n\n" +
            "Über obenstehenden Link kannst Du Dich bei uns registrieren. Danach erwartet Dich ein kurzer und unkomplizierter Prozess, der Dich durch alle nötigen Schritte führt. Solltest Du mal nicht weiterkommen, ist unser Service-Team für Dich da.\n" +
            "Dein Willkommensgeschenk\n\n" +
            "Wir begrüßen Dich nicht nur mit offenen Armen, sondern auch mit einem schönen Geschenk in den Händen! Du erhältst von uns ein KeepLocal-Thekendisplay, das über vier Gutschein- und ein Flyer-Fach verfügt. Mit ihm kannst Du Deine brandneuen Gutscheine perfekt präsentieren.\n";
        const emailLink = `mailto:?subject=Deine KeepLocal Empfehlung&body=${encodeURIComponent(emailText)}`;
        window.location.href = emailLink;
    }

    render() {
        return (
            <div>
                {this.state.showLinkcopiedAlert && (
                    <Alert
                        onClose={() => {
                            this.setState({ showLinkcopiedAlert: false });
                        }}
                        style={{ marginBottom: "20px" }}
                        severity="success"
                    >
                        Link wurde in die Zwischenablage kopiert
                    </Alert>
                )}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        padding: "0 50px",
                        marginTop: "50px",
                    }}
                >
                    <div
                        style={{
                            width: "40%",
                            fontFamily: "Cabin",
                            textAlign: "left",
                        }}
                    >
                        <h1 style={{ fontSize: "40px", marginTop: "0px" }}>Partner werben und 50 € abstauben</h1>
                        <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                            Richtig gelesen: Du erhältst von uns 50 €, wenn Du erfolgreich einen neuen KeepLocal-Partner
                            wirbst. Aber von vorne:{" "}
                        </p>
                        <h2>Das Partner-werben-Partner-Konzept </h2>
                        <p style={{ fontSize: "14px" }}>
                            Wir möchten, dass unsere KeepLocal-Community mit Gleichgesinnten wächst – deutschlandweit.
                            Wenn Dir also jetzt tolle Menschen mit noch tolleren Unternehmen einfallen, die unbedingt
                            bei uns mitmachen sollten, ist jetzt der perfekte Zeitpunkt, sie anzusprechen.{" "}
                        </p>
                        <div style={{ borderBottom: "1px solid #e1e1e1" }} />
                        <div
                            style={{ display: "flex", alignItems: "center", margin: "10px 0", cursor: "pointer" }}
                            onClick={this.openEmailClient}
                        >
                            <i className="kl-icon-Icon_Mail shareIcon" />
                            <span style={{ fontWeight: "bold" }}>Empfehlung per E-Mail versenden </span>
                            <Tooltip
                                style={{ fontSize: 20, marginLeft: 5 }}
                                title="Der hinterlegte Einladungslink darf nicht verändert werden, sonst können wir Deine Empfehlungsprämie nicht zuweisen."
                            >
                                <i className="kl-icon-Icon_Information" />
                            </Tooltip>
                        </div>
                        <div style={{ borderBottom: "1px solid #e1e1e1" }} />
                        <div
                            style={{ display: "flex", alignItems: "center", margin: "10px 0", cursor: "pointer" }}
                            onClick={this.copyToClipboard}
                        >
                            <i className="kl-icon-Icon_Link copyLinkIcon" />
                            <span style={{ fontWeight: "bold" }}>Link kopieren</span>
                        </div>
                        <div style={{ borderBottom: "1px solid #e1e1e1" }} />
                    </div>
                    <div
                        style={{
                            width: "60%",
                            marginLeft: "10px",
                            display: "flex",
                            alignItems: "stretch",
                        }}
                    >
                        <img
                            alt="partnerRecruiting"
                            src={require("../../../img/Partner_werben_Partner_Motiv_WebApp.png")}
                            style={{
                                width: "100%",
                                maxHeight: "100%",
                                alignSelf: "flex-start",
                            }}
                        />
                    </div>
                </div>
                <div style={{ textAlign: "center", marginTop: "20px", padding: "0 50px" }}>
                    <h2>So funktioniert's</h2>
                    <div
                        style={{
                            backgroundColor: "#fff",
                            border: "1px solid #e1e1e1",
                            borderRadius: "5px",
                            padding: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "20px 0",
                        }}
                    >
                        <div style={{ flex: 1, borderRight: "1px solid #e1e1e1", padding: "0 10px" }}>
                            <div style={{ textAlign: "center" }}>
                                <i className="kl-icon-Icon_Promote_01" style={{ fontSize: 40 }} />
                            </div>
                            <p style={{ fontFamily: "Cabin" }}>
                                Du teilst obenstehenden Link mit der Person, der Du KeepLocal empfehlen möchtest.
                            </p>
                        </div>
                        <div style={{ flex: 1, borderRight: "1px solid #e1e1e1", padding: "0 10px" }}>
                            <div style={{ textAlign: "center" }}>
                                <i className="kl-icon-Icon_Gift" style={{ fontSize: 40 }} />
                            </div>
                            <p style={{ fontFamily: "Cabin" }}>
                                Die angeworbene Person erhält von uns als Schmankerl einen kostenfreien Gutscheinständer
                                zum Verkauf der Gutscheine.
                            </p>
                        </div>
                        <div style={{ flex: 1, padding: "0 10px" }}>
                            <div style={{ textAlign: "center" }}>
                                <i className="kl-icon-Icon_Bonus" style={{ fontSize: 40 }} />
                            </div>
                            <p style={{ fontFamily: "Cabin" }}>
                                Sobald Deine Empfehlung zum ersten Mal einen Gutschein aktiviert oder einlöst, zahlen
                                wir Deine 50 € bei der folgenden Abrechnung aus.
                            </p>
                        </div>
                    </div>
                    <p style={{ textDecoration: "underline", cursor: "pointer" }} onClick={this.openPopup}>
                        Häufig gestellte Fragen & Teilnahmebedingungen
                    </p>
                </div>
                <RecruitingFaqsPopup
                    open={this.state.popupOpen}
                    closePopup={this.closePopup}
                    openPopup={this.openPopup}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(withRouter(RecruitNewPartner));
