import MomentUtils from "@date-io/moment";
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "typeface-roboto";
import App from "./App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { persistor, store } from "./store/store";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#73c9e2",
        },
        secondary: {
            main: "#38a65b",
        },
    },
    typography: {
        fontFamily: [
            "Cabin",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <CssBaseline />
                <ThemeProvider theme={theme}>
                    <SnackbarProvider>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <App />
                        </MuiPickersUtilsProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
