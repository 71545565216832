import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, TextField, InputLabel } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import * as Yup from "yup";
import { store } from "../../../../store/store";
import { DatePicker } from "@material-ui/pickers";
import { getAllRetailers } from "../../../../store/actions/retailerActions";

export default class UpdateCardForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            multiActivation: false,
            showSpinner: false,
            retailers: [],
            selectedRetailer: undefined,
            couponEmployee: this.props.couponEmployee
        };

        this.handleSubmitUpdateCard = this.handleSubmitUpdateCard.bind(this);
    }

    componentDidMount() {
        store.dispatch(getAllRetailers()).then((response) => {
            if (response) {
                var sortedList = response.sort(function (a, b) {
                    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                });
                this.setState({ retailers: sortedList });
                if (this.props.couponEmployee && !this.props.couponEmployee.original.cardNumber) {
                    this.setState({
                        selectedRetailer: sortedList.find((value) => {if (value.retailersId === this.props.couponEmployee.original.retailersId) return value})
                    })
                } 
            }
        });
    }

    handleSubmitUpdateCard(values) {
        if (this.props.isEmployee) {
            this.props.handleSubmitUpdateCard(values, this.props.couponEmployee.original.couponEmployeesId, this.props.couponEmployee.original.couponEmployeeRetailersId , this.state.selectedRetailer)
        }
        else {
            this.props.handleSubmitUpdateCard(values, this.props.couponEmployee.original.couponEmployeesId, this.props.couponEmployee.original.couponEmployeeRetailersId );
        }
    }

    render() {
        const { showSpinner } = this.state;

        return (
            <React.Fragment>
                <Formik
                initialValues={{
                    firstTransaction: this.props.couponEmployee ? this.props.couponEmployee.original.firstTransaction : null,
                    lastTransaction: this.props.couponEmployee ? this.props.couponEmployee.original.lastTransaction : null,
                    email: this.props.couponEmployee ? this.props.couponEmployee.original.email : "",
                    comment: (this.props.couponEmployee && this.props.couponEmployee.original.comment) ? this.props.couponEmployee.original.comment : "",
                    name: (this.props.couponEmployee && this.props.couponEmployee.original.name) ? this.props.couponEmployee.original.name : "",
                }}
                validationSchema={
                    (this.props.couponEmployee && !this.props.couponEmployee.original.cardNumber) &&
                    Yup.object().shape({
                    email: Yup.string()
                    .email("Keine korrekte E-Mail-Adresse")
                    .required("Dieses Feld wird benötigt"),
                })}
                onSubmit={(values) => {
                    this.handleSubmitUpdateCard(values);
                }}
            >
                {(props) => {
                    return (
                    <Form onSubmit={props.handleSubmit}>
                        <DialogTitle id="form-dialog-title">
                            {this.props.couponEmployee.original.cardNumber ? this.props.couponEmployee.original.displayValue + " bearbeiten" : "Mitarbeiter " + this.props.couponEmployee.original.email + " bearbeiten"}
                        </DialogTitle>
                        {this.props.error && (
                            <Box mb={2}>
                                <Alert severity="error">{this.props.errorText}</Alert>
                            </Box>
                        )}
                        <DialogContent>
                            <Grid container spacing={1}>
                                {this.state.selectedRetailer && this.state.retailers && this.props.couponEmployee && !this.props.couponEmployee.original.cardNumber && <Grid item xs={12}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={this.state.retailers}
                                        getOptionLabel={(retailer) => retailer.name}
                                        getOptionSelected={(option, value) => value.retailersId === option.retailersId}
                                        style={{ width: "100%" }}
                                        value={this.state.selectedRetailer}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                selectedRetailer: newValue
                                            })
                                        }}
                                        renderInput={(params) => <TextField {...params} label="wähle einen Händler aus" variant="outlined" />}
                                    />
                                </Grid>}
                                {this.props.couponEmployee && !this.props.couponEmployee.original.cardNumber && <Grid item xs={12}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="E-Mail-Adresse"
                                        type="email"
                                        onChange={props.handleChange}
                                        value={props.values.email}
                                        error={props.errors.email}
                                        helperText={props.errors.email ? props.errors.email : ""}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>}
                                <Grid item xs={12}>
                                    <TextField
                                        label="Name (optional)"
                                        name="name"
                                        fullWidth
                                        size="small"
                                        onChange={props.handleChange}
                                        value={props.values.name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Kommentar (optional)"
                                        name="comment"
                                        fullWidth
                                        size="small"
                                        onChange={props.handleChange}
                                        value={props.values.comment}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel id="demo-mutiple-checkbox-label">Eintrittsdatum (optional)</InputLabel>
                                    <DatePicker
                                        variant="inline"
                                        openTo="month"
                                        label=""
                                        fullWidth
                                        format="DD/MM/yyyy"
                                        helperText=""
                                        value={props.values.firstTransaction}
                                        emptyLabel="wähle ein Eintrittsdatum aus"
                                        onChange={(value) => props.setFieldValue("firstTransaction", value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel id="demo-mutiple-checkbox-label">Austrittsdatum (optional)</InputLabel>
                                    <DatePicker
                                        variant="inline"
                                        openTo="month"
                                        label=""
                                        fullWidth
                                        format="DD/MM/yyyy"
                                        helperText=""
                                        value={props.values.lastTransaction}
                                        emptyLabel="wähle ein Austrittsdatum aus"
                                        onChange={(value) => props.setFieldValue("lastTransaction", value)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            style={{
                                position: "relative",
                            }}
                        >
                            <Button onClick={this.props.handleCloseUpdateCardDialog} color="default"  disabled={showSpinner}>
                                Abbrechen
                            </Button>
                            <Box display="inline" position="relative">
                                <Button type="submit" color="secondary" disabled={showSpinner}>
                                Speichern
                                </Button>
                                {showSpinner && (
                                    <CircularProgress
                                        size=""
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: -12,
                                            marginLeft: -12,
                                            width: "24px",
                                        }}
                                    />
                                )}
                            </Box>
                        </DialogActions>
                    </Form>
                )}}
            </Formik>                       
            </React.Fragment>
        );
    }
}
