import React, { Component } from 'react'
import './Employees.scss'
import { store } from '../../../store/store'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { IconButton, Grid, Box, Paper, FormControlLabel, Checkbox, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { updateEmployeeRights } from '../../../store/actions/userAction'
import { getRetailerInformationsWithRetailersId, getAllEmployees, deleteEmployee, getQRCodeForAddingEmployees } from '../../../store/actions/retailerActions'
import NewEmployeeQRCode from './NewEmployeeQRCode'

class EmployeeDetails extends Component {
    constructor(props) {
        super(props)

        var paramEmployee = props.location.state ? props.location.state['employee'] : undefined;
        this.state = {
            showSpinner: false,
            showDeleteDialog: false,
            employee: paramEmployee,
            error: false,
            showQrCode: false,
            qrCodeObject: '',
            errorText: '',
            hasRightChangeRetailer: (paramEmployee && paramEmployee.Rights) ? paramEmployee.Rights.some(r => r === 1) : false,
            hasRightChangeCouponEmployees: (paramEmployee && paramEmployee.Rights) ? paramEmployee.Rights.some(r => r === 2) : false,
            hasRightChangeEmployees: (paramEmployee && paramEmployee.Rights) ? paramEmployee.Rights.some(r => r === 3) : false,
            hasRightOrder: (paramEmployee && paramEmployee.Rights) ? paramEmployee.Rights.some(r => r === 4) : false,
            hasRightRedemmCoupon: (paramEmployee && paramEmployee.Rights) ? paramEmployee.Rights.some(r => r === 5) : true,
            hasRightActivateCoupon: (paramEmployee && paramEmployee.Rights) ? paramEmployee.Rights.some(r => r === 6) : true
        }

        this.getRightsList = this.getRightsList.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.deleteSelectedEmployee = this.deleteSelectedEmployee.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseShowQRCode = this.handleCloseShowQRCode.bind(this);
        this.handleCloseError = this.handleCloseError.bind(this);
    }

    getRightsList() {
        var rightsList = [];
        if (this.state.hasRightChangeRetailer) {
            rightsList.push(1);
        }
        if (this.state.hasRightChangeCouponEmployees) {
            rightsList.push(2);
        }
        if (this.state.hasRightChangeEmployees) {
            rightsList.push(3);
        }
        if (this.state.hasRightOrder) {
            rightsList.push(4);
        }
        if (this.state.hasRightRedemmCoupon) {
            rightsList.push(5);
        }
        if (this.state.hasRightActivateCoupon) {
            rightsList.push(6);
        }
        return rightsList;
    }

    handleClose() {
        this.setState({
            showDeleteDialog: false
        })
    }

    handleCloseError() {
        this.setState({
            error: false
        })
    }

    handleCloseShowQRCode() {
        this.setState({
            showQrCode: false
        })
    }

    handleSubmitClick() {
        this.setState({
            showSpinner: true
        })
        if (this.state.employee) {
            store.dispatch(updateEmployeeRights(this.state.employee.cognitoId, this.props.retailer.informations.retailersId, this.getRightsList())).then(store.dispatch(getRetailerInformationsWithRetailersId(this.props.retailer.informations.retailersId)))
                .then(response => {
                    this.setState({
                        showSpinner: false
                    }, this.props.history.push('/settings/employees'))
                })
        }
        else {
            store.dispatch(getQRCodeForAddingEmployees(this.props.user.employee.cognitoId, this.props.retailer.informations.retailersId, this.getRightsList()))
                .then(response => {
                    this.setState({ showSpinner: false, showQrCode: true, qrCodeObject: response })
                    // this.props.history.push('/settings/new-employee-qrcode', { 'qrCodeObject': response, retailerName: this.props.retailer.informations.name }))
                })
                .catch(
                    err => {
                        this.setState({
                            showDeleteDialog: false,
                            error: true,
                            showSpinner: false,
                            errorText: "QR Code konnte nicht erstellt werden. Bitte versuche es erneut."
                        })
                    }
                )
        }
    }

    deleteSelectedEmployee() {
        if (this.props.user.employee.cognitoId === this.state.employee.cognitoId) {
            this.setState({
                showDeleteDialog: false,
                error: true,
                errorText: "Du kannst Dich selbst nicht löschen"
            })
            return;
        }
        this.setState({
            showSpinner: true,
            showDeleteDialog: false
        })
        store.dispatch(deleteEmployee(this.props.retailer.informations.retailersId, this.state.employee.cognitoId))
            .then(response => {
                store.dispatch(getAllEmployees(this.props.retailer.informations.retailersId)).then(response => {
                    this.setState({
                        showSpinner: false
                    }, this.props.history.push('/settings/employees'))
                })
            })
            .catch(err => {
                this.setState({
                    showSpinner: false,
                    error: true,
                    errorText: err.message
                })
            })
    }

    render() {
        return (
            <div>
                <Box className="ActivateCoupon">
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={12} sm={12} md={7} lg={5} xl={4}>
                            <Box m={2}>
                                <Paper>
                                    <Box textAlign='left' p={2}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <IconButton disabled={this.state.showSpinner} edge="start" aria-label="Details" onClick={() => this.props.history.push('/settings/employees')}>
                                                        <ArrowBackIos />
                                                    </IconButton>
                                                    <div>{this.state.employee ? this.state.employee.forename + ' ' + this.state.employee.surname : 'Neuen Benutzer anlegen'}</div>
                                                </div>
                                                <div>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={this.state.hasRightRedemmCoupon} onChange={() => this.setState({ hasRightRedemmCoupon: !this.state.hasRightRedemmCoupon })} name="redeemCityCoupons" />}
                                                        label="Kann Gutscheine einlösen"
                                                        disabled={this.state.showSpinner}
                                                        style={{ textAlign: 'left' }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={this.state.hasRightActivateCoupon} onChange={() => this.setState({ hasRightActivateCoupon: !this.state.hasRightActivateCoupon })} name="redeemCityCoupons" />}
                                                        label="Kann Gutscheine aktivieren"
                                                        disabled={this.state.showSpinner}
                                                        style={{ textAlign: 'left' }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={this.state.hasRightChangeRetailer} onChange={() => this.setState({ hasRightChangeRetailer: !this.state.hasRightChangeRetailer })} name="redeemCityCoupons" />}
                                                        label="Kann Filialen anlegen und bearbeiten"
                                                        disabled={this.state.showSpinner}
                                                        style={{ textAlign: 'left' }}
                                                    />
                                                </div>
                                                <div>

                                                    <FormControlLabel
                                                        control={<Checkbox checked={this.state.hasRightChangeCouponEmployees} onChange={() => this.setState({ hasRightChangeCouponEmployees: !this.state.hasRightChangeCouponEmployees })} name="redeemCityCoupons" />}
                                                        label="Kann Mitarbeiter-Gutscheine einsehen und verwalten"
                                                        disabled={this.state.showSpinner}
                                                        style={{ textAlign: 'left' }}
                                                    />
                                                </div>
                                                <div>

                                                    <FormControlLabel
                                                        control={<Checkbox checked={this.state.hasRightChangeEmployees} onChange={() => this.setState({ hasRightChangeEmployees: !this.state.hasRightChangeEmployees })} name="redeemCityCoupons" />}
                                                        label="Kann neue Benutzer anlegen und verwalten"
                                                        disabled={this.state.showSpinner}
                                                        style={{ textAlign: 'left' }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={this.state.hasRightOrder} onChange={() => this.setState({ hasRightOrder: !this.state.hasRightOrder })} name="redeemCityCoupons" />}
                                                        label="Kann Bestellungen einsehen und erfassen"
                                                        disabled={this.state.showSpinner}
                                                        style={{ textAlign: 'left' }}
                                                    />
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        style={{ marginTop: 10 }}
                                                        color="primary"
                                                        disabled={this.state.showSpinner}
                                                        onClick={() => this.handleSubmitClick()}
                                                    >{this.state.employee ? 'Änderungen speichern' : 'QR Code erzeugen'}</Button>
                                                    {this.state.employee &&
                                                        <Button
                                                            fullWidth
                                                            variant="contained"
                                                            style={{ marginTop: 10 }}
                                                            color="primary"
                                                            disabled={this.state.showSpinner}
                                                            onClick={() => this.setState({ showDeleteDialog: true })}
                                                        >Benutzer löschen</Button>
                                                    }
                                                    {this.state.showSpinner && <CircularProgress size={24} className='ButtonSpinner' />}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {this.state.employee &&
                    <Dialog
                        open={this.state.showDeleteDialog}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Benutzer löschen</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"Möchtest Du den Benutzer " + this.state.employee.forename + " " + this.state.employee.surname + " wirklich löschen?"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Nein
                    </Button>
                            <Button onClick={this.deleteSelectedEmployee} color="primary" autoFocus>
                                Ja
                    </Button>
                        </DialogActions>
                    </Dialog>
                }
                <Dialog
                    open={this.state.error}
                    onClose={this.handleCloseError}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Fehler</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.errorText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseError} color="primary" autoFocus>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.props.retailer && this.props.retailer.informations &&
                    <Dialog
                        open={this.state.showQrCode}
                        onClose={this.handleCloseShowQRCode}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">QR Code</DialogTitle>
                        <DialogContent>
                            <NewEmployeeQRCode qrCodeObject={this.state.qrCodeObject} retailerName={this.props.retailer.informations.name} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseShowQRCode} color="primary" autoFocus>
                                Ok
                        </Button>
                        </DialogActions>
                    </Dialog>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state
    return {
        user, retailer
    }
}

export default connect(mapStateToProps)(withRouter(EmployeeDetails))
