import { Button, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import IBAN from "iban";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getBillingInformations } from "../../store/actions/retailerActions";
import { store } from "../../store/store";
import BillingAddress from "./BillingAddress";
import SkipStepWarning from "./SkipStepWarning";

class NewStoreBillingInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errorText: "",
            iban: "",
            bic: "",
            accountOwner: "",
            billingName: "",
            billingTown: "",
            billingStreet: "",
            showSpinner: false,
            payToParent: this.props.retailer.informations ? true : false,
            localData: this.props.location.state.localData ? this.props.location.state.localData : undefined,
            townData: this.props.location.state.townData ? this.props.location.state.townData : undefined,
            stateData: this.props.location.state.stateData ? this.props.location.state.stateData : undefined,
            skipVisible: false,
            setBillingAddress: false,
        };

        this.updateBillingInformation = this.updateBillingInformation.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
        this.handleToggleSkip = this.handleToggleSkip.bind(this);
    }

    componentDidMount() {
        console.log(this.props.retailer);
        if (this.props.retailer.informations) {
            store
                .dispatch(getBillingInformations(this.props.retailer.informations.parentRetailersId))
                .then((result) => {
                    this.setState({
                        iban: result.billingInformation.iban,
                        bic: result.billingInformation.bic,
                        accountOwner: result.billingInformation.accountOwner,
                        billingName: result.billingInformation.billingAddress
                            ? result.billingInformation.billingAddress.billingName
                            : "",
                        billingTown: result.billingInformation.billingAddress
                            ? result.billingInformation.billingAddress.billingTown
                            : "",
                        billingStreet: result.billingInformation.billingAddress
                            ? result.billingInformation.billingAddress.billingStreet
                            : "",
                    });
                });
        }
    }
    handleToggleSkip() {
        this.setState({
            skipVisible: !this.state.skipVisible,
        });
    }

    updateBillingInformation(values) {
        console.log(values);
        if (IBAN.isValid(values.iban)) {
            this.setState(
                {
                    showSpinner: false,
                    localData: {
                        ...this.state.localData,
                        billingInformation: {
                            iban: values.iban,
                            bic: values.bic,
                            accountOwner: values.accountOwner,
                            billingAddress: {
                                billingName: values.billingName,
                                billingTown: values.billingTown,
                                billingStreet: values.billingStreet,
                            },
                        },
                    },
                },
                () => {
                    this.props.history.push("/account/new-store-contact-person", {
                        localData: this.state.localData,
                        townData: this.state.townData,
                        stateData: this.state.stateData,
                    });
                }
            );
        } else {
            this.setState({
                showSpinner: false,
                error: true,
                errorText: "Die eingegeben IBAN ist ungültig",
            });
        }
    }

    handleSkip() {
        this.setState(
            {
                showSpinner: false,
                skipVisible: false,
                localData: {
                    ...this.state.localData,
                    billingInformation: {
                        iban: " ",
                        bic: " ",
                        accountOwner: " ",
                    },
                    billingAddress: {
                        billingName: " ",
                        billingTown: " ",
                        billingStreet: " ",
                    },
                },
            },
            () => {
                this.props.history.push("/account/new-store-contact-person", {
                    localData: this.state.localData,
                    townData: this.state.townData,
                    stateData: this.state.stateData,
                });
            }
        );
    }

    render() {
        return (
            <div className="NewStoreBillingInformation">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <Typography variant="h6">Zahlungsinformationen</Typography>
                <Formik
                    initialValues={{
                        iban: this.state.iban,
                        bic: this.state.bic,
                        accountOwner: this.state.accountOwner,
                        billingName: "",
                        billingStreet: "",
                        billingTown: "",
                    }}
                    onSubmit={(values) => {
                        this.setState({
                            showSpinner: true,
                            error: false,
                            errorText: "",
                        });
                        this.updateBillingInformation(values);
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="iban"
                                        name="iban"
                                        label="IBAN"
                                        onChange={props.handleChange}
                                        value={props.values.iban}
                                        fullWidth
                                        disabled={this.state.showSpinner || this.state.payToParent}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="bic"
                                        name="bic"
                                        label="BIC"
                                        onChange={props.handleChange}
                                        value={props.values.bic}
                                        fullWidth
                                        disabled={this.state.showSpinner || this.state.payToParent}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="accountOwner"
                                        name="accountOwner"
                                        label="Kontoinhaber"
                                        onChange={props.handleChange}
                                        value={props.values.accountOwner}
                                        fullWidth
                                        disabled={this.state.showSpinner || this.state.payToParent}
                                    />
                                </Grid>
                                <BillingAddress
                                    visible={this.state.setBillingAddress}
                                    values={props.values}
                                    handleChange={props.handleChange}
                                />
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", position: "relative" }}
                                >
                                    <Button
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Speichern
                                    </Button>
                                    {this.state.showSpinner && <CircularProgress size={24} className="Spinner" />}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", position: "relative" }}
                                >
                                    <Button
                                        onClick={this.handleToggleSkip}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                        type="button"
                                        variant="contained"
                                        color="default"
                                    >
                                        Überspringen
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <SkipStepWarning
                    visible={this.state.skipVisible}
                    onHiding={this.handleToggleSkip}
                    onSkip={this.handleSkip}
                    title="Achtung"
                >
                    <p>
                        <b>Ohne Bankverbindung gibt's kein Geld!</b>
                    </p>
                    <p>
                        Logischerweise können wir Guthaben nur überweisen, wenn wir auch wissen wo hin. Deshalb gib uns
                        am Besten jetzt Deine Bankverbindung.
                    </p>
                </SkipStepWarning>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(NewStoreBillingInformation);
