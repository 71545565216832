import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,    
    Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Auth, Storage } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createRetailer } from "../../store/actions/retailerActions";
import { getEmployeeWithCognitoId } from "../../store/actions/userAction";
import { store } from "../../store/store";
import { withRouter } from "react-router-dom";
import Licence from "../../pages/StoreSettings/TradingLicence/Licence";
import UploadTradingLicence from "./UploadTradingLicense";

class TradingLicenceForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errorText: "",
            success: false,
            successText: "",
            image: null,
            showSpinner: false,
            overlayIsVisible: false,
            skipVisible: false,
            licence: null,
        };

        Storage.configure({
            Storage: {
                AWSS3: {
                    bucket: "licence90108-develop",
                },
            },
        });

        this.handleUploadTradingLicence = this.handleUploadTradingLicence.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
        this.handleToggleSkip = this.handleToggleSkip.bind(this);  
        this.setTradingLicence = this.setTradingLicence.bind(this);   
    }

    handleToggleSkip() {
        this.setState({
            skipVisible: !this.state.skipVisible,
        });
    }

    setTradingLicence(licence){
        this.setState({
            licence: licence
        });
    }

    handleUploadTradingLicence = async (values) => {
        var newLocalData = this.props.localData;
        this.setState({ showSpinner: true });
        if (values.image !== null) {
            try {
                const blob = await URL.createObjectURL(values.image);
                var split = values.image.name.split(".");

                Storage.put(newLocalData.name + "." + split[split.length - 1], blob, {
                    contentType: "image/" + split[split.length - 1],
                })
                    .then((result) => {
                        newLocalData = {
                            ...this.props.localData,
                            licence: result.key,
                        };

                        Auth.currentAuthenticatedUser().then((user) => {
                            store
                                .dispatch(
                                    createRetailer(
                                        newLocalData,
                                        this.props.stateData,
                                        this.props.townData,
                                        user.attributes.sub
                                    )
                                )
                                .then((response) => {
                                    store
                                        .dispatch(getEmployeeWithCognitoId(user.attributes.sub))
                                        .then((res) => {
                                            if (
                                                newLocalData.licence === " " ||
                                                newLocalData.ManagingDirector.name === " " ||
                                                newLocalData.billingInformation.iban === " "
                                            ) {
                                                this.setState({
                                                    showSpinner: false,
                                                    success: true,
                                                    successText:
                                                        "Händler erfolgreich angelegt. Zur Überprüfung und zur Freischaltung des Händlers werden weitere Informationen benötigt. Bitte vervollständigen Sie ihre Daten.",
                                                });
                                            } else {
                                                this.setState({
                                                    showSpinner: false,
                                                    success: true,
                                                    successText:
                                                        "Händler erfolgreich angelegt. Händler wurde erfolgreich angelegt und wird nun geprüft.",
                                                });
                                            }
                                        })
                                        .catch((err) => {
                                            if (
                                                newLocalData.licence === " " ||
                                                newLocalData.ManagingDirector.name === " " ||
                                                newLocalData.billingInformation.iban === " "
                                            ) {
                                                this.setState({
                                                    showSpinner: false,
                                                    success: true,
                                                    successText:
                                                        "Händler erfolgreich angelegt. Zur Überprüfung und zur Freischaltung des Händlers werden weitere Informationen benötigt. Bitte vervollständigen Sie ihre Daten.",
                                                });
                                            } else {
                                                this.setState({
                                                    showSpinner: false,
                                                    success: true,
                                                    successText:
                                                        "Händler erfolgreich angelegt. Händler wurde erfolgreich angelegt und wird nun geprüft.",
                                                });
                                            }
                                        });
                                })
                                .catch((err) => {
                                    this.setState({
                                        showSpinner: false,
                                        error: true,
                                        errorText:
                                            "Leider ist ein Fehler beim Anlegen passiert. Bitte wende dich an unseren Support unter support@keeplocal.de",
                                    });
                                });
                        });
                    })
                    .catch((err) => {
                        this.setState({
                            showSpinner: false,
                            error: true,
                            errorText:
                                "Leider ist ein Fehler beim Hochladen des Gewerbescheins aufgtreten. Bitte schicken sie uns den Gewerbeschein per Mail an support@keeplocal.de",
                        });
                    });
            } catch (err) {
                this.setState({
                    showSpinner: false,
                    error: true,
                    errorText:
                        "Leider ist ein Fehler beim Hochladen des Gewerbescheins aufgtreten. Bitte schicken sie uns den Gewerbeschein per Mail an support@keeplocal.de",
                });
            }
        } else {
            this.setState({
                showSpinner: false,
                error: true,
                errorText: "Es wurde kein Bild ausgewählt",
            });
        }
    };

    handleSkip() {
        this.setState({ showSpinner: true });
        Auth.currentAuthenticatedUser().then((user) => {
            store
                .dispatch(
                    createRetailer(this.props.localData, this.props.stateData, this.props.townData, user.attributes.sub)
                )
                .then((response) => {
                    store
                        .dispatch(getEmployeeWithCognitoId(user.attributes.sub))
                        .then((res) => {
                            if (
                                this.props.localData.licence === " " ||
                                this.props.localData.ManagingDirector.name === " " ||
                                this.props.localData.billingInformation.iban === " "
                            ) {
                                this.setState({
                                    showSpinner: false,
                                    success: true,
                                    successText:
                                        "Händler erfolgreich angelegt. Zur Überprüfung und zur Freischaltung des Händlers werden weitere Informationen benötigt. Bitte vervollständigen Sie ihre Daten.",
                                });
                            } else {
                                this.setState({
                                    showSpinner: false,
                                    success: true,
                                    successText:
                                        "Händler erfolgreich angelegt. Händler wurde erfolgreich angelegt und wird nun geprüft.",
                                });
                            }
                        })
                        .catch((err) => {
                            if (
                                this.props.localData.licence === " " ||
                                this.props.localData.ManagingDirector.name === " " ||
                                this.props.localData.billingInformation.iban === " "
                            ) {
                                this.setState({
                                    showSpinner: false,
                                    success: true,
                                    successText:
                                        "Händler erfolgreich angelegt. Zur Überprüfung und zur Freischaltung des Händlers werden weitere Informationen benötigt. Bitte vervollständigen Sie ihre Daten.",
                                });
                            } else {
                                this.setState({
                                    showSpinner: false,
                                    success: true,
                                    successText:
                                        "Händler erfolgreich angelegt. Händler wurde erfolgreich angelegt und wird nun geprüft.",
                                });
                            }
                        });
                })
                .catch((err) => {
                    this.setState({
                        showSpinner: false,
                        error: true,
                        errorText:
                            "Leider ist ein Fehler beim Anlegen passiert. Bitte wende dich an unseren Support unter support@keeplocal.de",
                    });
                });
        });
    }

    render() {       
        return (
            <div className="NewStoreUploadTradingLicence">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <Typography variant="h6" style={{ marginBottom: "16px" }}>
                    Gewerbelizenz
                </Typography>
                <UploadTradingLicence setTradingLicence={this.setTradingLicence}/>
                {this.state.licence && (
                    <React.Fragment>
                        <Licence licence={this.state.image} preview={true} />
                    </React.Fragment>
                )}
                <Box mt={2} position="relative">
                    <Button
                        fullWidth
                        disabled={this.state.showSpinner}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={this.handleUploadTradingLicence}
                    >
                        Speichern
                    </Button>
                    {this.state.showSpinner && <CircularProgress size={24} className="Spinner" />}
                </Box>
                <Box mt={2}>
                    <Button
                        onClick={this.handleToggleSkip}
                        fullWidth
                        disabled={this.state.showSpinner}
                        type="button"
                        variant="contained"
                        color="default"
                    >
                        Überspringen
                    </Button>
                </Box>
                <Dialog
                    open={this.state.success}
                    onBackdropClick={() => this.props.history.push("/administration/")}
                    onClose={() => this.props.history.push("/administration/")}
                    aria-labelledby="success-dialog-title"
                >
                    <DialogTitle id="success-dialog-title">Händler erfolgreich angelegt</DialogTitle>
                    <DialogContent>{this.state.successText}</DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.history.push("/administration/")}>Weiter</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(withRouter(TradingLicenceForm));
