import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    withStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import CompareArrows from "@material-ui/icons/CompareArrows";
import ContactsIcon from "@material-ui/icons/Contacts";
import DescriptionIcon from "@material-ui/icons/Description";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";
import InfoIcon from "@material-ui/icons/Info";
import PaymentIcon from "@material-ui/icons/Payment";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import AccessTimeSharpIcon from "@material-ui/icons/AccessTimeSharp";
import SettingsIcon from "@material-ui/icons/Settings";
import StoreIcon from "@material-ui/icons/Store";
import TransformIcon from "@material-ui/icons/Transform";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { userHasRight } from "../../helpers/UserRights";
import logo from "../../img/logo-dark.png";
import CheckCouponDialog from "../../pages/Administration/CheckCoupon/CheckCouponDialog";
import {
    getActualCouponAccountValue,
    getRetailerInformationsWithRetailersId,
    getRetailerTransactions,
} from "../../store/actions/retailerActions";
import { store } from "../../store/store";
import "./Navigation.scss";
import colors from "./../Style/Colors.scss";

const StyledListItem = withStyles({
    root: {
        "&.Mui-selected": {
            backgroundColor: colors.colorSecond,
            color: colors.colorLight,
        },
    },
})(ListItem);

class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openSettings: false,
            showSwitchRetailer: false,
            showDialog: false,
            openCheckCoupon: false,
            openStoreSettings: false,
            openEmployeeCoupons: false,
            selectedIndex: -1,
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleCloseSwitchRetailer = this.handleCloseSwitchRetailer.bind(this);
        this.handleOpenCheckCoupon = this.handleOpenCheckCoupon.bind(this);
        this.handleCloseCheckCoupon = this.handleCloseCheckCoupon.bind(this);
    }

    componentDidMount() {
        if (this.props.retailer && this.props.retailer.informations) {
            store.dispatch(getActualCouponAccountValue(this.props.retailer.informations.retailersId));
        }
    }

    handleClose() {
        this.setState({ showSpinner: false, showDialog: false, showSwitchRetailer: false });
    }

    handleCloseSwitchRetailer() {
        this.setState({
            showSwitchRetailer: false,
        });
    }

    handleOpenCheckCoupon() {
        this.setState({
            openCheckCoupon: true,
        });
    }

    handleCloseCheckCoupon() {
        this.setState({
            openCheckCoupon: false,
        });
    }

    render() {
        return (
            <Drawer variant="permanent" anchor="left" className="Navigation">
                <Box pt={2} pb={2} component={Link} to="/administration/">
                    <img src={logo} alt="KeepLocal: Von hier. Für Dich." />
                </Box>
                <Divider />
                {this.props.retailer && this.props.retailer.informations && (
                    <div>
                        <div style={{ padding: 5, display: "flex", alignItems: "center" }}>
                            {this.props.user.employee.Retailers.length > 1 && (
                                <IconButton onClick={() => this.setState({ showSwitchRetailer: true })}>
                                    <CompareArrows />
                                </IconButton>
                            )}
                            <Typography
                                variant="h6"
                                display="block"
                                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                            >
                                {this.props.retailer.informations.name}
                            </Typography>
                        </div>
                        <Divider />
                    </div>
                )}
                <List>
                    {this.props.retailer && this.props.retailer.informations && (
                        <StyledListItem
                            button
                            component={Link}
                            to="/administration/transactions"
                            selected={this.state.selectedIndex === 0}
                            onClick={() => this.setState({ selectedIndex: 0 })}
                        >
                            <ListItemIcon>
                                <TransformIcon />
                            </ListItemIcon>
                            <ListItemText primary="Transaktionen" />
                        </StyledListItem>
                    )}
                    {userHasRight(6) && this.props.retailer && (
                        <StyledListItem
                            button
                            component={Link}
                            to="/administration/activate-coupon"
                            selected={this.state.selectedIndex === 1}
                            onClick={() => this.setState({ selectedIndex: 1 })}
                        >
                            <ListItemIcon>
                                <PlaylistAddIcon />
                            </ListItemIcon>
                            <ListItemText primary="Gutscheine aktivieren" />
                        </StyledListItem>
                    )}
                    {userHasRight(5) && this.props.retailer && (
                        <StyledListItem
                            button
                            component={Link}
                            to="/administration/redeem-coupon"
                            selected={this.state.selectedIndex === 2}
                            onClick={() => this.setState({ selectedIndex: 2 })}
                        >
                            <ListItemIcon>
                                <PlaylistAddCheckIcon />
                            </ListItemIcon>
                            <ListItemText primary="Gutscheine einlösen" />
                        </StyledListItem>
                    )}
                    {userHasRight(5) && (
                        <StyledListItem button onClick={this.handleOpenCheckCoupon}>
                            <ListItemIcon>
                                <PaymentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Gutscheinwert abfragen" />
                        </StyledListItem>
                    )}
                    {/* {userHasRight(2) && (
                        <StyledListItem
                            button
                            onClick={() =>
                                this.setState({
                                    openEmployeeCoupons: !this.state.openEmployeeCoupons,
                                    selectedIndex: 4,
                                })
                            }
                            selected={this.state.selectedIndex === 4}
                        >
                            <ListItemIcon>
                                <CardTravel />
                            </ListItemIcon>
                            <ListItemText primary="Mitarbeiter-Gutscheine" />
                            {this.state.openEmployeeCoupons ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </StyledListItem>
                    )} */}
                    {/* <Collapse
                        in={this.state.openEmployeeCoupons}
                        timeout="auto"
                        unmountOnExit
                        style={{ backgroundColor: grey["200"] }}
                    >
                        <List component="div" disablePadding>
                            {userHasRight(2) && (
                                <StyledListItem
                                    button
                                    className="nested"
                                    component={Link}
                                    to="/administration/employee-coupons/employees"
                                    selected={this.state.selectedIndex === 5}
                                    onClick={() => this.setState({ selectedIndex: 5 })}
                                >
                                    <ListItemIcon>
                                        <CardMembership />
                                    </ListItemIcon>
                                    <ListItemText primary="Mitarbeiter verwalten" />
                                </StyledListItem>
                            )}
                            {userHasRight(2) && (
                                <StyledListItem
                                    button
                                    className="nested"
                                    component={Link}
                                    to="/administration/employee-coupons/jobs"
                                    selected={this.state.selectedIndex === 6}
                                    onClick={() => this.setState({ selectedIndex: 6 })}
                                >
                                    <ListItemIcon>
                                        <PostAdd />
                                    </ListItemIcon>
                                    <ListItemText primary="Aufträge" />
                                </StyledListItem>
                            )}
                            {userHasRight(2) && (
                                <StyledListItem
                                    button
                                    className="nested"
                                    component={Link}
                                    to="/administration/employee-coupons/OrderCoupons"
                                    selected={this.state.selectedIndex === 8}
                                    onClick={() => this.setState({ selectedIndex: 8 })}
                                >
                                    <ListItemIcon>
                                        <PostAdd />
                                    </ListItemIcon>
                                    <ListItemText primary="Mitarbeiterkarten bestellen" />
                                </StyledListItem>
                            )}
                        </List>
                    </Collapse> */}
                    {(userHasRight(1) || userHasRight(3)) && (
                        <StyledListItem
                            button
                            onClick={() =>
                                this.setState({ openStoreSettings: !this.state.openStoreSettings, selectedIndex: 9 })
                            }
                            selected={this.state.selectedIndex === 9}
                        >
                            <ListItemIcon>
                                <StoreIcon />
                            </ListItemIcon>
                            <ListItemText primary="Filiale" />
                            {this.state.openStoreSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </StyledListItem>
                    )}
                    <Collapse
                        in={this.state.openStoreSettings}
                        timeout="auto"
                        unmountOnExit
                        style={{ backgroundColor: grey["200"] }}
                    >
                        <List component="div" disablePadding>
                            {userHasRight(3) && (
                                <StyledListItem
                                    button
                                    className="nested"
                                    component={Link}
                                    to="/store-settings/information"
                                    selected={this.state.selectedIndex === 10}
                                    onClick={() => this.setState({ selectedIndex: 10 })}
                                >
                                    <ListItemIcon>
                                        <InfoIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Informationen" />
                                </StyledListItem>
                            )}
                            {userHasRight(3) && (
                                <StyledListItem
                                    button
                                    className="nested"
                                    component={Link}
                                    to="/store-settings/gewerbeschein"
                                    selected={this.state.selectedIndex === 11}
                                    onClick={() => this.setState({ selectedIndex: 11 })}
                                >
                                    <ListItemIcon>
                                        <DescriptionIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Gewerbeschein" />
                                </StyledListItem>
                            )}
                            {userHasRight(5) && (
                                <StyledListItem
                                    button
                                    className="nested"
                                    component={Link}
                                    to="/store-settings/erscheinungsbild"
                                    onClick={() => this.setState({ selectedIndex: 12 })}
                                    selected={this.state.selectedIndex === 12}
                                >
                                    <ListItemIcon>
                                        <ColorLensIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Erscheinungsbild" />
                                </StyledListItem>
                            )}
                            {userHasRight(5) && (
                                <StyledListItem
                                    button
                                    className="nested"
                                    component={Link}
                                    to="/store-settings/oeffnungszeiten"
                                    selected={this.state.selectedIndex === 13}
                                    onClick={() => this.setState({ selectedIndex: 13 })}
                                >
                                    <ListItemIcon>
                                        <AccessTimeSharpIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Öffnungszeiten" />
                                </StyledListItem>
                            )}
                            {userHasRight(5) && (
                                <StyledListItem
                                    button
                                    className="nested"
                                    component={Link}
                                    to="/store-settings/Fotos"
                                    selected={this.state.selectedIndex === 14}
                                    onClick={() => this.setState({ selectedIndex: 14 })}
                                >
                                    <ListItemIcon>
                                        <PhotoSizeSelectActualIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Fotos" />
                                </StyledListItem>
                            )}
                        </List>
                    </Collapse>
                    <StyledListItem
                        button
                        onClick={() => this.setState({ openSettings: !this.state.openSettings, selectedIndex: 15 })}
                        selected={this.state.selectedIndex === 15}
                    >
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Einstellungen" />
                        {this.state.openSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </StyledListItem>
                    <Collapse
                        in={this.state.openSettings}
                        timeout="auto"
                        unmountOnExit
                        style={{ backgroundColor: grey["200"] }}
                    >
                        <List component="div" disablePadding>
                            {userHasRight(3) && (
                                <StyledListItem
                                    button
                                    className="nested"
                                    component={Link}
                                    to="/settings/employees"
                                    selected={this.state.selectedIndex === 16}
                                    onClick={() => this.setState({ selectedIndex: 16 })}
                                >
                                    <ListItemIcon>
                                        <ContactsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Benutzer" />
                                </StyledListItem>
                            )}
                            {userHasRight(1) && (
                                <StyledListItem
                                    button
                                    className="nested"
                                    component={Link}
                                    to="/settings/new-store"
                                    selected={this.state.selectedIndex === 17}
                                    onClick={() => this.setState({ selectedIndex: 17 })}
                                >
                                    <ListItemIcon>
                                        <StoreIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Neue Filiale" />
                                </StyledListItem>
                            )}
                            <StyledListItem
                                button
                                className="nested"
                                component={Link}
                                to="/settings/add-to-retailer"
                                selected={this.state.selectedIndex === 18}
                                onClick={() => this.setState({ selectedIndex: 18 })}
                            >
                                <ListItemIcon>
                                    <PersonAddIcon />
                                </ListItemIcon>
                                <ListItemText primary="Einer weiteren Filiale hinzufügen" />
                            </StyledListItem>

                            {/* <ListItem button className="nested" component={Link} to="/settings/store">
                                <ListItemIcon>
                                    <BusinessIcon />
                                </ListItemIcon>
                                <ListItemText primary="Filialeinstellungen" />
                            </ListItem> */}
                            {/* <ListItem button className="nested" component={Link} to="/settings/order">
                                <ListItemIcon>
                                    <ShoppingCartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Bestellen" />
                            </ListItem> */}
                        </List>
                    </Collapse>
                    {/* <StyledListItem
                        button
                        onClick={() =>
                            this.setState({ openRecruitPartners: !this.state.openRecruitPartners, selectedIndex: 19 })
                        }
                        selected={this.state.selectedIndex === 19}
                    >
                        <ListItemIcon>
                            <i className="kl-icon-Icon_Promote_02_Small" style={{ fontSize: 24 }} />
                        </ListItemIcon>
                        <ListItemText primary="Partner Werben" />
                        {this.state.openRecruitPartners ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </StyledListItem> */}
                    <Collapse
                        in={this.state.openRecruitPartners}
                        timeout="auto"
                        unmountOnExit
                        style={{ backgroundColor: grey["200"] }}
                    >
                        <List component="div" disablePadding>
                            <StyledListItem
                                button
                                className="nested"
                                component={Link}
                                to="/partner-recruiting/recruit-new-partner"
                                selected={this.state.selectedIndex === 20}
                                onClick={() => this.setState({ selectedIndex: 20 })}
                            >
                                <ListItemIcon>
                                    <i className="kl-icon-Icon_Mail_Small" style={{ fontSize: 24 }} />
                                </ListItemIcon>
                                <ListItemText primary="So funktioniert's" />
                            </StyledListItem>
                            <StyledListItem
                                button
                                className="nested"
                                component={Link}
                                to="/partner-recruiting/recruiting-statistics"
                                selected={this.state.selectedIndex === 21}
                                onClick={() => this.setState({ selectedIndex: 21 })}
                            >
                                <ListItemIcon>
                                    <i className="kl-icon-Icon_Counter_Small" style={{ fontSize: 24 }} />
                                </ListItemIcon>
                                <ListItemText primary="Meine Empfehlungen" />
                            </StyledListItem>
                        </List>
                    </Collapse>
                </List>
                {/* {this.props.retailer && this.props.retailer.informations &&
                    <div style={{ display: "flex" }}>
                        <p>Gutscheine im Umlauf:</p>
                        <p> {this.props.retailer.actualCouponAccountValue} €</p>
                    </div>} */}
                <Dialog
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Fehlende Daten"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{this.state.dialogText}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() =>
                                this.setState(
                                    { showSpinner: false, showDialog: false },
                                    this.props.history.push("/administration/")
                                )
                            }
                            color="primary"
                            autoFocus
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showSwitchRetailer}
                    onClose={this.handleCloseSwitchRetailer}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Filiale wechseln"}</DialogTitle>
                    <DialogContent>
                        {this.props.user.employee.Retailers.map((retailer) => {
                            return (
                                <ListItem
                                    button
                                    key={retailer.retailersId}
                                    onClick={() => {
                                        this.setState(() => ({ showSpinner: true }));
                                        store.dispatch(getRetailerTransactions(retailer.retailersId));
                                        store.dispatch(getActualCouponAccountValue(retailer.retailersId));
                                        store
                                            .dispatch(getRetailerInformationsWithRetailersId(retailer.retailersId))
                                            .then((response) => {
                                                try {
                                                    if (
                                                        this.props.user.employee.Rights.find((element) => element === 1)
                                                    ) {
                                                        if (
                                                            this.props.retailer.informations.imageUrl ===
                                                                "https://local-heroes-retailer-images.s3.eu-central-1.amazonaws.com/keeplocallogofarbig.png" ||
                                                            this.props.retailer.informations.licence === " " ||
                                                            !this.props.retailer.informations.ManagingDirector
                                                        ) {
                                                            const logo =
                                                                this.props.retailer.informations.imageUrl ===
                                                                "https://local-heroes-retailer-images.s3.eu-central-1.amazonaws.com/keeplocallogofarbig.png"
                                                                    ? "\n - Händlerlogo"
                                                                    : "";
                                                            const licence =
                                                                this.props.retailer.informations.licence === " "
                                                                    ? "\n - Gewerbeschein"
                                                                    : "";
                                                            const managingDirector = !this.props.retailer.informations
                                                                .ManagingDirector
                                                                ? "\n - Geschäftsführer"
                                                                : "";
                                                            this.setState({
                                                                showSwitchRetailer: false,
                                                                showDialog: true,
                                                                dialogText:
                                                                    "Bitte vervollständige deine Daten in der App. Folgende Informationen fehlen: \n " +
                                                                    logo +
                                                                    licence +
                                                                    managingDirector,
                                                            });
                                                        } else {
                                                            this.setState(
                                                                { showSpinner: false, showSwitchRetailer: false },
                                                                this.props.history.push("/administration/")
                                                            );
                                                        }
                                                    } else {
                                                        this.setState(
                                                            { showSpinner: false, showSwitchRetailer: false },
                                                            this.props.history.push("/administration/")
                                                        );
                                                    }
                                                } catch (e) {
                                                    this.setState(
                                                        { showSpinner: false },
                                                        this.props.history.push("/administration/")
                                                    );
                                                }
                                            })
                                            .catch(() =>
                                                this.setState({ showSpinner: false, showSwitchRetailer: false })
                                            );
                                    }}
                                >
                                    <ListItemText primary={retailer.name} />
                                </ListItem>
                            );
                        })}
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Abbrechen
                            </Button>
                        </DialogActions>
                        {this.state.showSpinner && <CircularProgress size={24} className="ButtonSpinner" />}
                    </DialogContent>
                </Dialog>

                <CheckCouponDialog
                    openCheckCoupon={this.state.openCheckCoupon}
                    handleCloseCheckCoupon={this.handleCloseCheckCoupon}
                />
            </Drawer>
        );
    }
}

function mapStateToProps(state) {
    const { retailer, user } = state;
    return {
        retailer,
        user,
    };
}

export default connect(mapStateToProps)(withRouter(Navigation));
