import { Backdrop, Box, Button, CircularProgress, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import numeral from "numeral";
import Modal from "@material-ui/core/Modal";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getRetailerTransactions, getAllRetailerTransactions } from "../../store/actions/retailerActions";
import { store } from "../../store/store";
import Filter from "./Filter";
import moment from "moment";
import "./Transactions.scss";

class Transactions extends Component {
    constructor() {
        super();
        this.state = {
            refreshing: false,
            filterOpen: false,
        };

        this.fetchTransactions = this.fetchTransactions.bind(this);
        this.handleModelClose = this.handleModelClose.bind(this);
    }

    fetchTransactions(allTransactions = false, startDate = null, endDate = null) {
        let period = false;

        if (startDate && !endDate) {
            endDate = new moment().endOf("day").format("x");
        }

        if (endDate && !startDate) {
            startDate = "0";
        }

        if (startDate && endDate) {
            period = true;
        }

        if (!this.props.retailer || !this.props.retailer.informations) {
            this.props.history.push("/account/choose-retailer");
        } else {
            this.props.setHeaderTitle("Transaktionen");

            if (!this.state.refreshing) {
                this.setState({ refreshing: true });
                if (allTransactions) {
                    store
                        .dispatch(
                            getAllRetailerTransactions(
                                this.props.retailer.informations.retailersId,
                                period,
                                startDate,
                                endDate
                            )
                        )
                        .then((response) => {
                            this.setState({ refreshing: false });
                        })
                        .catch((error) => {
                            this.setState({ refreshing: false });
                        });
                } else {
                    store
                        .dispatch(
                            getRetailerTransactions(
                                this.props.retailer.informations.retailersId,
                                period,
                                startDate,
                                endDate
                            )
                        )
                        .then((response) => {
                            this.setState({ refreshing: false });
                        })
                        .catch((error) => {
                            this.setState({ refreshing: false });
                        });
                }
            }
        }
    }

    componentDidMount() {
        this.fetchTransactions();
    }
    handleModelClose = () => {
        this.setState({ filterOpen: false });
    };
    render() {
        return (
            <div>
                {this.props.retailer && this.props.retailer.transactions && (
                    <React.Fragment>
                        <Backdrop open={this.state.refreshing} style={{ zIndex: 9999 }}>
                            <CircularProgress />
                        </Backdrop>
                        <Box style={{ margin: "5px" }} display="flex" justifyContent="flex-end">
                            <Button
                                onClick={() => this.setState({ filterOpen: true })}
                                color="primary"
                                variant="contained"
                            >
                                <Typography variant="h8">Download</Typography>
                            </Button>
                        </Box>
                        <TableContainer component={Paper} style={{ marginTop: "5px" }}>
                            <Table className="table" aria-label="simple table">
                                <TableHead>
                                    <TableRow style={{ background: "#DCDCDC" }}>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell style={{ fontSize: "24px" }}>Transaktionstyp</TableCell>
                                        <TableCell style={{ fontSize: "24px" }}>Kunde</TableCell>
                                        <TableCell style={{ fontSize: "24px" }}>Datum</TableCell>
                                        <TableCell style={{ fontSize: "24px" }}>Benutzer</TableCell>
                                        <TableCell style={{ fontSize: "24px" }} align="right">
                                            Betrag
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.retailer.transactions.map((transaction, index) => {
                                        let timestamp = Math.round(transaction.timestamp);
                                        let datetime = new Date(timestamp).toLocaleString();
                                        let moneyAmount = numeral(parseFloat(transaction.moneyAmount)).format(
                                            "0[.]00 $"
                                        );
                                        var type = "";
                                        switch (transaction.type) {
                                            case 1:
                                                type = "Gutscheinverkauf extern";
                                                break;
                                            case 2:
                                                type = "Gutscheineinlösung";
                                                break;
                                            case 3:
                                                type = "Gutscheinaktivierung";
                                                break;
                                            case 4:
                                                type = "Stornierung Gutscheinkauf extern";
                                                break;
                                            case 5:
                                                type = "Stornierung Gutscheineinlösung";
                                                break;
                                            case 6:
                                                type = "Stornierung Gutscheinaktivierung";
                                                break;
                                            case 7:
                                                type = "Einlösung Essensmarke";
                                                break;
                                            case 8:
                                                type = "Storno Einlösung Essensmarke";
                                                break;
                                            default:
                                                break;
                                        }
                                        return (
                                            <TableRow
                                                style={index % 2 ? { background: "#F5F5F5" } : { background: "white" }}
                                                key={transaction.retailerTransactionsId}
                                            >
                                                <TableCell>{this.props.retailer.transactions.length - index}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div
                                                        className="retailerImage"
                                                        style={{ backgroundImage: "url(" + transaction.imageUrl + ")" }}
                                                    ></div>
                                                </TableCell>
                                                <TableCell>{type}</TableCell>
                                                <TableCell>{transaction.displayName}</TableCell>
                                                <TableCell>{datetime}</TableCell>
                                                <TableCell>{transaction.userName}</TableCell>
                                                <TableCell align="right">{moneyAmount}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {this.props.retailer.transactions.length === 0 && (
                            <Box p={2}>
                                <Typography style={{ textAlign: "center" }}>keine Transaktionen gefunden</Typography>
                            </Box>
                        )}
                        <Modal open={this.state.filterOpen} onClose={this.handleModelClose}>
                            <Filter onAbort={() => this.setState({ filterOpen: false })} />
                        </Modal>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(withRouter(Transactions));
