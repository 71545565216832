import { Box, Button, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getBillingInformations } from "../../store/actions/retailerActions";
import { store } from "../../store/store";

class BillingAddressForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: true,
        };
        this.formRef = React.createRef();
        this.updateBillingInformation = this.updateBillingInformation.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
    }

    componentDidMount() {
        store.dispatch(getBillingInformations(this.props.retailer.informations.retailersId)).then((response) => {
            let newValues = {
                billingName: response.billingInformation.billingAddress
                    ? response.billingInformation.billingAddress.billingName
                    : "",
                billingTown: response.billingInformation.billingAddress
                    ? response.billingInformation.billingAddress.billingTown
                    : "",
                billingStreet: response.billingInformation.billingAddress
                    ? response.billingInformation.billingAddress.billingStreet
                    : "",
                iban: response.billingInformation.iban,
                bic: response.billingInformation.bic,
                accountOwner: response.billingInformation.accountOwner,
            };
            this.setState({
                showSpinner: false,
            });
            this.formRef.current.resetForm({ values: newValues });
        });
    }

    updateBillingInformation(values) {
        let newLocalData = {
            ...this.props.localData,
            billingInformation: {
                iban: values.iban,
                bic: values.bic,
                accountOwner: values.accountOwner,
            },

            billingAddress: {
                billingName: values.billingName === "" ? " " : values.billingName,
                billingTown: values.billingTown === "" ? " " : values.billingTown,
                billingStreet: values.billingStreet === "" ? " " : values.billingStreet,
            },
        };
        this.props.successCallback(newLocalData, this.props.townData, this.props.stateData);
    }

    handleSkip() {
        this.props.successCallback(this.props.localData, this.props.townData, this.props.stateData);
    }
    render() {
        return (
            <div>
                <Formik
                    innerRef={this.formRef}
                    initialValues={{
                        iban: "",
                        bic: "",
                        accountOwner: "",
                        billingName: "",
                        billingTown: "",
                        billingStreet: "",
                    }}
                    onSubmit={(values) => {
                        this.setState({
                            error: false,
                            errorText: "",
                        });
                        this.updateBillingInformation(values);
                    }}
                >
                    {(props) => (
                        <Form onBlur={this.props.handlesubmit}>
                            <Typography variant="h6">Kontoinformationen:</Typography>
                            <Box m={2} />
                            <Typography variant="caption">
                                Ändern sie die Zahlungsinformationen, wenn sie für diese Filiale abweichen
                            </Typography>
                            <Box m={3} />
                            <Grid item xs={12}>
                                <TextField
                                    id="iban"
                                    name="iban"
                                    label="IBAN"
                                    value={props.values.iban}
                                    onChange={props.handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="bic"
                                    name="bic"
                                    label="BIC"
                                    value={props.values.bic}
                                    onChange={props.handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="accountOwner"
                                    name="accountOwner"
                                    label="Kontoinhaber"
                                    value={props.values.accountOwner}
                                    onChange={props.handleChange}
                                    fullWidth
                                />
                                <Box m={5} />
                            </Grid>
                            <Typography>Rechnungsadresse:</Typography>
                            <Box m={2} />
                            <Grid item xs={12}>
                                <TextField
                                    id="billingName"
                                    name="billingName"
                                    label="Name"
                                    value={props.values.billingName}
                                    onChange={props.handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="billingTown"
                                    name="billingTown"
                                    label="Stadt"
                                    value={props.values.billingTown}
                                    onChange={props.handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="billingStreet"
                                    name="billingStreet"
                                    value={props.values.billingStreet}
                                    onChange={props.handleChange}
                                    label="Straße und Hausnummer"
                                    fullWidth
                                />
                            </Grid>
                            <Grid className="NewStoreStepperButtons" container>
                                <Grid item xs={6} className="PrevButtons">
                                    <Button
                                        type="button"
                                        onClick={this.props.handlePrevStep}
                                        variant="text"
                                        color="secondary"
                                    >
                                        Zurück
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={this.props.handleCancel}
                                        variant="text"
                                        color="secondary"
                                    >
                                        Abbrechen
                                    </Button>
                                </Grid>
                                <Grid item xs={6} className="NextButtons">
                                    <Button
                                        disabled={this.state.showSpinner}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Speichern
                                    </Button>
                                    <Button
                                        onClick={this.handleSkip}
                                        disabled={this.state.showSpinner}
                                        type="button"
                                        variant="contained"
                                        color="default"
                                    >
                                        Überspringen
                                    </Button>
                                </Grid>
                                {this.state.showSpinner && <CircularProgress size={24} className="ButtonSpinner" />}
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(BillingAddressForm);
