import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { activateCoupon } from "../../../store/actions/couponActions";
import { store } from "../../../store/store";
import { Card, CardMedia, CardContent, Typography, ButtonGroup, Button, InputAdornment, TextField, CardHeader, CircularProgress, Box } from "@material-ui/core";
import "./ActivateCoupon.scss"
import NumberFormat from 'react-number-format'
import EuroIcon from '@material-ui/icons/Euro';
import { Alert, AlertTitle } from "@material-ui/lab";

class ActivateCouponDetailsScreen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            couponDetails: props.location.state['couponDetails'],
            showSpinner: false,
            value: "20,00",
            error: false,
            errorTitle: '',
            errorText: ''
        }

        this.time = -1
        this.activateCouponClick = this.activateCouponClick.bind(this);
        this.useCustomValue = this.useCustomValue.bind(this);
        this.useDefaultValue = this.useDefaultValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.backClick = this.backClick.bind(this);
    }

    activateCouponClick() {
        var elapsedTime = new Date().getTime() - this.time

        if (elapsedTime > 2000) {
            this.time = new Date().getTime();
            if (this.state.couponDetails.startValue === null) {
                var number = Number(this.state.value.replace(',', '.'))
                if (number > 500) {
                    this.setState({
                        error: true,
                        errorTitle: 'Betrag zu hoch',
                        errorText: 'Bitte gib einen Wert von maximal 500€ ein.'
                    })
                }
                else {
                    this.setState({
                        errorText: '',
                        error: false,
                        errorTitle: '',
                        showSpinner: true
                    })
                    store.dispatch(activateCoupon(this.state.couponDetails.activationcode, this.props.retailer.informations.retailersId, number)).then(response => {
                        if (response.success) {
                            this.setState({
                                showSpinner: false
                            }, () => this.props.history.push('/administration/activate-coupon-success', { couponDetails: this.state.couponDetails, couponValue: number, message: response.message }))
                        }
                        else {
                            this.setState({
                                errorText: response.message,
                                error: true,
                                errorTitle: 'Fehler beim Aktivieren',
                                showSpinner: false
                            })
                        }
                    })
                        .catch(err => {
                            this.setState({
                                errorText: 'Der Gutschein konnte nicht aktiviert werden. Bitte versuch es erneut.',
                                error: true,
                                errorTitle: 'Fehler beim Aktivieren',
                                showSpinner: false
                            })
                        })
                }
            }
            else {
                this.setState({
                    showSpinner: true
                })
                store.dispatch(activateCoupon(this.state.couponDetails.activationcode, this.props.retailer.informations.retailersId)).then(response => {
                    if (response.success) {
                        this.setState({
                            showSpinner: false
                        }, () => this.props.history.push('/administration/activate-coupon-success', { couponDetails: this.state.couponDetails, couponValue: this.state.couponDetails.startValue, message: response.message }))
                    }
                    else {
                        this.setState({
                            errorText: response.message,
                            error: true,
                            errorTitle: 'Fehler beim Aktivieren',
                            showSpinner: false
                        })
                    }
                })
                    .catch(err => {
                        this.setState({
                            errorText: 'Der Gutschein konnte nicht aktiviert werden. Bitte versuch es erneut.',
                            error: true,
                            errorTitle: 'Fehler beim Aktivieren',
                            showSpinner: false
                        })
                    })
            }
        }
    }

    backClick() {
        this.setState({
            showSpinner: false
        }, () => this.props.history.push('/administration/activate-coupon'))
    }

    useCustomValue(value) {
        this.setState({ errorText: '' })
        var newValue;
        var moneyAmountWithOutSeperator = Number(value.nativeEvent.text.replace(',', '').replace('.', ''));
        if (!isNaN(moneyAmountWithOutSeperator)) {
            if (moneyAmountWithOutSeperator.toString().length === 1) {
                newValue = `0,0${moneyAmountWithOutSeperator.toString().charAt(0)}`
            } else if (moneyAmountWithOutSeperator.toString().length === 2) {
                newValue = `0,${moneyAmountWithOutSeperator.toString().substring(0)}`
            }
            else {
                newValue = `${moneyAmountWithOutSeperator.toString().substring(0, moneyAmountWithOutSeperator.toString().length - 2)},${moneyAmountWithOutSeperator.toString().substring(moneyAmountWithOutSeperator.toString().length - 2)}`
            }
            this.setState({ value: newValue })
        }
    }

    useDefaultValue(value) {
        this.setState({
            value: value,
            error: false,
            errorTitle: '',
            errorText: ''
        });
    }

    handleChange(e) {
        this.setState({
            value: e.target.value
        })
    }

    numberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator='.'
                decimalSeparator=','
                isNumericString
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
            />
        );
    }

    render() {
        return (
            <div className="cardContainer">
                <div style={{ marginTop: 20 }}>
                    <Card className="card">
                        {this.state.error &&
                            <CardHeader title={
                                <Alert severity="error">
                                    <AlertTitle>{this.state.errorTitle}</AlertTitle>
                                    {this.state.errorText}
                                </Alert>
                            } />}
                        <CardMedia
                            component="img"
                            height="140"
                            image={this.state.couponDetails.Retailer.imageUrl}
                            title={this.state.couponDetails.Retailer.name}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {this.state.couponDetails.Retailer.name}
                            </Typography>
                            {this.state.couponDetails.startValue &&
                                <Typography gutterBottom variant="h3" component="h1">
                                    {this.state.couponDetails.startValue}&nbsp;€
                                </Typography>}
                            {this.state.couponDetails.startValue === null &&
                                <div>
                                    <TextField
                                        disabled={this.state.showSpinner}
                                        name="activateValue"
                                        label="Betrag eingeben"
                                        size="small"
                                        variant="outlined"
                                        value={this.state.value}
                                        InputProps={{
                                            inputComponent: this.numberFormatCustom,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EuroIcon fontSize="small" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        className='activateValue'
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                    <ButtonGroup fullWidth={true} size="large" style={{ marginTop: 15 }}>
                                        <Button onClick={() => { this.useDefaultValue("20,00") }}>20€</Button>
                                        <Button onClick={() => { this.useDefaultValue("25,00") }}>25€</Button>
                                        <Button onClick={() => { this.useDefaultValue("35,00") }}>35€</Button>
                                    </ButtonGroup>
                                    <ButtonGroup fullWidth={true} size="large" style={{ marginTop: 5 }}>
                                        <Button onClick={() => { this.useDefaultValue("50,00") }}>50€</Button>
                                        <Button onClick={() => { this.useDefaultValue("75,00") }}>75€</Button>
                                        <Button onClick={() => { this.useDefaultValue("100,00") }}>100€</Button>
                                    </ButtonGroup>
                                </div>
                            }
                            <div className='buttonArea'>
                                <Box textAlign="left" position="relative">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.backClick}
                                        disabled={this.state.showSpinner}
                                        style={{ marginTop: 10 }}
                                    >
                                        Zurück
                                </Button>
                                </Box>
                                <Box textAlign="right" position="relative">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.activateCouponClick}
                                        disabled={this.state.showSpinner}
                                        style={{ marginTop: 10 }}
                                    >
                                        Aktivieren
                                </Button>
                                </Box>
                                {this.state.showSpinner && <CircularProgress size={24} className='ButtonSpinner' />}
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user, retailer
    };
}
export default connect(mapStateToProps)(withRouter(ActivateCouponDetailsScreen));