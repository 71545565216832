import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { AddCircle, CloudUpload } from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getChargings } from "../../../../store/actions/chargingActions";
import { store } from "../../../../store/store";
import "../CouponEmployees.scss";
import CreateOrderPopup from "./CreateOrderPopup";
import ImportOrderPopup from "./ImportOrderPopup";
import moment from "moment";
import OrdersTable from "./OrdersTable";

class Orders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            createOrderPopupVisible: false,
            importOrderPopupVisible: false,
            showHelperText: false,
            charging: undefined,
            showEditChargingWarning: false,
            editChargingWarning: "",
            showOldChargings: false,
        };

        this.handleOpenCreateOrderPopup = this.handleOpenCreateOrderPopup.bind(this);
        this.handleCloseCreateOrderPopup = this.handleCloseCreateOrderPopup.bind(this);
        this.handleOpenImportOrderPopup = this.handleOpenImportOrderPopup.bind(this);
        this.handleCloseImportOrderPopup = this.handleCloseImportOrderPopup.bind(this);
        this.toggleHelperText = this.toggleHelperText.bind(this);
        this.openEditCharging = this.openEditCharging.bind(this);
        this.toggleEditChargingWarning = this.toggleEditChargingWarning.bind(this);
        this.forceOpenEditCharging = this.forceOpenEditCharging.bind(this);
        this.handleSwitchShowOldChargings = this.handleSwitchShowOldChargings.bind(this);
    }

    componentDidMount() {
        store.dispatch(getChargings(this.props.retailer.informations.retailersId));
    }

    handleOpenCreateOrderPopup() {
        this.setState({
            charging: undefined,
            couponEmployee: undefined,
            createOrderPopupVisible: true,
        });
    }

    handleCloseCreateOrderPopup() {
        this.setState({
            createOrderPopupVisible: false,
        });
    }

    handleOpenImportOrderPopup() {
        this.setState({
            couponEmployee: undefined,
            importOrderPopupVisible: true,
        });
    }

    handleCloseImportOrderPopup() {
        this.setState({
            importOrderPopupVisible: false,
        });
    }

    toggleHelperText() {
        this.setState({ showHelperText: !this.state.showHelperText });
    }

    openEditCharging(charging) {
        var openEdit = false;
        var editChargingWarning = "";
        if (moment.unix(charging.nextExecutionDate) > moment().add(21, "days"))
        {
            openEdit = true;
        }
        else if (moment.unix(charging.nextExecutionDate) > moment().add(14, "days")) {
            editChargingWarning = "Dieser Auftrag wird am " + moment.unix(charging.nextExecutionDate).format("DD.MM.YYYY") + " ausgeführt. Alle Änderungen werden auf diese Ausführung angewendet. Möchstest du den Auftrag bearbeiten?" 
        }
        else if (moment.unix(charging.nextExecutionDate) > moment().add(-1, "days")) {
            editChargingWarning = "Dieser Auftrag ist bereits in Bearbeitung und wird am " + moment.unix(charging.nextExecutionDate).format("DD.MM.YYYY") + " ausgeführt. Alle Änderungen werden erst auf die darauf folgende Ausführung angewendet. Möchstest du den Auftrag bearbeiten?" 
        }
        else {
            editChargingWarning = "Dieser Auftrag ist bereits abgelaufen. Wenn Du die Termine anpasst, dann kannst du den Auftrag erneut aktivieren. Möchtest du den Auftrag bearbeiten?"
        }

        if (openEdit) {
            this.setState({
                charging: charging,
                couponEmployee: undefined,
                createOrderPopupVisible: true,
            })
        }
        else {
            this.setState({
                charging: charging,
                couponEmployee: undefined,
                editChargingWarning: editChargingWarning
            })
            this.toggleEditChargingWarning()
        }
    }

    toggleEditChargingWarning() {
        this.setState({
            showEditChargingWarning: !this.state.showEditChargingWarning
        })
    }

    forceOpenEditCharging(){
        this.toggleEditChargingWarning();
        this.setState({
            couponEmployee: undefined,
            createOrderPopupVisible: true,
        })
    }

    handleSwitchShowOldChargings() {
        this.setState({
            showOldChargings: !this.state.showOldChargings
        })
    }

    render() {
        var chargings = [];
        if (this.state.showOldChargings) {
            chargings = this.props.charging.chargings
        }
        else {
            chargings = this.props.charging.chargings.filter((value) => moment.unix(value.nextExecutionDate) > moment())
        }
        return (
            <div id="couponEmployees">
                <Backdrop open={this.props.charging.isLoading} style={{ zIndex: 9999 }}>
                    <CircularProgress />
                </Backdrop>
                <Box className="retailerSettings">
                <Button
                        style={{ margin: "10px" }}
                        color="default"
                        variant="contained"
                        onClick={this.handleSwitchShowOldChargings}
                    >
                        {this.state.showOldChargings ? "abgelaufene Aufträge ausblenden" : "abgelaufene Aufträge einblenden"}
                    </Button>
                    <Button
                        style={{ margin: "10px" }}
                        color="default"
                        variant="contained"
                        onClick={this.handleOpenCreateOrderPopup}
                        endIcon={<AddCircle />}
                    >
                        Auftrag erstellen
                    </Button>
                    <Button
                        style={{ margin: "10px" }}
                        color="secondary"
                        variant="contained"
                        onClick={this.handleOpenImportOrderPopup}
                        endIcon={<CloudUpload />}
                    >
                        Auftrag importieren
                    </Button>
                </Box>
                <OrdersTable 
                    data={chargings} 
                    toggleHelperText={this.toggleHelperText} 
                    showHelperText={this.state.showHelperText} 
                    showChargings={this.props.showChargings} 
                    openEditCharging={this.openEditCharging}
                    showOldChargings={this.state.showOldChargings}
                />
                <CreateOrderPopup
                    openCreateOrderPopup={this.state.createOrderPopupVisible}
                    handleCloseCreateOrderPopup={this.handleCloseCreateOrderPopup}
                    handleSubmit={this.handleSubmitCreateOrder}
                    charging={this.state.charging}
                />
                <ImportOrderPopup
                    openImportOrderPopup={this.state.importOrderPopupVisible}
                    handleCloseImportOrderPopup={this.handleCloseImportOrderPopup}
                    handleSubmit={this.handleSubmitActivateEmployeeCards}
                />
                <Dialog
                    onClose={this.toggleEditChargingWarning}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.showEditChargingWarning}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.toggleEditChargingWarning}>
                        Auftrag bearbeiten
                    </DialogTitle>
                    <DialogContent>
                        {this.state.editChargingWarning}
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={this.toggleEditChargingWarning} color="primary">
                            Abbrechen
                        </Button>
                        <Button autoFocus onClick={this.forceOpenEditCharging} color="primary">
                            Ja
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer, charging } = state;
    return {
        user,
        retailer,
        charging,
    };
}

export default connect(mapStateToProps)(withRouter(Orders));
