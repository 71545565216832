import { Button, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import SkipStepWarning from "./SkipStepWarning";

class NewStoreManagingDirector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            error: false,
            errorText: "",
            managingDirectorName: this.props.retailer.informations
                ? this.props.retailer.informations.ManagingDirector.name !== " "
                    ? this.props.retailer.informations.ManagingDirector.name
                    : ""
                : "",
            managingDirectorAddress: this.props.retailer.informations
                ? this.props.retailer.informations.ManagingDirector.address !== " "
                    ? this.props.retailer.informations.ManagingDirector.address
                    : ""
                : "",
            managingDirectorPhoneNumber: this.props.retailer.informations
                ? this.props.retailer.informations.ManagingDirector.phoneNumber !== " "
                    ? this.props.retailer.informations.ManagingDirector.phoneNumber
                    : ""
                : "",
            accountsDepartmentMail: this.props.retailer.informations
                ? this.props.retailer.informations.reportMailingList.length !== 0
                    ? this.props.retailer.informations.reportMailingList[0]
                    : ""
                : "",
            localData: this.props.location.state.localData ? this.props.location.state.localData : undefined,
            townData: this.props.location.state.townData ? this.props.location.state.townData : undefined,
            stateData: this.props.location.state.stateData ? this.props.location.state.stateData : undefined,
            skipVisible: false,
            setContactPerson: false,
            contactName: "",
            contactPhone: "",
            contactMail: "",
        };

        this.updateManagingDirector = this.updateManagingDirector.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
        this.handleToggleSkip = this.handleToggleSkip.bind(this);
    }

    updateManagingDirector(values) {
        this.setState({ showSpinner: true });
        this.setState(
            {
                showSpinner: false,
                localData: {
                    ...this.state.localData,
                    ManagingDirector: {
                        name: values.managingDirectorName === "" ? " " : values.managingDirectorName,
                        address: values.managingDirectorAddress === "" ? " " : values.managingDirectorAddress,
                        phoneNumber:
                            values.managingDirectorPhoneNumber === "" ? " " : values.managingDirectorPhoneNumber,
                    },
                },
            },
            () => {
                this.props.history.push("/account/new-store-report-mail-address", {
                    localData: this.state.localData,
                    townData: this.state.townData,
                    stateData: this.state.stateData,
                });
            }
        );
    }

    handleToggleSkip() {
        this.setState({
            skipVisible: !this.state.skipVisible,
        });
    }

    handleSkip() {
        this.setState(
            {
                showSpinner: false,
                skipVisible: false,
                localData: {
                    ...this.state.localData,
                    ManagingDirector: {
                        name: this.state.managingDirectorName === "" ? " " : this.state.managingDirectorName,
                        address: this.state.managingDirectorAddress === "" ? " " : this.state.managingDirectorAddress,
                        phoneNumber:
                            this.state.managingDirectorPhoneNumber === ""
                                ? " "
                                : this.state.managingDirectorPhoneNumber,
                    },
                },
                townData: {
                    ...this.state.townData,
                    contactPerson: {
                        contactName: " ",
                        contactPhone: " ",
                        contactMail: " ",
                    },
                    billingAddress: {
                        billingName: " ",
                        billingTown: " ",
                        billingStreet: " ",
                    },
                },
                stateData: {
                    ...this.state.stateData,
                    contactPerson: {
                        contactName: " ",
                        contactPhone: " ",
                        contactMail: " ",
                    },
                    billingAddress: {
                        billingName: " ",
                        billingTown: " ",
                        billingStreet: " ",
                    },
                },
            },
            () => {
                this.props.history.push("/account/new-store-report-mail-address", {
                    localData: this.state.localData,
                    townData: this.state.townData,
                    stateData: this.state.stateData,
                });
            }
        );
    }

    render() {
        return (
            <div className="NewManagingDirector">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <Typography variant="h6">Geschäftsführer</Typography>
                <Formik
                    initialValues={{
                        managingDirectorName: this.state.managingDirectorName,
                        managingDirectorAddress: this.state.managingDirectorAddress,
                        managingDirectorPhoneNumber: this.state.managingDirectorPhoneNumber,
                    }}
                    onSubmit={(values) => {
                        this.setState({
                            showSpinner: true,
                            error: false,
                            errorText: "",
                        });
                        this.updateManagingDirector(values);
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="managingDirectorName"
                                        name="managingDirectorName"
                                        label="Name"
                                        onChange={props.handleChange}
                                        value={props.values.managingDirectorName}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="managingDirectorAddress"
                                        name="managingDirectorAddress"
                                        label="Adresse"
                                        onChange={props.handleChange}
                                        value={props.values.managingDirectorAddress}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="managingDirectorPhoneNumber"
                                        name="managingDirectorPhoneNumber"
                                        label="Private Telefonnummer"
                                        onChange={props.handleChange}
                                        value={props.values.managingDirectorPhoneNumber}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", position: "relative" }}
                                >
                                    <Button
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Speichern
                                    </Button>
                                    {this.state.showSpinner && <CircularProgress size={24} className="Spinner" />}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", position: "relative" }}
                                >
                                    <Button
                                        onClick={this.handleToggleSkip}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                        type="button"
                                        variant="contained"
                                        color="default"
                                    >
                                        Überspringen
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <SkipStepWarning
                    visible={this.state.skipVisible}
                    onHiding={this.handleToggleSkip}
                    onSkip={this.handleSkip}
                    title="Achtung"
                >
                    <p>
                        <b>Ohne Angaben des Geschäftsführers können wir Dich nicht freischalten.</b>
                    </p>
                    <p>
                        Zur Legitimation brauchen wir neben Deinem Gewerbeschein oder Handelsregisterauszug auch die
                        Kontaktdaten des Geschäftsführers.
                    </p>
                </SkipStepWarning>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(NewStoreManagingDirector);
