import { Button, CircularProgress, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import React, { Component } from "react";
import "./RedeemForm.scss";
import { Alert } from "@material-ui/lab";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";

class RedeemConfirm extends Component {
    constructor() {
        super();
        this.state = {
            error: false,
            errorTypography: "",
            couponsValue: 0,
            scannedCodeCount: 0,
            remainingValue: "",
            transactions: [],
            couponList: [],
            wasEnough: false,
            orderIndex: 0,
        };

        this.onChangeInvoiceValueClick = this.onChangeInvoiceValueClick.bind(this);
        this.onAddCouponClick = this.onAddCouponClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            couponList: this.props.couponList,
            couponsValue: this.props.couponsValue,
            scannedCodeCount: this.props.scannedCodeCount,
            remainingValue: this.props.remainingValue,
            transactions: this.props.transactions,
            wasEnough: this.props.wasEnough,
            orderIndex: this.props.orderIndex,
            invoiceValue: this.props.invoiceValue,
        });
    }

    onChangeInvoiceValueClick() {
        this.props.onChangeInvoiceValueClick(
            this.state.invoiceValue,
            this.state.couponList, 
            this.state.couponsValue, 
            this.state.remainingValue, 
            this.state.scannedCodeCount, 
            this.state.wasEnough, 
            this.state.transactions,
            this.state.invoiceIsPaid,
            this.state.orderIndex,
            3
        );
    }

    onAddCouponClick() {
        this.props.onAddCouponClick(
            this.state.couponList, 
            this.state.couponsValue, 
            this.state.remainingValue, 
            this.state.scannedCodeCount, 
            this.state.wasEnough, 
            this.state.transactions,
            this.state.invoiceIsPaid,
            this.state.orderIndex,
        );
    }

    render() {
        const remainingValueColor = this.state.wasEnough ? "green" : "#ff0000";
        return (
            <div>
                {this.props.showSpinner && <CircularProgress size={40} style={{color:"#8d8d8d"}}/>}
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <div style={{display: "flex", justifyContent: "end"}}>
                    <div className="countCircle" >
                        <Typography className="countText">{this.state.scannedCodeCount}</Typography>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Typography>Zu zahlen:</Typography>
                    <Typography>{Number(this.state.invoiceValue).toFixed(2).replace(".",",")} €</Typography>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
                    <Typography>Guthaben:</Typography>
                    <Typography>{this.state.couponsValue.toFixed(2).replace(".",",")} €</Typography>
                </div>
                <div style={{height: 1, backgroundColor: "black", marginTop: 10, marginBlock:15}} />
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Typography style={{ color: remainingValueColor }}>
                        Offener Betrag:
                    </Typography>
                    <Typography style={{ color: remainingValueColor }}>
                        {this.state.wasEnough ? "0,00" : parseFloat(this.state.remainingValue).toFixed(2).replace(".", ",")} €
                    </Typography>
                </div>
                {this.state.transactions.length > 0 && (
                    <div>
                        <Typography style={{fontWeight: "bold", display: "flex", marginTop: 15, marginBottom: 10}}>
                            {this.state.transactions.length} verwendete
                            {this.state.transactions.length === 1 ? "r" : ""} Gutschein
                            {this.state.transactions.length > 1 ? "e" : ""}
                        </Typography>
                        <div>
                            {this.state.transactions.map((transaction, index) => {
                                return (
                                    <div key={index} style={{display: "flex", justifyContent: "space-between"}}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <span style={{display: "flex", marginRight: 5}}>
                                                <CardGiftcardIcon
                                                    fontSize="small"
                                                    style={{
                                                        color: transaction.fontColor,
                                                        backgroundColor: transaction.backgroundColor,
                                                        padding: "3px",
                                                        borderRadius: "3px",
                                                    }}
                                                />
                                            </span>
                                            <Typography>
                                                {transaction.name}
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography style={{marginRight: 30}}>
                                                ...
                                                {transaction.barcode.substr(
                                                    transaction.barcode.length - 5
                                                )}
                                            </Typography>
                                            <Typography>
                                                {parseFloat(transaction.value)
                                                    .toFixed(2)
                                                    .replace(".", ",") + " €"}
                                            </Typography>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 15}}>
                    <Typography style={{textDecoration: "underline", color: "green", cursor: "pointer", fontSize: 12}} disabled={this.props.showSpinner} onClick={this.onChangeInvoiceValueClick}>Betrag ändern</Typography>
                    {!this.state.wasEnough && <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={this.onAddCouponClick}
                        disabled={this.props.showSpinner}
                    >
                        Weiterer Gutscheincode
                    </Button>}
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={() => this.props.redeemCouponClick(this.state.couponList, this.state.invoiceValue)}
                        disabled={this.props.showSpinner}
                    >
                        Einlösen
                    </Button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(RedeemConfirm);
