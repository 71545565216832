import { Box, Collapse, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { globalConfig } from "../../../../helpers/globalConfig";

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{numeral(Number(row.sum)).format(globalConfig.currencyFormat)}</TableCell>
                <TableCell>{moment(row.creationDate).format("DD.MM.YYYY")}</TableCell>
                <TableCell>
                    {row.status === 1 ? "Offen" : row.status === 2 ? "In Bearbeitung" : "Abgeschlossen"}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Positionen
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Anzahl</TableCell>
                                        <TableCell>Beschreibung</TableCell>
                                        <TableCell align="right">Wert</TableCell>
                                        <TableCell align="right">Betrag</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.positions.map((position, i) => (
                                        <TableRow key={i}>
                                            <TableCell component="th" scope="row">
                                                {position.amount}
                                            </TableCell>
                                            <TableCell>{position.description}</TableCell>
                                            <TableCell align="right">{position.value}</TableCell>
                                            <TableCell align="right">
                                                {numeral(position.amount * position.value).format(
                                                    globalConfig.currencyFormat
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function BillingsTable(props) {
    return (
        <Table size="small">
            <TableHead style={{ backgroundColor: grey["200"] }}>
                <TableRow>
                    <TableCell />
                    <TableCell>Gesamtwert</TableCell>
                    <TableCell>Erstellungsdatum</TableCell>
                    <TableCell>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.data.map((row, i) => {
                    if (props.chargingsId === "" || props.chargingsId === row.chargingsId) return <Row key={row.ordersId} row={row} />;
                })}
            </TableBody>
        </Table>
    );
}

export default BillingsTable;
