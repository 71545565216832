import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Tabs, 
    Tab,
    Typography,
    Box
} from "@material-ui/core";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box p={3}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class RecruitingFaqsPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: "one",
        };

        this.closePopup = this.closePopup.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
    }

    closePopup() {
        this.props.closePopup();
    }

    openPopup() {
        this.props.openPopup();
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tab: newValue
        })
    }

    render() {
        return (
            <Dialog
                onClose={this.closePopup}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
            >
                <DialogTitle id="customized-dialog-title" onClose={this.closePopup}>
                    Partner werben Partner
                </DialogTitle>
                <DialogContent>
                    <div className="popup-content">
                        <Tabs value={this.state.tab} onChange={this.handleChangeTab} variant="fullWidth">
                            <Tab value="one" label={"Häufig gestellte Fragen"} />
                            <Tab value="two" label={"Teilnahmebedingungen"}  />
                        </Tabs>
                        <TabPanel value={this.state.tab} index="one">
                            <div>
                                <h3>Wie viele Personen kann ich empfehlen?</h3>
                                <p>So viele, wie Dir einfallen und je mehr, desto besser!</p>

                                <h3>Was passiert, wenn jemand dieselbe Person empfiehlt?</h3>
                                <p>Über Deinen persönlichen Einladungslink können wir nachvollziehen, dass die Registrierung aufgrund Deines Links vorgenommen wurde. Erhält eine Person mehrere Einladungslinks, zählt der, den sie anklickt. 
                                Tipp: Sprich am besten mit der zu empfehlenden Person, bevor Du den Link an sie verschickst. So kann sie direkt darauf reagieren und Du Dir Deine Belohnung sichern.</p>

                                <h3>Wann bekomme ich meine 50 €?</h3>
                                <p>Sobald die empfohlene Person zum ersten Mal eine Aktivierung oder Einlösung vorgenommen hat, zahlen wir automatisch die 50 € aus. Du findest sie dann auf Deiner nächsten Abrechnung.</p>

                                <h3>Was hat die empfohlene Person davon, wenn sie mitmacht?</h3>
                                <p>Außer, dass sie natürlich alle üblichen KeepLocal-Vorzüge und –Services genießt, bekommt sie obendrein noch einen unserer Gutscheinständer als Willkommensgeschenk. Dieses Thekendisplay verfügt über vier Gutschein- und ein Flyer-Fach.</p>
                            </div>
                        </TabPanel>
                        <TabPanel value={this.state.tab} index="two">
                            <div>
                                <h3>Teilnahmebedingungen KeepLocal Empfehlungsprogramm „Partner werben Partner“</h3>
                                <p>Anbieter des KeepLocal Empfehlungsprogramms „Partner werben Partner“ ist die KeepLocal GmbH, Tholeyer Str. 3A, Eingang B, 66606 St. Wendel.</p>
                                <p>Teilnahmeberechtigt sind ausschließlich bei KeepLocal bereits registrierte Partner. Diese haben die Möglichkeit, andere Unternehmen als neue Partner für das KeepLocal-Gutscheinsystem zu empfehlen.</p>
                                <p>Empfohlen werden können Unternehmen, die zum Zeitpunkt der Empfehlung noch nicht aktive Partner bei KeepLocal sind oder zum Zeitpunkt der Empfehlung mindestens 6 Monate nicht mehr als Partner bei KeepLocal registriert sind.</p>
                                <p>Der werbende Partner kann im Rahmen des Empfehlungsprogramms „Partner werben Partner“ über seinen KeepLocal Partneraccount an ein anderes Unternehmen eine Empfehlungsmail mit einem Link zur Registrierung bei KeepLocal versenden. Der werbende Partner sichert zu, dass der Versand der Empfehlungsmail nur mit vorheriger Einwilligung des Empfängers erfolgt.</p>
                                <p>Im Rahmen des Empfehlungsprozesses muss der neue Partner den Link des werbenden Partners anklicken und sich dann über die KeepLocal-Registrierungsseite bei KeepLocal registrieren. Eine nachträgliche manuelle Zuordnung eines neuen Partners durch KeepLocal zu einem werbenden Partner ist ausgeschlossen.</p>
                                <p>Erfolgreich ist eine Empfehlung nur dann, wenn der neue Partner sich bei KeepLocal registriert, KeepLocal den Account des neuen Partners freigeschalten hat und der neue Partner mindestens einen KeepLocal Gutschein in seinem Geschäft aktiviert oder eingelöst hat (kostenlose KeepLocal Testgutscheine zählen nicht mit). Der neue Partner erhält nach der erfolgreichen Registrierung und der Einweisung durch KeepLocal Gutscheine zum Verkauf, als auch einen kostenfreien Gutscheinständer zugeschickt.</p>                                
                                <p>Im Falle einer erfolgreichen Empfehlung eines neuen Partners erhält der werbende Partner einmalig eine 50 Euro Gutschrift, die ihm bei der nächsten Abrechnung von KeepLocal gutgeschrieben wird. Eine Barauszahlung sowie eine Übertragung des Gutschriftbetrags auf einen Dritten sind nicht möglich. Sonstige Ansprüche sind ausgeschlossen.</p>                                
                            </div>
                        </TabPanel>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withRouter(RecruitingFaqsPopup);
