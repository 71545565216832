import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import { Typography, Grid, TextField, Button, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import SkipStepWarning from "./SkipStepWarning";

class NewStoreReportMailAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            localData: this.props.location.state.localData ? this.props.location.state.localData : undefined,
            townData: this.props.location.state.townData ? this.props.location.state.townData : undefined,
            stateData: this.props.location.state.stateData ? this.props.location.state.stateData : undefined,
            accountsDepartmentMail: this.props.retailer.informations
                ? this.props.retailer.informations.reportMailingList.length !== 0
                    ? this.props.retailer.informations.reportMailingList[0]
                    : ""
                : "",
            error: false,
            errorText: "",
            skipVisible: false,
        };

        this.updateReportMailAddress = this.updateReportMailAddress.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
        this.handleToggleSkip = this.handleToggleSkip.bind(this);
    }

    handleToggleSkip() {
        this.setState({
            skipVisible: !this.state.skipVisible,
        });
    }

    updateReportMailAddress(values) {
        this.setState({ showSpinner: true });
        var reportList = [values.accountsDepartmentMail];
        this.setState(
            {
                showSpinner: false,
                localData: {
                    ...this.state.localData,
                    reportMailingList: reportList,
                },
            },
            () => {
                this.props.history.push("/account/new-store-billing-information", {
                    localData: this.state.localData,
                    townData: this.state.townData,
                    stateData: this.state.stateData,
                });
            }
        );
    }

    handleSkip() {
        var reportList = this.state.localData.reportMailingList;
        reportList.push(this.state.accountsDepartmentMail);
        this.setState(
            {
                showSpinner: false,
                skipVisible: false,
                localData: {
                    ...this.state.localData,
                    reportMailingList: reportList,
                },
            },
            () => {
                this.props.history.push("/account/new-store-billing-information", {
                    localData: this.state.localData,
                    townData: this.state.townData,
                    stateData: this.state.stateData,
                });
            }
        );
    }

    render() {
        return (
            <div className="NewStoreReportMailAddress">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <Typography variant="h6">Buchhaltungsadresse</Typography>
                <Formik
                    initialValues={{
                        accountsDepartmentMail: this.state.accountsDepartmentMail,
                    }}
                    validationSchema={Yup.object().shape({
                        accountsDepartmentMail: Yup.string()
                            .email("Keine korrekte E-Mail-Adresse")
                            .required("Dieses Feld wird benötigt"),
                    })}
                    onSubmit={(values) => {
                        this.setState({
                            showSpinner: true,
                            error: false,
                            errorText: "",
                        });
                        this.updateReportMailAddress(values);
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="accountsDepartmentMail"
                                        name="accountsDepartmentMail"
                                        label="E-Mail-Adresse"
                                        type="email"
                                        onChange={props.handleChange}
                                        value={props.values.accountsDepartmentMail}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", position: "relative" }}
                                >
                                    <Button
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Speichern
                                    </Button>
                                    {this.state.showSpinner && <CircularProgress size={24} className="Spinner" />}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", position: "relative" }}
                                >
                                    <Button
                                        onClick={this.handleToggleSkip}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                        type="button"
                                        variant="contained"
                                        color="default"
                                    >
                                        Überspringen
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <SkipStepWarning
                    visible={this.state.skipVisible}
                    onHiding={this.handleToggleSkip}
                    onSkip={this.handleSkip}
                    title="Achtung"
                >
                    <p>
                        <b>Ohne Buchhaltungsadresse gibt's keine Abrechnung.</b>
                    </p>
                    <p>
                        Wenn wir keine E-Mail-Adresse der Buchhaltung haben, können wir Dir die Abrechnung nicht
                        zukommen lassen. Wichtig für Deinen Steuerberater!
                    </p>
                </SkipStepWarning>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(NewStoreReportMailAddress);
