/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: "eu-central-1",
    aws_cloud_logic_custom: [
        {
            name: "digitalReloadApi",
            endpoint: "https://2lf9snnm0i.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "employee",
            endpoint: "https://8w7fxooy3e.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "localHeroesRetailer",
            endpoint: "https://7g0tkm6uh7.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
    ],
    aws_cognito_identity_pool_id: "eu-central-1:7640511b-47d7-46ce-940f-1cf5dd377a4e",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_wfnqN3Gib",
    aws_user_pools_web_client_id: "q02ibnhn5g0ovaetdnq9s3i7o",
    oauth: {
        scope: ["aws.cognito.signin.user.admin", "email", "openid", "phone", "profile"],
        redirectSignIn: "https://www.keeplocal.de/",
        redirectSignOut: "https://www.keeplocal.de/",
        responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
    aws_user_files_s3_bucket: "licence135950-main",
    aws_user_files_s3_bucket_region: "eu-central-1",
};

export default awsmobile;
