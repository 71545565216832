import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import Dropzone from "react-dropzone";

export default class UploadTradingLicence extends Component {
    constructor() {
        super();

        this.state = {
            showSpinner: true,   
            error: false,
            errorText: "",     
            licence: null,
        };

        
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDropRejected = this.handleDropRejected.bind(this);
    }

    handleDrop(e) {
        if (e[0]) {
            this.props.setTradingLicence(e[0]);
        }
    }

    handleDropRejected(e) {
        let errors = e[0].errors;
        var errorText = "Dateityp wurde nicht akzeptiert. Bitte nur *.pdf-Dateien, *.jpg- und *.png-Bilder verwenden";

        switch (errors[0].code) {
            case "file-too-large":
                errorText = "Datei ist zu groß!";
                break;

            default:
                break;
        }

        this.setState({
            error: true,
            errorText: errorText,
        });
    }

    render() {        
        const FILE_SIZE = 100000 * 1024;
        const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png", "application/pdf"];
        return (
            <Dropzone
            accept={SUPPORTED_FORMATS}
            onDropRejected={this.handleDropRejected}
            maxSize={FILE_SIZE}
            multiple={false}
            onDrop={this.handleDrop}
        >
            {({ getRootProps, getInputProps }) => (
                <section className={this.state.error ? "dropzoneContainer error" : "dropzoneContainer"}>
                    <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />

                        <React.Fragment>
                            <Typography variant="body1">
                                Ziehen Sie Dateien per Drag&nbsp;'n'&nbsp;Drop hierher oder klicken Sie, um
                                Dateien auszuwählen.
                            </Typography>
                            <Typography variant="body2">
                                (Nur *.pdf-Dateien, *.jpg- und *.png-Bilder werden akzeptiert)
                            </Typography>
                        </React.Fragment>
                    </div>
                </section>
            )}
        </Dropzone>
        );
    }
}
