import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Button, Box  } from "@material-ui/core";
import React, { Component } from "react";

export default class ActivateCardDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            errorText: "",
            showSpinner: false,
            selectedCard: undefined
        };

        this.handleReset = this.handleReset.bind(this);
        this.handleActivateCard = this.handleActivateCard.bind(this);
    }

    handleReset() {
        this.setState(
            {
                error: false,
                errorText: "",
                showSpinner: false,
            },
            this.props.handleCloseTransferCardDialog
        );
    }

    handleActivateCard() {
        this.props.handleActivateCard(this.props.couponEmployee.original.couponEmployeesId)
    }

    render() {
        const { showSpinner } = this.state;
        
        return (
            <Dialog
                maxWidth="xs"
                open={this.props.openActivateCardDialog}
                onClose={this.handleReset}
                aria-labelledby="form-dialog-title"
                className="CheckCoupon"
            >
                {this.props.couponEmployee && <DialogTitle id="form-dialog-title">
                    {this.props.couponEmployee.original.displayValue + (this.props.couponEmployee.original.isActive ? " deaktivieren" : " aktivieren")}
                </DialogTitle>}
                {this.props.couponEmployee && <DialogContent>
                    Soll {this.props.couponEmployee.original.displayValue + (this.props.couponEmployee.original.isActive ? " wirklich deaktiviert" : " wirklich aktiviert")} werden?
                    (Für laufende Aufträge werden nur aktivierte Mitarbeiter/Karten berücksichtigt)
                </DialogContent>}
                <DialogActions
                    style={{
                        position: "relative",
                    }}
                >
                    <Button onClick={this.props.handleCloseActivateCardDialog} color="default"  disabled={showSpinner}>
                        Nein
                    </Button>
                    <Box display="inline" position="relative">
                        <Button onClick={() => this.handleActivateCard()} color="secondary" disabled={showSpinner}>
                        Ja
                        </Button>
                        {showSpinner && (
                            <CircularProgress
                                size=""
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: -12,
                                    marginLeft: -12,
                                    width: "24px",
                                }}
                            />
                        )}
                    </Box>
                </DialogActions>
            </Dialog>
        );
    }
}
