import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import { Formik, Form } from "formik";
import { Grid, TextField, Button, Typography, CircularProgress } from "@material-ui/core";
import * as Yup from "yup";
import "./Confirm.scss";
import { userConfirmRegistrationWithCode, userResendConfirmationCode } from "../../store/actions/userAction";
import { store } from "../../store/store";

export default class Confirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            error: false,
            success: false,
            errorText: "",
            successText: "",
            username: this.props.location.state.username,
        };
    }

    handleClickResendCode = () => {
        this.setState({ showSpinner: true });
        store
            .dispatch(userResendConfirmationCode(this.state.username.toLowerCase()))
            .then((data) => {
                this.setState({ showSpinner: false });
            })
            .catch((err) => {
                this.setState({ showSpinner: false, error: true, errorText: err.message });
            });
    };

    render() {
        return (
            <div className="Confirm">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                {this.state.success && (
                    <Alert style={{ marginBottom: "20px" }} severity="success">
                        {this.state.successText}
                    </Alert>
                )}
                <Typography variant="body2" align="left">
                    Wir haben dir einen Verifizierungscode an die von dir angegebene E-Mail-Adresse gesendet. Bitte
                    trage diesen hier ein um deine Registierung abzuschließen.
                </Typography>
                <Formik
                    initialValues={{
                        code: "",
                    }}
                    validationSchema={Yup.object().shape({
                        code: Yup.string().required("Dieses Feld wird benötigt"),
                    })}
                    onSubmit={(values) => {
                        this.setState({
                            showSpinner: true,
                            error: false,
                            errorText: "",
                        });
                        store
                            .dispatch(
                                userConfirmRegistrationWithCode(this.state.username.toLowerCase(), values.code, false)
                            )
                            .then((response) => {
                                this.setState({
                                    showSpinner: false,
                                    success: true,
                                    successText: "Das Konto wurde erfolgreich aktiviert",
                                });
                                this.props.history.push({
                                    pathname: "/account",
                                    state: { registrationSuccessful: true },
                                });
                            })
                            .catch((err) => {
                                this.setState({ showSpinner: false });
                                switch (err.message) {
                                    case "Invalid verification code provided, please try again.":
                                        this.setState({
                                            error: true,
                                            errorText: "Der eingegebene Code ist inkorrekt!",
                                        });
                                        break;
                                    default:
                                        this.setState({ error: true, errorText: err.message });
                                        break;
                                }
                            });
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="code"
                                        name="code"
                                        label="Verifizierungscode"
                                        defaultValue={props.values.code}
                                        onChange={props.handleChange}
                                        error={props.errors.code ? true : false}
                                        helperText={props.errors.code}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", position: "relative" }}
                                >
                                    <Button
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Bestätigen
                                    </Button>
                                    {this.state.showSpinner && <CircularProgress size={24} className="Spinner" />}
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <Typography variant="body2" align="left">
                    Falls du keinen Verifizierungscode innerhalb der nächsten 5 Minuten erhälst, versuche einen neuen
                    Verifizierungscode zu versenden.
                </Typography>
                <Grid item xs={12} style={{ textAlign: "right", marginTop: "20px", position: "relative" }}>
                    <Button
                        fullWidth
                        disabled={this.state.showSpinner}
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleClickResendCode()}
                    >
                        Code erneut senden
                    </Button>
                    {this.state.showSpinner && <CircularProgress size={24} className="Spinner" />}
                </Grid>
            </div>
        );
    }
}
