import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Button, Box, TextField  } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class TransferCardDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            errorText: "",
            showSpinner: false,
            selectedEmployeeCard: undefined
        };

        this.handleReset = this.handleReset.bind(this);
        this.handleTransferCard = this.handleTransferCard.bind(this);
    }

    handleReset() {
        this.setState(
            {
                error: false,
                errorText: "",
                showSpinner: false,
            },
            this.props.handleCloseTransferCardDialog
        );
    }

    handleTransferCard() {
        if (this.state.selectedEmployeeCard){
            this.props.handleTransferCard(this.props.employeeCard, this.state.selectedEmployeeCard.id)
        }
    }

    render() {
        const { showSpinner } = this.state;

        return (
            <Dialog
                maxWidth="xs"
                open={this.props.openTransferCardDialog}
                onClose={this.handleReset}
                aria-labelledby="form-dialog-title"
                className="CheckCoupon"
            >
                <DialogTitle id="form-dialog-title">
                    Guthaben der Karte {this.props.employeeCard} übertragen
                </DialogTitle>
                <DialogContent style={{minHeight: '170px'}}>
                    <p>Auf welche Karte soll das Guthaben der Karte {this.props.employeeCard} übertragen werden?</p>
                    <Autocomplete
                        id="combo-box-demo"
                        options={this.props.retailer.employeeCardsNotActive}
                        getOptionLabel={(card) => card.id.toString()}
                        getOptionSelected={(option, value) => value.id === option.id}
                        style={{ width: "100%", marginTop: '20px' }}
                        onChange={(event, newValue) => {
                            this.setState({
                                selectedEmployeeCard: newValue
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="wähle eine Karte aus" variant="outlined" />}
                    />
                    <p style={{color:"red"}}>{this.props.lock ? " (Achtung: Die Karte " + this.props.employeeCard + " wird anschließend entfernt und kann nicht mehr genutzt werden!)" : ""}</p>
                </DialogContent>
                <DialogActions
                    style={{
                        position: "relative",
                    }}
                >
                    <Button onClick={this.props.handleCloseTransferCardDialog} color="default"  disabled={showSpinner}>
                        Abbrechen
                    </Button>
                    <Box display="inline" position="relative">
                        <Button onClick={() => this.handleTransferCard()} color="secondary" disabled={showSpinner}>
                        Transferieren
                        </Button>
                        {showSpinner && (
                            <CircularProgress
                                size=""
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: -12,
                                    marginLeft: -12,
                                    width: "24px",
                                }}
                            />
                        )}
                    </Box>
                </DialogActions>
            </Dialog>
        );
    }
}


function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(withRouter(TransferCardDialog));
