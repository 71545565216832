import { API } from "aws-amplify";

export function redeemCoupon(couponList, moneyAmount, retailersId) {
    const money = Number(moneyAmount.replace(",", "."));
    const message = {
        body: {
            couponList: couponList,
            moneyAmount: money,
            redeemRetailersId: retailersId,
            simulation: false,
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/redeemCoupon", message).then((response) => {
            return response;
        });
    };
}

export function testRedeemCoupon(barcode, moneyAmount, retailersId) {
    const money = Number(moneyAmount.replace(",", "."));
    const message = {
        body: {
            barcode: barcode,
            moneyAmount: money,
            redeemRetailersId: retailersId,
            simulation: true,
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/redeemCoupon", message).then((response) => {
            return response;
        });
    };
}

export const REMOVE_RETAILER = "REMOVE_RETAILER";
export function removeRetailer() {
    return {
        type: REMOVE_RETAILER,
    };
}

export function getRetailerInformationsWithRetailersId(retailersId) {
    const myInit = {
        body: { retailersId: retailersId },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getRetailerInformations", myInit).then((json) =>
            dispatch(receiveRetailerInformations(json))
        );
    };
}
export const RECEIVE_RETAILERINFORMATIONS = "RECEIVE_RETAILERINFORMATIONS";
export function receiveRetailerInformations(json) {
    return {
        type: RECEIVE_RETAILERINFORMATIONS,
        informations: json,
    };
}

export function getRetailerTransactions(retailersId, period = false, startDate = null, endDate = null) {
    let messageBody = period
        ? { retailersId, period, startDate: parseInt(startDate), endDate: parseInt(endDate) }
        : { retailersId, period };

    const myInit = {
        body: messageBody,
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getRetailerTransactionsByCognitoId", myInit).then((json) => {
            dispatch(receiveRetailerTransactions(json));
        });
    };
}

export function getAllRetailerTransactions(retailersId, period = false, startDate = null, endDate = null) {
    let messageBody = period
        ? { retailersId, period, startDate: parseInt(startDate), endDate: parseInt(endDate) }
        : { retailersId, period };

    const myInit = {
        body: messageBody,
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getRetailerTransactions", myInit).then((json) => {
            dispatch(receiveRetailerTransactions(json));
        });
    };
}

export const RECEIVE_RETAILERTRANSACTIONS = "RECEIVE_RETAILERTRANSACTIONS";
export function receiveRetailerTransactions(json) {
    return {
        type: RECEIVE_RETAILERTRANSACTIONS,
        transactions: json.Items,
    };
}

export function createRetailer(localData, stateData, townData, cognitoId) {
    const myInit = {
        body: {
            stateData: stateData,
            townData: townData,
            localData: localData,
            cognitoId: cognitoId,
        },
    };

    console.log(myInit);

    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/createRetailer", myInit).then((response) =>
            dispatch(receiveRetailerInformations(response))
        );
    };
}

export function getGoogleData(name, town, street = "", houseNumber = "") {
    var encodeUrl = encodeURIComponent(name + "%" + town + "%" + street + "%" + houseNumber);

    const requestOptions = {
        body: {
            type: "findPlace",
            encodedData: encodeUrl,
        },
    };

    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getGoogleData", requestOptions);
    };
}

export function getGoogleDataWithPlacesId(placesId) {
    console.log(placesId);
    const requestOptions = {
        body: {
            type: "places",
            places_id: placesId,
        },
    };

    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getGoogleData", requestOptions);
    };
}

export function getGeometryDataFromGoogle(town, street, houseNumber) {
    var encodeUrl = encodeURIComponent(street + "%" + houseNumber + "%" + town);

    const requestOptions = {
        body: {
            type: "geometry",
            encodedData: encodeUrl,
        },
    };

    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getGoogleData", requestOptions);
    };
}

export function getTownDataFromGoogle(town, state, country) {
    var encodeUrl = encodeURIComponent(town + "%" + state + "%" + country);

    const requestOptions = {
        body: {
            type: "geometry",
            encodedData: encodeUrl,
        },
    };

    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getGoogleData", requestOptions);
    };
}

export function getStateDataFromGoogle(state, country) {
    var encodeUrl = encodeURIComponent(state, +"%" + country);

    const requestOptions = {
        body: {
            type: "geometry",
            encodedData: encodeUrl,
        },
    };

    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getGoogleData", requestOptions);
    };
}

export function updateBillingInformation(iban, bic, accountOwner, cognitoId, billingAddress, retailersId) {
    console.log("action", billingAddress);
    const myInit = {
        body: {
            iban: iban,
            bic: bic,
            accountOwner: accountOwner,
            cognitoId: cognitoId,
            billingAddress: billingAddress,
            retailersId: retailersId,
        },
    };

    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/updateBillingInformation", myInit);
    };
}

export function updateTradingLicence(retailersId, licence) {
    const myInit = {
        body: {
            retailersId: retailersId,
            licence: licence,
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/updateRetailerSetLicence", myInit).then((json) =>
            dispatch(receiveRetailerInformations(json))
        );
    };
}

export function uploadRetailerLogo(retailersId, logo, fontColor, backgroundColor) {
    const myInit = {
        body: {
            retailersId: retailersId,
            logo: logo,
            fontColor: fontColor,
            backgroundColor: backgroundColor,
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/updateAppearance", myInit).then((json) =>
            dispatch(receiveRetailerInformations(json))
        );
    };
}

export function updateManagingDirector(name, address, phoneNumber, retailersId, reportMailingList) {
    const myInit = {
        body: {
            ManagingDirector: { name: name, phoneNumber: phoneNumber, address: address },
            retailersId: retailersId,
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/updateManagingDirector", myInit).then((json) =>
            dispatch(receiveRetailerInformations(json))
        );
    };
}

export function getCouponEmployeesByRetailersId(retailersId, isActive) {
    const myInit = {
        body: { retailersId: retailersId, isActive: isActive },
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/retailer/getAllCouponEmployeesByRetailersId", myInit).then((json) => {
            dispatch(receiveCouponEmployees(json, isActive));
        });
    };
}
export const RECEIVE_COUPONEMPLOYEESACTIVE = "RECEIVE_COUPONEMPLOYEESACTIVE";
export const RECEIVE_COUPONEMPLOYEESNOTACTIVE = "RECEIVE_COUPONEMPLOYEESNOTACTIVE";
export function receiveCouponEmployees(json, isActive) {
    console.log("json", json);
    if (isActive) {
        return {
            type: RECEIVE_COUPONEMPLOYEESACTIVE,
            couponEmployeesActive: json,
        };
    } else {
        return {
            type: RECEIVE_COUPONEMPLOYEESNOTACTIVE,
            couponEmployeesNotActive: json,
        };
    }
}

export function getAllEmployeeCardsByRetailersId(retailersId, isActive, isValid) {
    const myInit = {
        body: { retailersId: retailersId, isActive: isActive, isValid: isValid },
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/getAllEmployeeCardsByRetailersId ", myInit).then((json) => {
            dispatch(receiveEmployeeCards(json, isActive, isValid));
        });
    };
}
export const RECEIVE_EMPLOYEEECARDSACTIVE = "RECEIVE_EMPLOYEEECARDSACTIVE";
export const RECEIVE_EMPLOYEEECARDSNOTACTIVE = "RECEIVE_EMPLOYEEECARDSNOTACTIVE";
export const RECEIVE_EMPLOYEEECARDSVALID = "RECEIVE_EMPLOYEEECARDSVALID";
export const RECEIVE_EMPLOYEEECARDSNOTVALID = "RECEIVE_EMPLOYEEECARDSNOTVALID";
export function receiveEmployeeCards(json, isActive, isValid) {
    if (isValid === null) {
        if (isActive) {
            return {
                type: RECEIVE_EMPLOYEEECARDSACTIVE,
                employeeCardsActive: json,
            };
        } else {
            return {
                type: RECEIVE_EMPLOYEEECARDSNOTACTIVE,
                employeeCardsNotActive: json,
            };
        }
    } else {
        if (isValid) {
            return {
                type: RECEIVE_EMPLOYEEECARDSVALID,
                employeeCardsValid: json,
            };
        } else {
            return {
                type: RECEIVE_EMPLOYEEECARDSNOTVALID,
                employeeCardsNotValid: json,
            };
        }
    }
}

export function getCouponEmployeesTransactions(couponEmployeesId) {
    const myInit = {
        body: { couponEmployeesId: couponEmployeesId },
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/retailer/getCouponEmployeesTransactions", myInit).then((response) => {
            return response;
        });
    };
}

export function createCouponEmployee(
    email,
    cardNumber,
    couponEmployeeRetailersId,
    retailersId,
    firstTransaction,
    lastTransaction,
    comment,
    name
) {
    const myInit = {
        body: {
            email: email,
            cardNumber: cardNumber,
            couponEmployeeRetailersId: couponEmployeeRetailersId,
            retailersId: retailersId,
            firstTransaction: firstTransaction,
            lastTransaction: lastTransaction,
            comment: comment,
            name: name,
        },
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/retailer/createCouponEmployee", myInit).then((response) => {
            return response;
        });
    };
}

export function lockEmployeeCard(retailersId, cardNumber) {
    const myInit = {
        body: {
            retailersId: retailersId,
            cardNumber: cardNumber,
        },
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/lockEmployeeCard", myInit).then((response) => {
            return response;
        });
    };
}

export function activateCouponEmployee(retailersId, couponEmployeesId) {
    const myInit = {
        body: {
            retailersId: retailersId,
            couponEmployeesId: couponEmployeesId,
        },
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/activateCouponEmployee", myInit).then((response) => {
            return response;
        });
    };
}

export function transferEmployeeCard(retailersId, oldCardNumber, newCardNumber) {
    const myInit = {
        body: {
            retailersId: retailersId,
            oldCardNumber: oldCardNumber,
            newCardNumber: newCardNumber,
        },
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/transferEmployeeCard", myInit).then((response) => {
            return response;
        });
    };
}

export function updateCouponEmployee(
    couponEmployeesId,
    couponEmployeeRetailersId,
    email,
    retailersId,
    firstTransaction,
    lastTransaction,
    comment,
    name
) {
    const myInit = {
        body: {
            couponEmployeesId: couponEmployeesId,
            couponEmployeeRetailersId: couponEmployeeRetailersId,
            email: email,
            retailersId: retailersId,
            firstTransaction: firstTransaction,
            lastTransaction: lastTransaction,
            comment: comment,
            name: name,
        },
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/updateCouponEmployee", myInit).then((response) => {
            return response;
        });
    };
}

export function deleteCouponEmployee(couponEmployeesId) {
    const myInit = {
        body: { couponEmployeesId: couponEmployeesId, status: "delete" },
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/retailer/createCouponEmployee", myInit).then((response) => {
            return response;
        });
    };
}

export function getBillingInformations(retailersId) {
    const myInit = {
        body: { retailersId: retailersId },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getBillingInformations", myInit);
    };
}

export function getNotActivatedEmployeeCardsByRetailersId(retailersId) {
    const myInit = {
        body: { retailersId: retailersId },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getNotActivatedEmployeeCardsByRetailersId", myInit);
    };
}

export function getRecruitingStatistics(retailersId) {
    const myInit = {
        body: { retailersId: retailersId },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/getRecruitingStatistics", myInit);
    };
}

export function updateRetailerAppearance(retailersId, backgroundColor, fontColor, logo) {
    const myInit = {
        body: {
            retailersId: retailersId,
            backgroundColor: backgroundColor,
            fontColor: fontColor,
            logo: logo,
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/updateAppearance", myInit).then((json) =>
            dispatch(receiveRetailerInformations(json))
        );
    };
}

export function createCoupon() {
    const myInit = {
        body: {
            eancode: "4260675020000",
            currentValue: "0.02",
            startValue: "0.02",
            retailersId: "Saarland Gutschein",
            couponOrdersId: "6xA6eUu8G7L9ii8Q8QKtBo",
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/createCoupons", myInit);
    };
}

export function updateRetailerData(retailersId, type, localData, townData, stateData) {
    const myInit = {
        body: {
            retailersId: retailersId,
            type: type,
            localData: localData,
            townData: townData,
            stateData: stateData,
        },
    };
    console.log(myInit);
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/updateStoreData", myInit).then((json) =>
            dispatch(receiveRetailerInformations(json))
        );
    };
}

export function updateGoogleData(
    retailersId,
    phoneNumber,
    rating,
    userRatingTotal,
    website,
    weekday_text,
    tags,
    text,
    periods,
    contactPerson
) {
    if (phoneNumber === "") phoneNumber = " ";
    if (website === "") website = " ";
    if (text === "") text = " ";
    const myInit = {
        body: {
            retailersId: retailersId,
            phoneNumber: phoneNumber,
            rating: rating,
            userRatingTotal: userRatingTotal,
            website: website,
            weekday_text: weekday_text,
            tags: tags,
            text: text,
            periods: periods,
            contactPerson: contactPerson,
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/updateGoogleData", myInit).then((json) =>
            dispatch(receiveRetailerInformations(json))
        );
    };
}

export function getTags() {
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/getTags");
    };
}

export function setFavoriteImage(retailersId, favoriteImage) {
    const body = {
        body: {
            retailersId: retailersId,
            favoriteImage: favoriteImage,
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/setFavoriteImage", body).then((json) =>
            dispatch(receiveRetailerInformations(json))
        );
    };
}

export function checkRetailerAvailable(localData) {
    const myInit = {
        body: {
            localData: localData,
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/checkRetailerAvailable", myInit);
    };
}

export const RECEIVE_ALLEMPLOYEES = "RECEIVE_ALLEMPLOYEES";
export function receiveAllEmployees(json) {
    return {
        type: RECEIVE_ALLEMPLOYEES,
        employees: json,
    };
}

export function getAllEmployees(retailersId) {
    const myInit = {
        body: {
            retailersId: retailersId,
        },
    };
    return function (dispatch) {
        return API.post("employee", "/retailer/getAllEmployees", myInit).then((json) =>
            dispatch(receiveAllEmployees(json))
        );
    };
}

export function deleteEmployee(retailersId, employeesCognitoId) {
    const myInit = {
        body: {
            retailersId: retailersId,
            employeesCognitoId: employeesCognitoId,
        },
    };
    return function (dispatch) {
        return API.post("employee", "/retailer/deleteEmployee", myInit).then((json) => {
            return json;
        });
    };
}

export const RECEIVE_ACTUALCOUPONACCOUNTVALUE = "RECEIVE_ACTUALCOUPONACCOUNTVALUE";
export function receiveActualCouponAccountValue(json) {
    return {
        type: RECEIVE_ACTUALCOUPONACCOUNTVALUE,
        actualCouponAccountValue: json,
    };
}

export function getActualCouponAccountValue(retailersId) {
    const myInit = {
        body: {
            retailersId: retailersId,
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getActualCouponAccountValue", myInit).then((json) =>
            dispatch(receiveActualCouponAccountValue(json.currentValue))
        );
    };
}

export function updateRetailerSetReportMailAddress(retailersId, reportMailingList) {
    const myInit = {
        body: {
            retailersId: retailersId,
            reportMailingList: reportMailingList,
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/updateReportMailAddress", myInit).then((json) =>
            dispatch(receiveRetailerInformations(json))
        );
    };
}

export function getQRCodeForAddingEmployees(cognitoId, retailersId, Rights) {
    const message = {
        body: {
            cognitoId: cognitoId,
            retailersId: retailersId,
            Rights: Rights,
        },
    };
    return function (dispatch) {
        return API.post("employee", "/retailer/getQRCodeForAddingEmployees", message);
    };
}

export function getAllCities() {
    let message = {
        body: { getCities: true, name: "" },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getRetailers", message)
            .then((json) => dispatch(receiveCities(json)))
            .catch((err) => console.log(err));
    };
}

export const RECEIVECITIES = "RECEIVECITIES";
export function receiveCities(json) {
    return {
        type: RECEIVECITIES,
        citiesList: json,
    };
}

export function getAllRetailers() {
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getRetailers")
            .then((json) => {
                return json;
            })
            .catch((err) => console.log(err));
    };
}

export function makeOrder(articles, retailersId, sellCityCouponsAccepted, sellOtherRetailerCouponsAccepted) {
    let message = {
        body: {
            articles: articles,
            retailersId: retailersId,
            sellCityCouponsAccepted: sellCityCouponsAccepted,
            sellOtherRetailerCouponsAccepted: sellOtherRetailerCouponsAccepted,
        },
    };
    console.log(message);
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/makeOrder", message).then((json) => {
            return json;
        });
    };
}
