import React, { Component } from 'react'
import './NewEmployee.scss'
import { Button, Typography, Divider } from '@material-ui/core'

export default class NewEmployee extends Component {
    render() {
        return (
            <div className="NewEmployee">
                <div>
                    <Typography variant="h6" style={{ marginBottom: '16px' }} align="left">
                        Bitte wähle aus
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: '16px' }} align="left">
                        Wenn du der Chef von dem Unternehmen bist, kannst Du hier deinen Betrieb registrieren
                    </Typography>
                    <Button onClick={() => this.props.history.push('/account/new-store')} fullWidth variant="contained" color="primary">
                        Unternehmen anlegen
                        </Button>
                    <Divider style={{ margin: '24px 0px 16px 0px' }} />
                    <Typography variant="body1" style={{ marginBottom: '16px' }} align="left">
                        Wenn Du ein Benutzer bist, der auf ein bereits registriertes Unternehmen zugreifen soll, wählst Du diese Option
                    </Typography>
                    <Button onClick={() => this.props.history.push('/account/add-to-retailer')} fullWidth variant="contained" color="primary" >
                        Benutzer registrieren
                        </Button>
                </div>
            </div>
        )
    }
};
