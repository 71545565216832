import { Button, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import React, { Component } from "react";
import "./RedeemForm.scss";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";

class RedeemSuccess extends Component {
    constructor(props) {
        super(props);
        this.props = {
            showSpinner: false,
            error: false,
            errorTypography: "",
            couponsValue: 0,
            scannedCodeCount: 0,
            remainingValue: "",
            transactions: [],
            couponList: [],
            wasEnough: false,
            orderIndex: 0,
        };
    }

    render() {
        const remainingValueColor = this.props.wasEnough ? "green" : "#ff0000";
        return (
            <div>
                <span>
                    <CheckCircleOutlineIcon
                        style={{
                            color: "green",
                            padding: "3px",
                            borderRadius: "3px",
                            fontSize: "60px",
                        }}
                    />
                </span>
                <div style={{display: "flex", justifyContent: "end"}}>
                    <div className="countCircle" >
                        <Typography className="countText">{this.props.scannedCodeCount}</Typography>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Typography>Zu zahlen:</Typography>
                    <Typography>{Number(this.props.invoiceValue).toFixed(2).replace(".",",")} €</Typography>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
                    <Typography>Guthaben:</Typography>
                    <Typography>{this.props.couponsValue.toFixed(2).replace(".",",")} €</Typography>
                </div>
                <div style={{height: 1, backgroundColor: "black", marginTop: 10, marginBlock:15}} />
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Typography style={{ color: remainingValueColor }}>
                        Offener Betrag:
                    </Typography>
                    <Typography style={{ color: remainingValueColor }}>
                        {this.props.wasEnough ? "0,00" : parseFloat(this.props.remainingValue).toFixed(2).replace(".", ",")} €
                    </Typography>
                </div>
                {this.props.transactions.length > 0 && (
                    <div>
                        <Typography style={{fontWeight: "bold", display: "flex", marginTop: 15, marginBottom: 10}}>
                            {this.props.transactions.length} verwendete
                            {this.props.transactions.length === 1 ? "r" : ""} Gutschein
                            {this.props.transactions.length > 1 ? "e" : ""}
                        </Typography>
                        <div>
                            {this.props.transactions.map((transaction, index) => {
                                return (
                                    <div key={index} style={{display: "flex", justifyContent: "space-between"}}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <span style={{display: "flex", marginRight: 5}}>
                                                <CardGiftcardIcon
                                                    fontSize="small"
                                                    style={{
                                                        color: transaction.fontColor,
                                                        backgroundColor: transaction.backgroundColor,
                                                        padding: "3px",
                                                        borderRadius: "3px",
                                                    }}
                                                />
                                            </span>
                                            <Typography>
                                                {transaction.name}
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography style={{marginRight: 30}}>
                                                ...
                                                {transaction.barcode.substr(
                                                    transaction.barcode.length - 5
                                                )}
                                            </Typography>
                                            <Typography>
                                                {parseFloat(transaction.value)
                                                    .toFixed(2)
                                                    .replace(".", ",") + " €"}
                                            </Typography>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 15}}>
                    <Typography style={{textDecoration: "underline", color: "green", cursor: "pointer", fontSize: 12}} onClick={this.props.handleGoToTransactions}>Zu den Transaktionen</Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={this.props.handleCloseSuccess}
                    >
                        Neue Einlösung
                    </Button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(RedeemSuccess);
