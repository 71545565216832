export function weekOfTheMonth(mJsDate) {
    const startMonth = mJsDate.month();
    const oneWeekLater = mJsDate.clone().add(7, "days").month();
    const weekCount = Math.ceil(mJsDate.clone().date() / 7);
    const textCount = ["ersten", "zweiten", "dritten", "vierten", "fünften"];

    if (startMonth !== oneWeekLater) {
        return "letzten";
    }

    return textCount[weekCount - 1];
}
