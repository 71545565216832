import { IconButton, TextField } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import React from "react";
import { useFilters, useTable } from "react-table";

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
        <TextField
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Durchsuche ${count} Einträge...`}
        />
    );
}

function Table({ columns, data, handleOpenAddCouponEmployeeDialog}) {
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters
    );

    // Render the UI for your table
    return (
        <MaUTable {...getTableProps()} size="small">
            <TableHead style={{ backgroundColor: grey["200"] }}>
                {headerGroups.map((headerGroup, i) => (
                    <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <TableCell {...column.getHeaderProps()}>
                                {column.render("Header")}
                                <div className={column.canFilter ? "" : "noFilter"}>
                                    {column.canFilter ? column.render("Filter") : null}
                                </div>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <TableRow key={i} {...row.getRowProps()} >
                            {row.cells.map((cell) => {
                                if (cell.column.id === "barCode") {
                                    return (
                                        <TableCell {...cell.getCellProps()}>
                                            <IconButton
                                                title="Aktivieren"
                                                component="span"
                                                onClick={() => handleOpenAddCouponEmployeeDialog(null, row.original)}
                                            >
                                                {row.original.isActive ? <SentimentVeryDissatisfiedIcon /> : <SentimentSatisfiedAltIcon />}
                                            </IconButton>
                                        </TableCell>
                                    );
                                }
                                return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>;
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        </MaUTable>
    );
}

function NotActiveEmployeeCardsTable(props) {
    const columns = React.useMemo(
        () => [
            {
                Header: "Kartennummer",
                accessor: "id",
            },
            {
                Header: "",
                accessor: "barCode",
                disableFilters: true,
            },
        ],
        []
    );

    const data = props.data;
    // const data = React.useMemo(() => props.data, [])
    // eslint-disable-next-line
    const handleOpenAddCouponEmployeeDialog = props.handleOpenAddCouponEmployeeDialog;

    // console.log(couponEmployees)

    return <Table 
        columns={columns}
        data={data} 
        handleOpenAddCouponEmployeeDialog={handleOpenAddCouponEmployeeDialog} 
        />;
}

export default NotActiveEmployeeCardsTable;
