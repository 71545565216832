import { Box, Button, Typography } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { userHasRight } from "../../helpers/UserRights";
import { printTransaction } from "../../store/actions/couponActions";
import { store } from "../../store/store";
import { getAllRetailerTransactions, getRetailerTransactions } from "../../store/actions/retailerActions";
import "./Filter.scss";

const Filter = ({ onAbort = null, retailer }) => {
    const [transactionDropDown, setTransactionDropDown] = useState({
        value: "Alle Transaktionen",
        label: "Alle Transaktionen",
    });
    const [periodDropDown, setPeriodDropDownn] = useState({ value: "Heute", label: "Heute" });
    const [userDropDown, setUserDropDown] = useState({ value: "Eigener Benutzer", label: "Eigener Benutzer" });
    const [startDate, setStartDate] = useState(moment(new Date()).startOf("day"));
    const [endDate, setEndDate] = useState(moment(new Date()).endOf("day"));
    const transactionsDropDownOptions = [
        { value: "Alle Transaktionen", label: "Alle Transaktionen" },
        { value: "Nur Gutscheineinlösungen", label: "Nur Gutscheineinlösungen" },
        { value: "Nur Gutscheinaktivierungen", label: "Nur Gutscheinaktivierungen" },
    ];
    const periodDropDownOptions = [
        { value: "Heute", label: "Heute" },
        { value: "Aktueller Monat", label: "Aktueller Monat" },
        { value: "Letzter Monat", label: "Letzer Monat" },
        { value: "Aktuelles Jahr", label: "Aktuelles Jahr" },
        { value: "Benutzerdefiniert", label: "Benutzerdefiniert" },
    ];
    const userDropDownOptions = [
        { value: "Eigener Benutzer", label: "Eigener Benutzer" },
        { value: "Alle Benutzer", label: "Alle Benutzer" },
    ];

    const resetFilter = () => {
        setTransactionDropDown({ value: "Alle Transaktionen", label: "Alle Transaktionen" });
        setPeriodDropDownn({ value: "Heute", label: "Heute" });
        setStartDate(moment(new Date()).startOf("day"));
        setEndDate(moment(new Date()).endOf("day"));
        setUserDropDown({ value: "Eigener Benutzer", label: "Eigener Benutzer" });
    };

    const handleFilter = () => {
        if (userDropDown.value === "Alle Benutzer") {
            store
                .dispatch(
                    getAllRetailerTransactions(
                        retailer.informations.retailersId,
                        true,
                        startDate.valueOf(),
                        endDate.valueOf()
                    )
                )
                .then((response) => {
                    onAbort();
                })
                .catch((error) => {});
        } else {
            store
                .dispatch(
                    getRetailerTransactions(
                        retailer.informations.retailersId,
                        true,
                        startDate.valueOf(),
                        endDate.valueOf()
                    )
                )
                .then((response) => {
                    onAbort();
                })
                .catch((error) => {});
        }
    };

    const handleExport = async () => {
        store
            .dispatch(
                printTransaction({
                    startDate: startDate.valueOf(),
                    endDate: endDate.valueOf(),
                    transactionType: transactionDropDown.value,
                    userType: userDropDown.value,
                    retailersId: retailer.informations.retailersId,
                })
            )
            .then((response) => {
                createAndDownloadPdf(response);
            });
    };

    const createAndDownloadPdf = async (base64String) => {
        const linkSource = `data:application/pdf;base64,${base64String}`;
        const downloadLink = document.createElement("a");
        const fileName = `Export_Transaktionen_${moment(new Date()).format("YYMMDD")}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const handleAbort = () => {
        onAbort();
    };

    const handleChangeStartDate = (date) => {
        setStartDate(date);
    };

    const handleChangeEndDate = (date) => {
        setEndDate(date);
    };

    const handleTransactionDropDownChange = (event) => {
        setTransactionDropDown(event);
    };

    const handlePeriodDropDownChange = (event) => {
        console.log(event);
        setPeriodDropDownn(event);
        switch (event.value) {
            case "Heute":
                setStartDate(moment(new Date()).startOf("day"));
                setEndDate(moment(new Date()).endOf("day"));
                break;
            case "Aktueller Monat":
                setStartDate(moment(new Date()).startOf("month"));
                setEndDate(moment(new Date()).endOf("month"));
                break;
            case "Letzter Monat":
                setStartDate(moment(new Date()).subtract(1, "month").startOf("month"));
                setEndDate(moment(new Date()).subtract(1, "month").endOf("month"));
                break;
            case "Aktuelles Jahr":
                setStartDate(moment(new Date()).startOf("year"));
                setEndDate(moment(new Date()).endOf("year"));
                break;
            case "Benutzerdefiniert":
                break;
            default:
                break;
        }
    };

    const handleUserDropDownChange = (event) => {
        setUserDropDown(event);
    };

    return (
        <Box
            style={{
                borderRadius: 1,
                display: "flex",
                backgroundColor: "white",
                height: "80%",
                width: "75%",
                position: "absolute",
                top: "10%",
                left: "12.5%",
            }}
            flexDirection="column"
            p={2}
        >
            <div className="modal-header">
                <h2>Transaktionen exportieren</h2>
                <button className="close-button" onClick={handleAbort}>
                    &times;
                </button>
            </div>
            <p>Exportiere Deinen Transaktionsverlauf im Zeitraum Deiner Wahl als PDF-Dokument.</p>

            <div className="filter-section">
                <label>TRANSAKTIONEN</label>
                <div className="transaction-section">
                    <Select
                        options={transactionsDropDownOptions.filter((element) => element !== transactionDropDown)}
                        onChange={handleTransactionDropDownChange}
                        defaultValue={transactionsDropDownOptions[0]}
                        value={transactionDropDown}
                    />
                </div>

                <label>ZEITRAUM</label>
                <div className="period-section">
                    <Select
                        options={periodDropDownOptions.filter((element, index) => element !== periodDropDown)}
                        onChange={handlePeriodDropDownChange}
                        value={periodDropDown}
                        defaultValue={periodDropDownOptions[0]}
                    />
                    {periodDropDown.value === "Benutzerdefiniert" && (
                        <Box display="flex" alignItems="center" paddingRight={2} paddingTop={2}>
                            <DateTimePicker
                                value={startDate}
                                onChange={handleChangeStartDate}
                                maxDate={endDate ? endDate : new moment()}
                                initialFocusedDate={new moment()}
                                inputVariant="outlined"
                                size="small"
                                format="DD.MM.YYYY HH:mm"
                                clearable={true}
                                cancelLabel="abbrechen"
                                clearLabel="leeren"
                                ampm={false}
                                placeholder="von"
                            />
                            <DateTimePicker
                                value={endDate}
                                onChange={handleChangeEndDate}
                                minDate={startDate ? startDate : new moment("1900-01-01")}
                                initialFocusedDate={new moment()}
                                inputVariant="outlined"
                                size="small"
                                format="DD.MM.YYYY HH:mm"
                                clearable={true}
                                cancelLabel="abbrechen"
                                clearLabel="leeren"
                                ampm={false}
                                placeholder="bis"
                            />
                        </Box>
                    )}
                </div>

                {userHasRight(1) && (
                    <>
                        <label>BENUTZER</label>
                        <div className="user-selection">
                            <Select
                                options={userDropDownOptions.filter((element, index) => element !== userDropDown)}
                                onChange={handleUserDropDownChange}
                                value={userDropDown}
                                defaultValue={userDropDownOptions[0]}
                            />
                        </div>
                    </>
                )}
            </div>

            <div className="filter-reset" onClick={resetFilter}>
                <button>Alle Filter zurücksetzen</button>
            </div>

            <div className="modal-footer">
                <Button className="export-button" color="primary" variant="contained" onClick={handleFilter}>
                    Filtern
                </Button>
                <Button className="export-button" color="primary" variant="contained" onClick={handleExport}>
                    Transaktionen exportieren
                </Button>
                <Button color="primary" variant="outline" onClick={handleAbort}>
                    Abbrechen
                </Button>
            </div>
        </Box>
    );

    // return (
    //     <Box
    //         style={{
    //             borderRadius: 1,
    //             display: "flex",
    //             backgroundColor: "white",
    //             height: "80%",
    //             width: "80%",
    //             position: "absolute",
    //             top: "10%",
    //             left: "10%",
    //         }}
    //         flexDirection="column"
    //         p={2}
    //     >
    //         <div style={{}}>
    //             <Typography variant="h6">Transkationsverlauf</Typography>
    //             <Typography variant="h6">
    //                 Filtere Deine Ansicht oder exportiere Deinen Transaktionsverlauf als PDF-Dokument.
    //             </Typography>
    //         </div>
    //         <div>
    //             <div>
    //                 <Dropdown
    //                     options={transactionsDropDown.filter((element) => element !== transactionDropDownLabel)}
    //                     onChange={handleTransactionDropDownChange}
    //                     value={transactionDropDownLabel}
    //                 />
    //             </div>
    //             <div>
    //                 <Typography variant="h6">Zeitraum</Typography>
    //                 <Dropdown
    //                     options={periodDropDown.filter((element, index) => element !== periodDropDownLabel)}
    //                     onChange={handlePeriodDropDownChange}
    //                     value={periodDropDownLabel}
    //                 />
    //                 {periodDropDownLabel === "Benutzerdefiniert" && (
    //                     <Box display="flex" alignItems="center" paddingRight={2}>
    //                         <DateTimePicker
    //                             value={startDate}
    //                             onChange={handleChangeStartDate}
    //                             maxDate={endDate ? endDate : new moment()}
    //                             initialFocusedDate={new moment()}
    //                             inputVariant="outlined"
    //                             size="small"
    //                             label="von"
    //                             format="DD.MM.YYYY HH:mm"
    //                             clearable={true}
    //                             cancelLabel="abbrechen"
    //                             clearLabel="leeren"
    //                             ampm={false}
    //                         />
    //                         <DateTimePicker
    //                             value={endDate}
    //                             onChange={handleChangeEndDate}
    //                             minDate={startDate ? startDate : new moment("1900-01-01")}
    //                             initialFocusedDate={new moment()}
    //                             inputVariant="outlined"
    //                             size="small"
    //                             label="bis"
    //                             format="DD.MM.YYYY HH:mm"
    //                             clearable={true}
    //                             cancelLabel="abbrechen"
    //                             clearLabel="leeren"
    //                             ampm={false}
    //                         />
    //                     </Box>
    //                 )}
    //             </div>
    //             <div>
    //                 {userHasRight(1) && (
    //                     <>
    //                         <Typography variant="h6">Benutzer</Typography>
    //                         <Dropdown
    //                             options={userDropDown.filter((element, index) => element !== userDropDownLabel)}
    //                             onChange={handleUserDropDownChange}
    //                             value={userDropDownLabel}
    //                         />
    //                     </>
    //                 )}
    //             </div>

    //             <Button onClick={resetFilter}>
    //                 <Typography variant="h6">Filter zurücksetzen</Typography>
    //             </Button>
    //         </div>
    //         <div>
    //             <Button color="primary" variant="contained" onClick={handleFilter}>
    //                 Filtern
    //             </Button>
    //             <Button color="primary" variant="contained" onClick={handleExport}>
    //                 Transaktionen exportieren
    //             </Button>
    //             <Button color="primary" variant="contained" onClick={handleAbort}>
    //                 Abbrechen
    //             </Button>
    //         </div>
    //     </Box>
    // );
};

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(Filter);
