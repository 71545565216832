import { Backdrop, Box, CircularProgress } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getOrders } from "../../../../store/actions/chargingActions";
import { store } from "../../../../store/store";
import "../CouponEmployees.scss";
import BillingsTable from "./BillingsTable";

class Billings extends Component {
    componentDidMount() {
        store.dispatch(getOrders(this.props.retailer.informations.retailersId));
    }

    render() {
        return (
            <div id="couponEmployees">
                <Backdrop open={this.props.order.isLoading} style={{ zIndex: 9999 }}>
                    <CircularProgress />
                </Backdrop>
                <Box className="retailerSettings"></Box>
                <BillingsTable data={this.props.order.orders} chargingsId={this.props.chargingsId} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer, charging, order } = state;
    return {
        user,
        retailer,
        charging,
        order,
    };
}

export default connect(mapStateToProps)(withRouter(Billings));
