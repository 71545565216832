import { Button, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import SkipStepWarning from "./SkipStepWarning";

class NewStoreContactPerson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errorText: "",
            iban: "",
            bic: "",
            accountOwner: "",
            showSpinner: false,
            payToParent: this.props.retailer.informations ? true : false,
            localData: this.props.location.state.localData ? this.props.location.state.localData : undefined,
            townData: this.props.location.state.townData ? this.props.location.state.townData : undefined,
            stateData: this.props.location.state.stateData ? this.props.location.state.stateData : undefined,
            skipVisible: false,
            setBillingAddress: false,
        };

        this.updateContactPerson = this.updateContactPerson.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
        this.handleToggleSkip = this.handleToggleSkip.bind(this);
    }

    handleToggleSkip() {
        this.setState({
            skipVisible: !this.state.skipVisible,
        });
    }

    updateContactPerson(values) {
        console.log(this.state);
        this.setState(
            {
                showSpinner: false,
                localData: {
                    ...this.state.localData,
                    contactPerson: {
                        contactName: values.contactName,
                        contactPhone: values.contactPhone,
                        contactMail: values.contactMail,
                    },
                },
            },
            () => {
                this.props.history.push("/account/new-store-upload-trading-licence", {
                    localData: this.state.localData,
                    townData: this.state.townData,
                    stateData: this.state.stateData,
                });
            }
        );
    }

    handleSkip() {
        this.setState(
            {
                showSpinner: false,
                skipVisible: false,
                localData: {
                    ...this.state.localData,
                    contactPerson: {
                        contactName: " ",
                        contactPhone: " ",
                        contactMail: " ",
                    },
                },
            },
            () => {
                this.props.history.push("/account/new-store-upload-trading-licence", {
                    localData: this.state.localData,
                    townData: this.state.townData,
                    stateData: this.state.stateData,
                });
            }
        );
    }

    render() {
        return (
            <div className="NewStoreContactPerson">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <Typography variant="h6">Ansprechpartner</Typography>
                <Formik
                    initialValues={{
                        contactName: "",
                        contactPhone: "",
                        contactMail: "",
                    }}
                    onSubmit={(values) => {
                        this.setState({
                            showSpinner: true,
                            error: false,
                            errorText: "",
                        });
                        this.updateContactPerson(values);
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid item xs={12}>
                                <TextField
                                    id="contactName"
                                    name="contactName"
                                    label="Name"
                                    value={props.values.contactName}
                                    onChange={props.handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="contactPhone"
                                    name="contactPhone"
                                    label="Telefonnummer"
                                    defaultValue={props.values.contactPhone}
                                    onChange={props.handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="contactMail"
                                    name="contactMail"
                                    defaultValue={props.values.contactMail}
                                    onChange={props.handleChange}
                                    label="E-Mail"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: "right", marginTop: "20px", position: "relative" }}>
                                <Button
                                    fullWidth
                                    disabled={this.state.showSpinner}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Speichern
                                </Button>
                                {this.state.showSpinner && <CircularProgress size={24} className="Spinner" />}
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "right", marginTop: "20px", position: "relative" }}>
                                <Button
                                    onClick={this.handleToggleSkip}
                                    fullWidth
                                    disabled={this.state.showSpinner}
                                    type="button"
                                    variant="contained"
                                    color="default"
                                >
                                    Überspringen
                                </Button>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <SkipStepWarning
                    visible={this.state.skipVisible}
                    onHiding={this.handleToggleSkip}
                    onSkip={this.handleSkip}
                    title="Achtung"
                >
                    <p>
                        <b>Ohne einen Ansprechpartner wissen wir nicht wen wir kontaktieren sollen.</b>
                    </p>
                </SkipStepWarning>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(NewStoreContactPerson);
