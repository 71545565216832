import { Button, CircularProgress, Grid, Link, TextField, Typography, Modal } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import {
    getActualCouponAccountValue,
    getRetailerInformationsWithRetailersId,
    getRetailerTransactions,
} from "../../store/actions/retailerActions";
import {
    createEmployeeWithCognitoId,
    getEmployeeWithCognitoId,
    userLoginWithEmailAndPassword,
} from "../../store/actions/userAction";
import { store } from "../../store/store";
import "./Login.scss";
import AcceptAGBs from "./AcceptAGBs/AcceptAGBs";

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            error: false,
            errorText: "",
            openAgpPopup: false,
            retailersId: undefined,
            openAgbPopup: false,
        };

        this.login = this.login.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
        this.toggleAgbPopup = this.toggleAgbPopup.bind(this);
        this.acceptAGBLater = this.acceptAGBLater.bind(this);
    }

    handleResponse(response, user) {
        console.log(response, user);
        if (response.employee === null) {
            store
                .dispatch(
                    createEmployeeWithCognitoId(
                        user.cognitoUser.attributes.sub,
                        [],
                        user.cognitoUser.attributes.family_name,
                        user.cognitoUser.attributes.name
                    )
                )
                .then((response) =>
                    this.setState({ showSpinner: false }, () => {
                        this.props.history.push("/account/new-employee");
                    })
                )

                .catch((err) => {
                    alert("Fehler beim Anlegen des Benutzers");
                    this.setState({ showSpinner: false });
                });
        } else if (!response.employee.Retailers || response.employee.Retailers.length === 0) {
            this.setState({ showSpinner: false }, () => {
                this.props.history.push("/account/new-employee");
            });
        } else if (response.employee.Retailers.length === 1) {        
            console.log(response.employee.Retailers[0]);
            store.dispatch(getRetailerTransactions(response.employee.Retailers[0].retailersId));
            store.dispatch(getActualCouponAccountValue(response.employee.Retailers[0].retailersId));
            store
                .dispatch(getRetailerInformationsWithRetailersId(response.employee.Retailers[0].retailersId))
                .then((response) => {
                    if (response.informations.acceptNewAgbs !== true) {                    
                        this.setState({ showSpinner: false, retailersId: response.informations.retailersId, openAgbPopup: true });
                    } else {
                        this.setState({ showSpinner: false }, () => {
                            window.location = "/administration";
                        });
                    }
                })
                .catch(() => {
                    if (this.chooseRetailerMounted) {
                        this.setState(() => ({ showSpinner: false }));
                    }
                });
        } else {
            this.setState({ showSpinner: false }, () => {
                window.location = "/account/choose-retailer";
                //this.props.history.push("/account/choose-retailer");
            });
        }
    }

    handleLoginSubmit(username, password) {
        this.login(username, password);
    }

    login(username, password) {
        store
            .dispatch(userLoginWithEmailAndPassword(username, password))
            .then((user) => {
                if (user.cognitoUser.challengeName) {
                    if (user.cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                        console.log(user);
                        this.props.history.push("/account/force-new-password", {
                            username: username,
                            password: password,
                        });
                        return false;
                    }
                }
                store
                    .dispatch(getEmployeeWithCognitoId(user.cognitoUser.attributes.sub))
                    .then((response) => {
                        this.handleResponse(response, user);
                    })
                    .catch((err) => {
                        this.setState({
                            showSpinner: false,
                            error: true,
                            errorText: "Fehler beim abrufen der Daten",
                        });
                    });
            })
            .catch((err) => {
                switch (err.code) {
                    case "UserNotConfirmedException":
                        this.setState({ showSpinner: false }, () => {
                            this.props.history.push("/account/confirm", { username: this.state.username });
                        });
                        break;
                    case "PasswordResetRequiredException":
                        this.setState({ showSpinner: false }, () => {
                            this.props.history.push("/account/change-password", { username: this.state.username });
                        });
                        break;
                    case "NotAuthorizedException":
                        this.setState({
                            showSpinner: false,
                            error: true,
                            errorText: "Passwort falsch",
                        });
                        break;
                    case "UserNotFoundException":
                        this.setState({
                            showSpinner: false,
                            error: true,
                            errorText: "Benutzer nicht gefunden",
                        });
                        break;
                    default:
                        this.setState({
                            showSpinner: false,
                            error: true,
                            errorText: "Fehler",
                        });
                        break;
                }
            });
    }

    toggleAgbPopup() {      
        this.setState({
            openAgbPopup: !this.state.openAgbPopup,
        });
    }

    acceptAGBLater() {
        this.setState({
            openAgbPopup: false
        }, () => {
            window.location = "/administration";
        });
    }

    render() {
        const { openAgbPopup } = this.state;
        console.log(openAgbPopup);
        return (
            <div className="Login">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                 <Modal open={openAgbPopup} onClose={() => this.setState({ openAgbPopup: false })}>
                        <AcceptAGBs
                            retailersId={this.state.retailersId}
                            open={openAgbPopup}
                            acceptAGBLater={this.acceptAGBLater}
                            toggle={this.toggleAgbPopup}
                        />
                    </Modal>
                <Formik
                    initialValues={{
                        username: "",
                        password: "",
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string()
                            .email("Keine korrekte E-Mail-Adresse")
                            .required("Dieses Feld wird benötigt"),
                        password: Yup.string().required("Dieses Feld wird benötigt"),
                    })}
                    onSubmit={(values) => {
                        this.setState(
                            {
                                showSpinner: true,
                                error: false,
                                errorText: "",
                            },
                            () => {
                                this.handleLoginSubmit(values.username.trim().toLowerCase(), values.password);
                            }
                        );
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="username"
                                        name="username"
                                        label="E-Mail-Adresse"
                                        type="email"
                                        defaultValue={props.values.username}
                                        onChange={props.handleChange}
                                        error={props.errors.username ? true : false}
                                        helperText={props.errors.username}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="password"
                                        name="password"
                                        label="Passwort"
                                        type="password"
                                        defaultValue={props.values.password}
                                        onChange={props.handleChange}
                                        error={props.errors.password ? true : false}
                                        helperText={props.errors.password}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", position: "relative" }}
                                >
                                    <Button
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Anmelden
                                    </Button>
                                    {this.state.showSpinner && <CircularProgress size={24} className="Spinner" />}
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <Typography variant="body2">
                    Hast Du noch keinen Account?{" "}
                    <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="https://keeplocal.de/mitmachen/partner/registrierung/" target="_blank" rel="noopener noreferrer">
                        Registrieren
                    </a>
                </Typography>
                <Typography variant="body2">
                    <Link component={RouterLink} to="/account/request-password-change">
                        Passwort vergessen?
                    </Link>
                </Typography>
            </div>
        );
    }
}
