import React, { Component } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../helpers/PrivateRoute";
import AdministrationLayout from "../../layout/AdminstrationLayout/AdministrationLayout";
import RecruitNewPartner from "./RecruitNewPartner/RecruitNewPartner";
import RecruitingStatistics from "./RecruitingStatistics/RecruitingStatistics";

export default class Recruiting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headerTitle: "",
        };

        this.setHeaderTitle = this.setHeaderTitle.bind(this);
    }

    setHeaderTitle(title) {
        this.setState({
            headerTitle: title,
        });
    }

    render() {
        return (
            <AdministrationLayout headerTitle={this.state.headerTitle}>
                <Switch>
                    <PrivateRoute
                        path={"/partner-recruiting/recruit-new-partner"}
                        render={() => <RecruitNewPartner setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        path={"/partner-recruiting/recruiting-statistics"}
                        render={() => <RecruitingStatistics setHeaderTitle={this.setHeaderTitle} />}
                    />
                </Switch>
            </AdministrationLayout>
        );
    }
}
