import React, { Component } from 'react'
import AddEmployeeToRetailer from '../../helpers/AddEmployeeToRetailer'

export default class AddToRetailer extends Component {
    render() {
        return (
            <AddEmployeeToRetailer />
        )
    }
}
