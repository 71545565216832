import { IconButton, TextField } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InfoIcon from "@material-ui/icons/Info";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { SwapHoriz } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import React from "react";

import { useFilters, useTable } from "react-table";

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
        <TextField
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Durchsuche ${count} Einträge...`}
        />
    );
}

function getIntervalLabel(key) {
    var intervalList = [
        { label: "jährlich", key: 1 },
        { label: "vierteljährlich", key: 4 },
        { label: "halbjährlich", key: 2 },
        { label: "monatlich", key: 12 },
    ];
    var label = intervalList.map((interval) => {
        if (interval.key === key) {
            return interval.label;
        } else {
            return "";
        }
    });

    return label;
}

function Table({
    columns,
    data,
    editEmployee,
    handleOpenUpdateCardsDialog,
    handleOpenLockCardsDialog,
    handleOpenActivateCardsDialog,
    handleOpenTransferCardsDialog,
}) {
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters
    );

    // Render the UI for your table
    return (
        <MaUTable {...getTableProps()} size="small">
            <TableHead style={{ backgroundColor: grey["200"] }}>
                {headerGroups.map((headerGroup, i) => (
                    <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <TableCell {...column.getHeaderProps()}>
                                {column.render("Header")}
                                <div className={column.canFilter ? "" : "noFilter"}>
                                    {column.canFilter ? column.render("Filter") : null}
                                </div>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <TableRow
                            key={i}
                            {...row.getRowProps()}
                            style={{ backgroundColor: row.original.cardNumber && !row.original.isValid ? "red" : "" }}
                        >
                            {row.cells.map((cell) => {
                                if (cell.column.id === "cardNumber") {
                                    if (row.values.cardNumber) {
                                        return (
                                            <TableCell {...cell.getCellProps()}>
                                                <IconButton
                                                    title="Auf andere Karte übertragen"
                                                    component="span"
                                                    onClick={() =>
                                                        handleOpenTransferCardsDialog(
                                                            row.original.cardNumber,
                                                            row.original.isValid
                                                        )
                                                    }
                                                >
                                                    <SwapHoriz />
                                                </IconButton>
                                                <IconButton
                                                    title={row.original.isValid ? "Sperren" : "Entsperren"}
                                                    component="span"
                                                    onClick={() =>
                                                        handleOpenLockCardsDialog(
                                                            row.original.cardNumber,
                                                            row.original.isValid
                                                        )
                                                    }
                                                >
                                                    {row.original.isValid ? <LockIcon /> : <LockOpenIcon />}
                                                </IconButton>
                                                <IconButton
                                                    title="Details"
                                                    component="span"
                                                    onClick={() => editEmployee(cell.row)}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                                <IconButton
                                                    title="Bearbeiten"
                                                    component="span"
                                                    onClick={() => handleOpenUpdateCardsDialog(row)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    title={row.original.isActive ? "Deaktivieren" : "Aktivieren"}
                                                    component="span"
                                                    onClick={() => handleOpenActivateCardsDialog(row)}
                                                >
                                                    {row.original.isActive ? (
                                                        <SentimentVeryDissatisfiedIcon />
                                                    ) : (
                                                        <SentimentSatisfiedAltIcon />
                                                    )}
                                                </IconButton>
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell {...cell.getCellProps()}>
                                                <IconButton
                                                    title="Details"
                                                    component="span"
                                                    onClick={() => editEmployee(cell.row)}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                                <IconButton
                                                    title="Bearbeiten"
                                                    component="span"
                                                    onClick={() => handleOpenUpdateCardsDialog(row)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    title={row.original.isActive ? "Deaktivieren" : "Aktivieren"}
                                                    component="span"
                                                    onClick={() => handleOpenActivateCardsDialog(row)}
                                                >
                                                    {row.original.isActive ? (
                                                        <SentimentVeryDissatisfiedIcon />
                                                    ) : (
                                                        <SentimentSatisfiedAltIcon />
                                                    )}
                                                </IconButton>
                                            </TableCell>
                                        );
                                    }
                                }
                                if (cell.column.id === "interval")
                                    return (
                                        <TableCell {...cell.getCellProps()}>
                                            {getIntervalLabel(cell.row.values.interval)}
                                        </TableCell>
                                    );
                                if (cell.column.id === "firstTransaction")
                                    return (
                                        <TableCell {...cell.getCellProps()}>
                                            {cell.row.values.firstTransaction
                                                ? moment(cell.row.values.firstTransaction).format("DD/MM/YYYY")
                                                : ""}
                                        </TableCell>
                                    );
                                if (cell.column.id === "lastTransaction")
                                    return (
                                        <TableCell {...cell.getCellProps()}>
                                            {cell.row.values.lastTransaction
                                                ? moment(cell.row.values.lastTransaction).format("DD/MM/YYYY")
                                                : ""}
                                        </TableCell>
                                    );
                                return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>;
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        </MaUTable>
    );
}

function ActiveCouponEmployeesTable(props) {
    const columns = React.useMemo(
        () => [
            {
                Header: "E-Mail oder Kartennummer",
                accessor: "displayValue",
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Kommentar",
                accessor: "comment",
            },
            {
                Header: "Eintrittsdatum",
                accessor: "firstTransaction",
            },
            {
                Header: "Austrittsdatum",
                accessor: "lastTransaction",
            },
            {
                Header: "",
                accessor: "cardNumber",
                disableFilters: true,
            },
        ],
        []
    );

    const data = props.data;
    // const data = React.useMemo(() => props.data, [])
    // eslint-disable-next-line
    const editEmployee = React.useMemo(() => props.editEmployee, []);

    const handleOpenUpdateCardsDialog = props.handleOpenUpdateCardsDialog;
    const handleOpenLockCardsDialog = props.handleOpenLockCardsDialog;
    const handleOpenActivateCardsDialog = props.handleOpenActivateCardsDialog;
    const handleOpenTransferCardsDialog = props.handleOpenTransferCardsDialog;

    // console.log(couponEmployees)

    return (
        <Table
            columns={columns}
            data={data}
            editEmployee={editEmployee}
            handleOpenUpdateCardsDialog={handleOpenUpdateCardsDialog}
            handleOpenLockCardsDialog={handleOpenLockCardsDialog}
            handleOpenActivateCardsDialog={handleOpenActivateCardsDialog}
            handleOpenTransferCardsDialog={handleOpenTransferCardsDialog}
        />
    );
}

export default ActiveCouponEmployeesTable;
