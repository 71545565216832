import { Modal, Grid, Link, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { store } from "../../../store/store";
import "./AcceptAGBs.scss";
import "../../../fonts/keeplocal/style.css";
import { acceptAGB } from "../../../store/actions/userAction";

export default class AcceptAGBs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAcceptLater = this.handleAcceptLater.bind(this);
    }

    handleSubmit() {
        console.log(this.props.retailersId);
        store.dispatch(acceptAGB(this.props.retailersId)).then(() => {
            this.props.toggle(this.props.retailersId);
        });
    }

    handleAcceptLater() {
        this.props.acceptAGBLater();
    }

    render() {
        return (
            <div>
                <Modal className="modalContainer" open={this.props.open}>
                    <div className="modalContent">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className="infoText">
                                    <Typography className="headerText" variant="h3">
                                        Hallo neue AGB!
                                    </Typography>
                                    <Typography className="bodyText" variant="body1">
                                        Wir haben unsere AGB aktualisiert und neu
                                        <br />
                                        strukturiert. Bitte nimm Dir einen Schokoriegel-lang Zeit, um sie Dir einmal
                                        <br />
                                        durchzulesen und zu bestätigen.
                                    </Typography>
                                    <i
                                        style={{ alignSelf: "center", color: "white", fontSize: 80 }}
                                        className="kl-icon-handshake"
                                    />
                                    <Typography className="bodyText" variant="body1">
                                        Vielen Dank und toll,
                                        <br />
                                        dass du bei KeepLocal
                                        <br />
                                        dabei bist ❤️
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} className="buttonContainer">
                                    <Grid item xs={12}>
                                        <div>
                                            <Typography className="acceptText" variant="body1">
                                                Ja, ich habe die{" "}
                                                <Link
                                                    underline="always"
                                                    color="black"
                                                    href="https://keeplocal.de/agb-partner/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    AGB
                                                </Link>{" "}
                                                gelesen,
                                                <br />
                                                verstanden und stimme ihnen zu,
                                                <br />
                                                damit ich jetzt wieder zurück zum
                                                <br />
                                                Business kann 😁
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="center">
                                            <button className="acceptButton" onClick={this.handleSubmit} type="submit">
                                                Zustimmen
                                            </button>
                                            <button
                                                className="acceptButton alternative"
                                                onClick={this.handleAcceptLater}
                                                type="submit"
                                            >
                                                Später zustimmen
                                            </button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
            </div>
        );
    }
}
