import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getCouponEmployeeOrderByRetailersId } from "../../../../store/actions/couponActions";
import { store } from "../../../../store/store";

class OrderOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            sortModel: [
                {
                    field: "creationDate",
                    sort: "asc",
                },
            ],
        };

        this.setSortModel = this.setSortModel.bind(this);
    }

    componentDidMount() {
        store
            .dispatch(getCouponEmployeeOrderByRetailersId(this.props.retailer.informations.retailersId))
            .then((response) => {
                response.sort((a, b) => b.creationDate - a.creationDate);
                response.forEach((element) => {
                    element.id = response.indexOf(element);
                });

                this.setState({ transactions: response });
            });
    }

    setSortModel(model) {
        this.setState({ sortModel: model });
    }

    render() {
        return (
            <div style={{ width: "1000px" }}>
                <Table size="small" style={{ width: "1000px" }}>
                    <TableHead style={{ backgroundColor: grey["200"] }}>
                        <TableRow>
                            <TableCell align="right"> Anzahl an Karten </TableCell>
                            <TableCell align="right"> Design </TableCell>
                            <TableCell align="right"> Bestelldatum </TableCell>
                            <TableCell align="right"> Bestellstatus </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.transactions &&
                            this.state.transactions.map((transaction) => {
                                return (
                                    <TableRow key={transaction.id}>
                                        <TableCell align="right"> {transaction.amount} </TableCell>
                                        <TableCell align="right">
                                            {" "}
                                            {transaction.blanko ? "Standard" : "Personalisiert"}{" "}
                                        </TableCell>
                                        <TableCell align="right">
                                            {new Date(transaction.creationDate).toLocaleDateString("de-DE")}{" "}
                                        </TableCell>
                                        <TableCell>
                                            {" "}
                                            {transaction.status === 1
                                                ? "Offen"
                                                : transaction.status === 2
                                                ? "In Bearbeitung"
                                                : "Abgeschlossen"}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withSnackbar(OrderOverview));
