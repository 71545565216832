import { Box, Card, CardContent, CardHeader, Grid, TextField } from "@material-ui/core";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import { Auth } from "aws-amplify";
import { Form, Formik } from "formik";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import { updateGoogleData } from "../../../../store/actions/retailerActions";
import { store } from "../../../../store/store";

class BillingInformation extends Component {
    constructor(props) {
        super(props);
        let cn = props.retailer.informations.contactPerson ? props.retailer.informations.contactPerson.contactName : "";
        let cp = props.retailer.informations.contactPerson
            ? props.retailer.informations.contactPerson.contactPhone
            : "";
        let cm = props.retailer.informations.contactPerson ? props.retailer.informations.contactPerson.contactMail : "";
        this.state = {
            showSpinner: true,
            setBillingAddress: false,
            contactName: cn,
            contactPhone: cp,
            contactMail: cm,
        };

        this.update = this.update.bind(this);
        this.formRef = React.createRef();
    }

    componentDidMount() {
        console.log(this.props);
        if (this.props.retailer.informations.contactPerson) {
            let newValues = {
                contactName: this.props.retailer.informations.contactPerson.contactName,
                contactPhone: this.props.retailer.informations.contactPerson.contactPhone,
                contactMail: this.props.retailer.informations.contactPerson.contactMail,
            };
            this.formRef.current.resetForm({ values: newValues });
        }
    }

    update(newValues) {
        let cp = {
            contactName: newValues.contactName,
            contactPhone: newValues.contactPhone,
            contactMail: newValues.contactMail,
        };
        console.log("ansprechpartner", cp);
        Auth.currentAuthenticatedUser()
            .then((user) => {
                store
                    .dispatch(
                        updateGoogleData(
                            this.props.retailer.informations.retailersId,
                            this.props.retailer.informations.phoneNumber,
                            this.props.retailer.informations.rating,
                            this.props.retailer.informations.userRatingTotal,
                            this.props.retailer.informations.website,
                            this.props.retailer.informations.weekday_text,
                            this.props.retailer.informations.tags,
                            this.props.retailer.informations.text,
                            this.props.retailer.informations.periods,
                            cp
                        )
                    )
                    .then(() => {
                        this.props.enqueueSnackbar("Ansprechpartner erfolgreich aktualisiert", {
                            variant: "success",
                        });
                        console.log(this.props.retailer.informations);
                    })
                    .catch(() => {
                        this.props.enqueueSnackbar("Fehler beim aktualsieren des Ansprechpartners", {
                            variant: "error",
                        });
                    });
            })
            .catch(() => {
                this.props.enqueueSnackbar("Fehler beim aktualsieren des Ansprechpartners", {
                    variant: "error",
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardHeader
                        title={
                            <Box display="flex" alignItems="center">
                                <PermContactCalendarIcon style={{ marginRight: 5 }} /> Ansprechpartner
                            </Box>
                        }
                    />
                    <CardContent>
                        <Formik
                            innerRef={this.formRef}
                            initialValues={{
                                contactName: "",
                                contactPhone: "",
                                contactMail: "",
                            }}
                            onSubmit={(values) => {
                                this.update(values);
                            }}
                            validateOnChange={true}
                        >
                            {(props) => (
                                <Form onBlur={props.handleSubmit}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="contactName"
                                            name="contactName"
                                            label="Name"
                                            value={props.values.contactName}
                                            onChange={props.handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="contactPhone"
                                            name="contactPhone"
                                            label="Telefonnummer"
                                            value={props.values.contactPhone}
                                            onChange={props.handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="contactMail"
                                            name="contactMail"
                                            value={props.values.contactMail}
                                            onChange={props.handleChange}
                                            label="E-Mail"
                                            fullWidth
                                        />
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withSnackbar(BillingInformation));
