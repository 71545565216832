import { Backdrop, CircularProgress, Box, Button, AppBar, Tabs, Tab, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
    activateCouponEmployee,
    createCouponEmployee,
    getAllEmployeeCardsByRetailersId,
    getCouponEmployeesByRetailersId,
    lockEmployeeCard,
    transferEmployeeCard,
    updateCouponEmployee,
} from "../../../../store/actions/retailerActions";
import { store } from "../../../../store/store";
import "../CouponEmployees.scss";
import AddCouponEmployeeDialog from "./AddCouponEmployeeDialog";
import ActiveCouponEmployeesTable from "./ActiveCouponEmployeesTable";
import LockCardDialog from "./LockCardDialog";
import TransferCardDialog from "./TransferCardDialog";
import UpdateCardDialog from "./UpdateCardDialog";
import PropTypes from "prop-types";
import NotActiveCouponEmployeesTable from "./NotActiveCouponEmployeesTable";
import ActivateCardDialog from "./ActivateCardDialog";
import NotActiveEmployeeCardsTable from "./NotActiveEmployeeCardsTable";
import LockedCardsTable from "./LockedCardsTable";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class Cards extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addCouponEmployeePopupVisible: false,
            updateCardsPopupVisible: false,
            lockCardsPopupVisible: false,
            importCardsPopupVisible: false,
            transferCardPopupVisible: false,
            activateCardPopupVisible: false,
            couponEmployee: undefined,
            refreshing: false,
            selectedCouponEmployee: undefined,
            selectedEmployeeCard: undefined,
            selectedEmployeeCardNumber: 0,
            employeeCards: [],
            lockedCards: [],
            lock: false,
            tab: "one",
        };

        this.handleOpenAddCouponEmployeeDialog = this.handleOpenAddCouponEmployeeDialog.bind(this);
        this.handleCloseAddCouponEmployeeDialog = this.handleCloseAddCouponEmployeeDialog.bind(this);
        this.handleSubmitNewCouponEmployeeEmail = this.handleSubmitNewCouponEmployeeEmail.bind(this);
        this.handleSubmitNewCouponEmployeeCard = this.handleSubmitNewCouponEmployeeCard.bind(this);
        this.editEmployee = this.editEmployee.bind(this);
        this.handleSubmitUpdateCard = this.handleSubmitUpdateCard.bind(this);
        this.handleCloseUpdateCardDialog = this.handleCloseUpdateCardDialog.bind(this);
        this.handleOpenUpdateCardsDialog = this.handleOpenUpdateCardsDialog.bind(this);
        this.handleCloseLockCardDialog = this.handleCloseLockCardDialog.bind(this);
        this.handleCloseTransferCardDialog = this.handleCloseTransferCardDialog.bind(this);
        this.handleOpenLockCardsDialog = this.handleOpenLockCardsDialog.bind(this);
        this.handleOpenTransferCardsDialog = this.handleOpenTransferCardsDialog.bind(this);
        this.handleLockCard = this.handleLockCard.bind(this);
        this.handleTransferCard = this.handleTransferCard.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleOpenActivateCardsDialog = this.handleOpenActivateCardsDialog.bind(this);
        this.handleCloseActivateCardDialog = this.handleCloseActivateCardDialog.bind(this);
        this.handleActivateCard = this.handleActivateCard.bind(this);
        this.updateTables = this.updateTables.bind(this);
    }

    componentDidMount() {
        this.props.setHeaderTitle("Mitarbeiter verwalten");
        this.updateTables();
    }

    updateTables() {
        this.setState({ refreshing: true });
        store
            .dispatch(getCouponEmployeesByRetailersId(this.props.retailer.informations.retailersId, true))
            .then((response) => {
                this.setState({ refreshing: false });
            })
            .catch((err) => {
                this.setState({ refreshing: false });
            });
        store.dispatch(getCouponEmployeesByRetailersId(this.props.retailer.informations.retailersId, false));
        store.dispatch(getAllEmployeeCardsByRetailersId(this.props.retailer.informations.retailersId, false, null));
        store.dispatch(getAllEmployeeCardsByRetailersId(this.props.retailer.informations.retailersId, null, false));
    }

    handleOpenAddCouponEmployeeDialog(e, employeeCard) {
        this.setState({
            couponEmployee: undefined,
            selectedEmployeeCard: employeeCard,
            addCouponEmployeePopupVisible: true,
        });
    }

    handleCloseAddCouponEmployeeDialog() {
        this.setState({
            addCouponEmployeePopupVisible: false,
        });
    }

    handleOpenUpdateCardsDialog(couponEmployee) {
        this.setState({
            updateCardsPopupVisible: true,
            selectedCouponEmployee: couponEmployee,
        });
    }

    handleCloseUpdateCardDialog() {
        this.setState({
            updateCardsPopupVisible: false,
        });
    }

    handleCloseTransferCardDialog() {
        this.setState({
            transferCardPopupVisible: false,
        });
    }

    handleCloseActivateCardDialog() {
        this.setState({
            activateCardPopupVisible: false,
        });
    }

    handleCloseLockCardDialog() {
        this.setState({
            lockCardsPopupVisible: false,
        });
    }

    handleOpenLockCardsDialog(employeeCardNumber, lock) {
        this.setState({
            lockCardsPopupVisible: true,
            selectedEmployeeCardNumber: employeeCardNumber,
            lock: lock,
        });
    }

    handleOpenTransferCardsDialog(employeeCardNumber, lock) {
        this.setState({
            transferCardPopupVisible: true,
            selectedEmployeeCardNumber: employeeCardNumber,
            lock: lock,
        });
    }

    handleOpenActivateCardsDialog(couponEmployee) {
        this.setState({
            activateCardPopupVisible: true,
            selectedCouponEmployee: couponEmployee,
        });
    }

    handleSubmitUpdateCard(values, couponEmployeesId, couponEmployeeRetailersId, retailersId) {
        this.setState({ refreshing: true });
        store
            .dispatch(
                updateCouponEmployee(
                    couponEmployeesId,
                    couponEmployeeRetailersId,
                    values.email ? values.email : null,
                    retailersId ? retailersId : null,
                    values.firstTransaction,
                    values.lastTransaction,
                    values.comment,
                    values.name
                )
            )
            .then((response) => {
                this.updateTables();
                this.handleCloseUpdateCardDialog();
            })
            .catch((err) => {
                this.handleCloseUpdateCardDialog();
                this.setState({ refreshing: false });
            });
    }

    handleLockCard(cardNumber) {
        this.setState({ refreshing: true });
        store
            .dispatch(lockEmployeeCard(this.props.retailer.informations.retailersId, cardNumber))
            .then((response) => {
                this.updateTables();
                this.handleCloseLockCardDialog();
            })
            .catch((err) => {
                this.handleCloseLockCardDialog();
                this.setState({ refreshing: false });
            });
    }

    handleTransferCard(oldEmployeeCardsId, newEmployeeCardsId) {
        this.setState({ refreshing: true });
        store
            .dispatch(
                transferEmployeeCard(
                    this.props.retailer.informations.retailersId,
                    oldEmployeeCardsId,
                    newEmployeeCardsId
                )
            )
            .then((response) => {
                this.updateTables();
                this.handleCloseTransferCardDialog();
            })
            .catch((err) => {
                this.handleCloseTransferCardDialog();
                this.setState({ refreshing: false });
            });
    }

    handleActivateCard(couponEmployeesId) {
        this.setState({ refreshing: true });
        store
            .dispatch(activateCouponEmployee(this.props.retailer.informations.retailersId, couponEmployeesId))
            .then((response) => {
                this.updateTables();
                this.handleCloseActivateCardDialog();
            })
            .catch((err) => {
                this.handleCloseActivateCardDialog();
                this.setState({ refreshing: false });
            });
    }

    handleSubmitNewCouponEmployeeEmail(values, couponRetailer) {
        if (!couponRetailer) {
            console.log("kein Händler ausgewählt");
            return;
        }
        this.setState({ refreshing: true });
        store
            .dispatch(
                createCouponEmployee(
                    values.email,
                    null,
                    this.props.retailer.informations.retailersId,
                    couponRetailer.retailersId,
                    values.firstTransaction,
                    values.lastTransaction,
                    values.comment,
                    values.name
                )
            )
            .then((response) => {
                this.updateTables();
                this.handleCloseAddCouponEmployeeDialog();
            })
            .catch((err) => {
                this.handleCloseAddCouponEmployeeDialog();
                this.setState({ refreshing: false });
            });
    }

    handleSubmitNewCouponEmployeeCard(values, employeeCard) {
        this.setState({ refreshing: true });
        store
            .dispatch(
                createCouponEmployee(
                    "",
                    employeeCard.id,
                    this.props.retailer.informations.retailersId,
                    null,
                    values.firstTransaction,
                    values.lastTransaction,
                    values.comment,
                    values.name
                )
            )
            .then((response) => {
                this.updateTables();
                this.handleCloseAddCouponEmployeeDialog();
            })
            .catch((err) => {
                this.handleCloseAddCouponEmployeeDialog();
                this.setState({ refreshing: false });
            });
    }

    editEmployee(data) {
        this.setState({
            couponEmployee: data.original,
            addCouponEmployeePopupVisible: true,
        });
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tab: newValue,
        });
    }

    render() {
        return (
            <div id="Cards">
                <Backdrop open={this.state.refreshing} style={{ zIndex: 9999 }}>
                    <CircularProgress />
                </Backdrop>
                <Box className="retailerSettings">
                    <Button
                        style={{ margin: "10px" }}
                        color="secondary"
                        variant="contained"
                        onClick={this.handleOpenAddCouponEmployeeDialog}
                    >
                        Mitarbeiter hinzufügen
                    </Button>
                </Box>
                <AppBar position="static" color="secondary">
                    <Tabs value={this.state.tab} onChange={this.handleChangeTab} variant="fullWidth">
                        <Tab
                            value="one"
                            label={"aktive Mitarbeiter (" + this.props.retailer.couponEmployeesActive.length + ")"}
                        />
                        <Tab
                            value="two"
                            label={
                                "nicht aktive Mitarbeiter (" + this.props.retailer.couponEmployeesNotActive.length + ")"
                            }
                        />
                        <Tab
                            value="three"
                            label={"gesperrte Karten (" + this.props.retailer.employeeCardsNotValid.length + ")"}
                        />
                        <Tab
                            value="four"
                            label={
                                "nicht zugewiesene Karten (" + this.props.retailer.employeeCardsNotActive.length + ")"
                            }
                        />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.tab} index="one">
                    <ActiveCouponEmployeesTable
                        data={this.props.retailer.couponEmployeesActive}
                        editEmployee={this.editEmployee}
                        handleOpenUpdateCardsDialog={this.handleOpenUpdateCardsDialog}
                        handleOpenLockCardsDialog={this.handleOpenLockCardsDialog}
                        handleOpenTransferCardsDialog={this.handleOpenTransferCardsDialog}
                        handleOpenActivateCardsDialog={this.handleOpenActivateCardsDialog}
                    />
                </TabPanel>
                <TabPanel value={this.state.tab} index="two">
                    <NotActiveCouponEmployeesTable
                        data={this.props.retailer.couponEmployeesNotActive}
                        editEmployee={this.editEmployee}
                        handleOpenUpdateCardsDialog={this.handleOpenUpdateCardsDialog}
                        handleOpenLockCardsDialog={this.handleOpenLockCardsDialog}
                        handleOpenTransferCardsDialog={this.handleOpenTransferCardsDialog}
                        handleOpenActivateCardsDialog={this.handleOpenActivateCardsDialog}
                    />
                </TabPanel>
                <TabPanel value={this.state.tab} index="three">
                    <LockedCardsTable
                        data={this.props.retailer.employeeCardsNotValid}
                        handleOpenLockCardsDialog={this.handleOpenLockCardsDialog}
                        handleOpenTransferCardsDialog={this.handleOpenTransferCardsDialog}
                    />
                </TabPanel>
                <TabPanel value={this.state.tab} index="four">
                    <NotActiveEmployeeCardsTable
                        data={this.props.retailer.employeeCardsNotActive}
                        handleOpenAddCouponEmployeeDialog={this.handleOpenAddCouponEmployeeDialog}
                    />
                </TabPanel>
                <AddCouponEmployeeDialog
                    openAddCouponEmployeeDialog={this.state.addCouponEmployeePopupVisible}
                    handleCloseAddCouponEmployeeDialog={this.handleCloseAddCouponEmployeeDialog}
                    handleSubmitNewCouponEmployeeEmail={this.handleSubmitNewCouponEmployeeEmail}
                    handleSubmitNewCouponEmployeeCard={this.handleSubmitNewCouponEmployeeCard}
                    selectedEmployeeCard={this.state.selectedEmployeeCard}
                />
                <UpdateCardDialog
                    couponEmployee={this.state.selectedCouponEmployee}
                    openUpdateEmployeeCardsDialog={this.state.updateCardsPopupVisible}
                    handleCloseUpdateCardDialog={this.handleCloseUpdateCardDialog}
                    handleSubmitUpdateCard={this.handleSubmitUpdateCard}
                />
                <LockCardDialog
                    openLockCardDialog={this.state.lockCardsPopupVisible}
                    employeeCard={this.state.selectedEmployeeCardNumber}
                    handleCloseLockCardDialog={this.handleCloseLockCardDialog}
                    handleLockCard={this.handleLockCard}
                    lock={this.state.lock}
                />
                <TransferCardDialog
                    openTransferCardDialog={this.state.transferCardPopupVisible}
                    employeeCard={this.state.selectedEmployeeCardNumber}
                    handleCloseTransferCardDialog={this.handleCloseTransferCardDialog}
                    handleTransferCard={this.handleTransferCard}
                    lock={this.state.lock}
                />
                <ActivateCardDialog
                    openActivateCardDialog={this.state.activateCardPopupVisible}
                    couponEmployee={this.state.selectedCouponEmployee}
                    handleCloseActivateCardDialog={this.handleCloseActivateCardDialog}
                    handleActivateCard={this.handleActivateCard}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(withRouter(Cards));
