import {
    RECEIVE_RETAILERINFORMATIONS,
    RECEIVE_RETAILERTRANSACTIONS,
    RECEIVE_COUPONEMPLOYEESACTIVE,
    RECEIVE_COUPONEMPLOYEESNOTACTIVE,
    RECEIVE_EMPLOYEEECARDSVALID,
    RECEIVE_EMPLOYEEECARDSNOTVALID,
    RECEIVE_EMPLOYEEECARDSACTIVE,
    RECEIVE_EMPLOYEEECARDSNOTACTIVE,
    RECEIVE_ALLEMPLOYEES,
    REMOVE_RETAILER,
    RECEIVE_ACTUALCOUPONACCOUNTVALUE,
    RECEIVECITIES,
} from "../actions/retailerActions";

const initialState = {
    informations: undefined,
    transactions: [],
    couponEmployeesActive: [],
    couponEmployeesNotActive: [],
    employeeCardsValid: [],
    employeeCardsNotValid: [],
    employeeCardsActive: [],
    employeeCardsNotActive: [],
    employees: [],
    actualCouponAccountValue: 0,
    citiesList: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_RETAILERINFORMATIONS:
            return Object.assign({}, state, {
                informations: action.informations,
            });
        case RECEIVE_ACTUALCOUPONACCOUNTVALUE:
            return Object.assign({}, state, {
                actualCouponAccountValue: Math.abs(action.actualCouponAccountValue),
            });
        case RECEIVE_RETAILERTRANSACTIONS:
            return Object.assign({}, state, {
                transactions: action.transactions,
            });
        case RECEIVE_COUPONEMPLOYEESACTIVE:
            return Object.assign({}, state, {
                couponEmployeesActive: action.couponEmployeesActive,
            });
        case RECEIVE_COUPONEMPLOYEESNOTACTIVE:
            return Object.assign({}, state, {
                couponEmployeesNotActive: action.couponEmployeesNotActive,
            });
        case RECEIVE_EMPLOYEEECARDSVALID:
            return Object.assign({}, state, {
                employeeCardsValid: action.employeeCardsValid,
            });
        case RECEIVE_EMPLOYEEECARDSNOTVALID:
            return Object.assign({}, state, {
                employeeCardsNotValid: action.employeeCardsNotValid,
            });
        case RECEIVE_EMPLOYEEECARDSACTIVE:
            return Object.assign({}, state, {
                employeeCardsActive: action.employeeCardsActive,
            });
        case RECEIVE_EMPLOYEEECARDSNOTACTIVE:
            return Object.assign({}, state, {
                employeeCardsNotActive: action.employeeCardsNotActive,
            });
        case RECEIVE_ALLEMPLOYEES:
            return Object.assign({}, state, {
                employees: action.employees,
            });
        case REMOVE_RETAILER:
            return Object.assign({}, state, {
                informations: undefined,
                transactions: [],
                couponEmployees: [],
                employees: [],
                actualCouponAccountValue: 0,
                citiesList: undefined,
            });
        case RECEIVECITIES:
            return Object.assign({}, state, {
                citiesList: action.citiesList,
            });
        default:
            return state;
    }
};
