import { ADD_COUPON_TO_LIST, CLEAR_COUPON_LIST, REMOVE_COUPON_FROM_LIST } from "../actions/couponActions";

const initialState = {
    couponList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_COUPON_TO_LIST:
            let duplicateCoupon = state.couponList.find((x) => x.activationcode === action.coupon.activationcode);
            if (duplicateCoupon === undefined) {
                return {
                    ...state,
                    couponList: [action.coupon, ...state.couponList],
                };
            } else {
                return { ...state };
            }
        case REMOVE_COUPON_FROM_LIST:
            let couponIndexRemove = state.couponList.findIndex(
                (x) => x.activationcode === action.coupon.activationcode
            );
            if (couponIndexRemove !== -1) {
                let updatedCouponList = [...state.couponList];
                updatedCouponList.splice(couponIndexRemove, 1);
                return {
                    ...state,
                    couponList: updatedCouponList,
                };
            } else {
                return { ...state };
            }
        case CLEAR_COUPON_LIST:
            return Object.assign({}, state, {
                couponList: [],
            });
        default:
            return state;
    }
};
