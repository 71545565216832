import { Box, Grid } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import BillingInformation from "./BillingInformation/BillingInformation";
import ContactPerson from "./ContactPerson/ContactPerson";
import ManagingDirector from "./ManagingDirector/ManagingDirector";
import ReportMailAddress from "./ReportMailAddress/ReportMailAddress";
import StoreData from "./StoreData/StoreData";

class Information extends Component {
    componentDidMount() {
        this.props.setHeaderTitle("Informationen");
    }
    render() {
        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="center"
                    style={{ textAlign: "left" }}
                    className="NewStore"
                >
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <Box pb={4} pt={4}>
                            <StoreData />
                        </Box>
                        <Box pb={4}>
                            <ManagingDirector />
                        </Box>
                        <Box pb={4}>
                            <BillingInformation />
                        </Box>
                        <Box pb={4}>
                            <ContactPerson />
                        </Box>
                        <Box pb={4}>
                            <ReportMailAddress />
                        </Box>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(Information);
