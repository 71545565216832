import React, { Component } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Alert from '@material-ui/lab/Alert';
import { Grid, TextField, Button, CircularProgress, Typography, Link } from '@material-ui/core';
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'

class ChangePasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            error: false,
            errorText: '',
            username: this.props.username,
        }
    }

    render() {
        var shape = {
            password: Yup.string()
                .required('Dieses Feld wird benötigt'),
            passwordConfirm: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwörter stimmen nicht überein')
                .required('Dieses Feld wird benötigt')
        }
        if (!this.state.username) {
            shape.oldPassword = Yup.string()
                .required('Dieses Feld wird benötigt');
        }
        else {
            shape.code = Yup.string()
                .required('Dieses Feld wird benötigt');
        }
        return (
            <div className="ChangePassword">
                {this.state.error &&
                    <Alert style={{ marginBottom: '20px' }} severity="error">{this.state.errorText}</Alert>
                }
                <Formik
                    initialValues={{
                        code: '',
                        oldPassword: '',
                        password: '',
                        passwordConfirm: ''
                    }}
                    validationSchema={Yup.object().shape(shape)}
                    onSubmit={(values) => {
                        this.setState({
                            showSpinner: true,
                            error: false,
                            errorText: ''
                        })
                        if (this.state.username) {
                            Auth.forgotPasswordSubmit(this.state.username, values.code, values.password)
                                .then(data => { this.setState({ showSpinner: false }); this.props.history.push('/account/change-password-success', { changePasswordSuccessful: true }) })
                                .catch(err => {
                                    this.setState({ showSpinner: false })
                                    switch (err.code) {
                                        case "UsernameExistsException":
                                            this.setState({ error: true, errorText: "Diese E-Mail Addresse wird bereits verwendet" });
                                            break;
                                        case "TooManyRequestsException":
                                            this.setState({ error: true, errorText: "Du hast zu oft versucht dich zu registrieren. Bitte versuche es später erneut" });
                                            break;
                                        case "InvalidPasswordException":
                                            this.setState({ error: true, errorText: "Das Passwort muss mindestens 8 Zeichen lang sein" });
                                            break;
                                        case "InvalidParameterException":
                                            this.setState({ error: true, errorText: "Das Passwort muss mindestens 8 Zeichen lang sein" });
                                            break;
                                        default:
                                            this.setState({ error: true, errorText: err.message });
                                    }
                                })
                        }
                        else {
                            Auth.currentAuthenticatedUser().then(user => {
                                Auth.changePassword(user, values.oldPassword, values.password)
                                    .then(data => { this.props.history.push('/administration/change-password-success', { changePasswordSuccessful: true }) })
                                    .catch(err => {
                                        this.setState({ showSpinner: false })
                                        switch (err.code) {
                                            case "UsernameExistsException":
                                                this.setState({ error: true, errorText: "Diese E-Mail Addresse wird bereits verwendet" });
                                                break;
                                            case "TooManyRequestsException":
                                                this.setState({ error: true, errorText: "Du hast zu oft versucht dich zu registrieren. Bitte versuche es später erneut" });
                                                break;
                                            case "InvalidPasswordException":
                                                this.setState({ error: true, errorText: "Das Passwort muss mindestens 8 Zeichen lang sein" });
                                                break;
                                            case "InvalidParameterException":
                                                this.setState({ error: true, errorText: "Das Passwort muss mindestens 8 Zeichen lang sein" });
                                                break;
                                            default:
                                                this.setState({ error: true, errorText: err.message });
                                        };
                                    })
                            })
                        }
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid container spacing={1}>
                                {this.state.username && <Grid item xs={12}>
                                    <TextField
                                        id="code"
                                        name="code"
                                        label="Verifizierungscode"
                                        defaultValue={props.values.code}
                                        onChange={props.handleChange}
                                        error={props.errors.code ? true : false}
                                        helperText={props.errors.code}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>}
                                {!this.state.username &&
                                    <Grid item xs={12}>
                                        <TextField
                                            id="oldPassword"
                                            name="oldPassword"
                                            label="Altes Passwort"
                                            type="password"
                                            defaultValue={props.values.oldPassword}
                                            onChange={props.handleChange}
                                            error={props.errors.oldPassword ? true : false}
                                            helperText={props.errors.oldPassword}
                                            fullWidth
                                            disabled={this.state.showSpinner}
                                        />
                                    </Grid>}
                                <Grid item xs={12}>
                                    <TextField
                                        id="password"
                                        name="password"
                                        label="Neues Passwort"
                                        type="password"
                                        defaultValue={props.values.password}
                                        onChange={props.handleChange}
                                        error={props.errors.password ? true : false}
                                        helperText={props.errors.password}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="passwordConfirm"
                                        name="passwordConfirm"
                                        label="Neues Passwort wiederholen"
                                        type="password"
                                        defaultValue={props.values.passwordConfirm}
                                        onChange={props.handleChange}
                                        error={props.errors.passwordConfirm ? true : false}
                                        helperText={props.errors.passwordConfirm}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: 'right', marginTop: '20px', position: 'relative' }}>
                                    <Button fullWidth disabled={this.state.showSpinner} type="submit" variant="contained" color="primary">
                                        weiter
                                    </Button>
                                    {this.state.showSpinner && <CircularProgress size={24} className='Spinner' />}
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                {this.state.username &&
                    <div>
                        <Typography variant="body2">
                            Passwort wieder eingefallen? <Link component={RouterLink} to="/account">Login</Link>
                        </Typography>
                        <Typography variant="body2">
                            Hast Du noch keinen Account? <Link component={RouterLink} to="/account/registration">Registieren</Link>
                        </Typography>
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(ChangePasswordForm)
