import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Button, Box, Paper } from "@material-ui/core";
import Day from "./Day";
import { updateGoogleData } from "../../../store/actions/retailerActions";
import { store } from "../../../store/store";

class OpeningHours extends Component {
    constructor() {
        super();
        this.state = {
            mondayOpen: [],
            mondayClose: [],
            tuesdayOpen: [],
            tuesdayClose: [],
            wednesdayOpen: [],
            wednesdayClose: [],
            thursdayOpen: [],
            thursdayClose: [],
            fridayOpen: [],
            fridayClose: [],
            saturdayOpen: [],
            saturdayClose: [],
            sundayOpen: [],
            sundayClose: [],
        };
        this.initialize = this.initialize.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.generateWeekdayText = this.generateWeekdayText.bind(this);
        this.generatePeriods = this.generatePeriods.bind(this);
        this.generatePeriod = this.generatePeriod.bind(this);
        this.generateDayText = this.generateDayText.bind(this);
        this.save = this.save.bind(this);
        this.week = [];
    }

    componentDidMount() {
        this.props.setHeaderTitle("Öffnungszeiten");
        this.week = this.props.retailer.informations.periods ? this.props.retailer.informations.periods : [];
        this.initialize();
    }

    render() {
        return (
            <div style={{ paddingLeft: "50px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Day
                            day="Montag"
                            key="Montag"
                            changeID="monday"
                            opening={this.state.mondayOpen}
                            closing={this.state.mondayClose}
                            handleChange={(e, index) => this.handleChange(e, index)}
                            handleAdd={(day) => this.handleAdd(day)}
                            handleDelete={(day, index) => this.handleDelete(day, index)}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Day
                            day="Dienstag"
                            key="Dienstag"
                            changeID="tuesday"
                            opening={this.state.tuesdayOpen}
                            closing={this.state.tuesdayClose}
                            handleChange={(e, index) => this.handleChange(e, index)}
                            handleAdd={(day) => this.handleAdd(day)}
                            handleDelete={(day, index) => this.handleDelete(day, index)}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Day
                            day="Mittwoch"
                            key="Mittwoch"
                            changeID="wednesday"
                            opening={this.state.wednesdayOpen}
                            closing={this.state.wednesdayClose}
                            handleChange={(e, index) => this.handleChange(e, index)}
                            handleAdd={(day) => this.handleAdd(day)}
                            handleDelete={(day, index) => this.handleDelete(day, index)}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Day
                            day="Donnerstag"
                            key="Donnerstag"
                            changeID="thursday"
                            opening={this.state.thursdayOpen}
                            closing={this.state.thursdayClose}
                            handleChange={(e, index) => this.handleChange(e, index)}
                            handleAdd={(day) => this.handleAdd(day)}
                            handleDelete={(day, index) => this.handleDelete(day, index)}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Day
                            day="Freitag"
                            key="Freitag"
                            changeID="friday"
                            opening={this.state.fridayOpen}
                            closing={this.state.fridayClose}
                            handleChange={(e, index) => this.handleChange(e, index)}
                            handleAdd={(day) => this.handleAdd(day)}
                            handleDelete={(day, index) => this.handleDelete(day, index)}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Day
                            day="Samstag"
                            key="Samstag"
                            changeID="saturday"
                            opening={this.state.saturdayOpen}
                            closing={this.state.saturdayClose}
                            handleChange={(e, index) => this.handleChange(e, index)}
                            handleAdd={(day) => this.handleAdd(day)}
                            handleDelete={(day, index) => this.handleDelete(day, index)}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Day
                            day="Sonntag"
                            key="Sonntag"
                            changeID="sunday"
                            opening={this.state.sundayOpen}
                            closing={this.state.sundayClose}
                            handleChange={(e, index) => this.handleChange(e, index)}
                            handleAdd={(day) => this.handleAdd(day)}
                            handleDelete={(day, index) => this.handleDelete(day, index)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Box m={1} />
                        <Paper
                            elevation={1}
                            style={{ width: "300px", minHeight: "230px", display: "flex", justifyContent: "center" }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ margin: "auto", justify: "center" }}
                                onClick={() => {
                                    this.save();
                                }}
                            >
                                Speichern
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
                <Box m={6} />
            </div>
        );
    }

    save() {
        let periods = this.generatePeriods();
        let weekdayText = this.generateWeekdayText();
        store.dispatch(
            updateGoogleData(
                this.props.retailer.informations.retailersId,
                this.props.retailer.informations.phoneNumber,
                this.props.retailer.informations.rating,
                this.props.retailer.informations.userRatingTotal,
                this.props.retailer.informations.website,
                weekdayText,
                this.props.retailer.informations.tags,
                this.props.retailer.informations.text,
                periods,
                this.props.retailer.informations.contactPerson
            )
        );
    }

    initialize() {
        let i = 0;

        if (this.week.length > 0) {
            while (i < this.props.retailer.informations.periods.length) {
                let element = this.week[i];

                if (element !== null && element)
                    switch (element.open.day) {
                        case 0: {
                            this.setState((state) => ({
                                sundayOpen: [...state.sundayOpen, insert(element.open.time, 2, ":")],
                                sundayClose: [...state.sundayClose, insert(element.close.time, 2, ":")],
                            }));
                            break;
                        }

                        case 1: {
                            this.setState((state) => ({
                                mondayOpen: [...state.mondayOpen, insert(element.open.time, 2, ":")],
                                mondayClose: [...state.mondayClose, insert(element.close.time, 2, ":")],
                            }));
                            break;
                        }

                        case 2: {
                            this.setState((state) => ({
                                tuesdayOpen: [...state.tuesdayOpen, insert(element.open.time, 2, ":")],
                                tuesdayClose: [...state.tuesdayClose, insert(element.close.time, 2, ":")],
                            }));
                            break;
                        }

                        case 3: {
                            this.setState((state) => ({
                                wednesdayOpen: [...state.wednesdayOpen, insert(element.open.time, 2, ":")],
                                wednesdayClose: [...state.wednesdayClose, insert(element.close.time, 2, ":")],
                            }));
                            break;
                        }

                        case 4: {
                            this.setState((state) => ({
                                thursdayOpen: [...state.thursdayOpen, insert(element.open.time, 2, ":")],
                                thursdayClose: [...state.thursdayClose, insert(element.close.time, 2, ":")],
                            }));
                            break;
                        }

                        case 5: {
                            this.setState((state) => ({
                                fridayOpen: [...state.fridayOpen, insert(element.open.time, 2, ":")],
                                fridayClose: [...state.fridayClose, insert(element.close.time, 2, ":")],
                            }));
                            break;
                        }

                        case 6: {
                            this.setState((state) => ({
                                saturdayOpen: [...state.saturdayOpen, insert(element.open.time, 2, ":")],
                                saturdayClose: [...state.saturdayClose, insert(element.close.time, 2, ":")],
                            }));
                            break;
                        }
                        default:
                    }

                i++;
            }
        }
    }

    generatePeriod(open, close, day) {
        let i = 0;
        let periods = [];
        if (!(this.state[open].length === 0)) {
            while (this.state[open][i]) {
                let opening = this.state[open][i];

                let closingTime = this.state[close][this.state[open].indexOf(opening)].replace(":", "");
                let time = opening.replace(":", "");
                i++;
                periods.push({
                    open: {
                        day: day,
                        time: time,
                    },
                    close: {
                        day: day,
                        time: closingTime,
                    },
                });
            }

            return periods;
        }
    }

    generatePeriods() {
        let periods = [];

        periods = periods.concat(this.generatePeriod("sundayOpen", "sundayClose", 0));
        periods = periods.concat(this.generatePeriod("mondayOpen", "mondayClose", 1));
        periods = periods.concat(this.generatePeriod("tuesdayOpen", "tuesdayClose", 2));
        periods = periods.concat(this.generatePeriod("wednesdayOpen", "wednesdayClose", 3));
        periods = periods.concat(this.generatePeriod("thursdayOpen", "thursdayClose", 4));
        periods = periods.concat(this.generatePeriod("fridayOpen", "fridayClose", 5));
        periods = periods.concat(this.generatePeriod("saturdayOpen", "saturdayClose", 6));

        return periods;
    }

    generateDayText(open, close, day) {
        let i = 0;
        let helper = "";
        let comma = "";

        if (this.state[open].length === 0) {
            helper = day + ": Geschlossen";
        } else {
            while (this.state[open][i]) {
                let opening = this.state[open][i];
                comma =
                    this.state[open].indexOf(opening) === 0 ||
                    this.state[open].indexOf(opening) === this.state[open].length
                        ? ","
                        : "";
                day = this.state[open].indexOf(opening) === 0 ? day + ":" : "";
                helper = `${helper}${day} ${opening}-${
                    this.state[close][this.state[open].indexOf(opening)]
                } Uhr${comma}`;

                i++;
            }
        }
        return helper;
    }

    generateWeekdayText() {
        let weekdayText = [];
        weekdayText.push(this.generateDayText("mondayOpen", "mondayClose", "Montag"));
        weekdayText.push(this.generateDayText("tuesdayOpen", "tuesdayClose", "Dienstag"));
        weekdayText.push(this.generateDayText("wednesdayOpen", "wednesdayClose", "Mittwoch"));
        weekdayText.push(this.generateDayText("thursdayOpen", "thursdayClose", "Donnerstag"));
        weekdayText.push(this.generateDayText("fridayOpen", "fridayClose", "Freitag"));
        weekdayText.push(this.generateDayText("saturdayOpen", "saturdayClose", "Samstag"));
        weekdayText.push(this.generateDayText("sundayOpen", "sundayClose", "Sonntag"));
        return weekdayText;
    }

    handleChange(e, index) {
        let items = this.state[e.target.id];
        let item = [...items[index]];
        item = e.target.value;
        items[index] = item;
        this.setState({ [e.target.id]: items });
    }

    handleAdd(day) {
        switch (day) {
            case "Montag": {
                this.setState({
                    mondayOpen: [...this.state.mondayOpen, "00:00"],
                    mondayClose: [...this.state.mondayClose, "00:00"],
                });
                break;
            }
            case "Dienstag": {
                this.setState({
                    tuesdayOpen: [...this.state.tuesdayOpen, "00:00"],
                    tuesdayClose: [...this.state.tuesdayClose, "00:00"],
                });
                break;
            }
            case "Mittwoch": {
                this.setState({
                    wednesdayOpen: [...this.state.wednesdayOpen, "00:00"],
                    wednesdayClose: [...this.state.wednesdayClose, "00:00"],
                });
                break;
            }
            case "Donnerstag": {
                this.setState({
                    thursdayOpen: [...this.state.thursdayOpen, "00:00"],
                    thursdayClose: [...this.state.thursdayClose, "00:00"],
                });
                break;
            }
            case "Freitag": {
                this.setState({
                    fridayOpen: [...this.state.fridayOpen, "00:00"],
                    fridayClose: [...this.state.fridayClose, "00:00"],
                });
                break;
            }
            case "Samstag": {
                this.setState({
                    saturdayOpen: [...this.state.saturdayOpen, "00:00"],
                    saturdayClose: [...this.state.saturdayClose, "00:00"],
                });
                break;
            }
            case "Sonntag": {
                this.setState({
                    sundayOpen: [...this.state.sundayOpen, "00:00"],
                    sundayClose: [...this.state.sundayClose, "00:00"],
                });
                break;
            }
            default:
                console.log("Add failed");
        }
    }

    handleDelete(day, index) {
        switch (day) {
            case "Montag": {
                this.setState({
                    mondayOpen: this.state.mondayOpen.filter((_, i) => i !== index),
                    mondayClose: this.state.mondayClose.filter((_, i) => i !== index),
                });
                break;
            }
            case "Dienstag": {
                this.setState({
                    tuesdayOpen: this.state.tuesdayOpen.filter((_, i) => i !== index),
                    tuesdayClose: this.state.tuesdayClose.filter((_, i) => i !== index),
                });
                break;
            }
            case "Mittwoch": {
                this.setState({
                    wednesdayOpen: this.state.wednesdayOpen.filter((_, i) => i !== index),
                    wednesdayClose: this.state.wednesdayClose.filter((_, i) => i !== index),
                });
                break;
            }
            case "Donnerstag": {
                this.setState({
                    thursdayOpen: this.state.thursdayOpen.filter((_, i) => i !== index),
                    thursdayClose: this.state.thursdayClose.filter((_, i) => i !== index),
                });
                break;
            }
            case "Freitag": {
                this.setState({
                    fridayOpen: this.state.fridayOpen.filter((_, i) => i !== index),
                    fridayClose: this.state.fridayClose.filter((_, i) => i !== index),
                });
                break;
            }
            case "Samstag": {
                this.setState({
                    saturdayOpen: this.state.saturdayOpen.filter((_, i) => i !== index),
                    saturdayClose: this.state.saturdayClose.filter((_, i) => i !== index),
                });
                break;
            }
            case "Sonntag": {
                this.setState({
                    sundayOpen: this.state.sundayOpen.filter((_, i) => i !== index),
                    sundayClose: this.state.sundayClose.filter((_, i) => i !== index),
                });
                break;
            }
            default:
                console.log("delete failed");
        }
    }
}
//insert : into time string to match expected format
function insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(OpeningHours);
