import { combineReducers } from "redux";
import retailer from "./retailerReducer";
import coupon from "./couponReducer";
import user from "./userReducer";
import charging from "./chargingReducer";
import order from "./orderReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const userPersistConfig = {
    key: "user",
    storage: storage,
    blacklist: ["cognitoUser", "isFetching"],
};

const rootReducer = combineReducers({
    retailer,
    coupon,
    charging,
    order,
    user: persistReducer(userPersistConfig, user),
});

export default rootReducer;
