import { Backdrop, CircularProgress } from "@material-ui/core";
import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { userHasRight } from "./UserRights";

class PrivateRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            haveAccess: false,
            loaded: false,
            redirectPath: "/account",
        };
    }

    componentDidMount() {
        if (this.props.user) {
            if (this.props.user.employee) {
                if (this.props.user.employee.Rights) {
                    Auth.currentSession()
                        .then((session) => {
                            Auth.currentAuthenticatedUser()
                                .then((user) => {
                                    let userRightOk = true;

                                    if (this.props.right) {
                                        userRightOk = userHasRight(this.props.right);
                                    }
                                    switch (this.props.type) {
                                        case "redeem":
                                            userRightOk =
                                                this.props.retailer.informations.isActive &&
                                                (this.props.retailer.informations.isAllowedToAcceptTownCoupons ||
                                                    this.props.retailer.informations.sellsAndRedeemOwnCoupons);
                                            break;
                                        case "activate":
                                            userRightOk = this.props.retailer.informations.isActive;
                                            break;
                                        case "transactions":
                                            userRightOk =
                                                this.props.retailer.informations.isActive ||
                                                this.props.retailer.informations.isAllowedToAcceptTownCoupons ||
                                                this.props.retailer.informations.sellsAndRedeemOwnCoupons;
                                            break;
                                        default:
                                            break;
                                    }

                                    this.setState({
                                        haveAccess: userRightOk,
                                        loaded: true,
                                        redirectPath: "/account",
                                    });
                                })
                                .catch((err) => {
                                    this.setState({
                                        haveAccess: false,
                                        loaded: true,
                                    });
                                });
                        })
                        .catch(() => {
                            this.setState({
                                haveAccess: false,
                                loaded: true,
                            });
                        });
                } else {
                    this.setState({
                        haveAccess: false,
                        loaded: true,
                    });
                }
            } else {
                this.setState({
                    haveAccess: false,
                    loaded: true,
                });
            }
        } else {
            this.setState({
                haveAccess: false,
                loaded: true,
            });
        }
    }

    render() {
        const { component: Component = null, render: Render = null, ...rest } = this.props;

        if (!this.state.loaded) {
            return (
                <Backdrop open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            );
        } else {
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        this.state.haveAccess ? (
                            Render ? (
                                Render(props)
                            ) : Component ? (
                                <Component {...props} />
                            ) : null
                        ) : (
                            <Redirect to={{ pathname: this.state.redirectPath, state: { from: props.location } }} />
                        )
                    }
                />
            );
        }
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return { user, retailer };
}

export default connect(mapStateToProps)(PrivateRoute);
