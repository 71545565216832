import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import handleGoogleData from "../../helpers/GoogleDataHelper";
import {
    checkRetailerAvailable,
    getGeometryDataFromGoogle,
    getGoogleData,
    getGoogleDataWithPlacesId,
    getStateDataFromGoogle,
    getTownDataFromGoogle,
} from "../../store/actions/retailerActions";
import { store } from "../../store/store";
import "./NewStore.scss";

class NewStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            googleData: false,
            error: false,
            errorText: "",
            name: "",
            town: "",
            state: "",
            street: "",
            houseNumber: "",
            showPopup: false,
            googlePlaces: [],
            showSpinner: false,
            sellsAndRedeemOwnCoupons: false,
            redeemCityCoupons: false,
            finalRetailer: undefined,
            retailerIsChecked: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleGoogleStoreClick = this.handleGoogleStoreClick.bind(this);
        this.handleRetailerInformationsChange = this.handleRetailerInformationsChange.bind(this);
        this.handleChooseTempRetailer = this.handleChooseTempRetailer.bind(this);
        this.handleRetailerNameChange = this.handleRetailerNameChange.bind(this);
        this.handleCreation = this.handleCreation.bind(this);
    }

    handleSubmit(values) {
        this.setState({ showSpinner: true });
        if (!this.state.retailerIsChecked) {
            if (values.googleData) {
                store
                    .dispatch(getGoogleData(values.name, values.town, values.street, values.houseNumber))
                    .then((response) => {
                        if (response.candidates.length === 0) {
                            this.setState({
                                error: true,
                                errorText: "Es wurden keine Geschäft mit diesen Daten bei Google gefunden",
                                showSpinner: false,
                            });
                        } else {
                            this.setState({ showSpinner: false });
                            store
                                .dispatch(getGeometryDataFromGoogle(values.town, values.street, values.houseNumber))
                                .then((data) => {
                                    var localData = handleGoogleData(data.results[0], values.name, true);
                                    this.setState({ showSpinner: false });
                                    if (
                                        localData.street !== "" &&
                                        localData.houseNumber !== "" &&
                                        localData.state !== ""
                                    ) {
                                        this.setState({
                                            googlePlaces: response.candidates,
                                            tempRetailer: localData,
                                            showPopup: true,
                                            googleData: values.googleData,
                                            sellsAndRedeemOwnCoupons: values.sellsAndRedeemOwnCoupons,
                                            redeemCityCoupons: values.redeemCityCoupons,
                                        });
                                    } else {
                                        this.setState({
                                            error: true,
                                            errorText: "Fehler beim Laden der Google Daten",
                                            showSpinner: false,
                                        });
                                    }
                                })
                                .catch((err) => {
                                    this.setState({
                                        error: true,
                                        errorText: err.message,
                                        showSpinner: false,
                                    });
                                });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            error: true,
                            errorText: err.message,
                            showSpinner: false,
                        });
                    });
            } else {
                store
                    .dispatch(getGeometryDataFromGoogle(values.town, values.street, values.houseNumber))
                    .then((data) => {
                        var localData = handleGoogleData(data.results[0], values.name, true);
                        this.setState({ showSpinner: false });
                        if (localData.street !== "" && localData.houseNumber !== "" && localData.state !== "") {
                            this.setState({
                                street: localData.street,
                                state: localData.state,
                                houseNumber: localData.houseNumber,
                                name: values.name,
                                town: localData.town,
                                showSpinner: false,
                                finalRetailer: localData,
                                retailerIsChecked: true,
                                googleData: values.googleData,
                                sellsAndRedeemOwnCoupons: values.sellsAndRedeemOwnCoupons,
                                redeemCityCoupons: values.redeemCityCoupons,
                            });
                        } else {
                            this.setState({
                                error: true,
                                errorText: "Fehler beim Laden der Google Daten",
                                showSpinner: false,
                            });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            error: true,
                            errorText: err.message,
                            showSpinner: false,
                        });
                    });
            }
        } else {
            var finalRetailer = this.state.finalRetailer;
            finalRetailer.sellsAndRedeemOwnCoupons = values.sellsAndRedeemOwnCoupons;
            finalRetailer.redeemCityCoupons = values.redeemCityCoupons;
            finalRetailer.name = values.name;
            this.setState(
                {
                    finalRetailer: finalRetailer,
                },
                this.handleCreation
            );
        }
    }

    handleClose() {
        this.setState({
            showPopup: false,
            name: this.state.tempRetailer.name,
            town: this.state.tempRetailer.town,
            state: this.state.tempRetailer.state,
            street: this.state.tempRetailer.street,
            houseNumber: this.state.tempRetailer.houseNumber,
        });
    }

    handleGoogleStoreClick(place) {
        this.setState({
            showSpinner: true,
        });
        store
            .dispatch(getGoogleDataWithPlacesId(place.place_id))
            .then((response) => {
                var data = handleGoogleData(response.result, response.result.name, true);
                this.setState({
                    showSpinner: false,
                    finalRetailer: data,
                    showPopup: false,
                    retailerIsChecked: true,
                    name: data.name,
                    town: data.town,
                    state: data.state,
                    street: data.street,
                    houseNumber: data.houseNumber,
                });
            })
            .catch((err) => this.setState({ showSpinner: false, showPopup: false }));
    }

    handleChooseTempRetailer() {
        this.setState({
            spinner: false,
            finalRetailer: this.state.tempRetailer,
            showPopup: false,
            retailerIsChecked: true,
        });
    }

    handleRetailerInformationsChange() {
        this.setState({
            retailerIsChecked: false,
        });
    }

    handleRetailerNameChange(e) {}

    handleCreation() {
        this.setState({ showSpinner: true });
        store
            .dispatch(getTownDataFromGoogle(this.state.town, this.state.state, this.state.country))
            .then((googleTownData) => {
                var townData = handleGoogleData(googleTownData.results[0], this.state.town, false, false, false);
                store
                    .dispatch(getStateDataFromGoogle(townData.state, townData.country))
                    .then((googleStateData) => {
                        var stateData = handleGoogleData(
                            googleStateData.results[0],
                            townData.state,
                            false,
                            false,
                            false
                        );
                        this.setState(
                            {
                                finalRetailer: {
                                    ...this.state.finalRetailer,
                                    parentRetailersId: this.props.retailer.informations
                                        ? this.props.retailer.informations.retailersId
                                        : " ",
                                    paidToParent: this.props.retailer.informations ? true : false,
                                },
                            },
                            () => {
                                store
                                    .dispatch(checkRetailerAvailable(this.state.finalRetailer))
                                    .then((response) => {
                                        this.setState({ showSpinner: false });
                                        this.props.history.push("/account/new-store-managing-director", {
                                            localData: this.state.finalRetailer,
                                            townData: townData,
                                            stateData: stateData,
                                        });
                                    })
                                    .catch((err) => {
                                        this.setState({
                                            error: true,
                                            errorText:
                                                "Diesen Händler gibt es bereits. Bitte wende dich an unseren Support",
                                            showSpinner: false,
                                        });
                                    });
                            }
                        );
                    })
                    .catch((err) => {
                        this.setState({
                            error: true,
                            errorText: "Diesen Händler gibt es bereits. Bitte wende dich an unseren Support",
                            showSpinner: false,
                        });
                    });
            })
            .catch((err) => {
                this.setState({
                    error: true,
                    errorText: "Diesen Händler gibt es bereits. Bitte wende dich an unseren Support",
                    showSpinner: false,
                });
            });
    }

    render() {
        return (
            <div className="NewStore">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <Typography variant="h6">Ihr Unternehmen</Typography>
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: this.state.name,
                        town: this.state.town,
                        street: this.state.street,
                        houseNumber: this.state.houseNumber,
                        state: this.state.state,
                        googleData: this.state.googleData,
                        sellsAndRedeemOwnCoupons: this.state.sellsAndRedeemOwnCoupons,
                        redeemCityCoupons: this.state.redeemCityCoupons,
                    }}
                    key={this.state.retailerIsChecked}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required("Dieses Feld wird benötigt"),
                        town: Yup.string().required("Dieses Feld wird benötigt"),
                        street: Yup.string().required("Dieses Feld wird benötigt"),
                        houseNumber: Yup.string().required("Dieses Feld wird benötigt"),
                    })}
                    onSubmit={(values) => {
                        this.setState({
                            showSpinner: true,
                            error: false,
                            errorText: "",
                        });
                        this.handleSubmit(values);
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name des Unternehmens / der Filiale"
                                        value={props.values.name}
                                        onChange={(e) => {
                                            props.handleChange(e);
                                            this.handleRetailerNameChange(e);
                                        }}
                                        error={props.errors.name ? true : false}
                                        helperText={props.errors.name}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="street"
                                        name="street"
                                        label="Straße"
                                        value={props.values.street}
                                        onChange={(e) => {
                                            props.handleChange(e);
                                            this.handleRetailerInformationsChange();
                                        }}
                                        error={props.errors.street ? true : false}
                                        helperText={props.errors.street}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="houseNumber"
                                        name="houseNumber"
                                        label="Hausnummer"
                                        value={props.values.houseNumber}
                                        onChange={(e) => {
                                            props.handleChange(e);
                                            this.handleRetailerInformationsChange();
                                        }}
                                        error={props.errors.houseNumber ? true : false}
                                        helperText={props.errors.houseNumber}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="town"
                                        name="town"
                                        label="Stadt"
                                        value={props.values.town}
                                        onChange={(e) => {
                                            props.handleChange(e);
                                            this.handleRetailerInformationsChange();
                                        }}
                                        error={props.errors.town ? true : false}
                                        helperText={props.errors.town}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="state"
                                        name="state"
                                        label="Bundesland (optional)"
                                        value={props.values.state}
                                        onChange={(e) => {
                                            props.handleChange(e);
                                            this.handleRetailerInformationsChange();
                                        }}
                                        error={props.errors.state ? true : false}
                                        helperText={props.errors.state}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={props.values.googleData}
                                                onChange={props.handleChange}
                                                name="googleData"
                                            />
                                        }
                                        label="Weitere Informationen von Google abrufen (Z.B. Bewertungen)"
                                        style={{ textAlign: "left" }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={props.values.sellsAndRedeemOwnCoupons}
                                                onChange={props.handleChange}
                                                name="sellsAndRedeemOwnCoupons"
                                            />
                                        }
                                        label="Eigene Gutscheine über die Keep Local Plattform verkaufen"
                                        style={{ textAlign: "left" }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={props.values.redeemCityCoupons}
                                                onChange={props.handleChange}
                                                name="redeemCityCoupons"
                                            />
                                        }
                                        label="Am Stadtgutscheinsystem teilnehmen"
                                        style={{ textAlign: "left" }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", position: "relative" }}
                                >
                                    <Button
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {this.state.retailerIsChecked ? "Weiter" : "Daten überprüfen"}
                                    </Button>
                                    {this.state.showSpinner && <CircularProgress size={24} className="Spinner" />}
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.showPopup}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Händler wählen
                    </DialogTitle>
                    <DialogContent dividers>
                        <List>
                            {this.state.googlePlaces &&
                                this.state.googlePlaces.map((place) => {
                                    return (
                                        <ListItem
                                            button
                                            onClick={() => this.handleGoogleStoreClick(place)}
                                            key={place.place_id}
                                        >
                                            <ListItemText>
                                                {place.name}, {place.formatted_address}
                                            </ListItemText>
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={this.handleClose} color="primary">
                            Abbrechen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(NewStore);
