import React, { Component } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../helpers/PrivateRoute";
import AdministrationLayout from "../../layout/AdminstrationLayout/AdministrationLayout";
import Appearance from "./Appearance/Appearance";
import Information from "./Information/Information";
import TradingLicence from "./TradingLicence/TradingLicence";
import OpeningHours from "./OpeningHours/OpeningHours";
import PhotoUpload from "./Information/PhotoUpload/PhotoUpload";

export default class StoreSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headerTitle: "",
        };

        this.setHeaderTitle = this.setHeaderTitle.bind(this);
    }

    setHeaderTitle(title) {
        this.setState({
            headerTitle: title,
        });
    }

    render() {
        return (
            <AdministrationLayout headerTitle={this.state.headerTitle}>
                <Switch>
                    <PrivateRoute
                        right={3}
                        path={"/store-settings/information"}
                        render={() => <Information setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={3}
                        path={"/store-settings/gewerbeschein"}
                        render={() => <TradingLicence setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={3}
                        path={"/store-settings/erscheinungsbild"}
                        render={() => <Appearance setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={3}
                        path={"/store-settings/oeffnungszeiten"}
                        render={() => <OpeningHours setHeaderTitle={this.setHeaderTitle} />}
                    />
                    <PrivateRoute
                        right={3}
                        path={"/store-settings/Fotos"}
                        render={() => <PhotoUpload setHeaderTitle={this.setHeaderTitle} />}
                    />
                </Switch>
            </AdministrationLayout>
        );
    }
}
