import {
    CircularProgress,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    activateCoupon,
    getCouponWithActivationcode,
    REMOVE_COUPON_FROM_LIST,
} from "../../../store/actions/couponActions";
import { store } from "../../../store/store";

class CouponListItem extends Component {
    constructor() {
        super();

        this.state = {
            error: false,
            errorText: "",
            couponDetails: null,
            statusText: "Lade Details vom Gutschein",
            message: "",
        };

        this.loadCoupon = this.loadCoupon.bind(this);
        this.removeCouponFromList = this.removeCouponFromList.bind(this);
        this.couponTitle = this.couponTitle.bind(this);
        this.showCouponDetails = this.showCouponDetails.bind(this);
    }

    componentDidMount() {
        this.loadCoupon();
    }

    loadCoupon() {
        this.setState({
            error: false,
            errorText: "",
            couponDetails: null,
            statusText: "Lade Details vom Gutschein",
        });
        const { coupon, currentStartValue } = this.props;
        if (coupon) {
            store
                .dispatch(getCouponWithActivationcode(coupon.activationcode))
                .then((response) => {
                    if (response !== null) {
                        this.setState({
                            couponDetails: response,
                        });

                        if (response.isValid && !response.isActive) {
                            console.log(response.startValue, currentStartValue);
                            if (!response.startValue && !currentStartValue) {
                                this.setState({
                                    error: true,
                                    errorText: "Kein Gutscheinwert festgelegt.",
                                });
                            } else {
                                this.setState({
                                    statusText: "Aktiviere Gutschein",
                                });
                                store
                                    .dispatch(
                                        activateCoupon(
                                            coupon.activationcode,
                                            this.props.retailer.informations.retailersId,
                                            response.startValue === null ? Number(currentStartValue) : undefined
                                        )
                                    )
                                    .then((response) => {
                                        this.props.addActivatedCouponToSet(coupon.activationcode);
                                        this.setState({
                                            statusText: "Gutschein aktiviert",
                                            message: response.message,
                                            couponDetails: {
                                                ...this.state.couponDetails,
                                                startValue:
                                                    this.state.couponDetails.startValue === null
                                                        ? currentStartValue
                                                        : this.state.couponDetails.startValue,
                                            },
                                        });
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        this.props.addCouponWithErrorToSet(coupon.activationcode);
                                        this.setState({
                                            error: true,
                                            errorText: "Fehler beim Aktivieren des Gutscheins",
                                        });
                                    });
                            }
                        }

                        if (response.isValid && response.isActive) {
                            this.props.addActivatedCouponToSet(coupon.activationcode);
                            this.setState({
                                statusText: "Gutschein aktiviert",
                            });
                        }

                        if (!response.isValid) {
                            this.props.addCouponWithErrorToSet(coupon.activationcode);
                            this.setState({
                                error: true,
                                errorText: "Gutscheins nicht gültig",
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.props.addCouponWithErrorToSet(coupon.activationcode);
                    this.setState({
                        error: true,
                        errorText: "Fehler beim Laden der Details vom Gutschein",
                    });
                });
        }
    }

    removeCouponFromList() {
        const { coupon, deleteCouponWithErrorFromSet } = this.props;

        store.dispatch({ type: REMOVE_COUPON_FROM_LIST, coupon: coupon });
        deleteCouponWithErrorFromSet(coupon.activationcode);
    }

    couponTitle() {
        const { couponDetails } = this.state;
        const { coupon } = this.props;
        let title = coupon.activationcode;

        if (couponDetails) {
            title = (
                <Typography component="span">
                    {!couponDetails.startValue && <span>{couponDetails.Retailer.name}</span>}
                    {couponDetails.startValue && (
                        <span>{couponDetails.Retailer.name + " - " + couponDetails.startValue}</span>
                    )}
                    <Typography component="span" color="textSecondary" style={{ fontSize: "12px" }}>
                        {" (" + coupon.activationcode + ")"}
                    </Typography>
                </Typography>
            );
        }

        return title;
    }

    showCouponDetails() {
        const { couponDetails, message } = this.state;

        this.props.history.push("/administration/activate-coupon-success", {
            couponDetails: couponDetails,
            couponValue: couponDetails.currentValue,
            message: message,
        });
    }

    render() {
        const { showDivider } = this.props;
        const { couponDetails, error, errorText, statusText } = this.state;
        return (
            <React.Fragment>
                <ListItem>
                    <ListItemIcon>
                        {!couponDetails && !error && <CircularProgress size={20} />}
                        {error && <ErrorIcon color="error" />}
                        {couponDetails && !error && <CheckCircleIcon color="secondary" />}
                    </ListItemIcon>
                    <ListItemText
                        primary={this.couponTitle()}
                        secondary={error ? errorText : statusText}
                        secondaryTypographyProps={{ color: error ? "error" : "textSecondary" }}
                    />
                    <ListItemSecondaryAction>
                        {error && (
                            <IconButton edge="end" title="Löschen" onClick={this.removeCouponFromList}>
                                <DeleteIcon />
                            </IconButton>
                        )}
                        {/* {error && (
                            <IconButton edge="end" title="Nochmal versuchen" onClick={this.loadCoupon}>
                                <ReplayIcon />
                            </IconButton>
                        )} */}
                        {couponDetails !== null && !error && (
                            <IconButton
                                edge="end"
                                title="Details"
                                onClick={() => {
                                    if (couponDetails !== null) {
                                        this.showCouponDetails();
                                    }
                                }}
                            >
                                <InfoIcon />
                            </IconButton>
                        )}
                    </ListItemSecondaryAction>
                </ListItem>
                {showDivider && <Divider />}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withRouter(CouponListItem));
