import { API } from "aws-amplify";

export function getCouponDetails(barcode) {
    let message = {
        body: { barcode: barcode },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getCouponDetails", message);
    };
}

export function getCouponWithActivationcode(activationcode) {
    let message = {
        body: { activationcode: activationcode },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/getCouponWithActivationcode", message);
    };
}

export function orderEmployeeCards(retailersId, amount, blanko, contactPerson, text, image) {
    let message = {
        body: {
            retailersId: retailersId,
            amount: amount,
            blanko: blanko,
            contactPerson: contactPerson,
            text: text,
            image: image,
        },
    };

    return function (dispatch) {
        return API.post("couponEmployee", "/orderEmployeeCards", message);
    };
}

export function getCouponEmployeeOrderByRetailersId(retailersId) {
    let message = {
        body: {
            retailersId: retailersId,
        },
    };
    return function (dispatch) {
        return API.post("couponEmployee", "/getCouponEmployeeOrderByRetailersId", message).then((json) => {
            return json;
        });
    };
}

export function activateCoupon(activationcode, retailersId, value) {
    let message = {
        body: { activationcode: activationcode, retailersId: retailersId, value: value },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/retailer/activateCoupon", message);
    };
}

export const ADD_COUPON_TO_LIST = "ADD_COUPON_TO_LIST";
export function addCouponToList(coupon) {
    return {
        type: ADD_COUPON_TO_LIST,
        coupon: coupon,
    };
}

export const REMOVE_COUPON_FROM_LIST = "REMOVE_COUPON_FROM_LIST";
export function removeCouponFromList(coupon) {
    return {
        type: REMOVE_COUPON_FROM_LIST,
        coupon: coupon,
    };
}

export const CLEAR_COUPON_LIST = "CLEAR_COUPON_LIST";
export function clearCouponList() {
    return {
        type: CLEAR_COUPON_LIST,
    };
}

export function printTransaction({ endDate, startDate, retailersId, transactionType, userType }) {
    let message = {
        body: {
            endDate: endDate,
            startDate: startDate,
            retailersId: retailersId,
            transactionType: transactionType,
            userType: userType,
        },
    };
    return function (dispatch) {
        return API.post("localHeroesRetailer", "/getTransactionList", message);
    };
}
