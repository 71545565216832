import { Box, Grid } from "@material-ui/core";
import { Storage } from "aws-amplify";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import { updateRetailerAppearance } from "../../../store/actions/retailerActions";
import { store } from "../../../store/store";
import Preview from "./Preview";
import SettingsForm from "./SettingsForm";

class Appearance extends Component {
    constructor() {
        super();

        this.state = {
            fontColor: "#000000",
            backgroundColor: "#FFFFFF",
            image: null,
        };

        this.handleSetFontColor = this.handleSetFontColor.bind(this);
        this.handleSetBackgroundColor = this.handleSetBackgroundColor.bind(this);
        this.handleSetColorsAndUpdate = this.handleSetColorsAndUpdate.bind(this);
        this.handleSetImage = this.handleSetImage.bind(this);
        this.update = this.update.bind(this);
    }

    componentDidMount() {
        this.props.setHeaderTitle("Erscheinungsbild");

        const { fontColor, backgroundColor, imageUrl } = this.props.retailer.informations;
        if (this.props.retailer) {
            this.setState({
                fontColor: fontColor,
                backgroundColor: backgroundColor,
                image: imageUrl,
            });
        }
    }

    handleSetFontColor(e) {
        this.setState({ fontColor: e.hex });
    }

    handleSetBackgroundColor(e) {
        this.setState({ backgroundColor: e.hex });
    }

    handleSetColorsAndUpdate(values) {
        this.setState(
            {
                fontColor: values.fontColor,
                backgroundColor: values.backgroundColor,
            },
            this.update
        );
    }

    handleSetImage(e, update = true) {
        this.setState({ image: e }, () => {
            if (update) {
                this.update();
            }
        });
    }

    update() {
        var oldPicture = this.props.retailer.informations.imageUrl;
        store
            .dispatch(
                updateRetailerAppearance(
                    this.props.retailer.informations.retailersId,
                    this.state.backgroundColor,
                    this.state.fontColor,
                    this.state.image.split("?")[0]
                )
            )
            .then((response) => {
                Storage.remove(oldPicture)
                    .then((response) => {
                        this.props.enqueueSnackbar("Die Daten wurden erfolgreich aktualisiert", {
                            variant: "success",
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.props.enqueueSnackbar("Die Daten wurden erfolgreich aktualisiert", {
                            variant: "success",
                        });
                    });
            })
            .catch((err) => {
                console.log(err);
                this.props.enqueueSnackbar("Es gab ein Fehler beim aktualisieren der Daten", {
                    variant: "error",
                });
            });
    }

    render() {
        return (
            <Grid container spacing={2} justify="center" style={{ textAlign: "left" }} className="appearance">
                <Grid item xs={12} sm={12} md={4}>
                    <Box p={2} pl={1} display="flex" alignItems="center">
                        <SettingsForm
                            fontColor={this.state.fontColor}
                            backgroundColor={this.state.backgroundColor}
                            image={this.state.image}
                            handleSetFontColor={this.handleSetFontColor}
                            handleSetBackgroundColor={this.handleSetBackgroundColor}
                            handleSetColorsAndUpdate={this.handleSetColorsAndUpdate}
                            handleSetImage={this.handleSetImage}
                            retailer={this.props.retailer}
                            update={this.update}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Box p={2} pr={1} display="flex" alignItems="center" justifyContent="flex-start">
                        <Preview
                            fontColor={this.state.fontColor}
                            backrgoundColor={this.state.backgroundColor}
                            image={this.state.image}
                        />
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withSnackbar(Appearance));
