import React, { Component } from 'react'
import { Box, Grid, Paper, Icon, Typography } from '@material-ui/core'
import CheckCircle from '@material-ui/icons/CheckCircle';

export default class ChangePasswordSuccess extends Component {
    render() {
        return (
            <Box className="RedeemCoupon">
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={12} sm={12} md={7} lg={5} xl={3}>
                        <Box m={2}>
                            <Paper>
                                <Box p={2}>
                                    <Grid container spacing={2} alignItems="center" justify='space-between'>
                                        <Grid item style={{ flexGrow: 1 }}>
                                            <div>
                                                <Typography gutterBottom variant="h5" component="h3">
                                                    Passwort erfolgreich geändert
                                                </Typography>
                                                <Icon fontSize='large'>
                                                    <CheckCircle fontSize='large' style={{ fill: "green" }} />
                                                </Icon>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}
