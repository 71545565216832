import React from 'react';
import { connect } from 'react-redux'
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import QRCode from 'qrcode.react'
import { Typography, Grid, Box } from '@material-ui/core';

class NewEmployeeQrCode extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        var qrCodeObject = this.props.qrCodeObject;
        return (
            <Box>
                {qrCodeObject !== '' &&
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography>Du kannst Dich über diesen QR-Code zur Filiale {this.props.retailerName} hinzufügen.</Typography>
                            <Box textAlign="center" mb={1} mt={2}>
                                <QRCode
                                    value={JSON.stringify(qrCodeObject)}
                                    size={200}
                                    fgColor="black"
                                    bgColor="white"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>Alternativ kannst Du diesen Code eingeben.</Typography>
                            <Box bgcolor="grey.200" textAlign="center" mt={2} p={2}>
                                <Typography variant="h5" noWrap={true}>
                                    {qrCodeObject.QRCode}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                }
                {!qrCodeObject === '' &&
                    <Typography>Bitte wähle zuerst die passenden Rechte, um dir einen QR Code erzeugen zu lassen.</Typography>}
            </Box>
        )
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state
    return {
        user, retailer
    }
}

export default connect(mapStateToProps)(withRouter(NewEmployeeQrCode))