import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardMedia, CardContent, Typography, Button, Box, Icon } from "@material-ui/core";
import "./ActivateCoupon.scss"
import CheckCircle from '@material-ui/icons/CheckCircle';

class ActivateCouponSuccess extends Component {
    constructor(props) {
        super(props)

        this.state = {
            couponDetails: props.location.state['couponDetails'],
            couponValue: props.location.state['couponValue'],
            message: props.location.state['message'],
        }

        this.handleDoneClick = this.handleDoneClick.bind(this);
    }

    handleDoneClick() {
        this.props.history.push('/administration/activate-coupon');
    }

    render() {
        return (
            <div className="cardContainer">
                <div style={{ marginTop: 20 }}>
                    <Card className="card">
                        <CardMedia
                            component="img"
                            height="140"
                            image={this.state.couponDetails.Retailer.imageUrl}
                            title={this.state.couponDetails.Retailer.name}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {this.state.couponDetails.Retailer.name}
                            </Typography>
                            <Typography gutterBottom variant="h3" component="h1">
                                {this.state.couponValue}&nbsp;€
                            </Typography>
                            <Icon fontSize='large'>
                                <CheckCircle fontSize='large' style={{ fill: "green" }} />
                            </Icon>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {this.state.message}
                            </Typography>
                            <Box textAlign="right" position="relative">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleDoneClick}
                                    style={{ marginTop: 10 }}
                                >
                                    Zurück
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user, retailer
    };
}
export default connect(mapStateToProps)(withRouter(ActivateCouponSuccess));