import { Box, Card, CardContent, CardHeader, Grid, TextField, Typography } from "@material-ui/core";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import FormatColorTextIcon from "@material-ui/icons/FormatColorText";
import { Storage } from "aws-amplify";
import { Form, Formik } from "formik";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { ChromePicker } from "react-color";
import Dropzone from "react-dropzone";
import * as Yup from "yup";

class SettingsForm extends Component {
    constructor() {
        super();

        this.state = {
            showFontColorPicker: false,
            showBackgroundColorPicker: false,
            logo: "",
        };

        Storage.configure({
            Storage: {
                AWSS3: {
                    bucket: "localheroesretailer-retailer-logos-master",
                },
            },
        });

        this.formRef = React.createRef();
        this.setDefaultColors = true;

        this.handleChangeFontColor = this.handleChangeFontColor.bind(this);
        this.handleChangeBackgroundColor = this.handleChangeBackgroundColor.bind(this);

        this.uploadImage = this.uploadImage.bind(this);
        this.uploadImageRejected = this.uploadImageRejected.bind(this);

        this.getAWSImageUrl = this.getAWSImageUrl.bind(this);
    }

    handleChangeFontColor(e) {
        this.props.handleSetFontColor(e);
    }

    handleChangeBackgroundColor(e) {
        this.props.handleSetBackgroundColor(e);
    }

    uploadImageRejected(e) {
        let errors = e[0].errors;
        var errorText = "Dateityp wurde nicht akzeptiert. Bitte nur *.pdf-Dateien, *.jpg- und *.png-Bilder verwenden";

        switch (errors[0].code) {
            case "file-too-large":
                errorText = "Datei ist zu groß!";
                break;

            default:
                break;
        }

        this.props.enqueueSnackbar(errorText, {
            variant: "error",
        });
    }

    uploadImage(e) {
        console.log(e);
        if (e[0] && this.props.retailer) {
            let timestamp = new Date().getTime();
            let fileType = e[0].type.split("/");
            let fileName = this.props.retailer.informations.name + "_logo_" + timestamp + "." + fileType[1];

            Storage.put(fileName, e[0], {
                contentType: e[0].type,
                level: "public",
            })
                .then((result) => {
                    var licenceKey = result.key;

                    Storage.get(licenceKey)
                        .then((result) => {
                            this.setState({ logo: result });

                            this.props.handleSetImage(result);
                        })
                        .catch((err) => {
                            console.log(err);
                            this.props.enqueueSnackbar(
                                "Fehler beim Hochladen der Datei. Bitte wende dich an unseren Support.",
                                {
                                    variant: "error",
                                }
                            );
                        });
                })
                .catch((err) => {
                    console.log(err);
                    this.props.enqueueSnackbar(
                        "Fehler beim Hochladen der Datei. Bitte wende dich an unseren Support.",
                        {
                            variant: "error",
                        }
                    );
                });
        }
    }

    getAWSImageUrl() {
        if (!this.state.logo && this.props.image) {
            if (typeof this.props.image === "string") {
                var key = this.props.image.split("/");
                Storage.get(key[key.length - 1])
                    .then((result) => {
                        this.setState({ logo: result });
                        this.props.handleSetImage(result, false);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.setDefaultColors) {
            this.formRef.current.setFieldValue("fontColor", this.props.fontColor);
            this.formRef.current.setFieldValue("backgroundColor", this.props.backgroundColor);
            this.formRef.current.validateForm();
            this.setDefaultColors = false;
        }

        if (this.props.image !== prevProps.image) {
            this.getAWSImageUrl();
        }
    }

    render() {
        console.log(this.state.logo);
        const FILE_SIZE = 100 * 1024;
        const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

        const cover = {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
        };
        return (
            <Card>
                <CardHeader
                    title={
                        <Box display="flex" alignItems="center">
                            <ColorLensIcon style={{ marginRight: 5 }} /> Erscheinungsbild
                        </Box>
                    }
                />
                <CardContent>
                    <Formik
                        innerRef={this.formRef}
                        initialValues={{
                            fontColor: this.props.fontColor,
                            backgroundColor: this.props.backgroundColor,
                        }}
                        validationSchema={Yup.object().shape({
                            fontColor: Yup.string()
                                .required("Dieses Feld wird benötigt")
                                .matches("^#(?:[0-9a-fA-F]{3}){1,2}$", "Kein gültiger Farbcode"),
                            backgroundColor: Yup.string()
                                .required("Dieses Feld wird benötigt")
                                .matches("^#(?:[0-9a-fA-F]{3}){1,2}$", "Kein gültiger Farbcode"),
                        })}
                        onSubmit={(values) => {
                            this.props.handleSetColorsAndUpdate(values);
                        }}
                        validateOnChange={true}
                    >
                        {(props) => (
                            <Form>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                                <FormatColorTextIcon
                                                    fontSize="small"
                                                    onClick={() => {
                                                        this.setState({ showFontColorPicker: true });
                                                    }}
                                                    style={{
                                                        color: this.props.fontColor,
                                                        backgroundColor: "#eee",
                                                        padding: "3px",
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id="fontColor"
                                                    name="fontColor"
                                                    label="Textfarbe"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        props.handleChange(e);
                                                    }}
                                                    value={props.values.fontColor}
                                                    fullWidth
                                                    error={props.errors.fontColor ? true : false}
                                                    onBlur={props.handleSubmit}
                                                />
                                            </Grid>
                                        </Grid>
                                        {this.state.showFontColorPicker && (
                                            <div style={{ position: "absolute", zIndex: 2, marginTop: "10px" }}>
                                                <div
                                                    style={cover}
                                                    onClick={() => {
                                                        this.setState({ showFontColorPicker: false });
                                                        this.formRef.current.setFieldValue(
                                                            "fontColor",
                                                            this.props.fontColor
                                                        );
                                                        props.handleSubmit();
                                                    }}
                                                ></div>
                                                <ChromePicker
                                                    color={this.props.fontColor}
                                                    onChange={this.handleChangeFontColor}
                                                    disableAlpha
                                                />
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                                <FormatColorFillIcon
                                                    fontSize="small"
                                                    onClick={() => {
                                                        this.setState({ showBackgroundColorPicker: true });
                                                    }}
                                                    style={{
                                                        color: this.props.backgroundColor,
                                                        backgroundColor: "#eee",
                                                        padding: "3px",
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id="backgroundColor"
                                                    name="backgroundColor"
                                                    label="Hintergrundfarbe"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        props.handleChange(e);
                                                    }}
                                                    value={props.values.backgroundColor}
                                                    fullWidth
                                                    error={props.errors.backgroundColor ? true : false}
                                                    onBlur={props.handleSubmit}
                                                />
                                            </Grid>
                                        </Grid>
                                        {this.state.showBackgroundColorPicker && (
                                            <div style={{ position: "absolute", zIndex: 2, marginTop: "10px" }}>
                                                <div
                                                    style={cover}
                                                    onClick={() => {
                                                        this.setState({ showBackgroundColorPicker: false });
                                                        this.formRef.current.setFieldValue(
                                                            "backgroundColor",
                                                            this.props.backgroundColor
                                                        );
                                                        props.handleSubmit();
                                                    }}
                                                ></div>
                                                <ChromePicker
                                                    color={this.props.backgroundColor}
                                                    onChange={this.handleChangeBackgroundColor}
                                                    disableAlpha
                                                />
                                            </div>
                                        )}
                                        <Grid item xs={12}>
                                            <Box mt={2}>
                                                <Typography variant="subtitle1">Logo</Typography>
                                                <Dropzone
                                                    accept={SUPPORTED_FORMATS}
                                                    onDropRejected={this.uploadImageRejected}
                                                    maxSize={FILE_SIZE}
                                                    multiple={false}
                                                    onDrop={this.uploadImage}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section
                                                            className={
                                                                this.state.error
                                                                    ? "dropzoneContainer error"
                                                                    : "dropzoneContainer"
                                                            }
                                                        >
                                                            <div {...getRootProps({ className: "dropzone" })}>
                                                                <input {...getInputProps()} />
                                                                {this.props.image === null && (
                                                                    <React.Fragment>
                                                                        <Typography variant="body1">
                                                                            Ziehen Sie Dateien per Drag 'n' Drop hierher
                                                                            oder klicken Sie, um Dateien auszuwählen.
                                                                        </Typography>
                                                                        <Typography variant="body2">
                                                                            (Nur *.jpg- und *.png-Bilder werden
                                                                            akzeptiert)
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                )}
                                                                {this.props.image && (
                                                                    <React.Fragment>
                                                                        <Box>
                                                                            {this.state.logo && (
                                                                                <img
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        maxWidth: "100%",
                                                                                        height: "auto",
                                                                                    }}
                                                                                    alt="Logo"
                                                                                    src={this.state.logo}
                                                                                />
                                                                            )}
                                                                        </Box>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        );
    }
}

export default withSnackbar(SettingsForm);
