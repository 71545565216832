import React, { Component } from "react";
import { Dialog, DialogContent, GridList, GridListTile, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import { setFavoriteImage } from "../../../../store/actions/retailerActions";
import { store } from "../../../../store/store";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

class PhotoViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPhoto: false,
            photoKey: "",
            favorite: undefined,
        };

        this.showPhoto = this.showPhoto.bind(this);
        this.isFavourite = this.isFavourite.bind(this);
        this.setFavoriteImage = this.setFavoriteImage.bind(this);
        console.log("favorite", this.props);
    }

    render() {
        return (
            <React.Fragment>
                <Dialog
                    maxWidth="xl"
                    open={this.state.showPhoto}
                    onBackdropClick={() => this.setState({ showPhoto: false })}
                >
                    <DialogContent>
                        <img
                            src={`https://keeplocalretailerimages-develop.s3.eu-central-1.amazonaws.com/public/${this.state.photoKey}`}
                            alt=""
                        />
                        <IconButton
                            style={{
                                color: "red",
                                position: "absolute",
                                backgroundColor: "white",
                                top: "30px",
                                right: "30px",
                            }}
                            onClick={() => this.setState({ showPhoto: false })}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogContent>
                </Dialog>

                <GridList
                    cellHeight={"auto"}
                    cols={5}
                    style={{ margin: "auto", width: "700", height: "auto", overflowY: "auto" }}
                    spacing={10}
                >
                    {this.props.images.length > 0 &&
                        this.props.images.map((image) => {
                            return (
                                <GridListTile
                                    key={image.key}
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        height: "auto",
                                        maxHeight: "300px",
                                        justifyContent: "space-around",
                                        position: "relative",
                                    }}
                                >
                                    <img
                                        onClick={() => {
                                            this.showPhoto(image.key);
                                        }}
                                        alt={image.alt}
                                        src={`https://keeplocalretailerimages-develop.s3.eu-central-1.amazonaws.com/public/${image.key}`}
                                    />
                                    <IconButton
                                        style={{
                                            color: "red",
                                            position: "absolute",
                                            backgroundColor: "white",
                                            top: "5px",
                                            right: "5px",
                                        }}
                                        onClick={() => this.props.delete(image.key)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                        style={{
                                            color: "blue",
                                            position: "absolute",
                                            backgroundColor: "white",
                                            top: "5px",
                                            right: "60px",
                                        }}
                                        onClick={() => this.setFavoriteImage(image)}
                                    >
                                        {this.isFavourite(image) ? <StarIcon /> : <StarOutlineIcon />}
                                    </IconButton>
                                </GridListTile>
                            );
                        })}
                </GridList>
            </React.Fragment>
        );
    }

    isFavourite(image) {
        if (this.props.favorite === image.key) return true;
        else return false;
    }
    setFavoriteImage(image) {
        this.setState({ favorite: image });
        store.dispatch(setFavoriteImage(this.props.retailersId, image.key)).then((result) => {
            console.log(result);
            this.props.enqueueSnackbar("Profilbild erfolgreich ausgewählt", { variant: "success" });
        });
    }

    showPhoto(key, width, height) {
        this.setState({
            showPhoto: true,
            photoKey: key,
        });
    }
}

function mapStateToProps(state) {
    const { retailer } = state;
    return { retailer };
}

export default connect(mapStateToProps)(withSnackbar(PhotoViewer));
