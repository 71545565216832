import { 
    Box, 
    Button, 
    TextField, 
    InputAdornment, 
    Typography, 
    CircularProgress, 
    IconButton, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, 
} from "@material-ui/core";
import { Form, Formik } from "formik";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import * as Yup from "yup";
import { testRedeemCoupon } from "../../../store/actions/retailerActions";
import { store } from "../../../store/store";
import React, { Component } from "react";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import Alert from "@material-ui/lab/Alert";
import "./RedeemForm.scss";
import RedeemWarningDialog from "./RedeemWarningDialog";
import Help from "@material-ui/icons/Help";

class RedeemInsertCoupon extends Component {
    constructor() {
        super();
        this.state = {
            showSpinner: false,
            error: false,
            errorText: "",
            couponsValue: 0,
            scannedCodeCount: 0,
            remainingValue: "",
            transactions: [],
            couponList: [],
            wasEnough: false,
            orderIndex: 0,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeBarcode = this.handleChangeBarcode.bind(this);
        this.onChangeInvoiceValueClick = this.onChangeInvoiceValueClick.bind(this);
        this.closeRedeemWarningPopup = this.closeRedeemWarningPopup.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
        this.formRef = React.createRef();
        this.textfieldRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            couponList: this.props.couponList,
            couponsValue: this.props.couponsValue,
            scannedCodeCount: this.props.scannedCodeCount,
            remainingValue: this.props.remainingValue,
            transactions: this.props.transactions,
            wasEnough: this.props.wasEnough,
            orderIndex: this.props.orderIndex,
            invoiceValue: this.props.invoiceValue,
        });
    }

    toggleDialog() {
        this.setState({
            openDialog: !this.state.openDialog,
        });
    }

    closeRedeemWarningPopup() {
        this.setState({ invoicePaidPopupVisible: false });
        if (this.state.couponList.length > 0 && !this.props.user.quickRedeemModeEnabled) {
            this.props.handleShowConfirm(
                this.state.invoiceValue,
                this.state.couponsValue,
                this.state.remainingValue,
                this.state.wasEnough,
                this.state.couponList,
                this.state.transactions,
                this.state.scannedCodeCount
            )
        } else if (this.props.user.quickRedeemModeEnabled && this.state.invoiceIsPaid) {
            this.props.redeemCouponClick(this.state.couponList, this.state.invoiceValue);
        }
    }

    handleChangeBarcode() {
        const textFieldValue = this.textfieldRef.current.value;
        let barcode = textFieldValue.replace(/\D/g, "");
        if (
            barcode.length === 20 &&
            (barcode.charAt(0) === "1" ||
                barcode.charAt(0) === "2" ||
                barcode.charAt(0) === "3" ||
                barcode.charAt(0) === "4" ||
                barcode.charAt(0) === "5" ||
                barcode.charAt(0) === "8")
        ) {
            if (this.state.couponList.find((coupon) => coupon.barcode === barcode)) {
                this.setState({ error: true, errorText: `Der Gutscheincode ${barcode} wurde bereits eingescannt` });
                return;
            }
            this.setState({
                showSpinner: true,
                errorText: "",
                error: false,
            });
            store
                .dispatch(
                    testRedeemCoupon(barcode, this.state.remainingValue, this.props.retailer.informations.retailersId)
                )
                .then((response) => {
                    if (response.success) {
                        var transactions = this.state.transactions;
                        transactions = transactions.concat(response.transactions);
                        var couponList = this.state.couponList;
                        couponList.push({ barcode: barcode, value: response.couponValue, orderIndex: this.state.orderIndex });
                        if (
                            response.transactions[0].isEmployeeCard || this.props.user.quickRedeemModeEnabled ||
                            (!barcode.startsWith("3") && response.remainingValue <= 0)
                        ) {
                            this.formRef.current.setFieldValue("barcode", "");
                            setTimeout(() => {
                                this.textfieldRef.current.focus();
                            }, 0);
                            this.setState({
                                showSpinner: false,
                                couponList: couponList,
                                couponsValue: this.state.couponsValue + Number(response.couponValue),
                                remainingValue: response.remainingValue.toFixed(2),
                                scannedCodeCount: this.state.scannedCodeCount + 1,
                                wasEnough: response.wasEnough,
                                transactions: transactions,
                                invoicePaidPopupVisible: (!barcode.startsWith("3") && response.remainingValue <= 0) || response.transactions[0].isEmployeeCard,
                                invoiceIsPaid: !barcode.startsWith("3") && response.remainingValue <= 0,
                                couponValueLastCoupon: response.transactions[0].remainingValueCoupon,
                                orderIndex: this.state.orderIndex + 1,
                            });
                        } else {
                            this.props.handleShowConfirm(
                                this.state.invoiceValue,
                                this.state.couponsValue + Number(response.couponValue),
                                response.remainingValue.toFixed(2),
                                response.wasEnough,
                                couponList,
                                transactions,
                                this.state.scannedCodeCount + 1
                            )
                        }
                    } else {
                        this.setState({
                            error: true,
                            errorText: response.message,
                            showSpinner: false,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.setState({
                        error: true,
                        errorText: "Fehler beim Prüfen des Gutscheins. Bitte versuch es erneut.",
                        showSpinner: false,
                    });
                });
        } else {
            this.setState({
                error: true,
                errorText: "Kein Gültiger Barcode",
            });
        }
    }

    handleSubmit(values) {
        this.setState({
            error: false,
            errorText: "",
        });

        values.barcode = values.barcode.replace(/\D/g, "");
        if (
            values.barcode.length === 20 &&
            (values.barcode.charAt(0) === "1" ||
                values.barcode.charAt(0) === "2" ||
                values.barcode.charAt(0) === "3" ||
                values.barcode.charAt(0) === "4" ||
                values.barcode.charAt(0) === "5" ||
                values.barcode.charAt(0) === "8")
        ) {
            this.setState({
                showSpinner: true,
            });
            store
                .dispatch(
                    testRedeemCoupon(values.barcode, values.invoiceValue, this.props.retailer.informations.retailersId)
                )
                .then((response) => {
                    if (response.success) {
                        this.setState({
                            showSpinner: false,
                        });
                    } else {
                        this.setState({
                            error: true,
                            errorText: response.message,
                            showSpinner: false,
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        error: true,
                        errorText: "Fehler beim Prüfen des Gutscheins. Bitte versuch es erneut.",
                        showSpinner: false,
                    });
                });
        } else {
            this.setState({
                error: true,
                errorText: "Kein Gültiger Barcode",
            });
        }
    }

    onChangeInvoiceValueClick() {
        this.props.onChangeInvoiceValueClick(
            this.state.invoiceValue,
            this.state.couponList, 
            this.state.couponsValue, 
            this.state.remainingValue, 
            this.state.scannedCodeCount, 
            this.state.wasEnough, 
            this.state.transactions,
            this.state.invoiceIsPaid,
            this.state.orderIndex,
            2
        );
    }

    render() {
        return (
            <div>
                {(this.props.showSpinner || this.state.showSpinner) && <CircularProgress size={40} style={{color:"#8d8d8d"}}/>}
                <div style={{display: "flex", justifyContent: "end"}}>
                    <div className="countCircle" >
                        <Typography className="countText">{this.state.scannedCodeCount}</Typography>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Typography>Guthaben:</Typography>
                    <Typography>{this.state.couponsValue.toFixed(2).replace(".",",")} €</Typography>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
                    <Typography>Offener Betrag:</Typography>
                    <Typography>{this.state.wasEnough ? "0,00" : Number(this.state.remainingValue).toFixed(2).replace(".",",")} €</Typography>
                </div>
                <Formik
                    initialValues={{
                        barcode: "",
                    }}
                    validationSchema={Yup.object().shape({
                        barcode: Yup.string().min(20, "kein korrekter Gutscheincode").required("Dieses Feld wird benötigt"),
                    })}
                    onSubmit={(values) => {
                        this.handleSubmit(values);
                    }}
                    innerRef={this.formRef}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            {this.state.error && (
                                <Box mb={2}>
                                    <Alert severity="error">{this.state.errorText}</Alert>
                                </Box>
                            )}
                            <Box mb={2} style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <TextField
                                    disabled={this.props.showSpinner || this.state.showSpinner || this.state.wasEnough}
                                    autoComplete="off"
                                    name="barcode"
                                    label="Barcode scannen oder manuell eingeben"
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    className="barcode"                                    
                                    autoFocus={true}
                                    onChange={(e) => { props.handleChange(e); this.handleChangeBarcode()}}                                    
                                    onBlur={(e) => {
                                        props.setFieldValue("barcode", e.target.value.replace(/\D/g, ""));
                                    }}
                                    value={props.values.barcode}
                                    fullWidth
                                    error={props.errors.barcode && props.touched.barcode}
                                    helperText={props.errors.barcode && props.touched.barcode ? props.errors.barcode : ""}
                                    InputProps={{
                                        inputRef: this.textfieldRef,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CardGiftcardIcon fontSize="small" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <label htmlFor="icon-button-file">
                                    <IconButton
                                        size="small"
                                        color="secondary"
                                        aria-label="upload picture"
                                        component="span"
                                        onClick={this.toggleDialog}
                                    >
                                        <Help />
                                    </IconButton>
                                </label>
                            </Box>
                        </Form>
                    )}
                </Formik>
                {this.state.transactions.length > 0 && (
                    <div>
                        <Typography style={{fontWeight: "bold", display: "flex", marginTop: 15, marginBottom: 10}}>
                            {this.state.transactions.length} verwendete
                            {this.state.transactions.length === 1 ? "r" : ""} Gutschein
                            {this.state.transactions.length > 1 ? "e" : ""}
                        </Typography>
                        <div style={{marginBottom: 10}}>
                            {this.state.transactions.map((transaction, index) => {
                                return (
                                    <div key={index} style={{display: "flex", justifyContent: "space-between"}}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <span style={{display: "flex", marginRight: 5}}>
                                                <CardGiftcardIcon
                                                    fontSize="small"
                                                    style={{
                                                        color: transaction.fontColor,
                                                        backgroundColor: transaction.backgroundColor,
                                                        padding: "3px",
                                                        borderRadius: "3px",
                                                    }}
                                                />
                                            </span>
                                            <Typography>
                                                {transaction.name}
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography style={{marginRight: 30}}>
                                                ...
                                                {transaction.barcode.substr(
                                                    transaction.barcode.length - 5
                                                )}
                                            </Typography>
                                            <Typography>
                                                {parseFloat(transaction.value)
                                                    .toFixed(2)
                                                    .replace(".", ",") + " €"}
                                            </Typography>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Typography style={{textDecoration: "underline", color: "green", cursor: "pointer", fontSize: 12}} onClick={this.onChangeInvoiceValueClick}>Betrag ändern</Typography>
                    {this.props.user.quickRedeemModeEnabled && <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={() => this.props.redeemCouponClick(this.state.couponList, this.state.invoiceValue)}
                        disabled={this.props.showSpinner || this.state.showSpinner || this.state.couponList.length === 0}
                    >
                        Einlösen
                    </Button>}
                </div>
                <RedeemWarningDialog
                    invoicePaidPopupVisible={this.state.invoicePaidPopupVisible}
                    closeRedeemWarningPopup={this.closeRedeemWarningPopup}
                    invoiceIsPaid={this.state.invoiceIsPaid}
                    scannedCodeCount={this.state.scannedCodeCount}
                    couponValueLastCoupon={this.state.couponValueLastCoupon}
                    transactions={this.state.transactions}
                    couponList={this.state.couponList}
                />
                <Dialog
                    onClose={this.toggleDialog}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.openDialog}
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={this.toggleDialog}
                    >
                        Beispielgutschein
                    </DialogTitle>
                    <DialogContent dividers>
                        <img
                            className="dummyImageRedeem"
                            alt="dummy"
                            src={require("../../../img/KL-Gutschein-Rueckseite-Endkunde.png")}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={this.toggleDialog} color="secondary">
                            Verstanden
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(RedeemInsertCoupon);
