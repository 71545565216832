import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./RecruitingStatistics.scss"
import RecruitingFaqsPopup from "../RecruitingFaqsPopup/RecruitingFaqsPopup";
import { store } from "../../../store/store";
import { connect } from 'react-redux'
import { getRecruitingStatistics } from "../../../store/actions/retailerActions";
import { CircularProgress, Tooltip } from "@material-ui/core";

class RecruitingStatistics extends Component {
    constructor() {
        super();

        this.state = {
            popupOpen: false,
            recruitedPartnersNotActive: 0,
            potentialBonus: 0,
            recruitedPartnersActive: 0,
            paidBonus: 0,
            recruitedPartners: [],
            isLoading: false,
        };

        this.closePopup = this.closePopup.bind(this);
        this.openPopup = this.openPopup.bind(this);
    }

    componentDidMount() {
        this.props.setHeaderTitle("Meine geworbenen Partner");
        this.setState({ isLoading: true });
        store.dispatch(getRecruitingStatistics(this.props.retailer.informations.retailersId)).then((response) => {
            this.setState({
                recruitedPartnersNotActive: response.filter(objekt => objekt.status === 0).length,
                potentialBonus: response.filter(objekt => objekt.status === 0).length * 50,
                recruitedPartnersActive: response.filter(objekt => objekt.status === 1).length,
                paidBonus: response.filter(objekt => objekt.status === 1).length * 50,
                recruitedPartners: response,
                isLoading: false,
            })
        });
    }

    closePopup() {
        this.setState({ popupOpen: false });
    }

    openPopup() {
        this.setState({ popupOpen: true });
    }

    render() {
        return (
            <div className="recruitingStatisticsContainer">
                <div className="recruitingStatistics">
                    <div className="top-section">
                        <div className="box">
                            <div className="box-content">
                            <p className="box-text">Noch nicht aktive Partner</p>
                            <hr className="box-divider" />
                            {this.state.isLoading ? <CircularProgress size={40} style={{color:"#8d8d8d"}}/> : <span className="box-number">{this.state.recruitedPartnersNotActive}</span>}
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-content">
                            <p className="box-text">Potentielle Prämien</p>
                            <hr className="box-divider" />
                            {this.state.isLoading ? <CircularProgress size={40} style={{color:"#8d8d8d"}}/> : <span className="box-number">{this.state.potentialBonus} €</span>}
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-content">
                            <p className="box-text">Erfolgreich geworbene Partner</p>
                            <hr className="box-divider" />
                            {this.state.isLoading ? <CircularProgress size={40} style={{color:"#8d8d8d"}}/> : <span className="box-number">{this.state.recruitedPartnersActive}</span>}
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-content">
                            <p className="box-text">Ausgezahlte Prämien</p>
                            <hr className="box-divider" />
                            {this.state.isLoading ? <CircularProgress size={40} style={{color:"#8d8d8d"}}/> : <span className="box-number">{this.state.paidBonus} €</span>}
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Firmenname</th>
                                    <th>Registriert am</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.recruitedPartners.map((partner, index) => {
                                    const date = new Date(Number(partner.registrationDate));
                                    return (
                                        <tr key={index}>
                                            <td>{partner.name}</td>
                                            <td>{`${date.toLocaleDateString()} - ${date.toLocaleTimeString()} Uhr`}</td>
                                            <td>{partner.status === 0 ? "Partner hat sich registriert" : "Partner ist aktiv und deine Prämie wird ausgezahlt    "}{partner.status === 1 && (<Tooltip title="Auszahlung erfolgt mit der nächsten Abrechnung"><i className="kl-icon-Icon_Information" /></Tooltip>)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <p style={{ textDecoration: "underline", cursor: "pointer" }} onClick={this.openPopup}>Häufig gestellte Fragen & Teilnahmebedingungen</p>
                <RecruitingFaqsPopup open={this.state.popupOpen} closePopup={this.closePopup} openPopup={this.openPopup} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state
    return {
        user, retailer
    }
}

export default connect(mapStateToProps)(withRouter(RecruitingStatistics));
