import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Button, Box  } from "@material-ui/core";
import React, { Component } from "react";

export default class LockCardDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            errorText: "",
            showSpinner: false,
        };

        this.handleReset = this.handleReset.bind(this);
    }

    handleReset() {
        this.setState(
            {
                error: false,
                errorText: "",
                showSpinner: false,
            },
            this.props.handleCloseLockCardDialog
        );
    }

    render() {
        const { showSpinner } = this.state;

        return (
            <Dialog
                maxWidth="xs"
                open={this.props.openLockCardDialog}
                onClose={this.handleReset}
                aria-labelledby="form-dialog-title"
                className="CheckCoupon"
            >
                <DialogTitle id="form-dialog-title">
                    Karte  {this.props.employeeCard} {this.props.lock ? "sperren" : "entsperren"} 
                </DialogTitle>
                <DialogContent>
                    Soll die Karte {this.props.employeeCard} wirklich {this.props.lock ? "gesperrt" : "entsperrt"} werden?
                </DialogContent>
                <DialogActions
                    style={{
                        position: "relative",
                    }}
                >
                    <Button onClick={this.props.handleCloseLockCardDialog} color="default"  disabled={showSpinner}>
                        Nein
                    </Button>
                    <Box display="inline" position="relative">
                        <Button onClick={() => this.props.handleLockCard(this.props.employeeCard)} color="secondary" disabled={showSpinner}>
                        Ja
                        </Button>
                        {showSpinner && (
                            <CircularProgress
                                size=""
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: -12,
                                    marginLeft: -12,
                                    width: "24px",
                                }}
                            />
                        )}
                    </Box>
                </DialogActions>
            </Dialog>
        );
    }
}
