import { Checkbox, FormControlLabel, Grid, TextField, Typography } from "@material-ui/core";
import React, { Component } from "react";

export default class BillingAddress extends Component {
    constructor(props) {
        super(props);

        this.state = {
            billingName: "",
            BillingTown: "",
            billingStreet: "",
            setBillingAddress: this.props.visible,
        };
    }

    render() {
        return (
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="billingAddress"
                            checked={this.state.setBillingAddress}
                            onChange={() =>
                                this.setState({
                                    setBillingAddress: !this.state.setBillingAddress,
                                })
                            }
                            fullWidth
                        />
                    }
                    label="Rechnungsadresse weicht von Geschäftsadresse ab"
                />
                {this.state.setBillingAddress && (
                    <React.Fragment>
                        <Typography>Rechnungsadresse:</Typography>
                        <Grid item xs={12}>
                            <TextField
                                id="BillingName"
                                name="billingName"
                                label="Name"
                                value={this.props.values.billingName}
                                onChange={this.props.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="billingTown"
                                name="billingTown"
                                label="Stadt"
                                value={this.props.values.billingTown}
                                onChange={this.props.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="billingStreet"
                                name="billingStreet"
                                defaultValue={this.props.values.billingStreet}
                                onChange={this.props.handleChange}
                                label="Straße"
                                fullWidth
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
