import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CheckCouponDialog from "../Administration/CheckCoupon/CheckCouponDialog";
import "./Home.scss";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openCheckCoupon: false,
        };

        this.handleOpenCheckCoupon = this.handleOpenCheckCoupon.bind(this);
        this.handleCloseCheckCoupon = this.handleCloseCheckCoupon.bind(this);
    }

    handleOpenCheckCoupon() {
        this.setState({
            openCheckCoupon: true,
        });
    }

    handleCloseCheckCoupon() {
        this.setState({
            openCheckCoupon: false,
        });
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then((res) => {
                this.props.history.push("/administration");
            })
            .catch((err) => {
                this.props.history.push("/account");
            });
    }

    render() {
        return (
            <CheckCouponDialog
                openCheckCoupon={this.state.openCheckCoupon}
                handleCloseCheckCoupon={this.handleCloseCheckCoupon}
            />
        );
    }
}

function mapStateToProps(state) {
    const { retailer, user } = state;
    return {
        retailer,
        user,
    };
}

export default connect(mapStateToProps)(withRouter(Home));
