import {
    Box,
    Button,
    TextField,
    CircularProgress,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    Tooltip,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import * as Yup from "yup";
import React, { Component } from "react";
import EuroIcon from "@material-ui/icons/Euro";
import Alert from "@material-ui/lab/Alert";
import RedeemWarningDialog from "./RedeemWarningDialog";
import { store } from "../../../store/store";
import { testRedeemCoupon } from "../../../store/actions/retailerActions";
import { updateEmployeeSettings } from "../../../store/actions/userAction";

class RedeemForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            error: false,
            errorText: "",
            invoiceValue: this.props.invoiceValue,
            remainingValue: this.props.remainingValue,
            transactions: this.props.transactions,
            wasEnough: this.props.wasEnough,
            couponsValue: this.props.couponsValue,
            couponList: this.props.couponList,
            scannedCodeCount: this.props.scannedCodeCount,
            invoiceIsPaid: this.props.invoiceIsPaid,
            orderIndex: this.props.orderIndex,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeRedeemWarningPopup = this.closeRedeemWarningPopup.bind(this);
        this.onChangeQuickRedeemMode = this.onChangeQuickRedeemMode.bind(this);
        this.numberFormatCustom = this.numberFormatCustom.bind(this);

        this.formRef = React.createRef();
    }

    componentDidUpdate() {
        if (this.props.resetForm && this.formRef) {
            this.formRef.current.resetForm({ values: "" });
        }
    }

    closeRedeemWarningPopup() {
        this.setState({ invoicePaidPopupVisible: false });
        if (this.state.couponList.length > 0 && !this.props.user.quickRedeemModeEnabled) {
            this.props.handleShowConfirm(
                this.state.invoiceValue,
                this.state.couponsValue,
                this.state.remainingValue,
                this.state.wasEnough,
                this.state.couponList,
                this.state.transactions,
                this.state.scannedCodeCount
            );
        } else if (this.props.user.quickRedeemModeEnabled && this.state.invoiceIsPaid) {
            this.props.redeemCouponClick(this.state.couponList, this.state.invoiceValue);
        } else {
            this.props.setInvoiceValue(
                this.state.invoiceValue,
                this.state.couponsValue,
                this.state.wasEnough,
                this.state.couponList,
                this.state.transactions,
                this.state.scannedCodeCount,
                this.state.invoiceIsPaid,
                this.state.orderIndex
            );
        }
    }

    async handleSubmit(values) {
        this.setState({
            error: false,
            errorText: "",
        });
        this.setState({
            invoiceValue: values.invoiceValue,
            remainingValue: values.newInvoiceValue,
        });
        await this.redeemAllCouponsAfterInvoiceValueChanged(values.invoiceValue);
    }

    numberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator="."
                decimalSeparator=","
                isNumericString
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
            />
        );
    }

    async redeemAllCouponsAfterInvoiceValueChanged(invoiceValue) {
        var remainingInvoiceValue = invoiceValue;
        var newCouponsValue = 0;
        var newTransactions = [];
        var newCouponList = [];
        var newScannedCodeCount = 0;
        var newOrderIndex = 0;
        var notUsedCoupons = [];
        if (this.props.couponList.length > 0) {
            this.setState({
                showSpinner: true,
                errorText: "",
                error: false,
            });
            for (const coupon of this.props.couponList) {
                if (parseFloat(remainingInvoiceValue) <= 0) {
                    notUsedCoupons.push(coupon);
                    continue;
                }
                try {
                    const response = await store.dispatch(
                        testRedeemCoupon(
                            coupon.barcode,
                            remainingInvoiceValue,
                            this.props.retailer.informations.retailersId
                        )
                    );

                    if (response.success) {
                        newTransactions = newTransactions.concat(response.transactions);
                        newCouponList.push({
                            barcode: coupon.barcode,
                            value: response.couponValue,
                            orderIndex: newOrderIndex,
                        });
                        remainingInvoiceValue = response.remainingValue.toFixed(2);
                        newCouponsValue = newCouponsValue + Number(response.couponValue);
                        newScannedCodeCount = newScannedCodeCount + 1;
                        newOrderIndex = newOrderIndex + 1;
                        this.setState({
                            couponList: newCouponList,
                            couponsValue: newCouponsValue,
                            remainingValue: response.remainingValue.toFixed(2),
                            scannedCodeCount: newScannedCodeCount,
                            wasEnough: response.wasEnough,
                            transactions: newTransactions,
                            invoicePaidPopupVisible:
                                (!coupon.barcode.startsWith("3") && response.remainingValue <= 0) ||
                                response.transactions[0].isEmployeeCard,
                            invoiceIsPaid: !coupon.barcode.startsWith("3") && response.remainingValue <= 0,
                            couponValueLastCoupon: response.transactions[0].remainingValueCoupon,
                            orderIndex: newOrderIndex,
                        });
                    } else {
                        this.setState({
                            error: true,
                            errorText: response.message,
                        });
                    }
                } catch (err) {
                    console.error(err);
                    this.setState({
                        error: true,
                        errorText: "Fehler beim Prüfen der Gutscheine. Bitte versuche es erneut.",
                    });
                    break; // Wenn ein Fehler auftritt, beende die Schleife
                }
            }
        }
        if (this.state.invoicePaidPopupVisible || notUsedCoupons.length > 0 || this.state.couponValueLastCoupon > 0) {
            this.setState({
                notUsedCoupons: notUsedCoupons,
                invoicePaidPopupVisible:
                    this.state.invoicePaidPopupVisible ||
                    notUsedCoupons.length > 0 ||
                    this.state.couponValueLastCoupon > 0,
            });
        } else {
            this.props.setInvoiceValue(
                this.state.invoiceValue,
                this.state.couponsValue,
                this.state.wasEnough,
                this.state.couponList,
                this.state.transactions,
                this.state.scannedCodeCount,
                this.state.invoiceIsPaid,
                this.state.orderIndex
            );
        }
    }

    onChangeQuickRedeemMode() {
        store.dispatch(
            updateEmployeeSettings(
                this.props.user.employee.cognitoId,
                this.props.user.scanTutorialEnabled,
                !this.props.user.quickRedeemModeEnabled
            )
        );
    }

    render() {
        return (
            <div>
                <Formik
                    initialValues={{
                        invoiceValue: parseFloat(this.state.invoiceValue).toFixed(2),
                    }}
                    validationSchema={Yup.object().shape({
                        invoiceValue: Yup.number()
                            .min(0.01, "Bitte geben Sie einen Wert der größer 0,00 € ist ein")
                            .required("Dieses Feld wird benötigt"),
                    })}
                    onSubmit={(values) => {
                        this.handleSubmit(values);
                    }}
                    innerRef={this.formRef}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            {this.state.error && (
                                <Box mb={2}>
                                    <Alert severity="error">{this.state.errorText}</Alert>
                                </Box>
                            )}
                            <Box mb={2}>
                                <TextField
                                    disabled={this.props.showSpinner || this.state.showSpinner}
                                    name="invoiceValue"
                                    label="Zu zahlenden Betrag eingeben"
                                    size="small"
                                    autoFocus={true}
                                    variant="outlined"
                                    color="secondary"
                                    value={props.values.invoiceValue}
                                    InputProps={{
                                        inputComponent: this.numberFormatCustom,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon fontSize="small" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    className="invoiceValue"
                                    onChange={props.handleChange}
                                    fullWidth
                                    error={props.errors.invoiceValue && props.touched.invoiceValue}
                                    helperText={
                                        props.errors.invoiceValue && props.touched.invoiceValue
                                            ? props.errors.invoiceValue
                                            : ""
                                    }
                                />
                            </Box>
                            <Box display="flex" justifyContent="space-between" position="relative">
                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.props.user.quickRedeemModeEnabled}
                                                onChange={this.onChangeQuickRedeemMode}
                                            />
                                        }
                                        label="Zeitspar-Modus aktivieren"
                                    />
                                    <Tooltip style={{fontSize: 20}} title="Dieser Modus erspart Dir Zeit, wenn Du mehrere Gutscheinkarten einscannen möchtest."><i className="kl-icon-Icon_Information" /></Tooltip>
                                </div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    disabled={
                                        (!props.isValid && !props.dirty) ||
                                        this.props.showSpinner ||
                                        this.state.showSpinner
                                    }
                                >
                                    Weiter
                                </Button>
                                {(this.props.showSpinner || this.state.showSpinner) && (
                                    <CircularProgress
                                        size={24}
                                        style={{ color: "#8d8d8d" }}
                                        className="ButtonSpinner"
                                    />
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
                <RedeemWarningDialog
                    invoicePaidPopupVisible={this.state.invoicePaidPopupVisible}
                    closeRedeemWarningPopup={this.closeRedeemWarningPopup}
                    invoiceIsPaid={this.state.invoiceIsPaid}
                    scannedCodeCount={this.state.scannedCodeCount}
                    couponValueLastCoupon={this.state.couponValueLastCoupon}
                    notUsedCoupons={this.state.notUsedCoupons}
                    transactions={this.state.transactions}
                    couponList={this.state.couponList}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(RedeemForm);
