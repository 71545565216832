import { Box, AppBar, Tabs, Tab, Typography  } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import Orders from "../Orders/Orders";
import Billings from "../Billings/Billings";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

class Jobs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: "one",
            chargingsId: "",
            chargingNumber: 0
        };

        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.showChargings = this.showChargings.bind(this);
    }

    componentDidMount() {
        this.props.setHeaderTitle("Aufträge");
    }

    handleChangeTab(event, newValue) {
        this.setState({
            chargingsId: "",
            chargingNumber: 0,
            tab: newValue
        })
    }

    showChargings(chargingsId, chargingNumber) {
        this.setState({
            chargingsId: chargingsId,
            chargingNumber: chargingNumber,
            tab: "two"
        })
    }

    render() {
        return (
            <div id="Cards">
                <AppBar position="static" color="secondary">
                    <Tabs value={this.state.tab} onChange={this.handleChangeTab} variant="fullWidth">
                        <Tab value="one" label={"laufende Aufträge"} />
                        <Tab value="two" label={"Abrechnungen (" + (this.state.chargingsId === "" ? "alle)" : "Auftrag " + this.state.chargingNumber + ")")}  />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.tab} index="one">
                    <Orders showChargings={this.showChargings}/>
                </TabPanel>
                <TabPanel value={this.state.tab} index="two">
                    <Billings chargingsId={this.state.chargingsId}/>
                </TabPanel>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(withRouter(Jobs));
