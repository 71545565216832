import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
import { Grid, TextField, Button, CircularProgress, Typography, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import "./RequestChangePassword.scss";
import { Auth } from "aws-amplify";

export default class RequestChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            error: false,
            errorText: "",
        };
    }

    render() {
        return (
            <div className="RequestChangePassword">
                {this.state.error && (
                    <Alert style={{ marginBottom: "20px" }} severity="error">
                        {this.state.errorText}
                    </Alert>
                )}
                <Formik
                    initialValues={{
                        username: "",
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string()
                            .email("Keine korrekte E-Mail-Adresse")
                            .required("Dieses Feld wird benötigt"),
                    })}
                    onSubmit={(values) => {
                        this.setState({
                            showSpinner: true,
                            error: false,
                            errorText: "",
                        });
                        Auth.forgotPassword(values.username.trim().toLowerCase())
                            .then((data) => {
                                this.props.history.push("change-password", {
                                    username: values.username.trim().toLowerCase(),
                                });
                                this.setState({ showSpinner: false });
                            })
                            .catch((err) => {
                                switch (err.code) {
                                    case "UserNotFoundException":
                                        this.setState({
                                            error: true,
                                            errorText:
                                                "Für die von dir angegebene E-Mail Adresse wurde kein Benutzer gefunden",
                                        });
                                        break;
                                    case "LimitExceededException":
                                        this.setState({
                                            error: true,
                                            errorText:
                                                "Du hast zu oft versucht dein Passwort zurückzusetzen. Bitte versuche es später erneut",
                                        });
                                        break;
                                    case "TooManyRequestsException":
                                        this.setState({
                                            error: true,
                                            errorText:
                                                "Du hast zu oft versucht dein Passwort zurückzusetzen. Bitte versuche es später erneut",
                                        });
                                        break;
                                    case "InvalidParameterException":
                                        this.setState({
                                            error: true,
                                            errorText:
                                                "Du hast deine E-Mail Adresse noch nicht verifiziert. Du kannst daher dein Passwort noch nicht zurücksetzen",
                                        });
                                        break;
                                    case "UserNotConfirmedException":
                                        this.setState({
                                            error: true,
                                            errorText:
                                                "Du hast deine E-Mail Adresse noch nicht verifiziert. Du kannst daher dein Passwort noch nicht zurücksetzen",
                                        });
                                        break;
                                    default:
                                        this.setState({ error: true, errorText: "err.message" });
                                }
                                this.setState({ showSpinner: false });
                            });
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="username"
                                        name="username"
                                        label="E-Mail-Adresse"
                                        type="email"
                                        defaultValue={props.values.username}
                                        onChange={props.handleChange}
                                        error={props.errors.username ? true : false}
                                        helperText={props.errors.username}
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "right", marginTop: "20px", position: "relative" }}
                                >
                                    <Button
                                        fullWidth
                                        disabled={this.state.showSpinner}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        weiter
                                    </Button>
                                    {this.state.showSpinner && <CircularProgress size={24} className="Spinner" />}
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <Typography variant="body2">
                    Passwort wieder eingefallen?{" "}
                    <Link component={RouterLink} to="/account">
                        Login
                    </Link>
                </Typography>
                <Typography variant="body2">
                    Hast Du noch keinen Account?{" "}
                    <a
                        className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                        href="https://keeplocal.de/mitmachen/partner/registrierung/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Registrieren
                    </a>
                </Typography>
            </div>
        );
    }
}
