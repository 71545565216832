import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";

class ContactPersonForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setContactPerson: this.props.visible,
            contactName: "",
            contactPhone: "",
            contactMail: "",
        };
        this.handleSkip = this.handleSkip.bind(this);
    }

    updateContactPerson(values) {
        let newLocalData = {
            ...this.props.localData,
            contactPerson: {
                contactName: values.contactName === "" ? " " : values.contactName,
                contactPhone: values.contactPhone === "" ? " " : values.contactPhone,
                contactMail: values.contactMail === "" ? " " : values.contactMail,
            },
        };

        this.props.successCallback(newLocalData, this.props.townData, this.props.stateData);
    }

    handleSkip() {
        this.props.successCallback(this.props.localData, this.props.townData, this.props.stateData);
    }
    render() {
        return (
            <div>
                <Typography variant="h6">Ansprechpartner:</Typography>
                <Formik
                    initialValues={{
                        contactName: this.props.localData.contactPerson
                            ? this.props.localData.contactPerson.contactName
                            : "",
                        contactPhone: this.props.localData.contactPerson
                            ? this.props.localData.contactPerson.contactPhone
                            : "",
                        contactMail: this.props.localData.contactPerson
                            ? this.props.localData.contactPerson.contactMail
                            : "",
                    }}
                    onSubmit={(values) => {
                        this.setState({
                            error: false,
                            errorText: "",
                        });
                        this.updateContactPerson(values);
                    }}
                >
                    {(props) => (
                        <Form onBlur={this.props.handlesubmit}>
                            <Grid item xs={12}>
                                <TextField
                                    id="contactName"
                                    name="contactName"
                                    label="Name"
                                    value={props.values.contactName}
                                    onChange={props.handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="contactPhone"
                                    name="contactPhone"
                                    label="Telefonnummer"
                                    value={props.values.contactPhone}
                                    onChange={props.handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="contactMail"
                                    name="contactMail"
                                    value={props.values.contactMail}
                                    onChange={props.handleChange}
                                    label="E-Mail"
                                    fullWidth
                                />
                            </Grid>

                            <Grid className="NewStoreStepperButtons" container>
                                <Grid item xs={6} className="PrevButtons">
                                    <Button
                                        type="button"
                                        onClick={this.props.handlePrevStep}
                                        variant="text"
                                        color="secondary"
                                    >
                                        Zurück
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={this.props.handleCancel}
                                        variant="text"
                                        color="secondary"
                                    >
                                        Abbrechen
                                    </Button>
                                </Grid>
                                <Grid item xs={6} className="NextButtons">
                                    <Button
                                        disabled={this.state.showSpinner}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Speichern
                                    </Button>
                                    <Button
                                        onClick={this.handleSkip}
                                        disabled={this.state.showSpinner}
                                        type="button"
                                        variant="contained"
                                        color="default"
                                    >
                                        Überspringen
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, retailer } = state;
    return {
        user,
        retailer,
    };
}

export default connect(mapStateToProps)(ContactPersonForm);
